/* eslint-disable max-classes-per-file */
import hoistNonReactStatics from 'hoist-non-react-statics'
import { useRouter } from 'next/router'
import { destroyCookie, getTopDomain, parseCookies, setCookie } from '@upvestcz/common/cookies'
import * as R from 'ramda'
import React, { useCallback } from 'react'
import { useApolloClient } from '@apollo/client'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'
import {
    areRolesSufficient,
    Profile,
    RequiredRoles,
    normalizeProfile,
    destroyLoginCookies,
    setLoginCookies,
    AUTH0_CONNECTION_NAMES,
} from '@upvestcz/common/auth'
import {
    ACCOUNT_STATUS,
    hasAccountFinishedRegistration,
    PRIV_MEMBER_COOKIE_KEY,
} from '@upvestcz/common/account-utils'
import { getLocalePrefix } from '@upvestcz/common/i18n/get-locale-prefix'
import {
    useAddDistributionPartnerAccountMutation,
    useMarkAsAffiliateMutation,
    useMarkAsPrivMemberMutation,
} from '@upvestcz/common/graphql/mutations'
import { replace } from '@upvestcz/common/routing'
import { DISTRIBUTION_PARTNER_ACCOUNT_ID_COOKIE_KEY } from '@upvestcz/common/kb'
import { AFFILIATE_COOKIE_KEY, REFERRAL_CODE_COOKIE_KEY } from '@upvestcz/common/referrals'
import { getLastUnfilledRegistrationPageUrl } from '@upvestcz/common/registration'
import { addReferralCode, createAccount, fetchAccount, fetchMyAgreementSet } from './api'
import { useAccount } from '../store/account'
import { useAgreementSet } from '../store/agreementSet'
import { useAuth } from '../store/auth'
import { removeAccountLinkTokenFromLocalStorage } from './registration'
import { NextPageWithData } from './next'
import { useClearStore } from '../store'

export const requireAuth = (ProtectedComponent: TODO) => {
    const ComponentWithAuth: NextPageWithData = props => <ProtectedComponent {...props} />

    // We want to hoist statics like `getLayout`
    hoistNonReactStatics(ComponentWithAuth, ProtectedComponent)

    // It's important to put the `getInitialProps` static method
    // AFTER the `hoistNonReactStatics(...)` so that the implementation
    // below is not overridden by the `getInitialProps` static method
    // on the `ProtectedComponent` component
    ComponentWithAuth.getInitialProps = ctx => {
        if (!ctx.auth.loggedIn) {
            replace({
                ctx,
                location: '/',
                cookies: {
                    redirect_url: ctx.asPath as string,
                },
                forwardParams: false,
            })

            return {}
        }

        if (typeof ProtectedComponent.getInitialProps === 'function') {
            const pageProps = ProtectedComponent.getInitialProps(ctx)

            return pageProps
        }

        return {}
    }

    return ComponentWithAuth
}

export const requireNonAuth = (ProtectedComponent: TODO) => {
    const ComponentWithAuth: NextPageWithData<unknown> = props => <ProtectedComponent {...props} />

    // We want to hoist statics like `getLayout`
    hoistNonReactStatics(ComponentWithAuth, ProtectedComponent)

    // It's important to put the `getInitialProps` static method
    // AFTER the `hoistNonReactStatics(...)` so that the implementation
    // below is not overridden by the `getInitialProps` static method
    // on the `ProtectedComponent` component
    ComponentWithAuth.getInitialProps = ctx => {
        if (ctx.auth.loggedIn) {
            return replace({
                ctx,
                location: '/',
            })
        }

        if (typeof ProtectedComponent.getInitialProps === 'function') {
            const pageProps = ProtectedComponent.getInitialProps(ctx)

            return pageProps
        }

        return {}
    }

    return ComponentWithAuth
}

export const withPermissionCheck = (requiredRoles: RequiredRoles) => (ProtectedComponent: TODO) => {
    const WithPermissionCheck: NextPageWithData = props => {
        return <ProtectedComponent {...props} />
    }

    WithPermissionCheck.getInitialProps = ctx => {
        if (!ctx.auth.loggedIn) {
            replace({ ctx, location: '/' })

            return {}
        }

        if (!areRolesSufficient({ requiredRoles, profile: ctx.auth.profile })) {
            replace({ ctx, location: '/404' })

            return {}
        }

        if (typeof ProtectedComponent.getInitialProps === 'function') {
            const pageProps = ProtectedComponent.getInitialProps(ctx)

            return pageProps
        }

        return {}
    }

    return WithPermissionCheck
}

export const teaserWall = (ProtectedComponent: TODO) => {
    const ComponentWithAuth: NextPageWithData<Record<string, unknown>> = props => (
        <ProtectedComponent {...props} />
    )

    // We want to hoist statics like `getLayout`
    hoistNonReactStatics(ComponentWithAuth, ProtectedComponent)

    // It's important to put the `getInitialProps` static method
    // AFTER the `hoistNonReactStatics(...)` so that the implementation
    // below is not overridden by the `getInitialProps` static method
    // on the `ProtectedComponent` component
    ComponentWithAuth.getInitialProps = ctx => {
        // Redirect to teaser when user is not logged in and wants to see opportunity detail
        if (ctx.pathname.includes('opportunities')) {
            if (!ctx.auth.loggedIn) {
                return replace({
                    ctx,
                    location: `${getLocalePrefix(ctx.locale)}/teaser/${ctx.query.opp_id}`,
                })
            }
        }
        // Redirect to opportunity detail when user is logged in and wants to see teaser
        if (ctx.pathname.includes('teaser')) {
            if (ctx.auth.loggedIn) {
                return replace({
                    ctx,
                    location: `${getLocalePrefix(ctx.locale)}/user/opportunities/${
                        ctx.query.opp_id
                    }`,
                })
            }
        }

        if (typeof ProtectedComponent.getInitialProps === 'function') {
            const pageProps = ProtectedComponent.getInitialProps(ctx)

            return pageProps
        }

        return {}
    }
    return ComponentWithAuth
}

export const requireFinishedRegistration = (ProtectedComponent: TODO) => {
    const ComponentWithAuth: NextPageWithData = props => <ProtectedComponent {...props} />

    // We want to hoist statics like `getLayout`
    hoistNonReactStatics(ComponentWithAuth, ProtectedComponent)

    // It's important to put the `getInitialProps` static method
    // AFTER the `hoistNonReactStatics(...)` so that the implementation
    // below is not overridden by the `getInitialProps` static method
    // on the `ProtectedComponent` component
    ComponentWithAuth.getInitialProps = ctx => {
        const { data } = ctx

        // Pending approval get sent to profile, not created accounts get redirected to registration
        if (!data.account || !hasAccountFinishedRegistration(data.account)) {
            return replace({ location: '/user/registration', ctx })
        }

        if (
            data.account.status === ACCOUNT_STATUS.PENDING &&
            !ctx.pathname.includes('/user/profile')
        ) {
            return replace({ location: '/user/profile', ctx })
        }

        if (typeof ProtectedComponent.getInitialProps === 'function') {
            const pageProps = ProtectedComponent.getInitialProps(ctx)

            return pageProps
        }

        return {}
    }

    return ComponentWithAuth
}

export const requireUnfinishedRegistration = (ProtectedComponent: TODO) => {
    const ComponentWithAuth: NextPageWithData<unknown> = props => <ProtectedComponent {...props} />

    // We want to hoist statics like `getLayout`
    hoistNonReactStatics(ComponentWithAuth, ProtectedComponent)

    // It's important to put the `getInitialProps` static method
    // AFTER the `hoistNonReactStatics(...)` so that the implementation
    // below is not overridden by the `getInitialProps` static method
    // on the `ProtectedComponent` component
    ComponentWithAuth.getInitialProps = ctx => {
        const { auth, data } = ctx

        if (auth.loggedIn) {
            // if the user has finished registration, redirect them to opportunities. If they're pending or suspended, redirect them to profile.
            if (data.account && hasAccountFinishedRegistration(data.account)) {
                let location = '/user/opportunities'
                if (data.account.status === ACCOUNT_STATUS.PENDING) location = '/user/profile'
                if (data.account.status === ACCOUNT_STATUS.SUSPENDED) location = '/user/profile'

                return replace({ ctx, location })
            }
        }

        if (typeof ProtectedComponent.getInitialProps === 'function') {
            const pageProps = ProtectedComponent.getInitialProps(ctx)

            return pageProps
        }

        return {}
    }

    return ComponentWithAuth
}

export function useLogout() {
    const router = useRouter()
    const clearStore = useClearStore()

    return useCallback(
        async ({ returnTo = '/' }: { returnTo?: string } = {}) => {
            destroyLoginCookies()

            const prevUrl = window.location.href
            const { route: prevRoute } = router // to allow logout from failed login
            await router.push(returnTo)

            // although we `await` the `route.push()`, if we put clearStore()
            // call right after it, it will be called before the route
            // actually changes and will cause an error on some pages
            // as the page will depend upon state which will be no longer
            // present (after clearStore())
            // it is not the nicest solution but I couldn't find anything regarding
            // this issue online
            const clearStoreInterval = setInterval(() => {
                if (prevUrl !== window.location.href || prevRoute === '/login') {
                    clearStore()
                    clearInterval(clearStoreInterval)
                }
            }, 100)
        },
        [clearStore, router],
    )
}

export const useAfterAuth = () => {
    const [, dispatchAccount] = useAccount()
    const [, dispatchAgreementSet] = useAgreementSet()
    const [, dispatchAuth] = useAuth()
    const router = useRouter()
    const [markAsAffiliate] = useMarkAsAffiliateMutation()
    const [addDistributionPartnerAccount] = useAddDistributionPartnerAccountMutation()
    const [markAsPrivMember] = useMarkAsPrivMemberMutation()
    const logout = useLogout()
    const client = useApolloClient()

    const cookies = parseCookies()

    const processCookieEffects = useCallback(
        (effects: Record<string, () => Promise<void>>) => {
            // get values from signupCookieEffects which keys exist in cookies
            const cookieEffectFunctions = Object.entries(effects).reduce(
                (acc: Array<() => Promise<void>>, [key, fn]) => {
                    if (cookies[key]) {
                        return [...acc, fn]
                    }
                    return acc
                },
                [],
            )

            // run all applicable signupCookieEffectFunctions
            return Promise.all(cookieEffectFunctions.map(fn => fn()))
        },
        [cookies],
    )

    return async ({
        profile,
        token,
        isSignUp = false,
    }: {
        profile: Profile
        token: { id_token: string; access_token: string }
        isSignUp?: boolean
    }) => {
        removeAccountLinkTokenFromLocalStorage()
        const normalizedProfile = normalizeProfile(profile)
        setLoginCookies({
            id_token: token.id_token,
            access_token: token.access_token,
            profile: JSON.stringify(normalizedProfile),
        })
        setCookie({}, 'isRegistered', 'true', {
            domain: getTopDomain(),
            maxAge: 100 * 365 * 24 * 60 * 60, // 100 yrs
        })

        /*
        clear any previous login data that might hav enot been cleared properly.
        prevents issues with "error upserting account..." like https://upvest-by.sentry.io/issues/3868058678/events/a289d1968b274c9f8401c1f525ddd30f/
        This is a hotfix and ultimately should be consistently done on logout.
         */
        await dispatchAccount({ type: 'clear' })
        await client.resetStore()

        await dispatchAuth({
            type: 'set',
            payload: {
                loggedIn: true,
                profile: normalizedProfile,
                token: token.id_token,
            },
        })

        if (isSignUp) {
            // @ts-ignore (expected 2 arguments instead of 1)
            ReactPixel.track('Lead')
            // Trigger GA event
            ReactGA.event('sign_up', {
                method: AUTH0_CONNECTION_NAMES[profile['http://upvest/identities'][0]],
            })
            ReactGA.event('tutorial_begin', {
                tutorial_name: 'registration',
            })

            const signupCookieEffects = {
                [REFERRAL_CODE_COOKIE_KEY]: async () => {
                    await addReferralCode({ referralCode: cookies[REFERRAL_CODE_COOKIE_KEY] })
                    destroyCookie({}, REFERRAL_CODE_COOKIE_KEY)
                },
                [AFFILIATE_COOKIE_KEY]: async () => {
                    await markAsAffiliate({
                        variables: {
                            affiliateKey: cookies[AFFILIATE_COOKIE_KEY],
                        },
                    })

                    destroyCookie({}, AFFILIATE_COOKIE_KEY)
                },
                [DISTRIBUTION_PARTNER_ACCOUNT_ID_COOKIE_KEY]: async () => {
                    await addDistributionPartnerAccount({
                        variables: {
                            distributionPartnerAccountId:
                                cookies[DISTRIBUTION_PARTNER_ACCOUNT_ID_COOKIE_KEY],
                        },
                    })

                    destroyCookie({}, DISTRIBUTION_PARTNER_ACCOUNT_ID_COOKIE_KEY)
                },
            }

            await processCookieEffects(signupCookieEffects)
        }

        const loginCookieEffects = {
            [PRIV_MEMBER_COOKIE_KEY]: async () => {
                await markAsPrivMember({
                    variables: {
                        privOpportunityID: parseInt(cookies[PRIV_MEMBER_COOKIE_KEY], 10),
                    },
                })

                destroyCookie({}, PRIV_MEMBER_COOKIE_KEY, {
                    domain: getTopDomain(),
                })
            },
        }

        await processCookieEffects(loginCookieEffects)

        try {
            // fetch account after it was potentially created in the if(isSignup) block
            const [account, agreementSet] = await (
                Promise.all([
                    fetchAccount(normalizedProfile.auth_id).catch(err => {
                        // If user hasn't got an account record in the DB
                        // (but *does* have got an Auth0 profile), create it
                        // as the whole app assumes that every user has got an account
                        if (err.status === 404) {
                            return createAccount(profile.sub!, { email: profile.email })
                        }

                        throw err
                    }),
                    fetchMyAgreementSet(),
                ]) as TODO
            ).then(R.map(R.prop('data')))
            // wait for dispatches to complete
            await Promise.all([
                dispatchAccount({ type: 'set', payload: account }),
                dispatchAgreementSet({ type: 'set', payload: agreementSet }),
            ])

            if (cookies.redirect_url) {
                destroyCookie({}, 'redirect_url')
                router.push(cookies.redirect_url)
            }
            // user has not got the investment account yet,
            // redirect them to the page where they can create it
            else if (!account) {
                router.push('/signup/2')
            } else if (!hasAccountFinishedRegistration(account)) {
                const url = await getLastUnfilledRegistrationPageUrl(account)

                router.push(url)
            } else {
                router.push('/user/opportunities')
            }
        } catch (err) {
            await logout() // abandon logging in because of error fetching critical info
            throw err
        }
    }
}
