import React from 'react'
import { IconProps, useAccordionContext, useAccordionItemState } from '@chakra-ui/react'
import { Dict } from '@chakra-ui/utils'
import * as icons from '../icons'

export const AccordionPlusIcon: React.FC<IconProps> = props => {
    const { reduceMotion } = useAccordionContext()
    const { isDisabled, isOpen } = useAccordionItemState()

    const iconStyles: Dict = {
        opacity: isDisabled ? 0.4 : 1,
        sx: {
            path: {
                ':nth-of-type(2)': {
                    transform: isOpen ? 'rotate(-90deg)' : undefined,
                    transition: reduceMotion ? undefined : 'transform 0.2s',
                    transformOrigin: 'center',
                },
            },
        },
    }

    return <icons.PlusIcon height="1.33333em" color="primary" {...iconStyles} {...props} />
}
