import { useMutation, gql, MutationHookOptions } from '@apollo/client'
import { AuthResultFields, ProfileFields, AuthTokenFields } from './fragments'
import {
    CreateEmailSubscriberMutation,
    CreateEmailSubscriberMutationVariables,
    CreateREviewEmailSubscriberMutation,
    CreateREviewEmailSubscriberMutationVariables,
    MarkAsAffiliateMutation,
    MarkAsAffiliateMutationVariables,
    RemoveEmailSubscriberMutation,
    RemoveEmailSubscriberMutationVariables,
    RemoveREviewEmailSubscriberMutation,
    RemoveREviewEmailSubscriberMutationVariables,
    AddDistributionPartnerAccountIdMutation,
    AddDistributionPartnerAccountIdMutationVariables,
    HandleLoginCallbackMutation,
    HandleLoginCallbackMutationVariables,
    HandleSignupCallbackMutation,
    HandleSignupCallbackMutationVariables,
    HandleLinkAccountsCallbackMutation,
    HandleLinkAccountsCallbackMutationVariables,
    MarkAsPrivMemberMutation,
    MarkAsPrivMemberMutationVariables,
} from './typegen'

export const useMarkAsAffiliateMutation = (
    options?: MutationHookOptions<MarkAsAffiliateMutation, MarkAsAffiliateMutationVariables>,
) => {
    return useMutation<MarkAsAffiliateMutation, MarkAsAffiliateMutationVariables>(
        gql`
            mutation MarkAsAffiliate($affiliateKey: String!) {
                markAsAffiliate(affiliateKey: $affiliateKey) {
                    isSuccess
                }
            }
        `,
        options,
    )
}

export const useMarkAsPrivMemberMutation = (
    options?: MutationHookOptions<MarkAsPrivMemberMutation, MarkAsPrivMemberMutationVariables>,
) => {
    return useMutation<MarkAsPrivMemberMutation, MarkAsPrivMemberMutationVariables>(
        gql`
            mutation MarkAsPrivMember($privOpportunityID: Int!) {
                markAsPrivMember(privOpportunityID: $privOpportunityID) {
                    isSuccess
                }
            }
        `,
        options,
    )
}

export const useCreateEmailSubscriberMutation = (
    options?: MutationHookOptions<
        CreateEmailSubscriberMutation,
        CreateEmailSubscriberMutationVariables
    >,
) => {
    return useMutation<CreateEmailSubscriberMutation, CreateEmailSubscriberMutationVariables>(
        gql`
            mutation CreateEmailSubscriber($email: String!) {
                createEmailSubscriber(email: $email) {
                    isSuccess
                }
            }
        `,
        options,
    )
}

export const useRemoveEmailSubscriberMutation = (
    options?: MutationHookOptions<
        RemoveEmailSubscriberMutation,
        RemoveEmailSubscriberMutationVariables
    >,
) => {
    return useMutation<RemoveEmailSubscriberMutation, RemoveEmailSubscriberMutationVariables>(
        gql`
            mutation RemoveEmailSubscriber {
                removeEmailSubscriber {
                    isSuccess
                }
            }
        `,
        options,
    )
}

export const useCreateREviewEmailSubscriberMutation = (
    options?: MutationHookOptions<
        CreateREviewEmailSubscriberMutation,
        CreateREviewEmailSubscriberMutationVariables
    >,
) => {
    return useMutation<
        CreateREviewEmailSubscriberMutation,
        CreateREviewEmailSubscriberMutationVariables
    >(
        gql`
            mutation CreateREviewEmailSubscriber($email: String!) {
                createREviewEmailSubscriber(email: $email) {
                    isSuccess
                }
            }
        `,
        options,
    )
}

export const useRemoveREviewEmailSubscriberMutation = (
    options?: MutationHookOptions<
        RemoveREviewEmailSubscriberMutation,
        RemoveREviewEmailSubscriberMutationVariables
    >,
) => {
    return useMutation<
        RemoveREviewEmailSubscriberMutation,
        RemoveREviewEmailSubscriberMutationVariables
    >(
        gql`
            mutation RemoveREviewEmailSubscriber {
                removeREviewEmailSubscriber {
                    isSuccess
                }
            }
        `,
        options,
    )
}

export const useAddDistributionPartnerAccountMutation = (
    options?: MutationHookOptions<
        AddDistributionPartnerAccountIdMutation,
        AddDistributionPartnerAccountIdMutationVariables
    >,
) => {
    return useMutation<
        AddDistributionPartnerAccountIdMutation,
        AddDistributionPartnerAccountIdMutationVariables
    >(
        gql`
            mutation AddDistributionPartnerAccountId($distributionPartnerAccountId: String!) {
                addDistributionPartnerAccount(
                    distributionPartnerAccountId: $distributionPartnerAccountId
                ) {
                    isSuccess
                }
            }
        `,
        options,
    )
}

export const useHandleLoginCallbackMutation = (
    options?: MutationHookOptions<
        HandleLoginCallbackMutation,
        HandleLoginCallbackMutationVariables
    >,
) => {
    return useMutation<HandleLoginCallbackMutation, HandleLoginCallbackMutationVariables>(
        gql`
            mutation HandleLoginCallback($code: String!) {
                handleLoginCallback(code: $code) {
                    ...AuthResultFields
                }
            }
            ${AuthResultFields}
        `,
        options,
    )
}

export const useHandleSignupCallbackMutation = (
    options?: MutationHookOptions<
        HandleSignupCallbackMutation,
        HandleSignupCallbackMutationVariables
    >,
) => {
    return useMutation<HandleSignupCallbackMutation, HandleSignupCallbackMutationVariables>(
        gql`
            mutation HandleSignupCallback(
                $code: String!
                $accountToken: String
                $is_corporate: Boolean
            ) {
                handleSignupCallback(
                    code: $code
                    accountToken: $accountToken
                    is_corporate: $is_corporate
                ) {
                    profile {
                        ...ProfileFields
                    }
                    token {
                        ...AuthTokenFields
                    }
                    bankAccountOptions
                }
            }
            ${ProfileFields}
            ${AuthTokenFields}
        `,
        options,
    )
}

export const useHandleLinkAccountsCallbackMutation = (
    options?: MutationHookOptions<
        HandleLinkAccountsCallbackMutation,
        HandleLinkAccountsCallbackMutationVariables
    >,
) => {
    return useMutation<
        HandleLinkAccountsCallbackMutation,
        HandleLinkAccountsCallbackMutationVariables
    >(
        gql`
            mutation HandleLinkAccountsCallback($code: String!, $accountLinkToken: String!) {
                handleLinkAccountsCallback(code: $code, accountLinkToken: $accountLinkToken) {
                    ...AuthResultFields
                }
            }
            ${AuthResultFields}
        `,
        options,
    )
}
