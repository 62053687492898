export const AFFILIATE_COOKIE_KEY = 'affiliate'
export const REFERRAL_CODE_COOKIE_KEY = 'referralCode'

export const REFERRAL_TYPES = {
    CODE: 'CODE',
    AFFILIATE: 'AFFILIATE',
    KB_EMPLOYEE: 'KB_EMPLOYEE',
} as const

export const REFERRAL_PERKS = {
    BONUS: 'BONUS',
    INVESTMENT_CLUB: 'INVESTMENT_CLUB',
    TOP_RATE: 'TOP_RATE',
} as const

type REFERRAL_TYPES_Keys = keyof typeof REFERRAL_TYPES
type REFERRAL_PERKS_Keys = keyof typeof REFERRAL_PERKS

export type ReferralTypesEnum = typeof REFERRAL_TYPES[REFERRAL_TYPES_Keys]
export type ReferralPerksEnum = typeof REFERRAL_PERKS[REFERRAL_PERKS_Keys]

export type AffilatesKey = 'E15' | 'REALITNI_SHAKER' | 'ROZBITE_PRASATKO'

export interface AffiliateDefinition {
    key: AffilatesKey
    type: ReferralPerksEnum
    partner_id: number
    account_id: number
    name: string
}

export const AFFILIATES: Array<AffiliateDefinition> = [
    {
        key: 'E15',
        type: REFERRAL_PERKS.INVESTMENT_CLUB,
        name: 'E15 Premium', // make this come from the DB?
        partner_id: 1,
        account_id: 1,
    },
    {
        key: 'REALITNI_SHAKER',
        type: REFERRAL_PERKS.INVESTMENT_CLUB,
        name: 'Realitní shaker',
        partner_id: 2,
        account_id: 2,
    },
    {
        key: 'ROZBITE_PRASATKO',
        type: REFERRAL_PERKS.INVESTMENT_CLUB,
        name: 'Rozbité prasátko',
        partner_id: 4,
        account_id: 1399,
    },
]

export const NON_KB_AFFILIATES_ACCOUNTS = AFFILIATES.map(({ account_id }) => account_id)
