import moment from 'moment'
import { ObjectValues } from './ts-utils'
import { COUNTRIES, countries, CountryCode } from './countries'
import { Currency } from './currency'
import { Locale, LOCALES } from './i18n/locales'
import { parseIBAN, BankAccount } from './bank-utils'

export type TransactionHistoryItem = {
    amount: number
    currency: Currency
    type: 'transaction' | 'payment' | 'investment'
    createdAt: Date
    note: string
    meta?: {
        opportunityId?: number
        transactionId?: number
        interestRate?: number
    }
}

export type TransactionHistory = Array<TransactionHistoryItem>

export type Note = {
    author: {
        name: string
        id: number
    }
    payload: {
        text: string
        url: string
        filename: string
    }
    createdAt: string
}

export type TaxResidency = {
    country: CountryCode
    hasVatNumber: boolean
    noVatNumberReason: Maybe<NotVatNumberReason>
    vatNumber: Maybe<string>
}

export const ACCOUNT_STATUS = {
    NOT_APPROVED: 'not_approved',
    PENDING: 'pending_approval',
    APPROVED: 'approved',
    REJECTED: 'rejected',
    DELETED: 'deleted',
    SUSPENDED: 'suspended',
} as const

export const ACCOUNT_STATUS_LABELS = {
    [ACCOUNT_STATUS.NOT_APPROVED]: 'Not approved',
    [ACCOUNT_STATUS.PENDING]: 'Pending approval',
    [ACCOUNT_STATUS.APPROVED]: 'Approved',
    [ACCOUNT_STATUS.REJECTED]: 'Rejected',
    [ACCOUNT_STATUS.DELETED]: 'Deleted',
    [ACCOUNT_STATUS.SUSPENDED]: 'Suspended',
} as const

export type AccountStatus = ObjectValues<typeof ACCOUNT_STATUS>

export const ACCOUNT_RISK = {
    LOW: 'low',
    MEDIUM_LOW: 'medium_low',
    MEDIUM_HIGH: 'medium_high',
    HIGH: 'high',
} as const

export type AccountRisk = ObjectValues<typeof ACCOUNT_RISK>

export const ACCOUNT_RISK_LABELS = {
    [ACCOUNT_RISK.LOW]: 'Low',
    [ACCOUNT_RISK.MEDIUM_LOW]: 'Medium low',
    [ACCOUNT_RISK.MEDIUM_HIGH]: 'Medium high',
    [ACCOUNT_RISK.HIGH]: 'High',
}

export const ACCOUNT_ADDITIONAL_RISK = {
    LOW: 'low',
    MEDIUM_LOW: 'medium_low',
    MEDIUM_HIGH: 'medium_high',
    HIGH: 'high',
} as const

export type AccountAdditionalRisk = ObjectValues<typeof ACCOUNT_RISK>

export const ACCOUNT_ADDITIONAL_RISK_LABELS = {
    [ACCOUNT_ADDITIONAL_RISK.LOW]: 'Low',
    [ACCOUNT_ADDITIONAL_RISK.MEDIUM_LOW]: 'Medium low',
    [ACCOUNT_ADDITIONAL_RISK.MEDIUM_HIGH]: 'Medium high',
    [ACCOUNT_ADDITIONAL_RISK.HIGH]: 'High',
}

export const ACCOUNT_LEGAL_FORM = {
    ASSOCIATION: 'association',
    POLITICAL_PARTY: 'political_party',
    FOUNDATION: 'foundation',
    FOUNDATION_FUND: 'foundation_fund',
    TRUST_FUND: 'trust_fund',
} as const

export type AccountLegalForm = ObjectValues<typeof ACCOUNT_LEGAL_FORM>

export const ACCOUNT_LEGAL_FORM_LABELS_CZ = {
    [ACCOUNT_LEGAL_FORM.ASSOCIATION]: 'Asociace',
    [ACCOUNT_LEGAL_FORM.FOUNDATION]: 'Nadace',
    [ACCOUNT_LEGAL_FORM.FOUNDATION_FUND]: 'Nadační fond',
    [ACCOUNT_LEGAL_FORM.POLITICAL_PARTY]: 'Politická strana',
    [ACCOUNT_LEGAL_FORM.TRUST_FUND]: 'Svěřenský fond',
}

export const VERIFICATION_PROVIDERS = {
    UPVEST: 'UPVEST',
    BAPO: 'BAPO',
    BANKID: 'BANKID',
} as const

export type VerificationProvider = ObjectValues<typeof VERIFICATION_PROVIDERS>

export const DOCUMENT_TYPES = {
    NATIONAL_ID: 'ID',
    PASSPORT: 'P',
    DRIVING_LICENSE: 'DL',
    RESIDENCE_PERMIT: 'IR',
    VISA_PERMIT_LABEL: 'VS',
    RESIDENTIAL_LABEL: 'PS',
    BOOK_WITH_RESIDENCE_PERMIT: 'IX',
    FORM_WITH_TEMPORARY_RESIDENCE: 'IE',
    GUN_LICENSE: 'GL',
    UNKNOWN: 'UNKNOWN',
} as const

export type IDDocumentType = ObjectValues<typeof DOCUMENT_TYPES>

export const DOCUMENT_LABELS = {
    [DOCUMENT_TYPES.NATIONAL_ID]: 'ID',
    [DOCUMENT_TYPES.PASSPORT]: 'Passport',
    [DOCUMENT_TYPES.DRIVING_LICENSE]: 'Driving license',
    [DOCUMENT_TYPES.RESIDENCE_PERMIT]: 'Redsidence permit',
    [DOCUMENT_TYPES.VISA_PERMIT_LABEL]: 'Visa permit label',
    [DOCUMENT_TYPES.RESIDENTIAL_LABEL]: 'Residential permit label',
    [DOCUMENT_TYPES.BOOK_WITH_RESIDENCE_PERMIT]: 'Book with residence permit',
    [DOCUMENT_TYPES.FORM_WITH_TEMPORARY_RESIDENCE]: 'Form with temporary residence',
    [DOCUMENT_TYPES.GUN_LICENSE]: 'Gun license',
    [DOCUMENT_TYPES.UNKNOWN]: 'Unknown',
} as const

export const DOCUMENT_LABELS_CZ = {
    [DOCUMENT_TYPES.NATIONAL_ID]: 'Občanský průkaz',
    [DOCUMENT_TYPES.PASSPORT]: 'Pas',
    [DOCUMENT_TYPES.DRIVING_LICENSE]: 'Řidičský průkaz',
    [DOCUMENT_TYPES.RESIDENCE_PERMIT]: 'Povolení k pobytu',
    [DOCUMENT_TYPES.VISA_PERMIT_LABEL]: 'Vízum',
    [DOCUMENT_TYPES.RESIDENTIAL_LABEL]: 'Vízový štítek',
    [DOCUMENT_TYPES.BOOK_WITH_RESIDENCE_PERMIT]:
        'Povolení k pobytu bez strojově čitelné zóny ve formě knížečky',
    [DOCUMENT_TYPES.FORM_WITH_TEMPORARY_RESIDENCE]:
        'Povolení k pobytu bez strojově čitelné zóny ve formě formuláře',
    [DOCUMENT_TYPES.GUN_LICENSE]: 'Zbrojní průkaz',
    [DOCUMENT_TYPES.UNKNOWN]: 'Neznámý',
} as const

export const DOCUMENT_CHECK_TYPES = {
    [DOCUMENT_TYPES.NATIONAL_ID]: 0,
    [DOCUMENT_TYPES.PASSPORT]: 4,
    [DOCUMENT_TYPES.GUN_LICENSE]: 6,
} as const

export const AZURE_DOCUMENT_TYPES = {
    PASSPORT: 'czechAllCards:Passport',
    DRIVING_LICENSE: 'czechAllCards:czechDrivingLicense',
    ID_FRONT: 'czechAllCards:czechIDCards',
    ID_BACK: 'czechAllCards:czechIDBack',
}

export const DOCUMENT_TYPES_PRTIMP = {
    [DOCUMENT_TYPES.NATIONAL_ID]: 1,
    [DOCUMENT_TYPES.PASSPORT]: 2,
    [DOCUMENT_TYPES.DRIVING_LICENSE]: 3,
    [DOCUMENT_TYPES.RESIDENCE_PERMIT]: 4,
    [DOCUMENT_TYPES.VISA_PERMIT_LABEL]: 5,
    [DOCUMENT_TYPES.RESIDENTIAL_LABEL]: 6,
    [DOCUMENT_TYPES.BOOK_WITH_RESIDENCE_PERMIT]: 7,
    [DOCUMENT_TYPES.FORM_WITH_TEMPORARY_RESIDENCE]: 8,
    [DOCUMENT_TYPES.GUN_LICENSE]: 9,
    [DOCUMENT_TYPES.UNKNOWN]: 0,
} as const

export const ACCOUNT_SUSPENSION_REASONS = {
    MISSING_ID_DOCUMENTS: 'missing_id_documents',
    EXPIRED_ID_DOCUMENTS: 'expired_id_documents',
    UNCHECKED_PEP: 'unchecked_PEP',
    OTHER: 'other',
} as const

export type AccountSuspensionReasons = ObjectValues<typeof ACCOUNT_SUSPENSION_REASONS>

export const SUSPENSION_REASON_LABELS = {
    [LOCALES.CS]: {
        [ACCOUNT_SUSPENSION_REASONS.MISSING_ID_DOCUMENTS]: 'Chybějící doklad totožnosti',
        [ACCOUNT_SUSPENSION_REASONS.EXPIRED_ID_DOCUMENTS]: 'Neplatný doklad totožnosti',
        [ACCOUNT_SUSPENSION_REASONS.UNCHECKED_PEP]: 'Neprověřený status politicky exponované osoby',
        [ACCOUNT_SUSPENSION_REASONS.OTHER]: 'Jiné důvody řešitelné s podporou Upvestu',
    },
    [LOCALES.EN]: {
        [ACCOUNT_SUSPENSION_REASONS.MISSING_ID_DOCUMENTS]: 'Missing verification document',
        [ACCOUNT_SUSPENSION_REASONS.EXPIRED_ID_DOCUMENTS]: 'Expired verification document',
        [ACCOUNT_SUSPENSION_REASONS.UNCHECKED_PEP]:
            'Unverified status of a Politically Exposed Person',
        [ACCOUNT_SUSPENSION_REASONS.OTHER]: 'Other reasons to be solved with Upvest support',
    },
} as const

export const ACCOUNT_LOG_ACTION_TYPE = Object.freeze({
    ID_RECOGNIZER_DATA_HANDOFF: 'id_recognizer_data_handoff',
    BANK_ID_DATA_HANDOFF: 'bank_id_data_handoff',
    INVESTMENT_ORDER: 'investment_order',
    ACCOUNT_AML_REVIEW: 'account_AML_review',
    SMS_MESSAGE_SENT: 'sms_message_sent',
    NEW_PRIMARY_DOCUMENT_UPLOADED: 'new_primary_document_uploaded',
} as const)

export const NO_VAT_NUMBER_REASONS = {
    NOT_NEEDED_FOR_JURISDICTION: 'not_needed_for_jurisdiction',
    COUNTRY_DOES_NOT_ISSUE_VAT_NUMBERS: 'country_does_not_issue_vat_numbers',
    NOT_ABLE_TO_OBTAIN: 'not_able_to_obtain',
} as const

export const NO_VAT_NUMBER_REASON_LABELS = {
    [LOCALES.CS]: {
        [NO_VAT_NUMBER_REASONS.NOT_NEEDED_FOR_JURISDICTION]: 'Není potřeba pro danou jurisdikci',
        [NO_VAT_NUMBER_REASONS.COUNTRY_DOES_NOT_ISSUE_VAT_NUMBERS]: 'Země nevydává DIČ',
        [NO_VAT_NUMBER_REASONS.NOT_ABLE_TO_OBTAIN]: 'Není možné získat',
    },
    [LOCALES.EN]: {
        [NO_VAT_NUMBER_REASONS.NOT_NEEDED_FOR_JURISDICTION]: 'Not needed for jurisdiction',
        [NO_VAT_NUMBER_REASONS.COUNTRY_DOES_NOT_ISSUE_VAT_NUMBERS]:
            'Country does not issue VAT numbers',
        [NO_VAT_NUMBER_REASONS.NOT_ABLE_TO_OBTAIN]: 'Not able to obtain',
    },
} as const

export type NotVatNumberReason = ObjectValues<typeof NO_VAT_NUMBER_REASONS>

export const CZECH_RESIDENCY_PLACEHOLDER = {
    country: COUNTRIES.CZ,
    hasVatNumber: false,
    vatNumber: '',
    noVatNumberReason: NO_VAT_NUMBER_REASONS.COUNTRY_DOES_NOT_ISSUE_VAT_NUMBERS,
}

export type AccountLogActionType = ObjectValues<typeof ACCOUNT_LOG_ACTION_TYPE>

export const getOpportunityTypeLabel = (
    reason: AccountSuspensionReasons,
    locale: Locale = LOCALES.CS,
) => {
    return SUSPENSION_REASON_LABELS[locale][reason]
}

export const PRIV_MEMBER_COOKIE_KEY = 'priv'

export const getDisplayName = (account: {
    is_corporate: boolean
    name?: string | null
    surname?: string | null
    corporate_name?: string | null
}) =>
    account.is_corporate
        ? account.corporate_name
        : account.name && account.surname
        ? `${account.name} ${account.surname}`
        : undefined

export const getPhoneNumber = ({
    phone,
    phone_country_code,
}: {
    phone: string | null
    phone_country_code: Maybe<number>
}): string | null => {
    if (!phone) {
        return null
    }
    // if the number consists of 9 digits, format it like a Czech number
    const formattedPhone =
        phone.length === 9
            ? `${phone.slice(0, 3)}\u00a0${phone.slice(3, 6)}\u00a0${phone.slice(6, 9)}`
            : phone

    return `${phone_country_code ? `+${phone_country_code}\u00a0` : ''}${formattedPhone}`
}

export const getBaPoName = ({ auth_id }: { auth_id: string | null }) => {
    return auth_id?.replace('samlp|kb-saml|', '') || ''
}

export const getBankAccountNumber = ({
    bank_account_prefix,
    bank_account_number,
    bank_account_code,
}: BankAccount) => {
    const prefix_formatted = bank_account_prefix ? `${bank_account_prefix}-` : ''
    const code_formatted = bank_account_code ? `/${bank_account_code}` : ''
    return `${prefix_formatted}${bank_account_number}${code_formatted}`
}

export const getBankAccountNumberIBAN = (iban: string | null) => {
    return iban ? getBankAccountNumber(parseIBAN(iban)) : ''
}

export const getIBANFormatted = (iban: string | null) => {
    return (iban || '').replace(/(.{4})/g, '$1 ').trim()
}

export function getBirthDate({ pid }: { pid: null }): null
export function getBirthDate({ pid }: { pid: string }): Date | null
// A heuristic function to get the birth Date object from the PID
export function getBirthDate({ pid }: { pid: string | null }) {
    if (!pid) return null

    let year = parseInt(pid.substr(0, 2), 10)
    let month = parseInt(pid.substr(2, 2), 10)
    const day = parseInt(pid.substr(4, 2), 10)
    year += Number(`20${year}`) < new Date().getFullYear() ? 2000 : 1900
    if (month > 70 && year > 2003) month -= 70
    else if (month > 50) month -= 50
    else if (month > 20 && year > 2003) month -= 20

    if (
        Number.isNaN(day) ||
        Number.isNaN(month) ||
        Number.isNaN(year) ||
        month === 0 ||
        month > 12 ||
        day === 0 ||
        day > 31
    )
        return null

    return new Date(year, month - 1, day)
}

export const getAddress = (account: TODO) => {
    if (!account.address || !account.city || !account.state_of_residency) {
        return null
    }

    if (account.zip) {
        return `${account.address}, ${account.zip} ${account.city}, ${
            countries.find(country => country.value === account.state_of_residency)?.czechLabel
        }`
    }

    return `${account.address}, ${account.city}, ${
        countries.find(country => country.value === account.state_of_residency)?.czechLabel
    }`
}

export const hasAccountFinishedRegistration = (account?: { status: AccountStatus } | null) =>
    account &&
    account.status !== ACCOUNT_STATUS.NOT_APPROVED &&
    account.status !== ACCOUNT_STATUS.REJECTED

export const isAccountRegisteredViaGoogle = (account: { auth_id: string }) =>
    account.auth_id.startsWith('google')

/*
    If the user has had details filled from BankID, or any other 3rd party, they should be marked in our DB.
*/
export const isAccountVerifiedBy = (
    account: {
        verification_provider: VerificationProvider
    } | null,
    verificationProviderToCheck: VerificationProvider,
) => !!(account && account.verification_provider === verificationProviderToCheck)

export const AUDIT_LOG_ACTION_TYPES = {
    FAILED_SANCTION_LIST_CHECK: 'FAILED_SANCTION_LIST_CHECK',
}

export const formatPid = (pid: string) => {
    // don't add an extra slash if already formatted :)
    if (!pid.includes('/')) {
        return `${pid.slice(0, 6)}/${pid.slice(6)}`
    }

    return pid
}

export const isAccountInvestmentClubMember = (account: {
    is_club_member: boolean
    kb_flag: boolean
}) => {
    return (
        account.is_club_member || account.kb_flag // marked as kb client
    )
}

export const isAccountPrivMember = (account: { is_priv_member: boolean }) => {
    return account.is_priv_member
}

export const AML_REVIEW_THRESHOLDS = [
    {
        risk_levels: [ACCOUNT_RISK.LOW, ACCOUNT_RISK.MEDIUM_LOW],
        years_until_review: 7, // last aml review date + 7yrs has passed
    },
    {
        risk_levels: [ACCOUNT_RISK.MEDIUM_HIGH],
        years_until_review: 2, // last aml review date + 2yrs has passed
    },
    {
        risk_levels: [ACCOUNT_RISK.HIGH],
        years_until_review: 1, // last aml review date + 1yr has passed
    },
] as { risk_levels: AccountRisk[]; years_until_review: number }[]

export const accountNeedsAmlReview = (account: {
    risk_profile: AccountRisk
    status: AccountStatus
    last_aml_review_date: Maybe<Date | number>
}) => {
    const { risk_profile, last_aml_review_date } = account
    const threshold = AML_REVIEW_THRESHOLDS.find(({ risk_levels }) =>
        risk_levels.includes(risk_profile),
    )
    return (
        account.status === ACCOUNT_STATUS.APPROVED &&
        !!threshold &&
        !!last_aml_review_date &&
        moment().isAfter(moment(last_aml_review_date).add(threshold.years_until_review, 'years'))
    )
}

export const getAccountAge = (account: { pid: Maybe<string> }) => {
    if (!account.pid) return null

    const pidYear = parseInt(account.pid.substring(0, 2), 10)
    const currentYear = moment().year()

    const age =
        pidYear > currentYear - 2000 ? currentYear - pidYear - 1900 : currentYear - pidYear - 2000

    return Number.isNaN(age) ? null : age
}
