import React, { useEffect, useState } from 'react'

const CypressJSLoadedDetector = () => {
    const [isMounted, setIsMounted] = useState(false)

    useEffect(() => {
        if (!isMounted) {
            setIsMounted(true)
        }
    }, [isMounted])

    if (!isMounted) {
        return null
    }

    return <span data-test-id="__js-loaded__" />
}

export default CypressJSLoadedDetector
