import * as yup from 'yup'
import * as R from 'ramda'
import {
    ACCOUNT_ADDITIONAL_RISK,
    ACCOUNT_LEGAL_FORM,
    ACCOUNT_STATUS,
    DOCUMENT_TYPES,
    NO_VAT_NUMBER_REASONS,
} from '@upvestcz/common/account-utils'
import { TFunction } from 'i18next'
import { acceptedBankCodes } from '@upvestcz/common/bank_codes'
import {
    isBankAccountNumberValidInIBAN,
    isIBANValid,
    parseIBAN,
    validateBankAccountNumber,
} from '@upvestcz/common/bank-utils'

import {
    chainAsyncValidation,
    handleNullNotRequired,
    makeAllEntriesNullable,
    makeAllEntriesOptional,
    makeAllInputsOptional,
    validatePid,
    validatePidAge,
} from '../utils'
import { countries } from '../../countries'
import { MaybePromise } from '../../ts-utils'

export const createPidSyncValidation = (t: TFunction = R.identity) =>
    yup
        .string()
        .required(t('Vyplňte, prosím, rodné číslo.'))
        .matches(
            /^\d{6}\/?\d{3,4}$/,
            t('Rodné číslo, prosím, uveďte ve správném formátu (např. 000000/0000)'),
        )
        .test('is-valid', t('Rodné číslo nesplňuje kritéria platného rodného čísla'), value => {
            if (!value) {
                return true
            }

            return validatePid(value)
        })
        .test('age', t('Pro investování na Upvestu musíte být starší 18 let'), value => {
            if (!value) {
                return true
            }

            return validatePidAge(value)
        })

export const createAdditionalBankAccountFields = (t: TFunction = R.identity) => ({
    bank_account_prefix: yup
        .string()
        .matches(/^[0-9]{0,6}$/, t('Předčíslí účtu může mít maximálně 6 číslic.'))
        .test('is-valid', t('Předčíslí účtu není platné.'), validateBankAccountNumber)
        .nullable(),
    bank_account_number: yup
        .string()
        .matches(/^[0-9]{2,10}$/, t('Číslo účtu musí mít minimálně 2 a maximálně 10 číslic.'))
        .test('is-valid', t('Číslo účtu není platné.'), validateBankAccountNumber)
        .required(t('Vyplňte, prosím, číslo účtu.')),
    bank_account_code: yup
        .string()
        .matches(/^[0-9]{4}$/, t('Kód banky musí mít 4 číslice.'))
        .oneOf([null, ...acceptedBankCodes], t('Akceptovány jsou pouze české a slovenské banky.'))
        .required(t('Vyplňte, prosím, kód banky.')),
})

export const createCommonBankAccountFields = (t: TFunction = R.identity) => ({
    bank_account_agreement: yup
        .boolean()
        .oneOf(
            [true],
            t('Z regulatorních důvodů je nutné, aby byl bankovní účet veden na Vaše jméno.'),
        ),
    // order of tests is important, first failing will be the message printed
    iban: yup
        .string()
        .test('is-valid', t('Neplatný IBAN.'), handleNullNotRequired(isIBANValid))
        .test(
            'is-valid',
            t('Akceptovány jsou pouze české a slovenské banky.'),
            handleNullNotRequired((number?: string) =>
                number ? acceptedBankCodes.includes(parseIBAN(number).bank_account_code) : true,
            ),
        )
        .test(
            'is-valid',
            t('Číslo účtu není platné.'),
            handleNullNotRequired(isBankAccountNumberValidInIBAN),
        )
        .test(
            'is-valid',
            t('Předčíslí účtu není platné.'),
            handleNullNotRequired((number?: string) =>
                number
                    ? validateBankAccountNumber(parseIBAN(number)?.bank_account_prefix || undefined)
                    : true,
            ),
        )
        .required(t('Vyplňte, prosím, IBAN.')),
    iban_eur: yup
        .string()
        .test('is-valid', t('Neplatný IBAN.'), handleNullNotRequired(isIBANValid))
        .required(t('Vyplňte, prosím, IBAN eurového účtu.')),
})

export const nullableCreateCommonBankAccountFields = (t = R.identity) =>
    makeAllEntriesNullable(
        makeAllEntriesOptional(R.pick(['iban', 'iban_eur'], createCommonBankAccountFields(t))),
    )

export const createFATCAFieldsSchema = (t: TFunction = R.identity) => ({
    tax_residences: yup
        .array()
        .of(
            yup.object({
                country: yup
                    .string()
                    .oneOf(countries.map(country => country.value))
                    .required(t('Vyplňte, prosím, zemi daňové rezidence.')),
                hasVatNumber: yup
                    .boolean()
                    .nullable()
                    .required(t('Vyplňte, prosím, zda daná země poskytuje DIČ.')),
                vatNumber: yup
                    .string()
                    .nullable()
                    .when('hasVatNumber', {
                        is: true,
                        then: yup.string().nullable(true).required(t('Vyplňte, prosím, DIČ.')),
                    }),
                noVatNumberReason: yup
                    .string()
                    .nullable()
                    .when('hasVatNumber', {
                        is: false,
                        then: yup
                            .string()
                            .oneOf(Object.values(NO_VAT_NUMBER_REASONS))
                            .nullable()
                            .required(t('Vyplňte, prosím, důvod, proč nemáte DIČ.')),
                    }),
            }),
        )
        .min(1, t('Vyplňte, prosím, alespoň jednu daňovou rezidenci.'))
        .required(),
    is_usa_citizen: yup
        .boolean()
        .nullable()
        .required(t('Vyplňte, prosím, zda jste či nejste občanem USA.')),
})

export const nullableCreateFATCAFieldsSchema = (t: TFunction = R.identity) =>
    makeAllEntriesNullable(makeAllEntriesOptional(createFATCAFieldsSchema(t)))

export const createAccountUpdateSchemaServer = ({ is_corporate }: { is_corporate: boolean }) => {
    const validations = {
        ...nullableCreateCommonBankAccountFields(),
        ...nullableCreateFATCAFieldsSchema(),
        bank_account_agreement: yup.boolean(),
        corporate_id: yup.string().nullable(),
        corporate_name: yup.string().nullable(),
        corporate_shareholder_equity_amount: yup.number().nullable(),
        corporate_shareholder_equity_origin: yup.string().nullable(),
        email: yup.string().email(),
        id: yup.number(),
        id1_back: yup.string().nullable(),
        id1_front: yup.string().nullable(),
        id2_front: yup.string().nullable(),
        is_corporate: yup.boolean(),
        is_not_PEP: yup.boolean(),
        name: yup.string().nullable(),
        phone: yup.string().nullable(),
        phone_country_code: yup.number().nullable(),
        pid: createPidSyncValidation().notRequired(),
        state: yup.string().nullable(),
        surname: yup.string().nullable(),
        kb_marketing_consent: yup.boolean(),
    }

    const CORPORATE_FIELD_WHITELIST = [
        'id',
        'corporate_name',
        'corporate_id',
        'is_corporate',
        'email',
        'iban',
        'iban_eur',
        'bank_account_agreement',
        'corporate_shareholder_equity_origin',
        'kb_marketing_consent',
    ]

    const REGULAR_FIELD_WHITELIST = [
        'id',
        'is_corporate',
        'email',
        'name',
        'surname',
        'pid',
        'phone',
        'phone_country_code',
        'id1_front',
        'id1_back',
        'id2_front',
        'iban',
        'iban_eur',
        'bank_account_agreement',
        'is_not_PEP',
        'kb_marketing_consent',
        'tax_residences',
        'is_usa_citizen',
    ]

    const onlyInputs = is_corporate ? CORPORATE_FIELD_WHITELIST : REGULAR_FIELD_WHITELIST

    return yup.object().shape(onlyInputs ? R.pick(onlyInputs, validations) : validations)
}

const idSyncSchema = yup
    .string()
    .nullable()
    .transform((_, val) => val || null)

const adminAccountValidations = ({
    validateID,
}: {
    validateID: (id_number: string, testContext: TODO) => MaybePromise<boolean>
}) => {
    return {
        additional_risk: yup.string().oneOf(Object.values(ACCOUNT_ADDITIONAL_RISK)),
        email: yup.string().email(),
        ...nullableCreateCommonBankAccountFields(),
        state_of_residency: yup
            .string()
            .oneOf(countries.map(country => country.value))
            .required(),
        state_of_citizenship: yup
            .string()
            .oneOf(countries.map(country => country.value))
            .required(),
        is_not_PEP: yup.boolean(),
        name: yup.string().nullable(),
        surname: yup.string().nullable(),
        pid: createPidSyncValidation().notRequired(),
        phone: yup.string().nullable(),
        phone_country_code: yup.number().nullable(),
        is_sanctioned: yup.boolean(),
        address: yup.string().nullable(),
        city: yup.string().nullable(),
        zip: yup.string().nullable(),
        notes: yup.string().nullable(),
        is_priv_member: yup.boolean(),
        is_club_member: yup.boolean(),
        kb_flag: yup.boolean(),
        id_type: yup.string().oneOf(Object.values(DOCUMENT_TYPES)).nullable(),
        id_number: chainAsyncValidation({
            syncValidation: idSyncSchema,
            validationName: 'checkPrimaryId',
            validationErrorMessage: 'Primary id is not valid.',
            validationFn: (id_number, testContext: TODO) => {
                /*
                 * if account is ACCOUNT_STATUS.APPROVED, don't require expired ID check on every change.
                 * We have customers where we want to update the bank account number, etc. but should not require updated documents.
                 * For ACCOUNT_STATUS.SUSPENDED accounts, the validateID should run, ID data should be up-to-date before resuming the account.
                 */
                return testContext.parent.status === ACCOUNT_STATUS.APPROVED
                    ? true
                    : validateID(id_number, testContext.parent.id_type)
            },
        }),
        id_document_expiration: yup.date().nullable(),
        state_of_birth: yup.string().nullable(),
        city_of_birth: yup.string().nullable(),
        id2_type: yup.string().oneOf(Object.values(DOCUMENT_TYPES)).nullable(),
        id2_number: chainAsyncValidation({
            syncValidation: idSyncSchema,
            validationName: 'checkSecondaryId',
            validationErrorMessage: 'Secondary id is not valid.',
            validationFn: (id_number, testContext: TODO) =>
                validateID(id_number, testContext.parent.id2_type),
        }),

        date_of_company_registration: yup.date().nullable(),
        company_turnover_czk: yup
            .number()
            .nullable()
            .transform(value => (Number.isNaN(value) ? null : value)),
        industries: yup.string().nullable(),
        government_ownership: yup.boolean(),
        prior_sars: yup
            .array()
            .of(yup.object({ date: yup.date(), note: yup.string() }))
            .nullable(),
        legal_form: yup
            .string()
            .nullable()
            .oneOf([null, ...Object.values(ACCOUNT_LEGAL_FORM)]), // oneOf with nullable option: https://github.com/jquense/yup/issues/104
        risky_behavior: yup.boolean(),
        ...nullableCreateFATCAFieldsSchema(),
    }
}

type AdminAccountValidationsType = ReturnType<typeof adminAccountValidations>

export const createAdminAccountEditSchema = ({
    onlyInputs,
    validateID,
}: {
    onlyInputs?: string[] | Readonly<string[]> | undefined
    validateID: (id_number: string, testContext: TODO) => MaybePromise<boolean>
}) => {
    return yup
        .object()
        .shape(
            onlyInputs
                ? R.pick(onlyInputs, adminAccountValidations({ validateID }))
                : adminAccountValidations({ validateID }),
        )
}

/* sever specific validation is about what values we should let as editable, not which are required */
export const createAdminAccountEditSchemaServer = ({
    onlyInputs,
}: {
    onlyInputs?: string[] | undefined
} = {}) => {
    const serverAdminAccountValidations = {
        ...adminAccountValidations({ validateID: () => true }), // no async ID validations on server side
    } as Partial<AdminAccountValidationsType>

    // we do not save id2_number to DB
    delete serverAdminAccountValidations.id2_number
    delete serverAdminAccountValidations.id2_type

    const inputs = onlyInputs
        ? R.pick(onlyInputs, serverAdminAccountValidations)
        : serverAdminAccountValidations

    return makeAllInputsOptional(inputs)
}
