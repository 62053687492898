import { useRouter } from 'next/router'
import { Text } from '@upvestcz/shared-components'
import InfoAlert from './InfoAlert'

function AutomaticTranslationAlert({ ...props }) {
    const { locale } = useRouter()

    if (locale !== 'en') {
        return null
    }

    return (
        <InfoAlert {...props}>
            <span>
                The text below was{' '}
                <Text as="span" fontWeight={500}>
                    translated <em>automatically</em>
                </Text>
                . Therefore, we cannot guarantee the accuracy of the translations.
            </span>
        </InfoAlert>
    )
}

export default AutomaticTranslationAlert
