import React, {
    createContext,
    useState,
    useContext,
    useRef,
    useReducer,
    ReactNode,
    useCallback,
} from 'react'
import { Dict } from '@chakra-ui/utils'
import { Box } from '@upvestcz/shared-components'
import { useTransition, animated } from 'react-spring'
import { Toast, ToastType } from '../components/Toast'

type ToastContext = (options: ToastOptions) => void

export const ToastContext = createContext<ToastContext>({} as ToastContext)

export const useToast = () => {
    return useContext(ToastContext)
}

type DragState =
    | {
          draggingItemKey?: number | null
          isDragging?: boolean
          referenceX?: number | null
          currentX?: number
      }
    | EmptyObject

interface DragStartAction {
    type: 'start'
    payload: {
        key: number
        x: number
    }
}

interface DragEndAction {
    type: 'end'
}

interface DragMoveAction {
    type: 'move'
    payload: number
}

type DragAction = DragStartAction | DragEndAction | DragMoveAction

function dragReducer(state: DragState, action: DragAction): DragState {
    switch (action.type) {
        case 'start':
            return {
                draggingItemKey: action.payload.key,
                referenceX: action.payload.x,
                currentX: action.payload.x,
            }
        case 'end':
            return {
                isDragging: false,
                referenceX: null,
                draggingItemKey: null,
            }
        case 'move':
            return { ...state, currentX: action.payload }
        default:
            throw new Error()
    }
}

const DEFAULT_TIMEOUT = 5000

interface ToastOptions {
    timeout?: number
    message: string
    type: ToastType
}

type Toast = ToastOptions & {
    key: number
}

export function ToastProvider({ children }: { children: ReactNode }) {
    const styles: Dict = {
        Container: {
            position: 'fixed',
            width: { base: 'calc(100vw - 16px)', md: '512px' },
            margin: '0 auto',
            right: 0,
            left: 0,
            display: 'flex',
            flexDirection: 'column-reverse',
            height: '100%',
            justifyContent: 'flex-start',
            paddingBottom: '16px',
            pointerEvents: 'none',
            zIndex: 2147483001, // we want it to be higher than the intercom button
        },
    } as const

    const refMap = useRef<Record<number, HTMLElement>>({})
    const timers = useRef<number[]>([])
    const [toasts, setToasts] = useState<Toast[]>([])
    const [currentToastIndex, setCurrentToastIndex] = useState(0)
    const [dragState, dragDispatch] = useReducer(dragReducer, {})

    const transition = useTransition(toasts, {
        keys: (item: Toast) => item.key,
        from: { opacity: 0, transform: 'translate3d(0,-16px,0)' },
        enter: item => next => {
            return next({
                opacity: 1,
                transform: 'translate3d(0,0,0)',
                height: refMap.current[item.key].offsetHeight,
            })
        },
        leave: () => async next => {
            await next({ opacity: 0 })
            await next({ height: 0 })
        },
    })

    const removeToast = (key: number) => {
        setToasts(prevToasts => prevToasts.filter(toast => toast.key !== key))
    }

    const clearTimers = () => {
        timers.current.forEach(clearTimeout)
    }

    const restartTimers = () => {
        timers.current = []

        toasts.forEach(toast => {
            const timer = setTimeout(() => {
                removeToast(toast.key)
            }, toast.timeout) as unknown as number

            timers.current.push(timer)
        })
    }

    const addToast = useCallback(
        ({ message, type, timeout }: ToastOptions) => {
            setToasts(prevToasts => {
                return [
                    ...prevToasts,
                    {
                        message,
                        key: currentToastIndex,
                        type,
                        timeout: timeout || DEFAULT_TIMEOUT,
                    },
                ]
            })
            const timer = setTimeout(() => {
                removeToast(currentToastIndex)
            }, timeout || DEFAULT_TIMEOUT) as unknown as number
            timers.current.push(timer)
            setCurrentToastIndex(prevIndex => prevIndex + 1)
        },
        [currentToastIndex],
    )

    return (
        <ToastContext.Provider value={addToast}>
            <>
                <Box {...styles.Container}>
                    {transition((style, item) => {
                        return (
                            <animated.div
                                style={style}
                                ref={ref => {
                                    if (ref) {
                                        refMap.current[item.key] = ref
                                    }
                                }}
                                onMouseOver={clearTimers}
                                onTouchStart={e => {
                                    dragDispatch({
                                        type: 'start',
                                        payload: {
                                            x: e.targetTouches[0].clientX,
                                            key: item.key,
                                        },
                                    })
                                    clearTimers()
                                }}
                                onTouchMove={e => {
                                    const currentX = e.touches[0].clientX

                                    dragDispatch({
                                        type: 'move',
                                        payload: currentX,
                                    })
                                }}
                                onFocus={clearTimers}
                                onTouchEnd={() => {
                                    const { referenceX, currentX } = dragState

                                    if (Math.abs(referenceX! - currentX!) > 100) {
                                        removeToast(item.key)
                                    } else {
                                        dragDispatch({ type: 'end' })
                                    }

                                    restartTimers()
                                }}
                                onMouseLeave={restartTimers}
                                onBlur={restartTimers}
                            >
                                <div
                                    style={
                                        dragState.draggingItemKey === item.key
                                            ? {
                                                  transform: `translateX(${
                                                      dragState.currentX! - dragState.referenceX!
                                                  }px)`,
                                                  opacity:
                                                      1 -
                                                      (Math.abs(
                                                          dragState.currentX! -
                                                              dragState.referenceX!,
                                                      ) *
                                                          0.8) /
                                                          100,
                                              }
                                            : { transform: 'none', opacity: 1 }
                                    }
                                >
                                    <Toast
                                        onRemoveClick={() => {
                                            removeToast(item.key)
                                        }}
                                        type={item.type}
                                    >
                                        {item.message}
                                    </Toast>
                                </div>
                            </animated.div>
                        )
                    })}
                </Box>

                {children}
            </>
        </ToastContext.Provider>
    )
}
