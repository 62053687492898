export const googleChartsTooltipStyles = {
    '.google-visualization-tooltip *': {
        fontFamily: 'body',
        fontSize: 'sm',
        color: 'black',
        opacity: 1,
        margin: 0,
        fontStyle: 'none',
        textDecoration: 'none',
        fontWeight: 'none',
    },

    '.google-visualization-tooltip': {
        border: 'solid 1px lightGrey',
        borderRadius: '1px',
        backgroundColor: 'white',
        boxShadow: 'none',
    },

    '.google-visualization-tooltip-item-list': {
        margin: '0.5em 0',
    },
    '.google-visualization-tooltip-item': {
        margin: '0.25em 0 !important',
    },
}
