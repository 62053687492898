import React from 'react'
import { Flex, BoxProps } from '@chakra-ui/react'
import { UPVEST_LP_BASE_URL } from '@upvestcz/common/constants'
import { MergeChakraProps } from '@upvestcz/common/ts-utils'
import Logo from './Logo'
import { NavContainer } from './Nav'
import Link from './Link'

type Props = MergeChakraProps<
    {
        onLogoClick?: () => void
        navRight?: React.ReactNode
    },
    BoxProps
>
const ModalNavbar = ({ navRight, onLogoClick, ...props }: Props) => {
    return (
        <NavContainer {...props}>
            <Flex alignItems="center" justifyContent="flex-start" flex={1} pr={10}>
                <Link aria-label="Upvest Logo" to={UPVEST_LP_BASE_URL} onClick={onLogoClick}>
                    <Logo />
                </Link>
            </Flex>
            <Flex alignItems="center" justifyContent="flex-end" flex={1} pl={10}>
                {navRight}
            </Flex>
        </NavContainer>
    )
}

export default ModalNavbar
