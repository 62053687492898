import {
    Drawer,
    DrawerBody,
    DrawerContent,
    DrawerOverlay,
    HStack,
    List,
    ListItem,
    Text,
} from '@chakra-ui/react'
import React from 'react'
import * as R from 'ramda'
import { useRouter } from 'next/router'
import { LOCALES_LABELS_LONG } from '@upvestcz/common/i18n/locales'
import LocaleLink from '../LocaleLink'
import { LocaleFlag } from '../LocaleFlag'
import { NAVBAR_Z_INDEX } from '../../theme/v2/variables'

type Props = {
    isOpen: boolean
    onClose: () => void
}
export const LocaleDrawer = ({ onClose, isOpen }: Props) => {
    const router = useRouter()

    return (
        <Drawer
            placement="bottom"
            onClose={onClose}
            isOpen={isOpen}
            autoFocus={false}
            portalProps={{
                appendToParentPortal: false,
            }}
        >
            <DrawerOverlay />
            <DrawerContent zIndex={NAVBAR_Z_INDEX + 3}>
                <DrawerBody>
                    <List>
                        {R.toPairs(LOCALES_LABELS_LONG).map(([locale, label]) => (
                            <ListItem key={locale}>
                                <LocaleLink
                                    fontWeight={router.locale === locale ? 700 : 400}
                                    variant="plain"
                                    locale={locale}
                                    display="block"
                                    py={2}
                                    onClick={onClose}
                                >
                                    <Text as={HStack} spacing={3}>
                                        <LocaleFlag locale={locale} />
                                        <span>{label}</span>
                                    </Text>
                                </LocaleLink>
                            </ListItem>
                        ))}
                    </List>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}
