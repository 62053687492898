import React from 'react'
import {
    Box,
    HStack,
    Flex,
    Text,
    VStack,
    Wrap,
    WrapItem,
    BoxProps,
    StackProps,
} from '@chakra-ui/react'
import {
    UPVEST_ADDRESS_LINE_1,
    UPVEST_ADDRESS_LINE_2,
    UPVEST_ADDRESS_LINE_3,
    UPVEST_COMPANY_NAME,
    UPVEST_EMAIL,
    UPVEST_PHONE_NUMBER,
} from '@upvestcz/common/constants'
import { MergeChakraProps } from '@upvestcz/common/ts-utils'
import ContentContainer from './ContentContainer'
import Link from './Link'
import * as icons from './icons'

export const FooterContainer = ({ children, ...props }: BoxProps) => {
    const styles = {
        Root: {
            background: 'linear-gradient(213.07deg, #F9F9F9 27.45%, #FFFFFF 88.3%)',
            paddingTop: 12,
            paddingBottom: '6px',
        },
    }

    return (
        <Box {...styles.Root} {...props}>
            <ContentContainer>{children}</ContentContainer>
        </Box>
    )
}

export const FooterContact = ({ color }: { color?: string }) => {
    const styles = {
        Icon: {
            color: color || 'primary',
            height: '24px',
            width: 'auto',
            mr: 3,
        },
    }

    return (
        <Wrap spacing={[4, null, 6]} lineHeight="1.75">
            <WrapItem>
                <VStack spacing={4} alignItems="flex-start">
                    <Flex>
                        <icons.EmailIcon {...styles.Icon} />
                        <Box>
                            <Text>
                                <Link
                                    to={`mailto:${UPVEST_EMAIL}`}
                                    color={color || 'primary'}
                                    fontWeight="bold"
                                >
                                    {UPVEST_EMAIL}
                                </Link>
                            </Text>
                        </Box>
                    </Flex>
                    <Flex>
                        <icons.PhoneIcon {...styles.Icon} />
                        <Box>
                            <Text>
                                <Link
                                    to={`tel:${UPVEST_PHONE_NUMBER.replace(/\s/g, '')}`}
                                    color={color || 'primary'}
                                    fontWeight="bold"
                                >
                                    {UPVEST_PHONE_NUMBER}
                                </Link>
                            </Text>
                        </Box>
                    </Flex>
                </VStack>
            </WrapItem>
            <WrapItem>
                <Flex>
                    <icons.LocationIcon {...styles.Icon} />
                    <Box>
                        <Box as="address" fontStyle="normal" whiteSpace="pre" color={color}>
                            {UPVEST_COMPANY_NAME}
                            <br />
                            {UPVEST_ADDRESS_LINE_1}
                            <br />
                            {UPVEST_ADDRESS_LINE_3} – {UPVEST_ADDRESS_LINE_2}
                        </Box>
                    </Box>
                </Flex>
            </WrapItem>
        </Wrap>
    )
}

type SocialsProps = MergeChakraProps<{ colorScheme?: string; size: string }, StackProps>
export const FooterSocials = ({ colorScheme, size, ...props }: SocialsProps) => {
    return (
        <HStack
            spacing={4}
            justifyContent={{ base: 'center', md: 'flex-start' }}
            fontSize={size}
            {...props}
        >
            <Link to="https://www.facebook.com/upvestcz" blank colorScheme={colorScheme}>
                <icons.SocialsFacebookIcon />
            </Link>
            <Link to="https://twitter.com/upvestcz" blank colorScheme={colorScheme}>
                <icons.SocialsTwitterIcon />
            </Link>
            <Link
                to="https://www.linkedin.com/company/upvest-s.r.o./posts/?feedView=all"
                blank
                colorScheme={colorScheme}
            >
                <icons.SocialsLinkedinIcon />
            </Link>
        </HStack>
    )
}
