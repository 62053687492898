export default {
    baseStyle: {
        _focus: { boxShadow: 'none' },
    },
    variants: {
        plain: {
            _hover: {
                textDecoration: 'none',
            },
        },
        contextualLink: {
            color: 'inherit',
            fontWeight: 'inherit',
            fontSize: 'inherit',
            textDecoration: 'underline',
            sx: {
                textDecorationColor: 'lightGrey',
            },
            _hover: {
                color: 'primary',
                cursor: 'pointer',
                sx: {
                    textDecorationColor: 'currentColor',
                },
            },
        },
        get primary() {
            return {
                ...this.plain,
                color: 'primary',
                _hover: {
                    textDecoration: 'underline',
                },
            }
        },
    },
}
