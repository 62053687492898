const FormError = {
    baseStyle: {
        text: {
            color: 'dangerRed',
        },
        icon: {
            color: 'dangerRed',
        },
    },
}

export default FormError
