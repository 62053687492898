import { APPS, AppType } from '@upvestcz/common/apps'
import React from 'react'

// this cannot be a standalone component, because the <Head> component does not support rendering custom components.
const getUrlPrefix = (app?: AppType) => {
    switch (app) {
        case APPS.ADMIN:
            return '/admin'
        default:
            return ''
    }
}

const FaviconsHead = (app?: AppType) => (
    <>
        <link
            rel="apple-touch-icon"
            sizes="180x180"
            href={`https://d3uvu5yktntepe.cloudfront.net/img/favicons${getUrlPrefix(
                app,
            )}/apple-touch-icon.png`}
        />
        <link
            rel="icon"
            type="image/png"
            sizes="32x32"
            href={`https://d3uvu5yktntepe.cloudfront.net/img/favicons${getUrlPrefix(
                app,
            )}/favicon-32x32.png`}
        />
        <link
            rel="icon"
            type="image/png"
            sizes="16x16"
            href={`https://d3uvu5yktntepe.cloudfront.net/img/favicons${getUrlPrefix(
                app,
            )}/favicon-16x16.png`}
        />
        <link
            rel="mask-icon"
            href={`https://d3uvu5yktntepe.cloudfront.net/img/favicons${getUrlPrefix(
                app,
            )}/safari-pinned-tab.svg`}
            color="#414549"
        />
        <meta name="theme-color" content="#414549" />
    </>
)

export default FaviconsHead
