import { i18n, Resource } from 'i18next'
import { initReactI18next } from 'react-i18next'
import { Locale, LOCALES } from './locales'
import { FormatterFunction } from './formatters'

export type TranslationsKeyValues = Record<string, string>
export type LocaleTranslationsMap = Record<Locale, TranslationsKeyValues>

export const DEFAULT_NS = 'translation'

export const initializeI18n = (
    i18nInstance: i18n,
    options: { resources: Resource; formatters?: Record<string, FormatterFunction> },
) => {
    const { formatters = {}, ...restOptions } = options
    i18nInstance.use(initReactI18next).init({
        // Load the translation synchronously.
        initImmediate: false,

        // needed to make natural keys work...
        keySeparator: false,
        nsSeparator: false,

        defaultNS: DEFAULT_NS,
        lng: LOCALES.CS,

        react: {
            // Suspense for data fetching is still experimental and its API might
            // change so I'd rather refrain from using it.
            // (The default value is `true`)
            useSuspense: false,
            transSupportBasicHtmlNodes: true,
            // transKeepBasicHtmlNodesFor: ['br', 'i', 'strong'], cannot keep nodes containing children because of: https://github.com/i18next/react-i18next/issues/1240
            transKeepBasicHtmlNodesFor: ['br'],
        },
        backend: {
            loadPath: '/public/locales/{{lng}}/{{ns}}.json',
        },
        // debug: true,
        interpolation: {
            format(value, format, lng) {
                const formatFn = format && formatters[format]

                if (formatFn) return formatFn(value, lng)

                return value
            },
            escapeValue: false, // React handles that for us
        },

        ...restOptions,
    })
    return i18nInstance
}
