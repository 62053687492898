import React from 'react'
import { HStack, Link, icons } from '@upvestcz/shared-components'
import { useTranslation } from 'react-i18next'

function ReferralShareButtons({ referralHref, ...props }) {
    const url = encodeURIComponent(referralHref)
    const { t } = useTranslation()

    const styles = {
        SocialIcon: {
            transition: 'color 0.2s ease',
            display: 'flex',
            alignItems: 'center',
            sx: {
                svg: {
                    fontSize: '32px',
                    transition: 'inherit',
                    color: 'primary',
                    marginRight: 1,
                },
                _hover: {
                    color: 'primaryLight',
                    svg: {
                        color: 'primaryLight',
                    },
                },
            },
        },
    }

    return (
        <HStack spacing={4} justifyContent={{ base: 'center', md: 'flex-start' }} {...props}>
            <Link to={`https://www.facebook.com/sharer.php?&u=${url}`} blank {...styles.SocialIcon}>
                <icons.SocialsFacebookIcon />
                {t('Facebook')}
            </Link>
            <Link to={`https://twitter.com/intent/tweet?url=${url}`} blank {...styles.SocialIcon}>
                <icons.SocialsTwitterIcon />
                {t('Twitter')}
            </Link>
        </HStack>
    )
}

export default ReferralShareButtons
