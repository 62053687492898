import { ChakraProps, CollapseProps, ModalProps } from '@chakra-ui/react'
import { Prisma } from '@prisma/client'

export type ObjectValues<Type> = Type[keyof Type]
export type ObjectKeys<Type> = keyof Type

// We omit those keys on `ChakraProps` (e. g. `BoxProps`, `FlexProps`, etc) which
// would conflict with our "own" props
export type MergeChakraProps<
    OwnProps,
    ChProps extends ChakraProps | Optional<ModalProps, 'children'> | CollapseProps,
> = OwnProps & Omit<ChProps, keyof OwnProps>

export type NotNull<Type> = Type extends null ? never : Type
export type NotUndefined<T> = T extends undefined ? never : T
export type Nullable<T> = { [K in keyof T]: T[K] | null }

export type NotNullObject<Type> = {
    [Key in keyof Type]: NotNull<Type[Key]>
}

export type AddPrefixToStrnigs<T extends string, P extends string> = ObjectKeys<{
    [key in `${P}${T}`]: any
}>

export type Optional<Type, Keys extends keyof Type> = Omit<Type, Keys> & Partial<Pick<Type, Keys>>
export type RequireOnly<T, K extends keyof T> = Partial<T> & Pick<T, K>
export type PickRequired<Type, Keys extends keyof Type> = Required<Pick<Type, Keys>> & Type
export type PickDefined<Type, Keys extends keyof Type> = NotNullObject<Required<Pick<Type, Keys>>> &
    Type
export type OverrideObject<T, R> = Omit<T, keyof R> & R
export type OneOrArrayOf<T> = T | T[]

// Workaround for TS not being able to take stuff like `'name' in user`
// into account when type narrowing
// Helper function copied from https://fettblog.eu/typescript-hasownproperty
// eslint-disable-next-line @typescript-eslint/ban-types
export function hasProperty<Obj extends {}, Prop extends PropertyKey>(
    obj: Obj,
    prop: Prop,
): obj is Obj & Record<Prop, unknown> {
    return prop in obj
}

export type MaybePromise<T> = Promise<T> | T
export type Awaited<T> = T extends PromiseLike<infer U> ? U : T

// type predicates

type Diff<T, U> = T extends U ? never : T

export type Predicate<I, O extends I> = (i: I) => i is O

// keep for future utility even if unused
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const and =
    <I, O1 extends I, O2 extends I>(p1: Predicate<I, O1>, p2: Predicate<I, O2>) =>
    (i: I): i is O1 & O2 =>
        p1(i) && p2(i)

// keep for future utility even if unused
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const or =
    <I, O1 extends I, O2 extends I>(p1: Predicate<I, O1>, p2: Predicate<I, O2>) =>
    (i: I): i is O1 | O2 =>
        p1(i) || p2(i)

const not =
    <I, O extends I>(p: Predicate<I, O>) =>
    (i: I): i is Diff<I, O> =>
        !p(i)

export const isNull = <I>(i: I | null): i is null => i === null

export const isUndefined = <I>(i: I | undefined): i is undefined => i === undefined

export const ensureArray = <T>(val: T | T[]) => ([] as T[]).concat(val)

export const isNotNull = not(isNull)
export const isNotUndefined = not(isUndefined)

export type TypeCastDecimalToNumber<Type> = {
    [Property in keyof Type]: Type[Property] extends Prisma.Decimal ? number : Type[Property]
}

// eslint-disable-next-line @typescript-eslint/ban-types
export type DeepPartial<T> = T extends object
    ? {
          [P in keyof T]?: DeepPartial<T[P]>
      }
    : T

export function defaultToEmptyArray<T extends unknown[]>(value: T | undefined): T {
    return value === undefined ? ([] as unknown as T) : value
}

export type Entries<T> = {
    [K in keyof T]: [K, T[K]]
}[keyof T][]

export type NumericValuesKeys<T> = {
    [K in keyof T]: T[K] extends number ? K : never
}[keyof T]

export type Exact<
    T extends {
        [key: string]: unknown
    },
> = { [K in keyof T]: T[K] }

export type Override<T, OT extends Record<string, unknown>> = Omit<T, keyof OT> & Exact<OT>

/**
 * Pre TS 4.9 "satisfies" alternative based on https://kentcdodds.com/blog/how-to-write-a-constrained-identity-function-in-typescript
 */
export const satisfies =
    <Given extends unknown>() =>
    <Inferred extends Given>(item: Inferred) =>
        item

/**
 * This serves just for exact type checking when creating objects
 * @deprecated use `satisfies` function instead.
 */
export const define = <T>(o: T) => o
