import type { ComponentStyleConfig } from '@chakra-ui/theme'
import { createMediaQueryString } from '@upvestcz/common/styles-utils'

const CustomTabs: ComponentStyleConfig = {
    parts: ['Tab', 'TabListActiveTrack', 'TabListActiveLine'],
    baseStyle: ({ theme, isSelected }) => ({
        Tab: {
            outline: 'none',
            // TODO: add focus visible styles
            // _focusVisible={{ boxShadow: 'rgba(66, 153, 225, 0.6) 0px 0px 0px 3px' }}
            transition: 'color 0.26s ease',
            color: isSelected ? 'text' : 'primary',
            _hover: {
                color: 'text',
            },
            _disabled: {
                cursor: 'not-allowed',
                color: '#BABABA',
            },
            [createMediaQueryString({ maxWidth: theme.breakpoints.md })]: {
                ...theme.textStyles.buttonText,
            },
            [createMediaQueryString({ minWidth: theme.breakpoints.md })]: {
                ...theme.textStyles.heading5,
            },
        },
        TabListActiveTrack: {
            height: '4px',
            pos: 'absolute',
            left: 0,
            right: 0,
            bottom: 0,
            m: '0 !important', // important has to override menu item stack spacing
        },
        TabListActiveLine: {
            bgColor: 'primary',
            pos: 'absolute',
            height: '100%',
        },
    }),
}

export default CustomTabs
