/* eslint-disable max-classes-per-file */
import { JobId } from 'bull'
import { hasProperty, ObjectKeys, ObjectValues } from './ts-utils'
import { AccountStatus } from './account-utils'

export const ERROR_CODES = {
    ACCOUNT_CLOSED: 'ACCOUNT_CLOSED',
    ACCOUNT_NOT_FOUND: 'ACCOUNT_NOT_FOUND',
    AFFILIATE_NOT_FOUND: 'AFFILIATE_NOT_FOUND',
    AGE_NOT_MET: 'AGE_NOT_MET',
    ALREADY_SIGNED_UP: 'ALREADY_SIGNED_UP',
    AUTH_CALLBACK_ERROR: 'AUTH_CALLBACK_ERROR',
    BANKID_DUPLICATE_IDENTITY: 'BANKID_DUPLICATE_IDENTITY',
    COMPANY_NOT_FOUND: 'COMPANY_NOT_FOUND',
    COMPANY_MISSING_STATUTARNI_ORGAN: 'COMPANY_MISSING_STATUTARNI_ORGAN',
    DISAGREES_WITH_AGREEMENT: 'DISAGREES_WITH_AGREEMENT',
    DISTRIBUTION_ACCOUNT_NOT_FOUND: 'DISTRIBUTION_ACCOUNT_NOT_FOUND',
    FUNDRAISING_NOT_STARTED: 'FUNDRAISING_NOT_STARTED',
    IDENTITY_NOT_LINKED: 'IDENTITY_NOT_LINKED',
    INVALID_ARGUMENTS: 'INVALID_ARGUMENTS',
    INVALID_BANKID_DATA: 'INVALID_BANKID_DATA',
    INVALID_CURRENCY: 'INVALID_CURRENCY',
    INVALID_DISTRIBUTION_PARTNER_ACCOUNT: 'INVALID_DISTRIBUTION_PARTNER_ACCOUNT',
    INVALID_GRANT: 'INVALID_GRANT',
    INVALID_TOKEN: 'INVALID_TOKEN',
    INVALID_UTF8_STRING: 'INVALID_UTF8_STRING',
    INVESTMENT_BELOW_MIN_INVESTMENT: 'INVESTMENT_BELOW_MIN_INVESTMENT',
    INVESTMENT_NOT_FOUND: 'INVESTMENT_NOT_FOUND',
    MAX_FUNDRAISING_TARGET_OVERFLOW: 'MAX_FUNDRAISING_TARGET_OVERFLOW',
    MISSING_TOKEN: 'MISSING_TOKEN',
    MISSING_USER_ROLES: 'MISSING_USER_ROLES',
    MISSING_VALUE: 'MISSING_VALUE',
    NOT_ENOUGH_BALANCE: 'NOT_ENOUGH_BALANCE',
    OPPORTUNITY_NOT_FOUND: 'OPPORTUNITY_NOT_FOUND',
    PID_ALREADY_EXISTS: 'PID_ALREADY_EXISTS',
    TOKEN_EXPIRED: 'TOKEN_EXPIRED',
    UNAUTHENTICATED: 'UNAUTHENTICATED',
    UNAUTHORIZED: 'UNAUTHORIZED',
    UNKNOWN_AD_GROUP: 'UNKNOWN_AD_GROUP',
    UNSUPPORTED_COMPANY_TYPE: 'UNSUPPORTED_COMPANY_TYPE',
    UNSUPPORTED_PARENT_COMPANY: 'UNSUPPORTED_PARENT_COMPANY',
    UNVERIFIED_BANK_ACCOUNT: 'UNVERIFIED_BANK_ACCOUNT',
    USER_DENIED_ACCESS: 'USER_DENIED_ACCESS',
    VS_ALREADY_EXISTS: 'VS_ALREADY_EXISTS',
    WITHDRAWAL_REQUEST_NOT_FOUND: 'WITHDRAWAL_REQUEST_NOT_FOUND',
    WRONG_BANK_ID_CODE: 'WRONG_BANK_ID_CODE',
    WRONG_BANK_ID_TOKEN: 'WRONG_BANK_ID_TOKEN',
    WRONG_COMPANY_ID_FORMAT: 'WRONG_COMPANY_ID_FORMAT',
    PHONE_VERIFICATION_INIT_ERROR: 'PHONE_VERIFICATION_INIT_ERROR',
    INVALID_ACCOUNT_STATUS: 'INVALID_ACCOUNT_STATUS',
    WORKER_JOB_NOT_FOUND: 'WORKER_JOB_NOT_FOUND',
    PROHIBITED_ACTION: 'PROHIBITED_ACTION',
    BANK_ACCOUNT_ALREADY_EXISTS: 'BANK_ACCOUNT_ALREADY_EXISTS',
    DOCUMENT_CHECK_ERROR: 'DOCUMENT_CHECK_ERROR',
} as const

export type ErrorCode = ObjectValues<typeof ERROR_CODES>

type ErrorName = ObjectKeys<typeof customErrorConstructors>

export interface CustomError extends Error {
    readonly name: ErrorName
    readonly code: ErrorCode
    message: string
}

export const isCustomErrorLike = (err: unknown): err is CustomError =>
    typeof err === 'object' &&
    err !== null &&
    hasProperty(err, 'code') &&
    typeof err.code === 'string' &&
    err.code in ERROR_CODES &&
    hasProperty(err, 'name') &&
    typeof err.name === 'string' &&
    err.name in customErrorConstructors

export class UnauthenticatedError extends Error implements CustomError {
    name = 'UnauthenticatedError' as const

    code = ERROR_CODES.UNAUTHENTICATED

    message = 'User is not authenticated'
}

export class UnauthorizedError extends Error implements CustomError {
    name = 'UnauthorizedError' as const

    code = ERROR_CODES.UNAUTHORIZED

    message: string = this.message || 'User is not authorized'
}

export class OpportunityNotFoundError extends Error implements CustomError {
    name = 'OpportunityNotFoundError' as const

    code = ERROR_CODES.OPPORTUNITY_NOT_FOUND

    message = 'Could not find requested opportunity'
}

export class InvalidArgumentsError extends Error implements CustomError {
    name = 'InvalidArgumentsError' as const

    code = ERROR_CODES.INVALID_ARGUMENTS

    message: string = this.message || 'Query arguments invalid or missing'
}

export class AccountNotFoundError extends Error implements CustomError {
    name = 'AccountNotFoundError' as const

    code = ERROR_CODES.ACCOUNT_NOT_FOUND

    message = 'Could not find requested account'
}

export class InvestmentNotFoundError extends Error implements CustomError {
    name = 'InvestmentNotFoundError' as const

    code = ERROR_CODES.INVESTMENT_NOT_FOUND

    message: string = this.message || 'Could not find requested investment'
}

export class PidAlreadyExistsError extends Error implements CustomError {
    name = 'PidAlreadyExistsError' as const

    code = ERROR_CODES.PID_ALREADY_EXISTS

    message = 'Account with this Pid already exists'
}

export class VariableSymbolAlreadyExistsError extends Error implements CustomError {
    name = 'VariableSymbolAlreadyExistsError' as const

    code = ERROR_CODES.VS_ALREADY_EXISTS

    message = 'Account with this variable symbol already exists'
}

export class BankIDTokenError extends Error implements CustomError {
    name = 'BankIDTokenError' as const

    code = ERROR_CODES.WRONG_BANK_ID_TOKEN

    message = 'The provided BankID token is not valid'
}

export class BankIDCodeError extends Error implements CustomError {
    name = 'BankIDCodeError' as const

    code = ERROR_CODES.WRONG_BANK_ID_CODE

    message = 'The provided BankID auth code is not valid'
}

export class InvalidUTF8StringError extends Error implements CustomError {
    name = 'InvalidUTF8StringError' as const

    code = ERROR_CODES.INVALID_UTF8_STRING

    message = 'An invalid utf8 string encountered'
}

export class TokenExpiredError extends Error implements CustomError {
    name = 'TokenExpiredError' as const

    code = ERROR_CODES.TOKEN_EXPIRED

    message = 'jwt token expired'
}

export class UnknownAdGroupError extends Error implements CustomError {
    name = 'UnknownAdGroupError' as const

    code = ERROR_CODES.UNKNOWN_AD_GROUP

    message: string = this.message || 'An unknown ad group encountered'
}

export class UserWhoDisagreesWithAgreementError extends Error implements CustomError {
    name = 'UserWhoDisagreesWithAgreementError' as const

    code = ERROR_CODES.DISAGREES_WITH_AGREEMENT

    message: string = this.message || 'Accepting the latest agreement set required'
}

export class MaxFundraisingTargetOverflowError extends Error implements CustomError {
    name = 'MaxFundraisingTargetOverflowError' as const

    code = ERROR_CODES.MAX_FUNDRAISING_TARGET_OVERFLOW

    message = 'Max fundraising target exceeded'
}

export class NotEnoughBalanceError extends Error implements CustomError {
    name = 'NotEnoughBalanceError' as const

    code = ERROR_CODES.NOT_ENOUGH_BALANCE

    message = 'Insufficient account balance'
}

export class InvestmentBelowMinInvestmentError extends Error implements CustomError {
    name = 'InvestmentBelowMinInvestmentError' as const

    code = ERROR_CODES.INVESTMENT_BELOW_MIN_INVESTMENT

    message = 'Minimum investment amount criteria not met'
}

export class FundraisingNotAvailableError extends Error implements CustomError {
    name = 'FundraisingNotAvailableError' as const

    code = ERROR_CODES.FUNDRAISING_NOT_STARTED

    message = "Fundraising is not available, it's either not started yet or closed"
}

export class AgeNotMetError extends Error implements CustomError {
    name = 'AgeNotMetError' as const

    code = ERROR_CODES.AGE_NOT_MET

    message = 'Age restrictions have not been met'
}

export class AffiliateNotFoundError extends Error implements CustomError {
    name = 'AffiliateNotFoundError' as const

    code = ERROR_CODES.AFFILIATE_NOT_FOUND

    message = 'Corresponding affiliate was not found'
}

export class UnsupportedCompanyTypeError extends Error implements CustomError {
    name = 'UnsupportedCompanyTypeError' as const

    code = ERROR_CODES.UNSUPPORTED_COMPANY_TYPE

    message: string = this.message || 'The type of the company is not supported'
}

export class UnsupportedParentCompany extends Error implements CustomError {
    name = 'UnsupportedParentCompany' as const

    code = ERROR_CODES.UNSUPPORTED_PARENT_COMPANY

    message =
        'One or more parent companies are not supported, perhaps they reside in a foreign country'
}

export class CompanyNotFoundError extends Error implements CustomError {
    name = 'CompanyNotFoundError' as const

    code = ERROR_CODES.COMPANY_NOT_FOUND

    message = 'The company was not found'
}

export class WrongCompanyIdFormatError extends Error implements CustomError {
    name = 'WrongCompanyIdFormatError' as const

    code = ERROR_CODES.WRONG_COMPANY_ID_FORMAT

    message = 'The company ID format is wrong'
}

export class CompanyMissingStatutarniOrganError extends Error implements CustomError {
    name = 'CompanyMissingStatutarniOrganError' as const

    code = ERROR_CODES.COMPANY_MISSING_STATUTARNI_ORGAN

    message = 'The company is missing statutarni organ'
}

export class DistributionPartnerAccountNotFoundError extends Error implements CustomError {
    name = 'DistributionPartnerAccountNotFoundError' as const

    code = ERROR_CODES.DISTRIBUTION_ACCOUNT_NOT_FOUND

    message = 'The distribution partner account was not found'
}

export class BankIDDuplicateIdentityError extends Error implements CustomError {
    name = 'BankIDDuplicateIdentityError' as const

    code = ERROR_CODES.BANKID_DUPLICATE_IDENTITY

    message = 'Duplicate BankID identites found and linked'
}

export class IdentityNotLinkedError extends Error implements CustomError {
    name = 'IdentityNotLinkedError' as const

    connection: string

    token: string

    code = ERROR_CODES.IDENTITY_NOT_LINKED

    constructor({ connection, token }: { connection: string; token: string }) {
        super(`The identity using ${connection} is not linked`)

        this.connection = connection
        this.token = token
    }
}

export class InvalidGrantError extends Error implements CustomError {
    name = 'InvalidGrantError' as const

    code = ERROR_CODES.INVALID_GRANT

    message = 'Invalid grant while authenticating'
}

export class AlreadySignedUpError extends Error implements CustomError {
    name = 'AlreadySignedUpError' as const

    code = ERROR_CODES.ALREADY_SIGNED_UP

    message = 'The account is already signed up'
}

export class InvalidTokenError extends Error implements CustomError {
    name = 'InvalidTokenError' as const

    code = ERROR_CODES.INVALID_TOKEN

    message = 'The token provided is invalid'
}

export class MissingTokenError extends Error implements CustomError {
    name = 'MissingTokenError' as const

    code = ERROR_CODES.MISSING_TOKEN

    message = 'The token is missing'
}

export class MissingValueError extends Error implements CustomError {
    name = 'MissingValueError' as const

    code = ERROR_CODES.MISSING_VALUE

    constructor(message = 'The value is missing') {
        super(message)
    }
}

export class InvalidBankIDData extends Error implements CustomError {
    name = 'InvalidBankIDData' as const

    code = ERROR_CODES.INVALID_BANKID_DATA

    message = 'Invalid data obtained from BankID'
}

export class InvalidCurrencyError extends Error implements CustomError {
    name = 'InvalidCurrencyError' as const

    code = ERROR_CODES.INVALID_CURRENCY

    message = 'Invalid currency'
}

export class UnverifiedBankAccountError extends Error implements CustomError {
    name = 'UnverifiedBankAccountError' as const

    code = ERROR_CODES.UNVERIFIED_BANK_ACCOUNT

    message = 'Unverified bank account'
}

export class WithdrawalRequestNotFoundError extends Error implements CustomError {
    name = 'WithdrawalRequestNotFoundError' as const

    code = ERROR_CODES.WITHDRAWAL_REQUEST_NOT_FOUND

    message = 'The withdrawal request was not found'
}
export class PhoneVerificationInitError extends Error implements CustomError {
    name = 'PhoneVerificationInitError' as const

    code = ERROR_CODES.PHONE_VERIFICATION_INIT_ERROR

    message = 'Initializing phone verification failed'
}
export class InvalidAccountStatusError extends Error implements CustomError {
    name = 'InvalidAccountStatusError' as const

    status: AccountStatus

    code = ERROR_CODES.INVALID_ACCOUNT_STATUS

    message: string = this.message || 'The account status is invalid'

    constructor(status: AccountStatus, message?: string) {
        super(message)
        this.status = status
    }
}

export class AuthCallbackError extends Error implements CustomError {
    name = 'AuthCallbackError' as const

    reason: string

    code = ERROR_CODES.AUTH_CALLBACK_ERROR

    constructor({ reason, message }: { reason: string; message: string }) {
        super(message)

        this.reason = reason
    }
}

export class AccountClosedError extends Error implements CustomError {
    name = 'AccountClosedError' as const

    code = ERROR_CODES.ACCOUNT_CLOSED

    message = 'The account has been closed'
}

export class InvalidDistributionPartnerAccountError extends Error implements CustomError {
    name = 'InvalidDistributionPartnerAccountError' as const

    code = ERROR_CODES.INVALID_DISTRIBUTION_PARTNER_ACCOUNT

    message = 'The distribution partner account is invalid'
}

export class ProhibitedActionError extends Error implements CustomError {
    name = 'ProhibitedActionError' as const

    code = ERROR_CODES.PROHIBITED_ACTION

    message = 'The invoked action is prohibited'
}

export class BankAccountAlreadyExistsError extends Error implements CustomError {
    name = 'BankAccountAlreadyExistsError' as const

    code = ERROR_CODES.BANK_ACCOUNT_ALREADY_EXISTS

    message = 'This bank account already exists'
}

export class WorkerJobNotFoundError extends Error implements CustomError {
    name = 'WorkerJobNotFoundError' as const

    code = ERROR_CODES.WORKER_JOB_NOT_FOUND

    message = 'The requested worker job was not found'

    jobId: JobId

    constructor(jobId: JobId, message?: string) {
        super(message)

        this.jobId = jobId
    }
}

export class DocumentCheckError extends Error implements CustomError {
    name = 'DocumentCheckError' as const

    code = ERROR_CODES.DOCUMENT_CHECK_ERROR

    message = 'Error while checking document validity from MVCR'
}

export const customErrorConstructors = {
    UnauthenticatedError,
    UnauthorizedError,
    OpportunityNotFoundError,
    InvalidArgumentsError,
    AccountNotFoundError,
    InvestmentNotFoundError,
    PidAlreadyExistsError,
    VariableSymbolAlreadyExistsError,
    BankIDTokenError,
    BankIDCodeError,
    InvalidUTF8StringError,
    TokenExpiredError,
    UnknownAdGroupError,
    UserWhoDisagreesWithAgreementError,
    MaxFundraisingTargetOverflowError,
    NotEnoughBalanceError,
    InvestmentBelowMinInvestmentError,
    FundraisingNotAvailableError,
    AgeNotMetError,
    AffiliateNotFoundError,
    UnsupportedCompanyTypeError,
    UnsupportedParentCompany,
    CompanyNotFoundError,
    WrongCompanyIdFormatError,
    DistributionPartnerAccountNotFoundError,
    BankIDDuplicateIdentityError,
    IdentityNotLinkedError,
    InvalidGrantError,
    AlreadySignedUpError,
    InvalidTokenError,
    MissingTokenError,
    MissingValueError,
    InvalidBankIDData,
    InvalidCurrencyError,
    UnverifiedBankAccountError,
    WithdrawalRequestNotFoundError,
    PhoneVerificationInitError,
    InvalidAccountStatusError,
    AuthCallbackError,
    AccountClosedError,
    InvalidDistributionPartnerAccountError,
    CompanyMissingStatutarniOrganError,
    WorkerJobNotFoundError,
    ProhibitedActionError,
    BankAccountAlreadyExistsError,
    DocumentCheckError,
} as const
