import React, { useCallback, useContext, useEffect, useState } from 'react'
import { CURRENCIES, Currency } from '@upvestcz/common/currency'
import { parseCookies, setCookie } from '@upvestcz/common/cookies'
import { useIsMounted } from '@upvestcz/common/hooks'

type CallbackFunction = (newCurrency: Currency) => void

export const CURRENCY_COOKIE_KEY = 'currency'

type CurrencyContext = { currency: Currency; handleCurrencyChange: CallbackFunction }

const CurrencyContext = React.createContext<CurrencyContext>({
    currency: CURRENCIES.CZK,
    handleCurrencyChange: () => null,
})

const useCurrencyProvider = () => {
    return useContext(CurrencyContext)
}

const CurrencyProvider = ({
    defaultCurrency,
    useCookie = true,
    children,
}: {
    defaultCurrency?: Currency
    useCookie?: boolean
    children: React.ReactNode | ((context: CurrencyContext) => React.ReactNode)
}) => {
    const cookies = parseCookies()
    const { currency: parentCurrency } = useCurrencyProvider()

    useEffect(() => {
        // if should use cookie and cookie is missing, set to the fallback value from CurrencyContext
        if (useCookie && !cookies[CURRENCY_COOKIE_KEY])
            setCookie({}, CURRENCY_COOKIE_KEY, parentCurrency)
    }, [cookies[CURRENCY_COOKIE_KEY], parentCurrency, useCookie])

    const defaultValue = ((useCookie ? cookies[CURRENCY_COOKIE_KEY] : false) ||
        defaultCurrency ||
        parentCurrency) as Currency // parentCurrency in top level provider is the default value of React.createContext

    const [currency, setCurrency] = useState<Currency>(defaultValue)

    const handleCurrencyChange = useCallback(
        (newCurrency: Currency) => {
            if (useCookie) setCookie({}, CURRENCY_COOKIE_KEY, newCurrency)
            setCurrency(newCurrency)
        },
        [useCookie],
    )

    const hasRun = useIsMounted()

    useEffect(() => {
        if (hasRun) {
            // sync with parentCurrency changing
            handleCurrencyChange(parentCurrency)
        }
    }, [handleCurrencyChange, parentCurrency]) // * hasRun deliberately omitted to avoid re-rendering on mount

    const context: CurrencyContext = {
        currency,
        handleCurrencyChange,
    }

    return (
        <CurrencyContext.Provider value={context}>
            {typeof children === 'function' ? children(context) : children}
        </CurrencyContext.Provider>
    )
}

const withCurrencyProvider =
    (Component: React.ComponentType, props = {}) =>
    (componentProps: Record<string, unknown>) => {
        return (
            <CurrencyProvider {...props}>
                <Component {...componentProps} />
            </CurrencyProvider>
        )
    }

export { CurrencyProvider, useCurrencyProvider, withCurrencyProvider }
