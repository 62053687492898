import React, { createContext, FormEvent, useCallback, useContext, useState } from 'react'
import * as R from 'ramda'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { FormikProps, FormikState } from 'formik'
import { NextPage } from 'next'
import { TFunction } from 'i18next'
import {
    createValidationSchemaPhysical as createValidationSchemaPhysicalOriginal,
    createValidationSchemaCorporate as createValidationSchemaCorporateOriginal,
    createValidationSchemaBankID as createValidationSchemaBankIDOriginal,
    createValidationSchemaBankIDSignUp as createValidationSchemaBankIDSignUpOriginal,
} from '@upvestcz/common/validation/schemas/registration'
import { replace } from '@upvestcz/common/routing'
import { formatPid } from '@upvestcz/common/account-utils'
import {
    getUserRegistrationFlowUrl,
    REGISTRATION_TYPE,
    REGISTRATION_TYPE_BASE_URL,
    RegistrationInitialValues,
} from '@upvestcz/common/registration'
import { useTranslation } from 'react-i18next'
import { ERROR_CODES } from '@upvestcz/common/errors'
import { UPVEST_EMAIL } from '@upvestcz/common/constants'
import { checkEmailUnique, createAccount, isPidUnique as isPidUniqueApiCall } from './api'
import { useAuth } from '../store/auth'
import { useAccount } from '../store/account'
import { useToast } from './toast'
import logger from './logger'

const isPidUnique = (pid: string) =>
    isPidUniqueApiCall({ pid }).then(R.path(['data', 'isPidUnique'])) as Promise<boolean>

export const isEmailUnique = (email: string) =>
    checkEmailUnique({ email }).then(R.path<boolean>(['data', 'isUnique'])) as Promise<boolean>

export const createValidationSchemaCorporate = (t: TFunction) =>
    createValidationSchemaCorporateOriginal(t)

export const createValidationSchemaPhysical = (t: TFunction) =>
    createValidationSchemaPhysicalOriginal({ isPidUnique, t })

export const createValidationSchemaBankID = (t: TFunction) =>
    createValidationSchemaBankIDOriginal({ isPidUnique, t })

export const createValidationSchemaBankIDSignUp = (t: TFunction) =>
    createValidationSchemaBankIDSignUpOriginal({ isPidUnique, t, isEmailUnique })

export type RegistrationFormContext = {
    formik: FormikProps<RegistrationInitialValues>
    syncState: SyncStateFn
    isLoading: boolean
    createSubmitHandler: (
        fieldsToValidate: (keyof RegistrationInitialValues)[],
        url?: string,
    ) => (e: FormEvent<HTMLFormElement>) => Promise<boolean>
    getFieldError: (field: keyof RegistrationInitialValues) => string | string[] | false | undefined
}

export type RegistrationFormCorporatePerson = RegistrationInitialValues['corporate_persons'][number]
export const RegistrationFormContext = createContext<RegistrationFormContext | EmptyObject>({})

export const useRegistrationForm = () => useContext(RegistrationFormContext)

export type SyncStateFn = (options?: { onlyInputs?: string[] }) => Promise<void>

export const useSyncState = (
    formik: FormikState<TODO>,
): {
    isLoading: boolean
    syncState: SyncStateFn
} => {
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [account, dispatchAccount] = useAccount()
    const addToast = useToast()
    const [auth] = useAuth()
    const { t } = useTranslation()

    const syncState = useCallback(
        ({ onlyInputs }: { onlyInputs?: string[] } = {}) => {
            const { auth_id } = auth.profile

            const data = {
                email: auth.profile.email,
                ...account,
                ...formik.values,
            }

            setIsLoading(true)

            return createAccount(
                auth_id,
                // replace empty strings with null
                R.pipe(
                    onlyInputs ? R.pick(onlyInputs) : (val: any) => val,
                    R.map(value => (value === '' ? null : value)),
                    // @ts-ignore
                    R.evolve({
                        pid: pid => (!pid ? null : formatPid(pid)),
                    }),
                    /*
                     * if iban has not changed omit iban and iban_eur from update.
                     * This prevents unnecessary updates of bank_accounts table.
                     */
                    R.omit(
                        (['iban', 'iban_eur'] as const).filter(
                            field => account?.[field] === formik.values[field],
                        ),
                    ),
                )(
                    // @ts-ignore
                    data,
                ),
            )
                .then((res: TODO) => dispatchAccount({ type: 'set', payload: res.data }))
                .catch(err => {
                    addToast({
                        type: 'error',
                        message: t('Při synchronizaci dat se serverem došlo k chybě'),
                    })

                    logger.error(err)
                    throw err
                })
                .finally(() => {
                    setIsLoading(false)
                })
        },
        [account, addToast, auth.profile, dispatchAccount, formik.values, t],
    )

    return {
        isLoading,
        syncState,
    }
}

export type RegistrationTypeGuardFn = (context: { requiredFlowURL: string; ctx: TODO }) => boolean

export const nonCorporateRegistrationTypeGuard: RegistrationTypeGuardFn = ({ requiredFlowURL }) => {
    // just disallow corporate flows from accessing this page.
    return requiredFlowURL !== REGISTRATION_TYPE_BASE_URL[REGISTRATION_TYPE.CORPORATE]
}

export const defaultRegistrationTypeGuard: RegistrationTypeGuardFn = ({ requiredFlowURL, ctx }) => {
    // get fisrt two url segments to identify the current flow
    const currentFlowURL = ctx.pathname.split('/').slice(0, 3).join('/') // use pathname to get url without i18n prefix

    // if current URL does not contain the correct one
    return currentFlowURL === requiredFlowURL
}

export const withRegistrationTypeGuard =
    (fn: RegistrationTypeGuardFn = defaultRegistrationTypeGuard) =>
    (ProtectedComponent: NextPage) => {
        const ComponentWithRegistrationTypeGuard = (props: Record<string, TODO>) => (
            // @ts-ignore
            <ProtectedComponent {...props} />
        )

        // We want to hoist statics like `getLayout`
        hoistNonReactStatics(ComponentWithRegistrationTypeGuard, ProtectedComponent)

        // It's important to put the `getInitialProps` static method
        // AFTER the `hoistNonReactStatics(...)` so that the implementation
        // below is not overridden by the `getInitialProps` static method
        // on the `ProtectedComponent` component
        ComponentWithRegistrationTypeGuard.getInitialProps = (ctx: TODO) => {
            const { account } = ctx.data
            const requiredFlowURL = getUserRegistrationFlowUrl(account)

            // if comparison function returns falsy
            if (!fn({ ctx, requiredFlowURL })) {
                replace({
                    ctx,
                    location: requiredFlowURL,
                })

                return {}
            }

            // all good, continue
            if (typeof ProtectedComponent.getInitialProps === 'function') {
                return ProtectedComponent.getInitialProps(ctx)
            }

            return {}
        }

        return ComponentWithRegistrationTypeGuard
    }

export const saveBankAccountOptionsToLocalStorage = (bankAccountOptions: Array<string>) =>
    localStorage.setItem('@@upvest/bankAccountOptions', JSON.stringify(bankAccountOptions))

export const getBankAccountOptionsFromLocalStorage = () =>
    JSON.parse(localStorage.getItem('@@upvest/bankAccountOptions') || 'null')

export const removeBankAccountOptionsFromLocalStorage = () =>
    localStorage.removeItem('@@upvest/bankAccountOptions')

export const saveAccountLinkTokenToLocalStorage = (token: string) =>
    localStorage.setItem('@@upvest/accountLinkToken', token)

export const getAccountLinkTokenFromLocalStorage = () =>
    localStorage.getItem('@@upvest/accountLinkToken')

export const removeAccountLinkTokenFromLocalStorage = () =>
    localStorage.removeItem('@@upvest/accountLinkToken')

export const getBankIDErrorData = ({
    t,
    errorCode,
    errorMessage,
    isSignup = false,
}: {
    t: TFunction
    errorCode: string
    errorMessage?: string
    isSignup?: boolean
}) => {
    switch (errorCode) {
        case ERROR_CODES.PID_ALREADY_EXISTS:
            return {
                title: t('Rodné číslo již registrováno'),
                body: t('Uživatel s vaším rodným číslem je již na platformě Upvest registrován.'),
            }
        case ERROR_CODES.AGE_NOT_MET:
            return {
                title: t('Věková hranice nebyla dosažena'),
                body: t('Pro investování na Upvestu musíte být starší 18 let'),
            }

        case ERROR_CODES.WRONG_BANK_ID_TOKEN:
            return {
                title: t('Neplatný odkaz'),
                body: t(
                    'Odkaz pro registraci není platný. Je možné, že jeho platnost vypršela. Zkuste si nechat znovu zaslat registrační SMS.',
                ),
            }

        case ERROR_CODES.WRONG_BANK_ID_CODE:
            return {
                title: t('Neplatný autorizační kód'),
                body: t(
                    'Jednorázový autorizační kód BankID není platný. Je možné, že jeho platnost vypršela. Zkuste zahájit registraci přes BankID znovu.',
                ),
            }
        case ERROR_CODES.INVALID_BANKID_DATA:
            return {
                title: t('Údaje jsou nekompletní'),
                body: t(
                    'Data předaná ze serverů BankID jsou nekompletní pro registraci na Upvestu. Prosím pokračujte registrací bez BankID.',
                ),
                button: {
                    url: isSignup ? '/signup' : '/user/registration',
                    text: t('Pokračovat bez BankID'),
                },
            }
        case ERROR_CODES.USER_DENIED_ACCESS:
            return {
                title: t('Předání údajů bylo neúspěšné'),
                body: t(
                    'Odmítli jste předání údajů nutných pro registraci na Upvestu. Zkuste prosím zahájit registraci znovu.',
                ),
            }
        default:
            // FIXME: TEMP for bankID KB error https://github.com/upvest-cz/upvest-app/issues/2014
            if (
                errorCode === 'access_denied' &&
                errorMessage === 'Failed to get resource owner session from request'
            ) {
                return {
                    title: t('Komunikace s bankou selhala'),
                    body: t(
                        'Odhlaste se z ostatních aplikací vaší banky nebo otevřete upvest v anonymním okně či jiném prohlížeči a zkuste to znovu.',
                    ),
                }
            }
            return {
                title: t('Došlo k neočekávané chybě'),
                body: t(
                    'Při synchronizaci dat došlo k neočekávané chybě. Zkuste to prosím znovu a kdyby obtíže přetrvávaly, kontaktuje prosím podporu Upvest na {{email}}.',
                    { email: UPVEST_EMAIL },
                ),
            }
    }
}
