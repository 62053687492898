const table = {
    baseStyle: {
        table: {
            borderTop: '1px solid #E1E1E1',
        },
        th: {
            fontFamily: 'body',
            textTransform: 'none',
            fontWeight: 400,
        },
    },
    variants: {
        simple: {
            th: {
                color: 'textSecondary',
                borderColor: '#E1E1E1',
            },
            td: {
                borderColor: '#E1E1E1',
            },
        },
    },
    sizes: {
        md: {
            th: {
                fontSize: 'sm',
                letterSpacing: '-0.02083335714em',
                py: 2,
                px: 4,
                _first: {
                    pl: 2,
                },
                _last: {
                    pr: 2,
                },
            },
            td: {
                py: 3,
                px: 4,
                _first: {
                    pl: 2,
                },
                _last: {
                    pr: 2,
                },
            },
        },
    },
}

export default table
