export default {
    baseStyle: {
        fontWeight: 'normal',
        color: 'textSecondary',
        mb: 1,
    },
    variants: {
        secondary: {
            fontSize: 'xs',
            textTransform: 'uppercase',
            color: 'textLight',
        },
    },
}
