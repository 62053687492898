import React, { Fragment, ReactNode, useEffect, useMemo } from 'react'
import { useRouter } from 'next/router'
import { AnnouncementBar, Container, icons, Link, Text } from '@upvestcz/shared-components'
import { NAVBAR_BREAKPOINT } from '@upvestcz/shared-components/theme/v2/variables'
import { Trans, useTranslation } from 'react-i18next'
import { Currency } from '@upvestcz/common/currency'
import { ACCOUNT_STATUS } from '@upvestcz/common/account-utils'
import Navbar from '../Navbar'
import { Footer } from '../Footer'
import { useAuth } from '../../store/auth'
import { useAccount } from '../../store/account'
import { InvestmentThanksModalRenderer } from '../InvestmentThanksModal'
import { useCurrencyProvider } from '../CurrencyProvider'

const AppLayout = ({
    children,
    announcements = [],
}: {
    children: ReactNode
    announcements?: ReactNode[]
}) => {
    const [auth] = useAuth()
    const [account] = useAccount()
    const { t } = useTranslation()

    const completeAnnouncements = useMemo<Array<ReactNode>>(() => {
        const duplicateAnnouncements = [...announcements]
        const shouldDisplayTaxAnnouncement = new Date().getMonth() < 4 // up to 01.05.
        if (auth.loggedIn && account) {
            if (shouldDisplayTaxAnnouncement) {
                duplicateAnnouncements.push(
                    <AnnouncementBar bg="highlightSuccess">
                        <Text>
                            <Trans t={t}>
                                Podklady k daňovému přiznání za rok{' '}
                                {{ year: new Date().getFullYear() - 1 }} nyní naleznete ve{' '}
                                <Link variant="contextualLink" to="/user/profile#dane">
                                    svém profilu
                                </Link>
                                .
                            </Trans>
                        </Text>
                    </AnnouncementBar>,
                )
            }

            if (account.status === ACCOUNT_STATUS.SUSPENDED) {
                duplicateAnnouncements.push(
                    <AnnouncementBar bg="highlightWarning">
                        <Text>
                            <Trans t={t}>
                                Váš účet byl pozastaven. Více informací naleznete ve{' '}
                                <Link
                                    variant="contextualLink"
                                    to="/user/profile"
                                    whiteSpace="nowrap"
                                >
                                    svém profilu
                                </Link>
                                .
                            </Trans>
                        </Text>
                    </AnnouncementBar>,
                )
            }

            duplicateAnnouncements.unshift(
                <AnnouncementBar
                    display={{
                        base: 'flex',
                        [NAVBAR_BREAKPOINT]: 'none',
                    }}
                >
                    <icons.WalletIcon height="1.1em" mt="-1px" mr={1} />
                    {t('{{amount,formatAmount}}', {
                        amount: { value: account.balance, currency: 'CZK' },
                    })}
                    {!!account.balance_eur &&
                        ` / ${t('{{amount,formatAmount}}', {
                            amount: { value: account.balance_eur, currency: 'EUR' },
                        })}`}
                </AnnouncementBar>,
            )
        }
        return duplicateAnnouncements
    }, [account, announcements, auth.loggedIn, t])

    const router = useRouter()

    const { handleCurrencyChange } = useCurrencyProvider()

    useEffect(() => {
        const { currency: urlCurrency } = router.query
        if (urlCurrency) {
            handleCurrencyChange(urlCurrency as Currency)
        }
    }, [router.query])

    return (
        <>
            {completeAnnouncements.map((el, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={index}>{el}</Fragment>
            ))}
            <Navbar />
            {/* ::before fills in the space above the white background arrow (under navbar) with white to have some visual continuity */}
            <Container
                background="transparent"
                position="relative"
                _before={{
                    content: '""',
                    height: '999px',
                    position: 'absolute',
                    bottom: '100%',
                    left: 0,
                    right: 0,
                    background: 'white',
                }}
                flexGrow={1}
            >
                {children}
            </Container>
            <Footer />
            <InvestmentThanksModalRenderer />
        </>
    )
}

export const getAppLayout = (page: ReactNode, props = {}) => (
    <AppLayout {...props}>{page}</AppLayout>
)

export default AppLayout
