import React, { useState, useEffect, useCallback } from 'react'
import { useTransition, animated } from 'react-spring'
import { useTranslation, Trans } from 'react-i18next'
import { destroyCookie, getTopDomain, parseCookies } from '@upvestcz/common/cookies'
import {
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    FormLabel,
    Switch,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    Stack,
    Box,
    Text,
    Button,
    Heading,
    useMultiStyleConfig,
    useTheme,
} from '@chakra-ui/react'
import { useFormik } from 'formik'
import { useCookieConsentContext } from '@use-cookie-consent/react'
import { withSafeArea } from '@upvestcz/common/styles-utils'
import Link from './Link'
import { AccordionPlusIcon } from './Accordion/AccordionPlusIcon'
import { useScreenBottomStackingContext } from './contexts/screenBottomStackingContext'
import { ApplyLayerStyle } from './v3'

function CookieBar() {
    const { t } = useTranslation()
    const [shouldShow, setShouldShow] = useState(false)
    const { acceptCookies, consent } = useCookieConsentContext()

    const theme = useTheme()

    const styles = useMultiStyleConfig('CookieBar', {})

    useEffect(() => {
        const hasAcceptedOldCookies = parseCookies().cookieConsent
        const hasChosenNewCookies = !(
            typeof consent.statistics === 'undefined' && typeof consent.marketing === 'undefined'
        )

        // if cookie was accepted, the app doesn't show the CookieBar
        // for users that accepted old cookie policy the new cookie
        // is accepted automatically
        if (!hasAcceptedOldCookies && !hasChosenNewCookies) {
            setShouldShow(true)
        } else if (hasAcceptedOldCookies && !hasChosenNewCookies) {
            acceptCookies({ necessary: true, statistics: true, marketing: true })
        }

        // we need to get rid of the old cookie
        destroyCookie({}, 'cookieConsent', {
            domain: getTopDomain(),
        })
    }, [])

    const transition = useTransition(shouldShow, {
        from: { opacity: 0, transform: 'translateY(-16px)' },
        enter: { opacity: 1, transform: 'translateY(0%)' },
        leave: { opacity: 0, transform: 'translateY(16px)' },
    })

    const AnimatedBox = animated(Box)

    const { isOpen, onOpen, onClose } = useDisclosure()

    const formik = useFormik({
        initialValues: {
            necessary: true,
            statistics: consent.statistics || false,
            marketing: consent.marketing || false,
        },
        enableReinitialize: true,
        onSubmit: values => {
            const { statistics, marketing } = values
            acceptCookies({ necessary: true, statistics, marketing })

            // closes modal
            onClose()

            // closes cookie bar
            setShouldShow(false)
        },
    })

    const onAcceptAll = useCallback(async () => {
        await formik.setValues({
            ...formik.values,
            marketing: true,
            statistics: true,
        })
        formik.handleSubmit()
    }, [formik])

    const { total: bottomOffset } = useScreenBottomStackingContext()

    return transition((style, item) => {
        return (
            item && (
                <ApplyLayerStyle layerStyle="section-steel">
                    {layerStyleProps => (
                        <AnimatedBox
                            style={style}
                            position="fixed"
                            // bottom={{ base: `${APP_MENU_HEIGHT}px`, [NAVBAR_BREAKPOINT]: 0 }}
                            bottom={withSafeArea(bottomOffset, 'bottom')}
                            left={0}
                            right={0}
                            zIndex={10}
                            backgroundColor="#EBEBEB"
                            boxShadow="0px 8px 16px rgba(0, 0, 0, 0.1);"
                            py={2}
                            {...layerStyleProps}
                        >
                            <Box
                                display={{ md: 'flex' }}
                                px={4}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <Text>
                                    {t(
                                        'Prohlížením těchto stránek souhlasíte s využíváním souborů cookies ve vašem prohlížeči.',
                                    )}
                                    &nbsp;
                                    <Link
                                        to="/cookies"
                                        blank
                                        variant={theme.version === 2 ? 'primary' : 'underline'}
                                    >
                                        {t('Více\u00A0informací\u00A0zde')}
                                    </Link>
                                </Text>
                                <Button
                                    onClick={onOpen}
                                    ml={{ md: 4 }}
                                    mt={{ base: 2, md: 0 }}
                                    width={{ base: '100%', md: 'auto' }}
                                    size="sm"
                                >
                                    {t('Možnosti')}
                                </Button>
                                <Modal isOpen={isOpen} onClose={onClose} size="xl">
                                    <ModalOverlay />
                                    <ModalContent
                                        my="auto"
                                        sx={styles.ModalContent}
                                        containerProps={{ py: 20 }}
                                        px={{ base: 2, md: 6 }}
                                        py={6}
                                        layerStyle="section-steel"
                                    >
                                        <ModalHeader>
                                            <Heading size="md">
                                                {t('Vaše data, vaše volba')}
                                            </Heading>
                                        </ModalHeader>
                                        <ModalCloseButton onClick={onClose} top={6} right={6} />
                                        <ModalBody>
                                            <Trans t={t}>
                                                <Text fontSize="sm">
                                                    Pro chod webové aplikace Upvestu používáme tzv.
                                                    cookies, které nám dovolují zlepšovat naše
                                                    služby a chod naší aplikace. Pro tento účel
                                                    využíváme 3 typy cookies, níže se o nich můžete
                                                    dozvědět více.
                                                </Text>
                                            </Trans>
                                            <Accordion allowMultiple mt={4} size="sm">
                                                <AccordionItem>
                                                    <AccordionButton>
                                                        <Text sx={styles.ModalAcordionItem}>
                                                            {t('Nezbytné cookies')}
                                                        </Text>
                                                        <AccordionPlusIcon height="1em" />
                                                    </AccordionButton>
                                                    <AccordionPanel sx={styles.ModalAccordionPanel}>
                                                        <Trans t={t}>
                                                            Tyto cookies jsou nezbytné pro správný
                                                            chod naší aplikace a tudíž není možné je
                                                            vypnout.
                                                        </Trans>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionButton>
                                                        <Text sx={styles.ModalAcordionItem}>
                                                            {t('Statistické cookies')}
                                                        </Text>
                                                        <AccordionPlusIcon height="1em" />
                                                    </AccordionButton>
                                                    <AccordionPanel sx={styles.ModalAccordionPanel}>
                                                        <Trans t={t}>
                                                            Tyto cookies jsou používány, abychom
                                                            pochopili, jak uživatelé používají naší
                                                            aplikaci. Tyto data jsou odesílána
                                                            anonymně.
                                                        </Trans>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                                <AccordionItem>
                                                    <AccordionButton>
                                                        <Text sx={styles.ModalAcordionItem}>
                                                            {t('Marketingové cookies')}
                                                        </Text>
                                                        <AccordionPlusIcon height="1em" />
                                                    </AccordionButton>
                                                    <AccordionPanel sx={styles.ModalAccordionPanel}>
                                                        <Trans t={t}>
                                                            Marketingové cookies nám pomáhají
                                                            porozumět správnému nastavení reklam
                                                            tak, aby se Vám naše reklamy zbytečně
                                                            nezobrazovaly v případě, že pro Vás
                                                            nejsou relevantní.
                                                        </Trans>
                                                    </AccordionPanel>
                                                </AccordionItem>
                                            </Accordion>
                                            <Stack direction="row" w="100%" mt={4}>
                                                <FormControl
                                                    as={Stack}
                                                    sx={styles.FormControlStack}
                                                >
                                                    <Switch
                                                        isDisabled
                                                        isChecked
                                                        sx={styles.ModalSwitch}
                                                    />
                                                    <FormLabel sx={styles.ModalSwitchLabel}>
                                                        {t('Nezbytné cookies')}
                                                    </FormLabel>
                                                </FormControl>
                                                <FormControl
                                                    as={Stack}
                                                    sx={styles.FormControlStack}
                                                >
                                                    <Switch
                                                        onChange={({ target }) => {
                                                            formik.setFieldValue(
                                                                'statistics',
                                                                target.checked,
                                                            )
                                                        }}
                                                        colorScheme="#74fbd6"
                                                        isChecked={formik.values.statistics}
                                                        sx={styles.ModalSwitch}
                                                    />
                                                    <FormLabel sx={styles.ModalSwitchLabel}>
                                                        {t('Statistické cookies')}
                                                    </FormLabel>
                                                </FormControl>
                                                <FormControl
                                                    as={Stack}
                                                    sx={styles.FormControlStack}
                                                >
                                                    <Switch
                                                        onChange={({ target }) => {
                                                            formik.setFieldValue(
                                                                'marketing',
                                                                target.checked,
                                                            )
                                                        }}
                                                        isChecked={formik.values.marketing}
                                                        sx={styles.ModalSwitch}
                                                    />
                                                    <FormLabel sx={styles.ModalSwitchLabel}>
                                                        {t('Marketingové cookies')}
                                                    </FormLabel>
                                                </FormControl>
                                            </Stack>
                                        </ModalBody>

                                        <ModalFooter>
                                            <Button
                                                mr={3}
                                                onClick={formik.submitForm}
                                                variant="outline"
                                                ml={{ md: 4 }}
                                                mt={{ base: 2, md: 0 }}
                                                width={{ base: '100%', md: 'auto' }}
                                                size="sm"
                                                colorScheme="blackWhite"
                                            >
                                                {t('Potvrdit výběr')}
                                            </Button>
                                            <Button
                                                onClick={onAcceptAll}
                                                ml={{ md: 4 }}
                                                mt={{ base: 2, md: 0 }}
                                                width={{ base: '100%', md: 'auto' }}
                                                size="sm"
                                            >
                                                {t('Přijmout vše')}
                                            </Button>
                                        </ModalFooter>
                                    </ModalContent>
                                </Modal>
                                <Button
                                    onClick={onAcceptAll}
                                    ml={{ md: 4 }}
                                    mt={{ base: 2, md: 0 }}
                                    width={{ base: '100%', md: 'auto' }}
                                    size="sm"
                                >
                                    {t('Přijmout vše')}
                                </Button>
                            </Box>
                        </AnimatedBox>
                    )}
                </ApplyLayerStyle>
            )
        )
    })
}

export default CookieBar
