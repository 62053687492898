export const UPVEST_ICO = '05835526'
export const KB_ICO = '45317054'
export const UPVEST_BANK_ACCOUNT_NUMBER = '7401552'
export const UPVEST_BANK_ACCOUNT_CODE = '0100'
export const UPVEST_BANK_ACCOUNT_NUMBER_EUR = '7600287'
export const UPVEST_MAIN_BANK_ACCOUNT_NUMBER = '7200267'
export const UPVEST_MAIN_BANK_ACCOUNT_CODE = '0100'
export const UPVEST_BANK_ACCOUNT_STRING = `${UPVEST_BANK_ACCOUNT_NUMBER}/${UPVEST_BANK_ACCOUNT_CODE}`
export const UPVEST_BANK_ACCOUNT_IBAN = 'CZ2201000000000007401552'
export const UPVEST_BANK_ACCOUNT_IBAN_EUR = 'CZ2301000000000007600287'
export const UPVEST_BANK_BIC_SWIFT = 'KOMBCZPPXXX'
export const UPVEST_BANK_ACCOUNT_STRING_EUR = `${UPVEST_BANK_ACCOUNT_NUMBER_EUR}/${UPVEST_BANK_ACCOUNT_CODE}`
export const UPVEST_ADDRESS_LINE_1 = 'Jindřišská 937/16'
export const UPVEST_ADDRESS_LINE_2 = 'Nové Město'
export const UPVEST_POSTAL_CODE = '110 00'
export const UPVEST_CITY = 'Praha 1'
export const UPVEST_ADDRESS_LINE_3 = `${UPVEST_POSTAL_CODE} ${UPVEST_CITY}`
export const UPVEST_ADDRESS = `${UPVEST_ADDRESS_LINE_1}, ${UPVEST_ADDRESS_LINE_2}, ${UPVEST_ADDRESS_LINE_3}`
export const KB_DISTRIBUTION_PARTNER_ID = 3
export const UPVEST_GOOGLE_MAPS_URL = 'https://goo.gl/maps/XVnFjBjiLJZ1ky7Z6'
export const UPVEST_LP_BASE_URL = 'https://www.upvest.cz'
export const UPVEST_APP_BASE_URL = 'https://app.upvest.cz'
export const UPVEST_ADMIN_BASE_URL = 'https://admin.upvest.cz'
export const UPVEST_BLOG_BASE_URL = 'https://blog.upvest.cz'
export const UPVEST_EMAIL = 'info@upvest.cz'
export const SHAREHOLDER_EQUITY_DISCLOSURE_THRESHOLD = 250000
export const MAILCHIMP_LIST_ID_PRODUCTION = '70dc323637'
export const MAILCHIMP_REVIEW_LIST_ID_PRODUCTION = 'a6f12a4dfe'
export const MAILCHIMP_ZFP_LIST_ID_PRODUCTION = '0896f3d3c4'
export const UPVEST_COMPANY_NAME = 'upvest s.r.o.'
export const KB_DISTRIBUTION_AGREEMENT_SIGN_DATE = '2021-09-30T00:00:00+02:00'
export const OPERATING_TIMEZONE = 'Europe/Prague'
export const OPPORTUNITY_ORDER = [171, 129, 175, 173, 134, 170, 94, 136]
export const UPVEST_PHONE_NUMBER = '+420 773 633 925'
export const MAGIC_SMS_CODE = '6969'
export const MAGIC_UPLOAD_TOKEN = 'my-DNA-is-USA'
export const MAGIC_INVESTMENT_THRESHOLD_FOR_INVESTMENT_CLUB = 1000000000
export const KB_MARKETING_AGREEMENT =
    'https://d3uvu5yktntepe.cloudfront.net/agreement_sets/souhlas_upvest_klient_marketing.pdf'
export const DEFAULT_EUR_RATE = 25
export const UPVEST_EMPLOYEE_ACCOUNTS = [
    69, // Petr Volny
    74, // David M.
    8210, // Matej
    4769, // Jirka
    12713, // Petr Ver
    6289, // Arnošt
    13652, // Marek
    6579, // Karolína
    13929, // Nikola
    87, // Lukas
    25440, // Marek M
    23027, // Štěpán
]

/*
Before deleting someone from this list, ensure they don't have any active investments.
Otherwise, ther investments would be reported without a KBSS flag in our daily KB reporting.
 */
export const KBSS_EMPLOYEE_ACCOUNTS = [
    2258, // Nový
    2286, // Přibyl
    2560, // Rothbauer
    9610, // Hollmanová
]

export const EMPLOYEE_BENEFIT_INVESTED_LIMIT = 2_000_000

export const POSTGRES_MAX_INT = 2147483647
