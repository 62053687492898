import { useEffect, useRef } from 'react'

// elements, callback and options have to be memoized!
export const useIntersectionObserver = (elements, callback, options = {}) => {
    const observer = useRef(null)
    useEffect(() => {
        if (observer.current) observer.current.disconnect()

        observer.current = new IntersectionObserver(callback, {
            // has to be here otherwise does not work correctly in storybook iframe
            // and safari does not support document as root.
            ...(window.self !== window.top ? { root: document } : {}),
            ...options,
        })
        elements.forEach(el => el && observer.current.observe(el))

        return () => observer.current.disconnect()
    }, [observer, elements, options, callback])

    return observer.current
}
