import { ReactNode } from 'react'
import { Box, BoxProps, forwardRef, useMultiStyleConfig } from '@chakra-ui/react'
import { CONTAINER_PX as PX } from './theme/v2/variables'

/*
Don't use `variant` as a prop for this component.
it breaks the usage when Card is used as={OtherComponent} and OtherComponent nedds a `variant` prop
 */
const Card = forwardRef<
    {
        children: ReactNode
        hasHover?: boolean
        shadowVariant?: 'shadowSm'
        type?: string
    } & BoxProps,
    'div'
>(function Card({ children, hasHover = false, shadowVariant, type, sx, ...props }, ref) {
    const shadowY = shadowVariant === 'shadowSm' ? '4px' : '8px'
    const styles = useMultiStyleConfig('Card', {
        variant: type,
        styleConfig: {
            parts: ['Root'],
            baseStyle: {
                Root: {
                    position: 'relative',
                    backgroundColor: 'white',
                    boxShadow: `0px ${shadowY} 16px rgba(0, 0, 0, 0.1)`,
                    ...(hasHover
                        ? {
                              transition: 'box-shadow 0.26s ease',
                              _hover: {
                                  boxShadow: `0px ${shadowY} 24px rgba(0, 0, 0, 0.18)`,
                              },
                              _groupHover: {
                                  boxShadow: `0px ${shadowY} 24px rgba(0, 0, 0, 0.18)`,
                              },
                          }
                        : {}),
                },
            },
            variants: {
                rounded: {
                    Root: {
                        position: 'relative',
                        boxShadow: 'none',
                        borderRadius: '16px',
                        py: 10,
                        px: {
                            // on sm down scale same as ContentContainer padding
                            base: PX.base,
                            sm: PX.sm,
                            md: 10,
                        },
                    },
                },
            },
        },
    })

    return (
        <Box ref={ref} sx={{ ...styles.Root, ...sx }} {...props}>
            {children}
        </Box>
    )
})

export default Card
