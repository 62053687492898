import debounce from 'lodash.debounce'
import { NextPageContext } from 'next'
import Router, { useRouter } from 'next/router'
import * as R from 'ramda'
import { useCallback } from 'react'
import { getHashFromPath } from './utils'
import { ContextObjectLike, setCookie } from './cookies'

/*
 push - is used because client-side redirect in getInitialProps get executed before the new url is written to history.
 /login -> /signup (getInitialProps redirect) -> /signup/1 would replace /login with /signup/1 if push was not used.
 */
export const replace = ({
    ctx,
    location,
    cookies,
    push = true,
    forwardParams = true,
}: {
    ctx?: ContextObjectLike
    location: string | null
    cookies?: Record<string, string>
    push?: boolean
    forwardParams?: boolean
}) => {
    const res = ctx?.res

    if (cookies) {
        Object.entries(cookies).forEach(([key, value]) => {
            setCookie(ctx, key, value)
        })
    }

    const getNewURLString = (currentRelativeURL: string) => {
        const current = `${process.env.BASE_URL}${currentRelativeURL}`
        const oldURL = new URL(current)
        if (location === null) {
            return ctx?.asPath || Router.asPath
        }

        const newURL = new URL(location, current)
        if (forwardParams) {
            // merge current and new url params. New params have priority. Keep url hash as well.
            const params = new URLSearchParams({
                ...Object.fromEntries(oldURL.searchParams.entries()),
                ...Object.fromEntries(newURL.searchParams.entries()),
            })
            const hash = newURL.hash || oldURL.hash
            newURL.search = params.toString()
            newURL.hash = hash
        }

        return newURL.toString()
    }

    if (res) {
        // checking for a middleware data request prevents issue https://github.com/vercel/next.js/issues/39126
        const isDataReq = ctx.req?.headers['x-nextjs-data']

        if (!isDataReq) {
            if (location === null) {
                // null is used to redirect to the current page
                res.writeHead(push ? 307 : 302, {
                    Refresh: 0,
                })
            } else {
                // Server redirect
                res.writeHead(push ? 307 : 302, {
                    Location: getNewURLString(ctx?.asPath || ctx?.req?.url || ''),
                })
            }
        }

        res.end()
    } else {
        // eslint-disable-next-line no-lonely-if
        if (location === null) {
            // null is used to redirect to the current page
            Router.reload()
        } else {
            // Browser redirect
            // ctx.asPath keeps track of multiple chained redirects, Router.asPath does not - it's the current browser url.
            Router[push ? 'push' : 'replace'](getNewURLString(ctx?.asPath || Router.asPath))
        }
    }

    return {}
}

interface TransitionOptions {
    shallow?: boolean
    locale?: string | false
    scroll?: boolean
    replace?: boolean
}

export const routerHashNavigation = async (to: string, optionsProp: TransitionOptions = {}) => {
    const hashId = getHashFromPath(to)

    const defaultShallow = typeof optionsProp.shallow === 'boolean' ? optionsProp.shallow : true
    const defaultScroll = typeof optionsProp.scroll === 'boolean' ? optionsProp.scroll : true
    const defaultReplace = typeof optionsProp.replace === 'boolean' ? optionsProp.replace : true

    const options = {
        ...optionsProp,
        shallow: defaultShallow,
        scroll: defaultScroll,
        replace: defaultReplace,
    }

    /*
    Hack for NextJs not respecting {scroll: false} on hash navigation.
    Discussion: https://github.com/vercel/next.js/discussions/13804
    We remove the `id` before navigation, and put it back after, making Next not find an element to scroll to.
     */
    const scrollElement = document.getElementById(hashId)
    if (scrollElement) scrollElement.setAttribute('id', '')
    await Router[options.replace ? 'replace' : 'push'](
        {
            pathname: Router.pathname,
            query: Router.query,
            hash: hashId,
        },
        undefined,
        options,
    )
    await Promise.resolve() // wait for all state updates.
    if (scrollElement) {
        scrollElement.setAttribute('id', hashId)
        if (options.scroll) {
            requestAnimationFrame(() => {
                const debouncedHandleScroll = debounce(() => {
                    window.removeEventListener('scroll', debouncedHandleScroll)
                    window.scrollBy(0, 1)
                }, 100)
                window.addEventListener('scroll', debouncedHandleScroll)
                scrollElement?.scrollIntoView(true)
            })
        }
    }
}

export function isResSent(ctx: NextPageContext) {
    const { res } = ctx
    return res && (res.finished || res.headersSent)
}

export const useQueryParams = () => {
    const router = useRouter()

    const updateRouterQuery = useCallback((router: ReturnType<typeof useRouter>, push = false) => {
        if (push) {
            router.push(R.pick(['pathname', 'query'], router))
        } else {
            router.replace(R.pick(['pathname', 'query'], router))
        }
    }, [])

    const removeQueryParam = useCallback(
        (key: string, push = false) => {
            router.query = R.dissoc(key, router.query)
            updateRouterQuery(router, push)
        },
        [router, updateRouterQuery],
    )

    const setQueryParam = useCallback(
        (key: string, value: string | undefined, push = false) => {
            if (value === undefined) {
                removeQueryParam(key, push)
            } else {
                router.query[key] = value
                updateRouterQuery(router, push)
            }
        },
        [removeQueryParam, router, updateRouterQuery],
    )

    return {
        setQueryParam,
        removeQueryParam,
        router,
    }
}
