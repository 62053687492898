import React, { useCallback } from 'react'
import {
    Box,
    NavLink,
    NavContainer,
    NavItems,
    Button,
    Link,
    Logo,
    Flex,
    AppMenu,
    icons,
    AppMenuLink,
    AppMenuIcon,
    AppMenuText,
    NavModal,
    NavModalContainer,
    ContentContainer,
    HStack,
    Nbsp,
    DashedLine,
    Container,
    VStack,
    useBreakpointValue,
    useDisclosure,
    AppMenuLanguage,
    BoxProps,
    AppLocaleDropdown,
} from '@upvestcz/shared-components'
import { Dict } from '@chakra-ui/utils'
import {
    hasAccountFinishedRegistration,
    isAccountInvestmentClubMember,
    isAccountPrivMember,
} from '@upvestcz/common/account-utils'
import { APPS, useAppUrl } from '@upvestcz/common/apps'
import { useTranslation } from 'react-i18next'
import ReactGA from 'react-ga4'
import { useAccount } from '../store/account'
import ProfileDropdown from './ProfileDropdown'
import { useAuth } from '../store/auth'
import { useLogout } from '../lib/auth'

export const NavLocaleDropdownList = (props: BoxProps) => (
    <Box textStyle="buttonTextSm" fontWeight="normal" {...props} />
)

function Navbar() {
    const [account] = useAccount()
    const [auth] = useAuth()
    const logout = useLogout()
    const { t } = useTranslation()
    const hasInvestorAccount = hasAccountFinishedRegistration(account)
    const menuModal = useDisclosure()
    const isAboveLg = useBreakpointValue({ base: false, lg: true })
    const appUrl = useAppUrl()

    const menuModals = [menuModal]

    const styles: Dict = {
        NavModalContainer: {
            background: 'arrow',
            flex: 1,
            mx: 0,
            display: 'flex',
            flexDirection: 'column',
        },
        ModalMenuLink: {
            textStyle: 'buttonTextLg',
            border: 'none !important',
        },
    }

    const closeAll = () => {
        menuModals.forEach(modal => modal.onClose())
    }

    const handleRegisterClick = useCallback(() => {
        ReactGA.event('register_intent', {
            source: 'navbar',
        })
    }, [])

    const renderRightMenuItems = () => {
        if (auth.loggedIn) {
            // logged in
            if (hasInvestorAccount) {
                // account approved
                return [
                    <NavLink
                        isActive={false}
                        to="/user/dashboard"
                        key="wallet"
                        isEmphasised
                        textTransform="initial"
                    >
                        {t('{{amount,formatAmount}}', {
                            amount: { value: account!.balance, currency: 'CZK' },
                        })}
                        {!!account!.balance_eur &&
                            t(' / {{amount,formatAmount}}', {
                                amount: { value: account!.balance_eur, currency: 'EUR' },
                            })}
                    </NavLink>,
                    <ProfileDropdown key="profile-dropdown" />,
                ]
            }
            // account not approved yet
            return [
                <NavLink key="create-investment-account" to="/user/registration/" isEmphasised>
                    {t('Založit investiční účet')}
                </NavLink>,
                <NavLink key="login" to="/login" onClick={() => logout()}>
                    {t('Odhlásit se')}
                </NavLink>,
            ]
        }
        // not logged in
        return [
            <NavLink key="registration" to="/signup" onClick={handleRegisterClick}>
                {t('Registrace')}
            </NavLink>,
            <NavLink key="login" to="/login">
                {t('Přihlásit se')}
            </NavLink>,
        ]
    }

    return (
        <>
            <NavModalContainer>
                <NavContainer as="header" variant="app">
                    <Flex alignItems="center" justifyContent="flex-start" flex={1} pr={10}>
                        <Link aria-label="Upvest Logo" to={appUrl(APPS.LP, '/')} onClick={closeAll}>
                            <Logo />
                        </Link>
                    </Flex>
                    {auth.loggedIn ? (
                        <NavItems ml="auto">
                            <NavLink to="/user/dashboard">{t('Moje investice')}</NavLink>
                            <NavLink to="/user/opportunities">{t('Nabídka investic')}</NavLink>
                            <NavLink to="/faq">{t('FAQ')}</NavLink>
                            <NavLink to={appUrl(APPS.BLOG, '/')}>Insights</NavLink>
                        </NavItems>
                    ) : (
                        <NavItems ml="auto">
                            <NavLink to={appUrl(APPS.LP, '/')}>{t('Naše produkty')}</NavLink>
                            <NavLink to="/user/opportunities">{t('Nabídka investic')}</NavLink>
                            <NavLink to={appUrl(APPS.LP, '/investicni-proces')}>
                                {t('Proces')}
                            </NavLink>
                            <NavLink to={appUrl(APPS.LP, '/o-upvestu')}>{t('O Upvestu')}</NavLink>
                            <NavLink to="/faq">{t('FAQ')}</NavLink>
                            <NavLink to={appUrl(APPS.BLOG, '/')}>{t('Insights')}</NavLink>
                        </NavItems>
                    )}
                    <Flex alignItems="center" justifyContent="flex-end" flex={1} pl={10}>
                        <NavItems ml="auto">
                            {renderRightMenuItems()}
                            <AppLocaleDropdown />
                        </NavItems>
                    </Flex>
                </NavContainer>
                <AppMenu>
                    {auth.loggedIn && (
                        <AppMenuLink to="/user/dashboard" onClick={closeAll}>
                            <AppMenuIcon as={icons.BarChartIcon} />
                            <AppMenuText>{t('Investice')}</AppMenuText>
                        </AppMenuLink>
                    )}
                    <AppMenuLink to="/user/opportunities" onClick={closeAll}>
                        <AppMenuIcon as={icons.BuildingsIcon} />
                        <AppMenuText>{t('Příležitosti')}</AppMenuText>
                    </AppMenuLink>
                    <AppMenuLink to="/faq" onClick={closeAll}>
                        <AppMenuIcon as={icons.FaqIcon} />
                        <AppMenuText>{t('FAQ')}</AppMenuText>
                    </AppMenuLink>
                    {auth.loggedIn && (
                        <AppMenuLink to="/user/profile" onClick={closeAll}>
                            {/* padding of the icon fixes visual thickness and size compared to other icons. */}
                            <AppMenuIcon
                                {...(account &&
                                (isAccountInvestmentClubMember(account) ||
                                    isAccountPrivMember(account) ||
                                    account.kb_employee)
                                    ? {
                                          as: icons.InvestmentClubIcon,
                                          padding: '2px',
                                          ...(account.kb_employee ? { fill: '#e4242f' } : {}),
                                      }
                                    : { as: icons.PersonIcon })}
                            />
                            <AppMenuText>{t('Profil')}</AppMenuText>
                        </AppMenuLink>
                    )}
                    <AppMenuLink as="button" onClick={menuModal.onToggle}>
                        <AppMenuIcon as={icons.MenuIcon} isOpen={menuModal.isOpen} />
                        <AppMenuText>{t('Menu')}</AppMenuText>
                        <NavModal
                            {...menuModal}
                            isOpen={menuModal.isOpen && !isAboveLg}
                            display="flex"
                            flexFlow="column"
                        >
                            <Container
                                as={ContentContainer}
                                variant="app"
                                {...styles.NavModalContainer}
                            >
                                <VStack spacing={8} alignItems="flex-start">
                                    {auth.loggedIn ? (
                                        <>
                                            <NavLink
                                                {...styles.ModalMenuLink}
                                                onClick={closeAll}
                                                to="/user/messages"
                                            >
                                                {t('Zprávy')}
                                            </NavLink>
                                            <NavLink
                                                {...styles.ModalMenuLink}
                                                onClick={closeAll}
                                                to={appUrl(APPS.BLOG, '/')}
                                            >
                                                {t('Insights')}
                                            </NavLink>
                                        </>
                                    ) : (
                                        <>
                                            <NavLink
                                                {...styles.ModalMenuLink}
                                                onClick={closeAll}
                                                to={appUrl(APPS.LP, '/')}
                                            >
                                                {t('Naše produkty')}
                                            </NavLink>
                                            <NavLink
                                                {...styles.ModalMenuLink}
                                                onClick={closeAll}
                                                to="/user/opportunities"
                                            >
                                                {t('Nabídka investic')}
                                            </NavLink>
                                            <NavLink
                                                {...styles.ModalMenuLink}
                                                onClick={closeAll}
                                                to={appUrl(APPS.LP, '/investicni-proces')}
                                            >
                                                {t('Proces')}
                                            </NavLink>
                                            <NavLink
                                                {...styles.ModalMenuLink}
                                                onClick={closeAll}
                                                to={appUrl(APPS.LP, '/o-upvestu')}
                                            >
                                                {t('O Upvestu')}
                                            </NavLink>
                                            <NavLink
                                                {...styles.ModalMenuLink}
                                                onClick={closeAll}
                                                to="/faq"
                                            >
                                                {t('FAQ')}
                                            </NavLink>
                                            <NavLink
                                                {...styles.ModalMenuLink}
                                                onClick={closeAll}
                                                to={appUrl(APPS.BLOG, '/')}
                                            >
                                                {t('Insights')}
                                            </NavLink>
                                        </>
                                    )}
                                </VStack>
                                <Box mt="auto" />
                                <AppMenuLanguage mb={2} />
                                <DashedLine />
                                <HStack spacing={2} my={4}>
                                    {auth.loggedIn ? (
                                        <Button
                                            variant="outline"
                                            onClick={() => {
                                                closeAll()
                                                logout()
                                            }}
                                            flexGrow={1}
                                        >
                                            <Nbsp>{t('Odhlásit se')}</Nbsp>
                                        </Button>
                                    ) : (
                                        <>
                                            <Button
                                                flex={1}
                                                as={Link}
                                                onClick={() => {
                                                    handleRegisterClick()
                                                    closeAll()
                                                }}
                                                to="/signup"
                                            >
                                                <Nbsp>{t('Začít investovat')}</Nbsp>
                                            </Button>
                                            <Button
                                                variant="outline"
                                                as={Link}
                                                to="/login"
                                                onClick={closeAll}
                                                flex={1}
                                            >
                                                <Nbsp>{t('Přihlásit se')}</Nbsp>
                                            </Button>
                                        </>
                                    )}
                                </HStack>
                            </Container>
                        </NavModal>
                    </AppMenuLink>
                </AppMenu>
            </NavModalContainer>
        </>
    )
}

export default Navbar
