import React, { useEffect, useState } from 'react'
import { useRouter } from 'next/router'
import { OPPORTUNITY_TYPE, OpportunityType } from '@upvestcz/common/opportunities-utils'
import { isValueOfEnum } from '@upvestcz/common/utils'
import { useDisclosure } from '@chakra-ui/react'
import { useAuth } from '../../store/auth'
import { useAccount } from '../../store/account'
import { InvestmentThanksModal } from './InvestmentThanksModal'

const getThanksVariantOrUndefined = (val?: string) =>
    isValueOfEnum(OPPORTUNITY_TYPE, val) ? val : undefined

export const InvestmentThanksModalRenderer = () => {
    const [auth] = useAuth()
    const [account] = useAccount()
    const router = useRouter()
    const thanksParam = getThanksVariantOrUndefined(router.query.thanks as string | undefined)

    const [thanksModalVariant, setThanksModalVariant] = useState<OpportunityType | undefined>(
        thanksParam,
    )
    const investmentThanksModalProps = useDisclosure({
        defaultIsOpen: !!thanksParam,
    })

    useEffect(() => {
        if (thanksParam) {
            setThanksModalVariant(thanksParam)
            investmentThanksModalProps.onOpen() // open thanks modal
            router.replace(router.pathname, undefined, { shallow: true }) // remove the url query param
        }
    }, [investmentThanksModalProps, router, thanksParam])

    return (
        <>
            {auth.loggedIn && account && thanksModalVariant && (
                <InvestmentThanksModal
                    {...investmentThanksModalProps}
                    variant={thanksModalVariant}
                />
            )}
        </>
    )
}
