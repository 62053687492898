export type Maybe<T> = T | null
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] }
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> }
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> }
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
    ID: string
    String: string
    Boolean: boolean
    Int: number
    Float: number
    BigInt: any
    /** Date custom scalar type */
    Date: number
    /** Decimal custom scalar type */
    Decimal: number
    /** A JSON scalar type */
    JSON: any
}

export type Account = {
    __typename?: 'Account'
    account_logs: Maybe<Array<AccountLogs>>
    account_stats: AccountStats
    account_suspensions: Array<AccountSuspension>
    additional_risk: AccountRisk
    address: Maybe<Scalars['String']>
    agreement_set: Maybe<AgreementSet>
    agreement_set_id: Maybe<Scalars['Int']>
    agrees_from: Maybe<Scalars['Date']>
    audit_log: Maybe<Scalars['String']>
    auth_id: Maybe<Scalars['String']>
    bank_account_agreement: Scalars['Boolean']
    bank_accounts: Array<BankAcount>
    city: Maybe<Scalars['String']>
    city_of_birth: Maybe<Scalars['String']>
    corporate_id: Maybe<Scalars['String']>
    corporate_name: Maybe<Scalars['String']>
    corporate_persons: Maybe<Array<CorporatePerson>>
    corporate_shareholder_equity_amount: Maybe<Scalars['Int']>
    corporate_shareholder_equity_origin: Maybe<Scalars['String']>
    created_at: Scalars['Date']
    date_of_company_registration: Maybe<Scalars['Date']>
    distribution_partner_account: Maybe<DistributionPartnerAccount>
    distribution_partner_account_id: Maybe<Scalars['Int']>
    email: Maybe<Scalars['String']>
    government_ownership: Scalars['Boolean']
    iban: Maybe<Scalars['String']>
    iban_eur: Maybe<Scalars['String']>
    id: Scalars['Int']
    id1_back: Maybe<Scalars['String']>
    id1_front: Maybe<Scalars['String']>
    id2_front: Maybe<Scalars['String']>
    id_document_expiration: Maybe<Scalars['Date']>
    id_number: Maybe<Scalars['String']>
    id_type: Maybe<Scalars['String']>
    identity_verified_by: Maybe<Scalars['String']>
    industries: Maybe<Scalars['String']>
    investments: Maybe<Array<Investment>>
    is_PEP: Maybe<Scalars['Boolean']>
    is_club_member: Scalars['Boolean']
    is_corporate: Scalars['Boolean']
    is_not_PEP: Maybe<Scalars['Boolean']>
    is_payment_account: Scalars['Boolean']
    is_priv_member: Scalars['Boolean']
    is_review_subscriber: Scalars['Boolean']
    is_sanctioned: Maybe<Scalars['Boolean']>
    is_subscriber: Scalars['Boolean']
    is_usa_citizen: Scalars['Boolean']
    kb_employee: Scalars['Boolean']
    kb_flag: Scalars['Boolean']
    kb_marketing_consent: Scalars['Boolean']
    last_aml_review_date: Maybe<Scalars['Date']>
    last_sanction_list_check: Maybe<Scalars['Date']>
    messages: Maybe<Array<Message>>
    name: Maybe<Scalars['String']>
    notes: Maybe<Scalars['String']>
    personal_data_agreement: Scalars['Boolean']
    phone: Maybe<Scalars['String']>
    phone_country_code: Maybe<Scalars['Int']>
    pid: Maybe<Scalars['String']>
    prior_sars: Maybe<Scalars['String']>
    referrals: Maybe<Array<Referral>>
    risk_profile: AccountRisk
    risk_profile_score: Maybe<Scalars['Int']>
    risky_behavior: Maybe<Scalars['Boolean']>
    scans: Maybe<Array<AccountScan>>
    sign_date: Maybe<Scalars['Date']>
    state_of_birth: Maybe<Country>
    state_of_citizenship: Maybe<Country>
    state_of_residency: Maybe<Country>
    status: AccountStatus
    surname: Maybe<Scalars['String']>
    tax_residences: Array<AccountTaxResidence>
    transaction_history: Maybe<Array<TransactionHistory>>
    transactions: Maybe<Array<Transaction>>
    updated_at: Scalars['Date']
    variable_symbol: Maybe<Scalars['Int']>
    verification_provider: Maybe<Scalars['String']>
    withdrawal_requests: Maybe<Array<WithdrawalRequest>>
    zip: Maybe<Scalars['String']>
}

export type AccountAccount_LogsArgs = {
    action_types: Maybe<Array<AccountLogActionType>>
}

export type AccountAccount_SuspensionsArgs = {
    onlyUnresolved: Maybe<Scalars['Boolean']>
}

export type AccountInvestmentsArgs = {
    currency?: Currency
}

export type AccountTransactionsArgs = {
    currency?: Currency
}

export type AccountWithdrawal_RequestsArgs = {
    currencies?: Array<Currency>
}

export type AccountAmlReviewData = {
    attachments: Array<Scalars['String']>
    note: Scalars['String']
}

export type AccountConnection = {
    __typename?: 'AccountConnection'
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
    edges: Array<AccountEdge>
    /** Flattened list of Account type */
    nodes: Array<Account>
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
    pageInfo: PageInfo
    totalCount: Scalars['Int']
}

export type AccountEdge = {
    __typename?: 'AccountEdge'
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
    cursor: Scalars['String']
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
    node: Account
}

export const AccountLogActionType = {
    AccountAmlReview: 'account_AML_review',
    BankIdDataHandoff: 'bank_id_data_handoff',
    IdRecognizerDataHandoff: 'id_recognizer_data_handoff',
    InvestmentOrder: 'investment_order',
    NewPrimaryDocumentUploaded: 'new_primary_document_uploaded',
    SmsMessageSent: 'sms_message_sent',
} as const

export type AccountLogActionType = typeof AccountLogActionType[keyof typeof AccountLogActionType]
export type AccountLogs = {
    __typename?: 'AccountLogs'
    account_id: Scalars['Int']
    action_type: AccountLogActionType
    created_at: Scalars['Date']
    data: Maybe<Scalars['JSON']>
    id: Scalars['Int']
    updated_at: Scalars['Date']
}

export type AccountOpportunityExposition = {
    __typename?: 'AccountOpportunityExposition'
    amount: Scalars['Decimal']
    amount_paid: Scalars['Decimal']
    interest_paid: Scalars['Decimal']
    opportunity: Opportunity
    opportunity_id: Scalars['Int']
    principal_paid: Scalars['Decimal']
}

export const AccountRisk = {
    High: 'high',
    Low: 'low',
    MediumHigh: 'medium_high',
    MediumLow: 'medium_low',
} as const

export type AccountRisk = typeof AccountRisk[keyof typeof AccountRisk]
export type AccountScan = {
    __typename?: 'AccountScan'
    account_id: Scalars['Int']
    created_at: Scalars['Date']
    id: Scalars['Int']
    sanctionScannerResult: Maybe<Scalars['JSON']>
    sanction_scanner_id: Maybe<Scalars['String']>
    updated_at: Scalars['Date']
}

export type AccountStats = {
    __typename?: 'AccountStats'
    balance: Scalars['Decimal']
    currently_invested: Scalars['Decimal']
    expected_yield: Scalars['Decimal']
    net_yield: Scalars['Decimal']
    paid_off: Scalars['Decimal']
    pending_referral_bonuses: Scalars['Decimal']
    pending_withdrawal_amount: Scalars['Decimal']
    total_invested: Scalars['Decimal']
}

export type AccountStatsBalanceArgs = {
    currency?: Currency
}

export type AccountStatsCurrently_InvestedArgs = {
    currency?: Currency
}

export type AccountStatsExpected_YieldArgs = {
    currency?: Currency
}

export type AccountStatsNet_YieldArgs = {
    currency?: Currency
}

export type AccountStatsPaid_OffArgs = {
    currency?: Currency
}

export type AccountStatsPending_Referral_BonusesArgs = {
    currency?: Currency
}

export type AccountStatsPending_Withdrawal_AmountArgs = {
    currency?: Currency
}

export type AccountStatsTotal_InvestedArgs = {
    currency?: Currency
}

export const AccountStatus = {
    Approved: 'approved',
    Deleted: 'deleted',
    NotApproved: 'not_approved',
    PendingApproval: 'pending_approval',
    Rejected: 'rejected',
    Suspended: 'suspended',
} as const

export type AccountStatus = typeof AccountStatus[keyof typeof AccountStatus]
export type AccountSuspension = {
    __typename?: 'AccountSuspension'
    account_id: Scalars['Int']
    created_at: Scalars['Date']
    id: Scalars['Int']
    is_resolved: Scalars['Boolean']
    reason: AccountSuspensionReason
    resolution_note: Maybe<Scalars['String']>
    resolved_at: Maybe<Scalars['Date']>
    updated_at: Scalars['Date']
}

export const AccountSuspensionReason = {
    ExpiredIdDocuments: 'expired_id_documents',
    MissingIdDocuments: 'missing_id_documents',
    Other: 'other',
    UncheckedPep: 'unchecked_PEP',
} as const

export type AccountSuspensionReason =
    typeof AccountSuspensionReason[keyof typeof AccountSuspensionReason]
export type AccountTaxResidence = {
    __typename?: 'AccountTaxResidence'
    country: Country
    hasVatNumber: Scalars['Boolean']
    noVatNumberReason: Maybe<NoVatNumberReason>
    vatNumber: Maybe<Scalars['String']>
}

export type AccountTaxResidenceData = {
    country: Country
    hasVatNumber: Scalars['Boolean']
    noVatNumberReason: Maybe<NoVatNumberReason>
    vatNumber: Maybe<Scalars['String']>
}

export type AccountUpdateData = {
    additional_risk: Maybe<AccountRisk>
    address: Maybe<Scalars['String']>
    agrees_from: Maybe<Scalars['Date']>
    city: Maybe<Scalars['String']>
    city_of_birth: Maybe<Scalars['String']>
    corporate_name: Maybe<Scalars['String']>
    corporate_shareholder_equity_amount: Maybe<Scalars['Int']>
    corporate_shareholder_equity_origin: Maybe<Scalars['String']>
    date_of_company_registration: Maybe<Scalars['Date']>
    distribution_partner_account_id: Maybe<Scalars['Int']>
    email: Maybe<Scalars['String']>
    government_ownership: Maybe<Scalars['Boolean']>
    id2_number: Maybe<Scalars['String']>
    id2_type: Maybe<Scalars['String']>
    id_document_expiration: Maybe<Scalars['Date']>
    id_number: Maybe<Scalars['String']>
    id_type: Maybe<Scalars['String']>
    identity_verified_by: Maybe<Scalars['String']>
    industries: Maybe<Scalars['String']>
    is_club_member: Maybe<Scalars['Boolean']>
    is_corporate: Maybe<Scalars['Boolean']>
    is_not_PEP: Maybe<Scalars['Boolean']>
    is_priv_member: Maybe<Scalars['Boolean']>
    is_sanctioned: Maybe<Scalars['Boolean']>
    is_usa_citizen: Maybe<Scalars['Boolean']>
    kb_flag: Maybe<Scalars['Boolean']>
    name: Maybe<Scalars['String']>
    notes: Maybe<Scalars['String']>
    phone: Maybe<Scalars['String']>
    phone_country_code: Maybe<Scalars['Int']>
    pid: Maybe<Scalars['String']>
    prior_sars: Maybe<Scalars['String']>
    risk_profile: Maybe<AccountRisk>
    risky_behavior: Maybe<Scalars['Boolean']>
    state_of_birth: Maybe<Scalars['String']>
    state_of_citizenship: Maybe<Scalars['String']>
    state_of_residency: Maybe<Scalars['String']>
    status: Maybe<AccountStatus>
    surname: Maybe<Scalars['String']>
    tax_residences: Maybe<Array<AccountTaxResidenceData>>
    variable_symbol: Maybe<Scalars['Int']>
    verification_provider: Maybe<Scalars['String']>
    zip: Maybe<Scalars['String']>
}

export type AccountsFilter = {
    has_bapo: Maybe<Scalars['Boolean']>
    is_club_member: Maybe<Scalars['Boolean']>
    is_corporate: Maybe<Scalars['Boolean']>
    is_not_PEP: Maybe<Scalars['Boolean']>
    is_priv_member: Maybe<Scalars['Boolean']>
    kb_flag: Maybe<Scalars['Boolean']>
    risk_profile: Maybe<Array<AccountRisk>>
    status: Maybe<Array<AccountStatus>>
}

export type AccountsOrderBy = {
    by: Maybe<AccountsSortBy>
    order: Maybe<Order>
}

export const AccountsSortBy = {
    CreatedAt: 'created_at',
    Email: 'email',
    Id: 'id',
    Pid: 'pid',
} as const

export type AccountsSortBy = typeof AccountsSortBy[keyof typeof AccountsSortBy]
export type AccountsStats = {
    __typename?: 'AccountsStats'
    total_count: Scalars['Int']
    total_invested: Scalars['Decimal']
    total_invested_eur: Scalars['Decimal']
    total_wallet: Scalars['Decimal']
    total_wallet_eur: Scalars['Decimal']
}

export const AffiliatesKey = {
    E15: 'E15',
    RealitniShaker: 'REALITNI_SHAKER',
    RozbitePrasatko: 'ROZBITE_PRASATKO',
} as const

export type AffiliatesKey = typeof AffiliatesKey[keyof typeof AffiliatesKey]
export type AgreementSet = {
    __typename?: 'AgreementSet'
    agreement_1: Scalars['String']
    agreement_2: Scalars['String']
    agreement_3: Scalars['String']
    agreement_4: Scalars['String']
    agreement_5: Scalars['String']
    agreement_6: Scalars['String']
    agreement_7: Maybe<Scalars['String']>
    created_at: Scalars['Date']
    id: Scalars['Int']
    updated_at: Scalars['Date']
    valid_from: Scalars['Date']
    valid_to: Scalars['Date']
}

export const Auth0Connection = {
    UsernamePasswordAuthentication: 'Username__Password__Authentication',
    Bankid: 'bankid',
    GoogleOauth2: 'google__oauth2',
} as const

export type Auth0Connection = typeof Auth0Connection[keyof typeof Auth0Connection]
export type AuthResult = {
    __typename?: 'AuthResult'
    profile: Profile
    token: AuthToken
}

export type AuthToken = {
    __typename?: 'AuthToken'
    access_token: Scalars['String']
    id_token: Scalars['String']
}

export type BankAcount = {
    __typename?: 'BankAcount'
    account: Account
    account_id: Scalars['Int']
    created_at: Scalars['Date']
    currency: Currency
    iban: Scalars['String']
    id: Scalars['Int']
    is_primary: Scalars['Boolean']
    updated_at: Scalars['Date']
}

export const BankType = {
    Csas: 'csas',
    Kb: 'kb',
} as const

export type BankType = typeof BankType[keyof typeof BankType]

export type CorporatePerson = {
    __typename?: 'CorporatePerson'
    account_id: Scalars['Int']
    address: Maybe<Scalars['String']>
    audit_log: Maybe<Scalars['String']>
    city: Maybe<Scalars['String']>
    city_of_birth: Maybe<Scalars['String']>
    company_relationship: Maybe<Scalars['String']>
    created_at: Scalars['Date']
    has_right_to_sign: Scalars['Boolean']
    id: Scalars['Int']
    id1_back: Maybe<Scalars['String']>
    id1_front: Maybe<Scalars['String']>
    id2_front: Maybe<Scalars['String']>
    id_number: Maybe<Scalars['String']>
    id_type: Maybe<Scalars['String']>
    is_beneficial_owner: Maybe<Scalars['Boolean']>
    is_executive: Maybe<Scalars['Boolean']>
    is_not_PEP: Maybe<Scalars['Boolean']>
    is_sanctioned: Maybe<Scalars['Boolean']>
    name: Maybe<Scalars['String']>
    personal_data_agreement: Maybe<Scalars['Boolean']>
    phone: Maybe<Scalars['String']>
    phone_country_code: Maybe<Scalars['Int']>
    pid: Maybe<Scalars['String']>
    risk_profile: AccountRisk
    risk_profile_score: Maybe<Scalars['Int']>
    state_of_birth: Maybe<Country>
    state_of_citizenship: Maybe<Country>
    state_of_residency: Maybe<Country>
    surname: Maybe<Scalars['String']>
    updated_at: Scalars['Date']
    zip: Maybe<Scalars['String']>
}

export type CorporatePersonCreateData = {
    name: Scalars['String']
    surname: Scalars['String']
}

export type CorporatePersonUpdateData = {
    address: Maybe<Scalars['String']>
    city: Maybe<Scalars['String']>
    city_of_birth: Maybe<Scalars['String']>
    company_relationship: Maybe<Scalars['String']>
    has_right_to_sign: Scalars['Boolean']
    id_number: Maybe<Scalars['String']>
    id_type: Maybe<Scalars['String']>
    is_beneficial_owner: Maybe<Scalars['Boolean']>
    is_executive: Maybe<Scalars['Boolean']>
    is_not_PEP: Maybe<Scalars['Boolean']>
    is_sanctioned: Maybe<Scalars['Boolean']>
    phone: Maybe<Scalars['String']>
    phone_country_code: Maybe<Scalars['Int']>
    pid: Maybe<Scalars['String']>
    state_of_birth: Maybe<Scalars['String']>
    state_of_citizenship: Maybe<Scalars['String']>
    state_of_residency: Maybe<Scalars['String']>
    zip: Maybe<Scalars['String']>
}

export type CountByRiskProfile = {
    __typename?: 'CountByRiskProfile'
    high: Maybe<Scalars['Int']>
    low: Maybe<Scalars['Int']>
    medium_high: Maybe<Scalars['Int']>
    medium_low: Maybe<Scalars['Int']>
}

export const Country = {
    Ad: 'AD',
    Ae: 'AE',
    Af: 'AF',
    Ag: 'AG',
    Ai: 'AI',
    Al: 'AL',
    Am: 'AM',
    An: 'AN',
    Ao: 'AO',
    Aq: 'AQ',
    Ar: 'AR',
    As: 'AS',
    At: 'AT',
    Au: 'AU',
    Aw: 'AW',
    Ax: 'AX',
    Az: 'AZ',
    Ba: 'BA',
    Bb: 'BB',
    Bd: 'BD',
    Be: 'BE',
    Bf: 'BF',
    Bg: 'BG',
    Bh: 'BH',
    Bi: 'BI',
    Bj: 'BJ',
    Bm: 'BM',
    Bn: 'BN',
    Bo: 'BO',
    Br: 'BR',
    Bs: 'BS',
    Bt: 'BT',
    Bv: 'BV',
    Bw: 'BW',
    By: 'BY',
    Bz: 'BZ',
    Ca: 'CA',
    Cc: 'CC',
    Cd: 'CD',
    Cf: 'CF',
    Cg: 'CG',
    Ch: 'CH',
    Ci: 'CI',
    Ck: 'CK',
    Cl: 'CL',
    Cm: 'CM',
    Cn: 'CN',
    Co: 'CO',
    Cr: 'CR',
    Cu: 'CU',
    Cv: 'CV',
    Cx: 'CX',
    Cy: 'CY',
    Cz: 'CZ',
    De: 'DE',
    Dj: 'DJ',
    Dk: 'DK',
    Dm: 'DM',
    Do: 'DO',
    Dz: 'DZ',
    Ec: 'EC',
    Ee: 'EE',
    Eg: 'EG',
    Eh: 'EH',
    Er: 'ER',
    Es: 'ES',
    Et: 'ET',
    Fi: 'FI',
    Fj: 'FJ',
    Fk: 'FK',
    Fm: 'FM',
    Fo: 'FO',
    Fr: 'FR',
    Ga: 'GA',
    Gb: 'GB',
    Gd: 'GD',
    Ge: 'GE',
    Gf: 'GF',
    Gg: 'GG',
    Gh: 'GH',
    Gi: 'GI',
    Gl: 'GL',
    Gm: 'GM',
    Gn: 'GN',
    Gp: 'GP',
    Gq: 'GQ',
    Gr: 'GR',
    Gs: 'GS',
    Gt: 'GT',
    Gu: 'GU',
    Gw: 'GW',
    Gy: 'GY',
    Hk: 'HK',
    Hm: 'HM',
    Hn: 'HN',
    Hr: 'HR',
    Ht: 'HT',
    Hu: 'HU',
    Id: 'ID',
    Ie: 'IE',
    Il: 'IL',
    Im: 'IM',
    In: 'IN',
    Io: 'IO',
    Iq: 'IQ',
    Ir: 'IR',
    Is: 'IS',
    It: 'IT',
    Je: 'JE',
    Jm: 'JM',
    Jo: 'JO',
    Jp: 'JP',
    Ke: 'KE',
    Kg: 'KG',
    Kh: 'KH',
    Ki: 'KI',
    Km: 'KM',
    Kn: 'KN',
    Kp: 'KP',
    Kr: 'KR',
    Kw: 'KW',
    Ky: 'KY',
    Kz: 'KZ',
    La: 'LA',
    Lb: 'LB',
    Lc: 'LC',
    Li: 'LI',
    Lk: 'LK',
    Lr: 'LR',
    Ls: 'LS',
    Lt: 'LT',
    Lu: 'LU',
    Lv: 'LV',
    Ly: 'LY',
    Ma: 'MA',
    Mc: 'MC',
    Md: 'MD',
    Me: 'ME',
    Mg: 'MG',
    Mh: 'MH',
    Mk: 'MK',
    Ml: 'ML',
    Mm: 'MM',
    Mn: 'MN',
    Mo: 'MO',
    Mp: 'MP',
    Mq: 'MQ',
    Mr: 'MR',
    Ms: 'MS',
    Mt: 'MT',
    Mu: 'MU',
    Mv: 'MV',
    Mw: 'MW',
    Mx: 'MX',
    My: 'MY',
    Mz: 'MZ',
    Na: 'NA',
    Nc: 'NC',
    Ne: 'NE',
    Nf: 'NF',
    Ng: 'NG',
    Ni: 'NI',
    Nl: 'NL',
    No: 'NO',
    Np: 'NP',
    Nr: 'NR',
    Nu: 'NU',
    Nz: 'NZ',
    Om: 'OM',
    Pa: 'PA',
    Pe: 'PE',
    Pf: 'PF',
    Pg: 'PG',
    Ph: 'PH',
    Pk: 'PK',
    Pl: 'PL',
    Pm: 'PM',
    Pn: 'PN',
    Pr: 'PR',
    Ps: 'PS',
    Pt: 'PT',
    Pw: 'PW',
    Py: 'PY',
    Qa: 'QA',
    Re: 'RE',
    Ro: 'RO',
    Rs: 'RS',
    Ru: 'RU',
    Rw: 'RW',
    Sa: 'SA',
    Sb: 'SB',
    Sc: 'SC',
    Sd: 'SD',
    Se: 'SE',
    Sg: 'SG',
    Sh: 'SH',
    Si: 'SI',
    Sj: 'SJ',
    Sk: 'SK',
    Sl: 'SL',
    Sm: 'SM',
    Sn: 'SN',
    So: 'SO',
    Sr: 'SR',
    St: 'ST',
    Sv: 'SV',
    Sy: 'SY',
    Sz: 'SZ',
    Tc: 'TC',
    Td: 'TD',
    Tf: 'TF',
    Tg: 'TG',
    Th: 'TH',
    Tj: 'TJ',
    Tk: 'TK',
    Tl: 'TL',
    Tm: 'TM',
    Tn: 'TN',
    To: 'TO',
    Tr: 'TR',
    Tt: 'TT',
    Tv: 'TV',
    Tw: 'TW',
    Tz: 'TZ',
    Ua: 'UA',
    Ug: 'UG',
    Um: 'UM',
    Us: 'US',
    Uy: 'UY',
    Uz: 'UZ',
    Va: 'VA',
    Vc: 'VC',
    Ve: 'VE',
    Vg: 'VG',
    Vi: 'VI',
    Vn: 'VN',
    Vu: 'VU',
    Wf: 'WF',
    Ws: 'WS',
    Ye: 'YE',
    Yt: 'YT',
    Za: 'ZA',
    Zm: 'ZM',
    Zw: 'ZW',
} as const

export type Country = typeof Country[keyof typeof Country]
export const Currency = {
    Czk: 'CZK',
    Eur: 'EUR',
} as const

export type Currency = typeof Currency[keyof typeof Currency]

export type DateRange = {
    from: Maybe<Scalars['Date']>
    to: Maybe<Scalars['Date']>
}

export type DeveloperInfoInput = {
    address: Scalars['String']
    ico: Scalars['String']
    logoImage: Scalars['String']
    markNo: Scalars['String']
    name: Scalars['String']
    registeredBy: Scalars['String']
}

export type DeveloperInfoJson = {
    __typename?: 'DeveloperInfoJSON'
    address: Scalars['String']
    ico: Scalars['String']
    logoImage: Scalars['String']
    markNo: Scalars['String']
    name: Scalars['String']
    registeredBy: Scalars['String']
}

export type DistributionPartner = {
    __typename?: 'DistributionPartner'
    created_at: Scalars['Date']
    distribution_partner_accounts: Array<DistributionPartnerAccount>
    id: Scalars['Int']
    name: Scalars['String']
    updated_at: Scalars['Date']
}

export type DistributionPartnerAccount = {
    __typename?: 'DistributionPartnerAccount'
    auth_id: Maybe<Scalars['String']>
    created_at: Scalars['Date']
    distribution_partner: DistributionPartner
    distribution_partner_id: Scalars['Int']
    email: Maybe<Scalars['String']>
    family_name: Maybe<Scalars['String']>
    given_name: Maybe<Scalars['String']>
    id: Scalars['Int']
    role: Maybe<DistributionPartnerAccountRole>
    updated_at: Scalars['Date']
    username: Scalars['String']
}

export const DistributionPartnerAccountRole = {
    Admin: 'admin',
    Bapo: 'bapo',
    Priv: 'priv',
    Read: 'read',
} as const

export type DistributionPartnerAccountRole =
    typeof DistributionPartnerAccountRole[keyof typeof DistributionPartnerAccountRole]
export type GetJobsStatusResult = {
    __typename?: 'GetJobsStatusResult'
    errorName: Maybe<Scalars['String']>
    failedReason: Maybe<Scalars['String']>
    id: Scalars['String']
    progress: Scalars['Int']
    returnvalue: Maybe<Scalars['JSON']>
    state: JobStatus
}

export type Investment = {
    __typename?: 'Investment'
    account: Account
    account_id: Scalars['Int']
    amount: Scalars['Decimal']
    created_at: Scalars['Date']
    currency: Currency
    distribution_fee: Scalars['Decimal']
    expected_yield: Scalars['Decimal']
    fees_agreement: Maybe<Scalars['String']>
    id: Scalars['Int']
    interest_period_end: Maybe<Scalars['Date']>
    interest_period_start: Maybe<Scalars['Date']>
    interest_rate: Scalars['Decimal']
    investment_lien_agreement: Maybe<Scalars['String']>
    opportunity: Opportunity
    opportunity_id: Scalars['Int']
    opportunity_tranche_id: Maybe<Scalars['Int']>
    payments_amount: Scalars['Decimal']
    reference_rate_id: Maybe<Scalars['Int']>
    state: InvestmentState
    updated_at: Scalars['Date']
}

export type InvestmentConnection = {
    __typename?: 'InvestmentConnection'
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
    edges: Array<InvestmentEdge>
    /** Flattened list of Investment type */
    nodes: Array<Investment>
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
    pageInfo: PageInfo
    totalCount: Scalars['Int']
}

export type InvestmentEdge = {
    __typename?: 'InvestmentEdge'
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
    cursor: Scalars['String']
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
    node: Investment
}

export const InvestmentState = {
    Confirmed: 'confirmed',
    Discarded: 'discarded',
    PaidOff: 'paid_off',
    Running: 'running',
} as const

export type InvestmentState = typeof InvestmentState[keyof typeof InvestmentState]
export type InvestmentsFilter = {
    opportunity_id: Maybe<Array<Scalars['Int']>>
}

export type InvestmentsOrderBy = {
    by: Maybe<InvestmentsSortBy>
    order: Maybe<Order>
}

export const InvestmentsSortBy = {
    Amount: 'amount',
    CreatedAt: 'created_at',
    Id: 'id',
    InterestRate: 'interest_rate',
} as const

export type InvestmentsSortBy = typeof InvestmentsSortBy[keyof typeof InvestmentsSortBy]
export type InvestmentsStats = {
    __typename?: 'InvestmentsStats'
    count: Scalars['Int']
    count_eur: Scalars['Int']
    mean_average: Scalars['Decimal']
    mean_average_eur: Scalars['Decimal']
    total: Scalars['Decimal']
    total_eur: Scalars['Decimal']
}

export const JobStatus = {
    Active: 'active',
    Completed: 'completed',
    Delayed: 'delayed',
    Failed: 'failed',
    Paused: 'paused',
    Waiting: 'waiting',
} as const

export type JobStatus = typeof JobStatus[keyof typeof JobStatus]
export type KbAccountsFilter = {
    has_not_invested_filter: Maybe<Scalars['Boolean']>
    show_only_accounts_for_current_bapo: Maybe<Scalars['Boolean']>
}

export type Message = {
    __typename?: 'Message'
    account_id: Maybe<Scalars['Int']>
    content: Maybe<Scalars['String']>
    created_at: Scalars['Date']
    from: Scalars['String']
    id: Scalars['Int']
    subject: Scalars['String']
    to: Scalars['String']
    updated_at: Scalars['Date']
}

export type MojeBankaResult = {
    __typename?: 'MojeBankaResult'
    account_stats: Maybe<AccountStats>
    created_at: Scalars['Date']
    email: Maybe<Scalars['String']>
    id: Scalars['Int']
    name: Maybe<Scalars['String']>
    surname: Maybe<Scalars['String']>
    updated_at: Scalars['Date']
    variable_symbol: Maybe<Scalars['Int']>
}

export type Mutation = {
    __typename?: 'Mutation'
    addAccountAMLReview: Account
    addAccountSuspension: AccountSuspension
    addBankAccount: BankAcount
    addDistributionPartnerAccount: SimpleMutationResult
    addOpportunityPrivatePlacementEmail: OpportunityPrivatePlacementEmail
    adminAccountUpdate: Array<Account>
    adminAddBankAccount: BankAcount
    adminPairTransaction: Transaction
    approveAccount: Account
    approveUploadedDocument: PendingAccountUpdates
    assignDistributionPartnerAccount: Account
    assignDistributionPartnerAccountKB: Account
    corporatePersonCreate: CorporatePerson
    corporatePersonDelete: CorporatePerson
    corporatePersonUpdate: CorporatePerson
    createEmailSubscriber: SimpleMutationResult
    createOpportunityNews: OpportunityNews
    createOrUpdateOpportunityMutation: Opportunity
    createREviewEmailSubscriber: SimpleMutationResult
    createReferral: Referral
    deleteOpportunityNews: OpportunityNews
    handleLinkAccountsCallback: AuthResult
    handleLoginCallback: AuthResult
    handleSignupCallback: SignupResult
    markAsAffiliate: SimpleMutationResult
    markAsPrivMember: SimpleMutationResult
    noop: SimpleMutationResult
    opportunityTrancheCreate: OpportunityTranche
    opportunityTrancheDelete: OpportunityTranche
    opportunityTrancheUpdate: OpportunityTranche
    payoffReferralsWithTransaction: Array<Referral>
    rejectAccount: Account
    removeBankAccount: BankAcount
    removeEmailSubscriber: SimpleMutationResult
    removeOpportunityPrivatePlacementEmail: SimpleMutationResult
    removeREviewEmailSubscriber: SimpleMutationResult
    requestAccountUpdate: PendingAccountUpdates
    resolveAccountSuspension: AccountSuspension
    setKBMarketingConsent: Account
    setPrimaryBankAccount: BankAcount
    startInterestPeriod: WorkerJobsStartedResult
    todoItemCreate: Array<TodoItem>
    todoItemRemove: Array<TodoItem>
    transactionAmlCheckUpdate: TransactionAmlChecks
    transactionUpdate: Array<Transaction>
    updateOpportunityCardAndCoverPhotosMutation: Opportunity
    updateOpportunityCashflowsAndInterestRatesMutation: Opportunity
    updateOpportunityNews: OpportunityNews
    updateOpportunityTracheCashflow: OpportunityTranche
    uploadNewIdDocument: PendingAccountUpdates
    withdrawalRequestsMutation: Maybe<Array<WithdrawalRequest>>
}

export type MutationAddAccountAmlReviewArgs = {
    account_id: Scalars['Int']
    data: AccountAmlReviewData
}

export type MutationAddAccountSuspensionArgs = {
    account_id: Scalars['Int']
    reason: AccountSuspensionReason
}

export type MutationAddBankAccountArgs = {
    currency?: Maybe<Currency>
    iban: Scalars['String']
    is_primary?: Maybe<Scalars['Boolean']>
}

export type MutationAddDistributionPartnerAccountArgs = {
    distributionPartnerAccountId: Scalars['String']
}

export type MutationAddOpportunityPrivatePlacementEmailArgs = {
    email: Scalars['String']
    opportunity_id: Scalars['Int']
}

export type MutationAdminAccountUpdateArgs = {
    data: AccountUpdateData
    ids: Array<Scalars['Int']>
}

export type MutationAdminAddBankAccountArgs = {
    account_id: Scalars['Int']
    currency?: Maybe<Currency>
    iban: Scalars['String']
    is_primary?: Maybe<Scalars['Boolean']>
}

export type MutationAdminPairTransactionArgs = {
    account_id: Scalars['Int']
    id: Scalars['Int']
}

export type MutationApproveAccountArgs = {
    account_id: Scalars['Int']
}

export type MutationApproveUploadedDocumentArgs = {
    account_id: Scalars['Int']
}

export type MutationAssignDistributionPartnerAccountArgs = {
    distribution_partner_account_id: Scalars['Int']
    id: Scalars['Int']
}

export type MutationAssignDistributionPartnerAccountKbArgs = {
    distribution_partner_account_id: Scalars['Int']
    id: Scalars['Int']
}

export type MutationCorporatePersonCreateArgs = {
    account_id: Scalars['Int']
    data: CorporatePersonCreateData
}

export type MutationCorporatePersonDeleteArgs = {
    person_id: Scalars['Int']
}

export type MutationCorporatePersonUpdateArgs = {
    data: CorporatePersonUpdateData
    person_id: Scalars['Int']
}

export type MutationCreateEmailSubscriberArgs = {
    email: Scalars['String']
}

export type MutationCreateOpportunityNewsArgs = {
    content: Scalars['String']
    is_investor_only: Scalars['Boolean']
    links: Maybe<Array<Maybe<OpportunityNewsLinkInput>>>
    opportunity_id: Scalars['Int']
    type: OpportunityNewsType
}

export type MutationCreateOrUpdateOpportunityMutationArgs = {
    data: OpportunityCreateOrUpdateData
    id: Maybe<Scalars['Int']>
}

export type MutationCreateREviewEmailSubscriberArgs = {
    email: Scalars['String']
}

export type MutationCreateReferralArgs = {
    amount: Scalars['Int']
    invitee_id: Scalars['Int']
    inviter_id: Scalars['Int']
}

export type MutationDeleteOpportunityNewsArgs = {
    id: Scalars['Int']
}

export type MutationHandleLinkAccountsCallbackArgs = {
    accountLinkToken: Scalars['String']
    code: Scalars['String']
}

export type MutationHandleLoginCallbackArgs = {
    code: Scalars['String']
}

export type MutationHandleSignupCallbackArgs = {
    accountToken: Maybe<Scalars['String']>
    code: Scalars['String']
    is_corporate: Maybe<Scalars['Boolean']>
}

export type MutationMarkAsAffiliateArgs = {
    affiliateKey: Scalars['String']
}

export type MutationMarkAsPrivMemberArgs = {
    privOpportunityID: Scalars['Int']
}

export type MutationOpportunityTrancheCreateArgs = {
    data: OpportunityTrancheData
}

export type MutationOpportunityTrancheDeleteArgs = {
    id: Scalars['Int']
}

export type MutationOpportunityTrancheUpdateArgs = {
    data: OpportunityTrancheData
    id: Scalars['Int']
}

export type MutationPayoffReferralsWithTransactionArgs = {
    opportunity_id: Scalars['Int']
    transaction_id: Scalars['Int']
}

export type MutationRejectAccountArgs = {
    account_id: Scalars['Int']
    rejection_reason: Scalars['String']
}

export type MutationRemoveBankAccountArgs = {
    id: Scalars['Int']
}

export type MutationRemoveOpportunityPrivatePlacementEmailArgs = {
    email: Scalars['String']
    opportunity_id: Scalars['Int']
}

export type MutationRequestAccountUpdateArgs = {
    account_id: Scalars['Int']
}

export type MutationResolveAccountSuspensionArgs = {
    id: Scalars['Int']
    resolution_note: Maybe<Scalars['String']>
}

export type MutationSetKbMarketingConsentArgs = {
    kb_marketing_consent: Scalars['Boolean']
}

export type MutationSetPrimaryBankAccountArgs = {
    id: Scalars['Int']
}

export type MutationStartInterestPeriodArgs = {
    fromDate: Maybe<Scalars['String']>
    opportunityId: Scalars['Int']
    startDate: Scalars['String']
    toDate: Maybe<Scalars['String']>
}

export type MutationTodoItemCreateArgs = {
    title: Scalars['String']
}

export type MutationTodoItemRemoveArgs = {
    id: Scalars['String']
}

export type MutationTransactionAmlCheckUpdateArgs = {
    checkType: Scalars['String']
    id: Scalars['Int']
    reason: Scalars['String']
}

export type MutationTransactionUpdateArgs = {
    data: TransactionUpdateData
    ids: Array<Scalars['Int']>
}

export type MutationUpdateOpportunityCardAndCoverPhotosMutationArgs = {
    data: OpportunityCardAndCoverPhotoUpdateData
    id: Scalars['Int']
}

export type MutationUpdateOpportunityCashflowsAndInterestRatesMutationArgs = {
    data: OpportunityCashflowAndInterestRatesUpdateData
    id: Scalars['Int']
}

export type MutationUpdateOpportunityNewsArgs = {
    content: Scalars['String']
    id: Scalars['Int']
    is_investor_only: Scalars['Boolean']
    links: Maybe<Array<Maybe<OpportunityNewsLinkInput>>>
    opportunity_id: Scalars['Int']
    type: OpportunityNewsType
}

export type MutationUpdateOpportunityTracheCashflowArgs = {
    data: OpportunityTrancheCashflowData
    id: Scalars['Int']
}

export type MutationUploadNewIdDocumentArgs = {
    id_back: Scalars['String']
    id_front: Scalars['String']
}

export type MutationWithdrawalRequestsMutationArgs = {
    data: WithdrawalRequestUpdateData
    ids: Array<Scalars['Int']>
}

export const NoVatNumberReason = {
    CountryDoesNotIssueVatNumbers: 'country_does_not_issue_vat_numbers',
    NotAbleToObtain: 'not_able_to_obtain',
    NotNeededForJurisdiction: 'not_needed_for_jurisdiction',
} as const

export type NoVatNumberReason = typeof NoVatNumberReason[keyof typeof NoVatNumberReason]
export type OpportunitiesFilter = {
    in_widget: Maybe<Scalars['Boolean']>
    type: Maybe<Array<OpportunityType>>
}

export type OpportunitiesOrderBy = {
    by: Maybe<OpportunitiesSortBy>
    order: Maybe<Order>
}

export const OpportunitiesSortBy = {
    CreatedAt: 'created_at',
    FundraisingPeriodStart: 'fundraising_period_start',
    Id: 'id',
} as const

export type OpportunitiesSortBy = typeof OpportunitiesSortBy[keyof typeof OpportunitiesSortBy]
export type Opportunity = {
    __typename?: 'Opportunity'
    address: Scalars['String']
    audit_log: Maybe<Scalars['String']>
    card_photo: Maybe<Scalars['String']>
    cashflow_model: Maybe<Scalars['JSON']>
    cover_photo: Maybe<Scalars['String']>
    created_at: Scalars['Date']
    currencies: Array<Currency>
    developer_info: DeveloperInfoJson
    distribution_fee: Scalars['Decimal']
    equity_data: Maybe<Scalars['JSON']>
    eur_exchange_rate: Maybe<Scalars['Decimal']>
    executive_summary_pdf: Maybe<Scalars['String']>
    expected_maturity: Scalars['Date']
    fees_agreement: Maybe<Scalars['String']>
    fundraising_period_end: Scalars['Date']
    fundraising_period_start: Scalars['Date']
    id: Scalars['Int']
    ik_fundraising_period_end: Maybe<Scalars['Date']>
    in_widget: Scalars['Boolean']
    interest_rate: Maybe<Scalars['String']>
    interest_rate_type: OpportunityInterestRateType
    interest_rates: Scalars['JSON']
    investments: Array<Investment>
    investors_count: Scalars['Int']
    is_private_placement: Scalars['Boolean']
    loan_interest_rate: Scalars['Decimal']
    loan_payoff_date: Maybe<Scalars['Date']>
    market_segment: OpportunityMarketSegment
    maturity: Scalars['Date']
    max_fundraising_target: Scalars['Decimal']
    max_investment: Scalars['Int']
    min_fundraising_target: Scalars['Decimal']
    min_investment: Scalars['Int']
    myInvestments: Array<Investment>
    myPayments: Array<Payment>
    net_value: Scalars['BigInt']
    news: Maybe<Array<OpportunityNews>>
    payment_frequency: PaymentFrequency
    priv_fees_agreement: Maybe<Scalars['String']>
    priv_interest_rate: Maybe<Scalars['Decimal']>
    private_placement_emails: Array<OpportunityPrivatePlacementEmail>
    published: Scalars['Boolean']
    raised: Scalars['Decimal']
    raised_eur: Scalars['Decimal']
    reference_rate_id: Maybe<Scalars['Int']>
    reference_rate_type: Maybe<ReferenceRateType>
    reference_rates: Maybe<Array<ReferenceRate>>
    referrals: Array<Referral>
    report_pdf: Maybe<Scalars['String']>
    reports: Array<OpportunityReport>
    status: OpportunityStatus
    subtitle: Maybe<Scalars['String']>
    subtitle_en: Maybe<Scalars['String']>
    text_id: Maybe<Scalars['String']>
    title: Scalars['String']
    title_en: Scalars['String']
    tranches: Array<OpportunityTranche>
    type: OpportunityType
    updated_at: Scalars['Date']
}

export type OpportunityCardAndCoverPhotoUpdateData = {
    card_photo: Scalars['String']
    cover_photo: Scalars['String']
}

export type OpportunityCashflowAndInterestRatesUpdateData = {
    cashflow_model: Scalars['JSON']
    interest_rates: Scalars['JSON']
}

export type OpportunityConnection = {
    __typename?: 'OpportunityConnection'
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
    edges: Array<OpportunityEdge>
    /** Flattened list of Opportunity type */
    nodes: Array<Opportunity>
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
    pageInfo: PageInfo
    totalCount: Scalars['Int']
}

export type OpportunityCreateOrUpdateData = {
    address: Scalars['String']
    currencies: Array<Scalars['String']>
    developer_info: DeveloperInfoInput
    distribution_fee: Scalars['Decimal']
    expected_maturity: Scalars['Date']
    fees_agreement: Scalars['String']
    fundraising_period_end: Scalars['Date']
    fundraising_period_start: Scalars['Date']
    ik_fundraising_period_end: Maybe<Scalars['Date']>
    in_widget: Scalars['Boolean']
    interest_rate_type: Scalars['String']
    is_private_placement: Scalars['Boolean']
    loan_interest_rate: Scalars['Decimal']
    market_segment: OpportunityMarketSegment
    maturity: Scalars['Date']
    max_fundraising_target: Scalars['Decimal']
    max_investment: Scalars['Int']
    min_fundraising_target: Scalars['Decimal']
    min_investment: Scalars['Int']
    net_value: Scalars['BigInt']
    payment_frequency: Scalars['String']
    reference_rate_id: Maybe<Scalars['Int']>
    reference_rate_type: Maybe<ReferenceRateType>
    report_pdf: Scalars['String']
    subtitle: Scalars['String']
    subtitle_en: Scalars['String']
    text_id: Scalars['String']
    title: Scalars['String']
    title_en: Scalars['String']
    type: OpportunityType
}

export type OpportunityEdge = {
    __typename?: 'OpportunityEdge'
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
    cursor: Scalars['String']
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
    node: Opportunity
}

export const OpportunityInterestRateType = {
    Compound: 'compound',
    Simple: 'simple',
} as const

export type OpportunityInterestRateType =
    typeof OpportunityInterestRateType[keyof typeof OpportunityInterestRateType]
export const OpportunityMarketSegment = {
    Logistics: 'logistics',
    Office: 'office',
    Residential: 'residential',
    Retail: 'retail',
} as const

export type OpportunityMarketSegment =
    typeof OpportunityMarketSegment[keyof typeof OpportunityMarketSegment]
export type OpportunityNews = {
    __typename?: 'OpportunityNews'
    content: Scalars['String']
    content_en: Scalars['String']
    created_at: Scalars['Date']
    id: Scalars['Int']
    is_investor_only: Scalars['Boolean']
    links: Maybe<Array<Maybe<OpportunityNewsLink>>>
    opportunity: Opportunity
    opportunity_id: Scalars['Int']
    type: OpportunityNewsType
    updated_at: Scalars['Date']
}

export type OpportunityNewsLink = {
    __typename?: 'OpportunityNewsLink'
    label: Maybe<Scalars['String']>
    label_en: Maybe<Scalars['String']>
    type: OpportunityNewsLinkType
    url: Scalars['String']
}

export type OpportunityNewsLinkInput = {
    label: Maybe<Scalars['String']>
    type: OpportunityNewsLinkType
    url: Scalars['String']
}

export const OpportunityNewsLinkType = {
    File: 'file',
    Link: 'link',
} as const

export type OpportunityNewsLinkType =
    typeof OpportunityNewsLinkType[keyof typeof OpportunityNewsLinkType]
export const OpportunityNewsType = {
    ExpectedMaturityChanged: 'expected_maturity_changed',
    GeneralNews: 'general_news',
    InterestPeriodStarted: 'interest_period_started',
    NewReportUploaded: 'new_report_uploaded',
    NewTranchStarted: 'new_tranch_started',
    OpportunityPayment: 'opportunity_payment',
} as const

export type OpportunityNewsType = typeof OpportunityNewsType[keyof typeof OpportunityNewsType]
export type OpportunityPrivatePlacementEmail = {
    __typename?: 'OpportunityPrivatePlacementEmail'
    email: Scalars['String']
    opportunity_id: Scalars['Int']
}

export type OpportunityReport = {
    __typename?: 'OpportunityReport'
    created_at: Scalars['Date']
    desc: Maybe<Scalars['String']>
    id: Scalars['Int']
    notifications_sent: Scalars['Boolean']
    opportunity: Opportunity
    title: Scalars['String']
    updated_at: Scalars['Date']
    url: Scalars['String']
}

export const OpportunityStatus = {
    Archived: 'archived',
    LoanPaidOff: 'loan_paid_off',
    LoanProvided: 'loan_provided',
    MinRaised: 'min_raised',
    Open: 'open',
    Raised: 'raised',
} as const

export type OpportunityStatus = typeof OpportunityStatus[keyof typeof OpportunityStatus]
export type OpportunityTranche = {
    __typename?: 'OpportunityTranche'
    cashflow_model: Maybe<Scalars['JSON']>
    created_at: Scalars['Date']
    fundraising_period_end: Scalars['Date']
    fundraising_period_start: Scalars['Date']
    id: Scalars['Int']
    interest_rates: Scalars['JSON']
    loan_interest_rate: Scalars['Decimal']
    max_fundraising_target: Scalars['Decimal']
    min_fundraising_target: Scalars['Decimal']
    opportunity: Opportunity
    opportunity_id: Scalars['Int']
    tranche_title: Maybe<Scalars['String']>
    updated_at: Scalars['Date']
}

export type OpportunityTrancheCashflowData = {
    cashflow_model: Maybe<Scalars['JSON']>
    interest_rates: Scalars['JSON']
}

export type OpportunityTrancheData = {
    fundraising_period_end: Scalars['Date']
    fundraising_period_start: Scalars['Date']
    loan_interest_rate: Scalars['Decimal']
    max_fundraising_target: Scalars['Decimal']
    min_fundraising_target: Scalars['Decimal']
    opportunity_id: Scalars['Int']
    tranche_title: Maybe<Scalars['String']>
}

export const OpportunityType = {
    BankLoanPortfolio: 'bank_loan_portfolio',
    Equity: 'equity',
    UpvestLoanJunior: 'upvest_loan_junior',
    UpvestLoanSenior: 'upvest_loan_senior',
} as const

export type OpportunityType = typeof OpportunityType[keyof typeof OpportunityType]
export const Order = {
    Asc: 'asc',
    Desc: 'desc',
} as const

export type Order = typeof Order[keyof typeof Order]
/** PageInfo cursor, as defined in https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
export type PageInfo = {
    __typename?: 'PageInfo'
    /** The cursor corresponding to the last nodes in edges. Null if the connection is empty. */
    endCursor: Maybe<Scalars['String']>
    /** Used to indicate whether more edges exist following the set defined by the clients arguments. */
    hasNextPage: Scalars['Boolean']
    /** Used to indicate whether more edges exist prior to the set defined by the clients arguments. */
    hasPreviousPage: Scalars['Boolean']
    /** The cursor corresponding to the first nodes in edges. Null if the connection is empty. */
    startCursor: Maybe<Scalars['String']>
}

export type Payment = {
    __typename?: 'Payment'
    account: Account
    amount: Scalars['Decimal']
    amount_interest: Scalars['Decimal']
    amount_principal: Scalars['Decimal']
    created_at: Scalars['Date']
    currency: Currency
    date_valid: Maybe<Scalars['Date']>
    id: Scalars['Int']
    investment: Investment
    updated_at: Scalars['Date']
}

export const PaymentFrequency = {
    Annually: 'annually',
    Biyearly: 'biyearly',
    BridgePrvnihoPluku: 'bridge_prvniho_pluku',
    Monthly: 'monthly',
    Quarterly: 'quarterly',
    UponMaturity: 'upon_maturity',
} as const

export type PaymentFrequency = typeof PaymentFrequency[keyof typeof PaymentFrequency]
export type PendingAccountUpdates = {
    __typename?: 'PendingAccountUpdates'
    account: Account
    account_id: Scalars['Int']
    created_at: Scalars['Date']
    id: Scalars['Int']
    id_back: Maybe<Scalars['String']>
    id_front: Maybe<Scalars['String']>
    updated_at: Scalars['Date']
}

export type PlatformStats = {
    __typename?: 'PlatformStats'
    active_investors_count: Scalars['Decimal']
    average_payoff: Scalars['Decimal']
    current_exposure: Scalars['Decimal']
    funded_opportunities_count: Scalars['Decimal']
    funded_projects_value: Scalars['Decimal']
    invested_amount: Scalars['Decimal']
    investment_count: Scalars['Decimal']
    opportunities_count: Scalars['Decimal']
    paid_out: Scalars['Decimal']
}

export type Profile = {
    __typename?: 'Profile'
    email: Maybe<Scalars['String']>
    email_verified: Maybe<Scalars['Boolean']>
    identities: Array<Auth0Connection>
    name: Scalars['String']
    nickname: Scalars['String']
    picture: Scalars['String']
    roles: Array<Role>
    sub: Scalars['String']
    updated_at: Scalars['String']
    user_metadata: ProfileMetadata
}

export type ProfileMetadata = {
    __typename?: 'ProfileMetadata'
    affiliateKey: Maybe<AffiliatesKey>
    privOpportunityID: Maybe<Scalars['Int']>
    referralCode: Maybe<Scalars['String']>
}

export type Query = {
    __typename?: 'Query'
    account: Maybe<Account>
    accountKBByID: Maybe<Account>
    accountKBByPID: Maybe<Account>
    account_exposition: Array<AccountOpportunityExposition>
    account_suspensions: Array<AccountSuspension>
    accounts: AccountConnection
    accountsKB: AccountConnection
    accountsStats: AccountsStats
    adminOpportunities: OpportunityConnection
    adminPendingAccountUpdate: Maybe<PendingAccountUpdates>
    corporate_person: CorporatePerson
    distributionPartnerAccounts: Array<DistributionPartnerAccount>
    distribution_partners: Array<DistributionPartner>
    getJobsStatus: Array<GetJobsStatusResult>
    investments: InvestmentConnection
    /** @deprecated Use investments instead */
    investmentsDeprecated: Array<Investment>
    investmentsStats: InvestmentsStats
    isTempTokenValid: Scalars['Boolean']
    mojeBankaQuery: MojeBankaResult
    myAccount: Account
    myInvestments: Array<Investment>
    myMessage: Message
    myMessages: Array<Message>
    myOpportunities: Array<Opportunity>
    myOpportunity: Opportunity
    myOpportunityNews: Array<OpportunityNews>
    opportunities: Array<Opportunity>
    opportunitiesById: Array<Opportunity>
    opportunity: Opportunity
    opportunity_reports: Array<OpportunityReport>
    pendingAccountUpdate: Maybe<PendingAccountUpdates>
    platform_stats: PlatformStats
    reference_rates: Array<ReferenceRate>
    referralAmounts: ReferralAmounts
    referralStats: ReferralStats
    referrals: ReferralConnection
    reporting: Reporting
    sanctionLists: Array<SanctionList>
    todoList: Array<TodoItem>
    transaction: Maybe<Transaction>
    transactions: TransactionConnection
    transactionsStats: TransactionsStats
    transactions_by_id: Array<Transaction>
    used_benefit_limit: Scalars['Float']
    validateXML: ValidationResult
    withdrawalRequests: WithdrawalRequestConnection
    withdrawalRequestsStats: WithdrawalRequestsStats
}

export type QueryAccountArgs = {
    id: Scalars['Int']
}

export type QueryAccountKbByIdArgs = {
    id: Scalars['Int']
}

export type QueryAccountKbByPidArgs = {
    pid: Scalars['String']
}

export type QueryAccount_ExpositionArgs = {
    account_id: Scalars['Int']
}

export type QueryAccount_SuspensionsArgs = {
    account_id: Scalars['Int']
}

export type QueryAccountsArgs = {
    after: Maybe<Scalars['String']>
    before: Maybe<Scalars['String']>
    dateRange: Maybe<DateRange>
    filter: Maybe<AccountsFilter>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
    sort: Maybe<AccountsOrderBy>
}

export type QueryAccountsKbArgs = {
    after: Maybe<Scalars['String']>
    before: Maybe<Scalars['String']>
    filter: Maybe<KbAccountsFilter>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export type QueryAccountsStatsArgs = {
    dateRange: Maybe<DateRange>
    filter: Maybe<AccountsFilter>
    sort: Maybe<AccountsOrderBy>
}

export type QueryAdminOpportunitiesArgs = {
    after: Maybe<Scalars['String']>
    before: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export type QueryAdminPendingAccountUpdateArgs = {
    account_id: Scalars['Int']
}

export type QueryCorporate_PersonArgs = {
    person_id: Scalars['Int']
}

export type QueryGetJobsStatusArgs = {
    jobIds: Array<Scalars['String']>
    queue: QueueName
}

export type QueryInvestmentsArgs = {
    after: Maybe<Scalars['String']>
    before: Maybe<Scalars['String']>
    dateRange: Maybe<DateRange>
    filter: Maybe<InvestmentsFilter>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
    sort: Maybe<InvestmentsOrderBy>
}

export type QueryInvestmentsDeprecatedArgs = {
    opportunity_id: Maybe<Scalars['Int']>
}

export type QueryInvestmentsStatsArgs = {
    dateRange: Maybe<DateRange>
    filter: Maybe<InvestmentsFilter>
    sort: Maybe<InvestmentsOrderBy>
}

export type QueryIsTempTokenValidArgs = {
    token: Scalars['String']
    type: TempTokenType
}

export type QueryMyMessageArgs = {
    id: Scalars['Int']
}

export type QueryMyOpportunityArgs = {
    id: Maybe<Scalars['Int']>
    text_id: Maybe<Scalars['String']>
}

export type QueryMyOpportunityNewsArgs = {
    dateRange: Maybe<DateRange>
    opportunityId: Maybe<Scalars['Int']>
}

export type QueryOpportunitiesArgs = {
    filter: Maybe<OpportunitiesFilter>
    limit: Maybe<Scalars['Int']>
    offset: Maybe<Scalars['Int']>
    openOnly: Maybe<Scalars['Boolean']>
    sort: Maybe<OpportunitiesOrderBy>
}

export type QueryOpportunitiesByIdArgs = {
    ids: Array<Scalars['Int']>
}

export type QueryOpportunityArgs = {
    id: Scalars['Int']
}

export type QueryOpportunity_ReportsArgs = {
    unsent_only: Maybe<Scalars['Boolean']>
}

export type QueryReference_RatesArgs = {
    type: ReferenceRateType
}

export type QueryReferralAmountsArgs = {
    opportunity_id: Scalars['Int']
}

export type QueryReferralsArgs = {
    after: Maybe<Scalars['String']>
    before: Maybe<Scalars['String']>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
}

export type QueryTransactionArgs = {
    id: Scalars['Int']
}

export type QueryTransactionsArgs = {
    after: Maybe<Scalars['String']>
    before: Maybe<Scalars['String']>
    dateRange: Maybe<DateRange>
    filter: Maybe<TransactionsFilter>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
    sort: Maybe<TransactionsOrderBy>
}

export type QueryTransactionsStatsArgs = {
    dateRange: Maybe<DateRange>
    filter: Maybe<TransactionsFilter>
}

export type QueryTransactions_By_IdArgs = {
    filter: Maybe<TransactionsFilter>
    ids: Array<Scalars['Int']>
}

export type QueryUsed_Benefit_LimitArgs = {
    account_id: Scalars['Int']
}

export type QueryValidateXmlArgs = {
    xmlString: Scalars['String']
    xsdSchema: Scalars['String']
}

export type QueryWithdrawalRequestsArgs = {
    after: Maybe<Scalars['String']>
    before: Maybe<Scalars['String']>
    dateRange: Maybe<DateRange>
    filter: Maybe<WithdrawalRequestsFilter>
    first: Maybe<Scalars['Int']>
    last: Maybe<Scalars['Int']>
    sort: Maybe<WithdrawalRequestsOrderBy>
}

export type QueryWithdrawalRequestsStatsArgs = {
    dateRange: Maybe<DateRange>
}

export const QueueName = {
    Investment: 'investment',
    Messages: 'messages',
    OpportunityActions: 'opportunityActions',
    Payoffs: 'payoffs',
    SanctionScans: 'sanctionScans',
    Workers: 'workers',
} as const

export type QueueName = typeof QueueName[keyof typeof QueueName]
export type ReferenceRate = {
    __typename?: 'ReferenceRate'
    created_at: Scalars['Date']
    date: Scalars['Date']
    id: Scalars['Int']
    rate: Scalars['Decimal']
    type: Scalars['String']
    updated_at: Scalars['Date']
}

export const ReferenceRateType = {
    Euribor_1M: 'EURIBOR_1M',
    Euribor_3M: 'EURIBOR_3M',
    Euribor_6M: 'EURIBOR_6M',
    Euribor_12M: 'EURIBOR_12M',
    Pribor_1M: 'PRIBOR_1M',
    Pribor_2M: 'PRIBOR_2M',
    Pribor_3M: 'PRIBOR_3M',
    Pribor_6M: 'PRIBOR_6M',
    Pribor_9M: 'PRIBOR_9M',
    Pribor_12M: 'PRIBOR_12M',
} as const

export type ReferenceRateType = typeof ReferenceRateType[keyof typeof ReferenceRateType]
export type Referral = {
    __typename?: 'Referral'
    amount: Scalars['Int']
    created_at: Scalars['Date']
    id: Scalars['Int']
    invitee: Account
    invitee_id: Scalars['Int']
    invitee_investment: Maybe<Investment>
    invitee_investment_id: Maybe<Scalars['Int']>
    invitee_payoff_transaction: Maybe<Transaction>
    invitee_payoff_transaction_id: Maybe<Scalars['Int']>
    inviter: Account
    inviter_id: Scalars['Int']
    inviter_investment: Maybe<Investment>
    inviter_investment_id: Maybe<Scalars['Int']>
    inviter_payoff_transaction: Maybe<Transaction>
    inviter_payoff_transaction_id: Maybe<Scalars['Int']>
    updated_at: Scalars['Date']
}

export type ReferralAmounts = {
    __typename?: 'ReferralAmounts'
    total_amount_already_paid: Scalars['Decimal']
    total_amount_to_be_paid: Scalars['Decimal']
}

export type ReferralConnection = {
    __typename?: 'ReferralConnection'
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
    edges: Array<ReferralEdge>
    /** Flattened list of Referral type */
    nodes: Array<Referral>
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
    pageInfo: PageInfo
    totalCount: Scalars['Int']
}

export type ReferralEdge = {
    __typename?: 'ReferralEdge'
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
    cursor: Scalars['String']
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
    node: Referral
}

export type ReferralStats = {
    __typename?: 'ReferralStats'
    total_count: Scalars['Int']
    total_invitee_invested: Scalars['Decimal']
    total_invitee_invested_average: Scalars['Decimal']
    total_invitee_invested_count: Scalars['Int']
}

export type Reporting = {
    __typename?: 'Reporting'
    clients_to_review: Array<Account>
    number_of_clients: CountByRiskProfile
    number_of_manually_resolved_transaction_aml_checks: Scalars['Int']
    number_of_non_cz_residents: Scalars['Int']
    number_of_peps: Scalars['Int']
    number_of_terminated_clients: Scalars['Int']
    number_of_unresolved_transaction_aml_checks: Scalars['Int']
}

export type ReportingClients_To_ReviewArgs = {
    dateRange: Maybe<DateRange>
}

export type ReportingNumber_Of_ClientsArgs = {
    dateRange: Maybe<DateRange>
}

export type ReportingNumber_Of_Manually_Resolved_Transaction_Aml_ChecksArgs = {
    dateRange: Maybe<DateRange>
}

export type ReportingNumber_Of_Non_Cz_ResidentsArgs = {
    dateRange: Maybe<DateRange>
}

export type ReportingNumber_Of_PepsArgs = {
    dateRange: Maybe<DateRange>
}

export type ReportingNumber_Of_Terminated_ClientsArgs = {
    dateRange: Maybe<DateRange>
}

export type ReportingNumber_Of_Unresolved_Transaction_Aml_ChecksArgs = {
    dateRange: Maybe<DateRange>
}

export const Role = {
    Admin: 'admin',
    Analyst: 'analyst',
    Guest: 'guest',
    Partner: 'partner',
} as const

export type Role = typeof Role[keyof typeof Role]
export type SanctionList = {
    __typename?: 'SanctionList'
    filename: Scalars['String']
    lastModified: Scalars['Date']
}

export type SignupResult = {
    __typename?: 'SignupResult'
    bankAccountOptions: Maybe<Array<Scalars['String']>>
    profile: Profile
    token: AuthToken
}

export type SimpleMutationResult = {
    __typename?: 'SimpleMutationResult'
    isSuccess: Scalars['Boolean']
}

export const TempTokenType = {
    DistributedAccountBankId: 'distributed_account_bank_id',
    DistributedAccountConclude: 'distributed_account_conclude',
    DistributedAccountConcludeVerificationCode: 'distributed_account_conclude_verification_code',
    ForgottenPasswordRequest: 'forgotten_password_request',
    IdsUpload: 'ids_upload',
    RequestEmailChange: 'request_email_change',
} as const

export type TempTokenType = typeof TempTokenType[keyof typeof TempTokenType]
export type TodoItem = {
    __typename?: 'TodoItem'
    created_at: Scalars['Date']
    id: Scalars['String']
    title: Scalars['String']
}

export type Transaction = {
    __typename?: 'Transaction'
    account: Maybe<Account>
    account_id: Maybe<Scalars['Int']>
    aml_checks: Maybe<TransactionAmlChecks>
    amount: Scalars['Decimal']
    audit_log: Maybe<Scalars['String']>
    bank_id: Maybe<Scalars['String']>
    bank_type: Maybe<Scalars['String']>
    created_at: Scalars['Date']
    currency: Currency
    id: Scalars['Int']
    note: Maybe<Scalars['String']>
    original_payload: Maybe<Scalars['String']>
    type: TransactionType
    updated_at: Scalars['Date']
    variable_symbol: Scalars['String']
    withdrawal_request: Maybe<WithdrawalRequest>
}

export type TransactionAmlChecks = {
    __typename?: 'TransactionAmlChecks'
    created_at: Scalars['Date']
    id: Scalars['Int']
    inside_EEA: Maybe<Scalars['Date']>
    no_4_consecutive_transactions: Maybe<Scalars['Date']>
    ten_times_less_than_average: Maybe<Scalars['Date']>
    under_2M: Maybe<Scalars['Date']>
    updated_at: Scalars['Date']
}

export type TransactionConnection = {
    __typename?: 'TransactionConnection'
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
    edges: Array<TransactionEdge>
    /** Flattened list of Transaction type */
    nodes: Array<Transaction>
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
    pageInfo: PageInfo
    totalCount: Scalars['Int']
}

export type TransactionEdge = {
    __typename?: 'TransactionEdge'
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
    cursor: Scalars['String']
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
    node: Transaction
}

export type TransactionHistory = {
    __typename?: 'TransactionHistory'
    amount: Scalars['Float']
    created_at: Scalars['Date']
    currency: Currency
    meta: Maybe<Scalars['JSON']>
    note: Maybe<Scalars['String']>
    type: TransactionHistoryItemType
}

export const TransactionHistoryItemType = {
    Investment: 'investment',
    Payment: 'payment',
    Transaction: 'transaction',
} as const

export type TransactionHistoryItemType =
    typeof TransactionHistoryItemType[keyof typeof TransactionHistoryItemType]
export const TransactionSortBy = {
    CreatedAt: 'created_at',
    Id: 'id',
} as const

export type TransactionSortBy = typeof TransactionSortBy[keyof typeof TransactionSortBy]
export const TransactionType = {
    Deposit: 'deposit',
    Forwarded: 'forwarded',
    Ignore: 'ignore',
    Loan: 'loan',
    ManagementFee: 'management_fee',
    Other: 'other',
    Overnight: 'overnight',
    ReferralPayoff: 'referral_payoff',
    Return: 'return',
    UpfrontFee: 'upfront_fee',
    Withdrawal: 'withdrawal',
} as const

export type TransactionType = typeof TransactionType[keyof typeof TransactionType]
export type TransactionUpdateData = {
    note: Maybe<Scalars['String']>
    type: Maybe<Scalars['String']>
}

export type TransactionsFilter = {
    aml_check: Maybe<Scalars['Boolean']>
    bank_type: Maybe<Array<BankType>>
    currency: Maybe<Array<Currency>>
    paired: Maybe<Scalars['Boolean']>
    type: Maybe<Array<TransactionType>>
    was_forwarded: Maybe<Scalars['Boolean']>
}

export type TransactionsOrderBy = {
    by: Maybe<TransactionSortBy>
    order: Maybe<Order>
}

export type TransactionsStats = {
    __typename?: 'TransactionsStats'
    csas: Scalars['Decimal']
    csas_eur: Scalars['Decimal']
    kb: Scalars['Decimal']
    kb_eur: Scalars['Decimal']
    total: Scalars['Decimal']
    total_count: Scalars['Int']
    total_eur: Scalars['Decimal']
}

export type ValidationResult = {
    __typename?: 'ValidationResult'
    errors: Array<Scalars['String']>
    valid: Scalars['Boolean']
}

export type WithdrawalRequest = {
    __typename?: 'WithdrawalRequest'
    account: Maybe<Account>
    account_id: Maybe<Scalars['Int']>
    amount: Scalars['Decimal']
    created_at: Scalars['Date']
    currency: Currency
    id: Scalars['Int']
    status: WithdrawalRequestStatus
    transaction_id: Maybe<Scalars['Int']>
    updated_at: Scalars['Date']
}

export type WithdrawalRequestConnection = {
    __typename?: 'WithdrawalRequestConnection'
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Edge-Types */
    edges: Array<WithdrawalRequestEdge>
    /** Flattened list of WithdrawalRequest type */
    nodes: Array<WithdrawalRequest>
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-undefined.PageInfo */
    pageInfo: PageInfo
    totalCount: Scalars['Int']
}

export type WithdrawalRequestEdge = {
    __typename?: 'WithdrawalRequestEdge'
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Cursor */
    cursor: Scalars['String']
    /** https://facebook.github.io/relay/graphql/connections.htm#sec-Node */
    node: WithdrawalRequest
}

export const WithdrawalRequestStatus = {
    NotResolved: 'not_resolved',
    Pending: 'pending',
    Resolved: 'resolved',
} as const

export type WithdrawalRequestStatus =
    typeof WithdrawalRequestStatus[keyof typeof WithdrawalRequestStatus]
export const WithdrawalRequestStatusInput = {
    NotResolved: 'not_resolved',
    Pending: 'pending',
} as const

export type WithdrawalRequestStatusInput =
    typeof WithdrawalRequestStatusInput[keyof typeof WithdrawalRequestStatusInput]
export type WithdrawalRequestUpdateData = {
    status: WithdrawalRequestStatusInput
}

export type WithdrawalRequestsFilter = {
    currency: Maybe<Array<Currency>>
    status: Maybe<Array<WithdrawalRequestStatus>>
}

export type WithdrawalRequestsOrderBy = {
    by: Maybe<WithdrawalRequestsSortBy>
    order: Maybe<Order>
}

export const WithdrawalRequestsSortBy = {
    Amount: 'amount',
    CreatedAt: 'created_at',
    Id: 'id',
} as const

export type WithdrawalRequestsSortBy =
    typeof WithdrawalRequestsSortBy[keyof typeof WithdrawalRequestsSortBy]
export type WithdrawalRequestsStats = {
    __typename?: 'WithdrawalRequestsStats'
    unresolved_amount: Scalars['Decimal']
    unresolved_amount_eur: Scalars['Decimal']
    unresolved_count: Scalars['Int']
    unresolved_count_eur: Scalars['Int']
}

export type WorkerJobsStartedResult = {
    __typename?: 'WorkerJobsStartedResult'
    jobIds: Array<Scalars['String']>
}

export type AccountApproveMutationMutationVariables = Exact<{
    account_id: Scalars['Int']
}>

export type AccountApproveMutationMutation = { __typename?: 'Mutation' } & {
    approveAccount: { __typename?: 'Account' } & Pick<Account, 'id' | 'status' | 'audit_log'>
}

export type AdminAccountAuditLogsQueryVariables = Exact<{
    id: Scalars['Int']
    action_types: Maybe<Array<AccountLogActionType> | AccountLogActionType>
}>

export type AdminAccountAuditLogsQuery = { __typename?: 'Query' } & {
    account: Maybe<
        { __typename?: 'Account' } & Pick<Account, 'id' | 'audit_log'> & {
                account_logs: Maybe<
                    Array<
                        { __typename?: 'AccountLogs' } & Pick<
                            AccountLogs,
                            'id' | 'created_at' | 'action_type' | 'data'
                        >
                    >
                >
            }
    >
}

export type AdminAccountBankAccountsQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type AdminAccountBankAccountsQuery = { __typename?: 'Query' } & {
    account: Maybe<
        { __typename?: 'Account' } & Pick<Account, 'id' | 'iban' | 'iban_eur'> & {
                bank_accounts: Array<
                    { __typename?: 'BankAcount' } & Pick<
                        BankAcount,
                        'id' | 'iban' | 'currency' | 'is_primary'
                    >
                >
            }
    >
}

export type AdminSetPrimaryBankAccountMutationVariables = Exact<{
    id: Scalars['Int']
}>

export type AdminSetPrimaryBankAccountMutation = { __typename?: 'Mutation' } & {
    setPrimaryBankAccount: { __typename?: 'BankAcount' } & Pick<BankAcount, 'id'>
}

export type AdminRemoveBankAccountMutationVariables = Exact<{
    id: Scalars['Int']
}>

export type AdminRemoveBankAccountMutation = { __typename?: 'Mutation' } & {
    removeBankAccount: { __typename?: 'BankAcount' } & Pick<BankAcount, 'id'>
}

export type AdminAddBankAccountMutationVariables = Exact<{
    account_id: Scalars['Int']
    iban: Scalars['String']
    currency: Currency
    is_primary: Scalars['Boolean']
}>

export type AdminAddBankAccountMutation = { __typename?: 'Mutation' } & {
    adminAddBankAccount: { __typename?: 'BankAcount' } & Pick<
        BankAcount,
        'id' | 'account_id' | 'is_primary'
    >
}

export type CorporatePersonsForAccountTableDataQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type CorporatePersonsForAccountTableDataQuery = { __typename?: 'Query' } & {
    account: Maybe<
        { __typename?: 'Account' } & Pick<Account, 'id'> & {
                corporate_persons: Maybe<
                    Array<
                        { __typename?: 'CorporatePerson' } & Pick<
                            CorporatePerson,
                            | 'id'
                            | 'name'
                            | 'surname'
                            | 'pid'
                            | 'is_beneficial_owner'
                            | 'is_executive'
                            | 'city'
                            | 'state_of_residency'
                            | 'zip'
                            | 'address'
                            | 'phone'
                            | 'phone_country_code'
                        >
                    >
                >
            }
    >
}

export type DeleteCorporatePersonMutationVariables = Exact<{
    person_id: Scalars['Int']
}>

export type DeleteCorporatePersonMutation = { __typename?: 'Mutation' } & {
    corporatePersonDelete: { __typename?: 'CorporatePerson' } & Pick<CorporatePerson, 'id'>
}

export type NoopMutationVariables = Exact<{ [key: string]: never }>

export type NoopMutation = { __typename?: 'Mutation' } & {
    noop: { __typename?: 'SimpleMutationResult' } & Pick<SimpleMutationResult, 'isSuccess'>
}

export type AdminAccountDetailQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type AdminAccountDetailQuery = { __typename?: 'Query' } & {
    account: Maybe<
        { __typename?: 'Account' } & Pick<
            Account,
            | 'auth_id'
            | 'id'
            | 'name'
            | 'surname'
            | 'corporate_name'
            | 'corporate_id'
            | 'pid'
            | 'state_of_citizenship'
            | 'city_of_birth'
            | 'state_of_birth'
            | 'email'
            | 'phone'
            | 'phone_country_code'
            | 'variable_symbol'
            | 'iban'
            | 'iban_eur'
            | 'bank_account_agreement'
            | 'address'
            | 'city'
            | 'zip'
            | 'state_of_residency'
            | 'notes'
            | 'status'
            | 'distribution_partner_account_id'
            | 'is_corporate'
            | 'sign_date'
            | 'created_at'
            | 'audit_log'
            | 'additional_risk'
            | 'is_not_PEP'
            | 'is_sanctioned'
            | 'last_sanction_list_check'
            | 'prior_sars'
            | 'risk_profile'
            | 'risk_profile_score'
            | 'risky_behavior'
            | 'government_ownership'
            | 'date_of_company_registration'
            | 'industries'
            | 'corporate_shareholder_equity_origin'
            | 'corporate_shareholder_equity_amount'
            | 'last_aml_review_date'
            | 'is_priv_member'
            | 'is_club_member'
            | 'kb_flag'
            | 'kb_employee'
            | 'is_payment_account'
            | 'kb_marketing_consent'
            | 'id1_front'
            | 'id1_back'
            | 'id2_front'
            | 'id_document_expiration'
            | 'id_type'
            | 'id_number'
            | 'verification_provider'
            | 'is_usa_citizen'
        > & {
                distribution_partner_account: Maybe<
                    { __typename?: 'DistributionPartnerAccount' } & Pick<
                        DistributionPartnerAccount,
                        'id' | 'username' | 'email' | 'given_name' | 'family_name'
                    >
                >
                tax_residences: Array<
                    { __typename?: 'AccountTaxResidence' } & Pick<
                        AccountTaxResidence,
                        'country' | 'hasVatNumber' | 'vatNumber' | 'noVatNumberReason'
                    >
                >
                transaction_history: Maybe<
                    Array<
                        { __typename?: 'TransactionHistory' } & Pick<
                            TransactionHistory,
                            'amount' | 'currency' | 'type' | 'created_at' | 'note' | 'meta'
                        >
                    >
                >
                withdrawal_requests: Maybe<
                    Array<
                        { __typename?: 'WithdrawalRequest' } & Pick<
                            WithdrawalRequest,
                            'id' | 'amount' | 'currency' | 'status' | 'created_at'
                        >
                    >
                >
                referrals: Maybe<
                    Array<
                        { __typename?: 'Referral' } & Pick<
                            Referral,
                            | 'id'
                            | 'inviter_id'
                            | 'invitee_id'
                            | 'amount'
                            | 'invitee_payoff_transaction_id'
                            | 'inviter_payoff_transaction_id'
                            | 'created_at'
                        > & {
                                inviter: { __typename?: 'Account' } & Pick<Account, 'id' | 'email'>
                                invitee: { __typename?: 'Account' } & Pick<Account, 'id' | 'email'>
                                inviter_investment: Maybe<
                                    { __typename?: 'Investment' } & Pick<
                                        Investment,
                                        'id' | 'amount'
                                    >
                                >
                                invitee_investment: Maybe<
                                    { __typename?: 'Investment' } & Pick<
                                        Investment,
                                        'id' | 'amount'
                                    >
                                >
                            }
                    >
                >
                account_stats: { __typename?: 'AccountStats' } & Pick<
                    AccountStats,
                    | 'paid_off'
                    | 'total_invested'
                    | 'pending_withdrawal_amount'
                    | 'currently_invested'
                    | 'balance'
                    | 'net_yield'
                    | 'expected_yield'
                > & {
                        paid_off_eur: AccountStats['paid_off']
                        total_invested_eur: AccountStats['total_invested']
                        pending_withdrawal_amount_eur: AccountStats['pending_withdrawal_amount']
                        currently_invested_eur: AccountStats['currently_invested']
                        balance_eur: AccountStats['balance']
                        net_yield_eur: AccountStats['net_yield']
                        expected_yield_eur: AccountStats['expected_yield']
                    }
                messages: Maybe<
                    Array<
                        { __typename?: 'Message' } & Pick<
                            Message,
                            'id' | 'content' | 'created_at' | 'to' | 'from' | 'subject'
                        >
                    >
                >
                agreement_set: Maybe<
                    { __typename?: 'AgreementSet' } & Pick<
                        AgreementSet,
                        | 'id'
                        | 'agreement_1'
                        | 'agreement_2'
                        | 'agreement_3'
                        | 'agreement_4'
                        | 'agreement_5'
                        | 'agreement_6'
                        | 'agreement_7'
                    >
                >
            }
    >
}

export type AdminAccountDetailMutationMutationVariables = Exact<{
    ids: Array<Scalars['Int']> | Scalars['Int']
    data: AccountUpdateData
}>

export type AdminAccountDetailMutationMutation = { __typename?: 'Mutation' } & {
    adminAccountUpdate: Array<
        { __typename?: 'Account' } & Pick<
            Account,
            | 'id'
            | 'name'
            | 'surname'
            | 'phone'
            | 'phone_country_code'
            | 'variable_symbol'
            | 'iban'
            | 'iban_eur'
            | 'corporate_name'
            | 'email'
            | 'notes'
            | 'status'
            | 'distribution_partner_account_id'
            | 'is_corporate'
            | 'is_usa_citizen'
        > & {
                tax_residences: Array<
                    { __typename?: 'AccountTaxResidence' } & Pick<
                        AccountTaxResidence,
                        'country' | 'hasVatNumber' | 'vatNumber' | 'noVatNumberReason'
                    >
                >
            }
    >
}

export type GetDistributionPartnersQueryVariables = Exact<{ [key: string]: never }>

export type GetDistributionPartnersQuery = { __typename?: 'Query' } & {
    distribution_partners: Array<
        { __typename?: 'DistributionPartner' } & Pick<DistributionPartner, 'id' | 'name'> & {
                distribution_partner_accounts: Array<
                    { __typename?: 'DistributionPartnerAccount' } & Pick<
                        DistributionPartnerAccount,
                        'id' | 'auth_id'
                    >
                >
            }
    >
}

export type AssignDistributionPartnerAccountMutationVariables = Exact<{
    id: Scalars['Int']
    distribution_partner_account_id: Scalars['Int']
}>

export type AssignDistributionPartnerAccountMutation = { __typename?: 'Mutation' } & {
    assignDistributionPartnerAccount: { __typename?: 'Account' } & Pick<
        Account,
        'id' | 'distribution_partner_account_id'
    >
}

export type GetAccountOpportunityExpositionQueryVariables = Exact<{
    account_id: Scalars['Int']
}>

export type GetAccountOpportunityExpositionQuery = { __typename?: 'Query' } & {
    account_exposition: Array<
        { __typename?: 'AccountOpportunityExposition' } & Pick<
            AccountOpportunityExposition,
            'amount' | 'interest_paid' | 'principal_paid' | 'amount_paid'
        > & {
                opportunity: { __typename?: 'Opportunity' } & Pick<
                    Opportunity,
                    'id' | 'title' | 'currencies'
                >
            }
    >
}

export type AdminAccountSubscriptionsQueryQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type AdminAccountSubscriptionsQueryQuery = { __typename?: 'Query' } & {
    account: Maybe<
        { __typename?: 'Account' } & Pick<Account, 'id' | 'is_subscriber' | 'is_review_subscriber'>
    >
}

export type AdminCreateReferralMutationVariables = Exact<{
    inviter_id: Scalars['Int']
    invitee_id: Scalars['Int']
    amount: Scalars['Int']
}>

export type AdminCreateReferralMutation = { __typename?: 'Mutation' } & {
    createReferral: { __typename?: 'Referral' } & Pick<
        Referral,
        | 'id'
        | 'inviter_id'
        | 'invitee_id'
        | 'amount'
        | 'invitee_payoff_transaction_id'
        | 'inviter_payoff_transaction_id'
        | 'created_at'
    > & {
            inviter: { __typename?: 'Account' } & Pick<Account, 'id' | 'email'>
            invitee: { __typename?: 'Account' } & Pick<Account, 'id' | 'email'>
            inviter_investment: Maybe<
                { __typename?: 'Investment' } & Pick<Investment, 'id' | 'amount'>
            >
            invitee_investment: Maybe<
                { __typename?: 'Investment' } & Pick<Investment, 'id' | 'amount'>
            >
        }
}

export type AccountRejectMutationMutationVariables = Exact<{
    account_id: Scalars['Int']
    rejection_reason: Scalars['String']
}>

export type AccountRejectMutationMutation = { __typename?: 'Mutation' } & {
    rejectAccount: { __typename?: 'Account' } & Pick<Account, 'id' | 'status' | 'audit_log'>
}

export type AdminAccountSanctionScansQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type AdminAccountSanctionScansQuery = { __typename?: 'Query' } & {
    account: Maybe<
        { __typename?: 'Account' } & Pick<
            Account,
            'id' | 'last_sanction_list_check' | 'verification_provider'
        > & {
                scans: Maybe<
                    Array<
                        { __typename?: 'AccountScan' } & Pick<
                            AccountScan,
                            | 'id'
                            | 'sanction_scanner_id'
                            | 'sanctionScannerResult'
                            | 'created_at'
                            | 'updated_at'
                        >
                    >
                >
            }
    >
}

export type GetAccountSuspensionsQueryVariables = Exact<{
    account_id: Scalars['Int']
}>

export type GetAccountSuspensionsQuery = { __typename?: 'Query' } & {
    account_suspensions: Array<
        { __typename?: 'AccountSuspension' } & Pick<
            AccountSuspension,
            'id' | 'created_at' | 'reason' | 'is_resolved' | 'resolution_note'
        >
    >
}

export type AddAccountSuspensionMutationVariables = Exact<{
    account_id: Scalars['Int']
    reason: AccountSuspensionReason
}>

export type AddAccountSuspensionMutation = { __typename?: 'Mutation' } & {
    addAccountSuspension: { __typename?: 'AccountSuspension' } & Pick<AccountSuspension, 'id'>
}

export type ResolveAccountSuspensionMutationVariables = Exact<{
    id: Scalars['Int']
    resolution_note: Scalars['String']
}>

export type ResolveAccountSuspensionMutation = { __typename?: 'Mutation' } & {
    resolveAccountSuspension: { __typename?: 'AccountSuspension' } & Pick<
        AccountSuspension,
        'id' | 'is_resolved' | 'resolution_note'
    >
}

export type UsedBenefitLimitQueryQueryVariables = Exact<{
    account_id: Scalars['Int']
}>

export type UsedBenefitLimitQueryQuery = { __typename?: 'Query' } & Pick<
    Query,
    'used_benefit_limit'
>

export type AdminAccountsStatsQueryVariables = Exact<{
    filter: Maybe<AccountsFilter>
    dateRange: Maybe<DateRange>
}>

export type AdminAccountsStatsQuery = { __typename?: 'Query' } & {
    accountsStats: { __typename?: 'AccountsStats' } & Pick<
        AccountsStats,
        | 'total_count'
        | 'total_invested'
        | 'total_invested_eur'
        | 'total_wallet'
        | 'total_wallet_eur'
    >
}

export type AdminAccountsQueryVariables = Exact<{
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
    filter: Maybe<AccountsFilter>
    dateRange: Maybe<DateRange>
}>

export type AdminAccountsQuery = { __typename?: 'Query' } & {
    accounts: { __typename?: 'AccountConnection' } & Pick<AccountConnection, 'totalCount'> & {
            nodes: Array<
                { __typename?: 'Account' } & Pick<
                    Account,
                    | 'id'
                    | 'status'
                    | 'name'
                    | 'surname'
                    | 'is_corporate'
                    | 'corporate_name'
                    | 'pid'
                    | 'email'
                    | 'phone'
                    | 'phone_country_code'
                    | 'variable_symbol'
                    | 'created_at'
                    | 'kb_flag'
                    | 'last_aml_review_date'
                    | 'risk_profile'
                    | 'sign_date'
                > & {
                        account_stats: { __typename?: 'AccountStats' } & Pick<
                            AccountStats,
                            'balance' | 'total_invested'
                        >
                    }
            >
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'endCursor' | 'hasNextPage' | 'hasPreviousPage'
            >
        }
}

export type AddAccountAmlReviewMutationVariables = Exact<{
    account_id: Scalars['Int']
    data: AccountAmlReviewData
}>

export type AddAccountAmlReviewMutation = { __typename?: 'Mutation' } & {
    addAccountAMLReview: { __typename?: 'Account' } & Pick<Account, 'id' | 'last_aml_review_date'>
}

export type AdminActiveOpportunitiesQueryVariables = Exact<{ [key: string]: never }>

export type AdminActiveOpportunitiesQuery = { __typename?: 'Query' } & {
    opportunities: Array<
        { __typename?: 'Opportunity' } & Pick<
            Opportunity,
            | 'id'
            | 'title'
            | 'fundraising_period_start'
            | 'fundraising_period_end'
            | 'max_fundraising_target'
            | 'min_fundraising_target'
            | 'raised'
            | 'raised_eur'
            | 'currencies'
        >
    >
}

export type CreateCorporatePersonMutationVariables = Exact<{
    account_id: Scalars['Int']
    data: CorporatePersonCreateData
}>

export type CreateCorporatePersonMutation = { __typename?: 'Mutation' } & {
    corporatePersonCreate: { __typename?: 'CorporatePerson' } & Pick<
        CorporatePerson,
        'id' | 'account_id' | 'name' | 'surname'
    >
}

export type AdminCorporatePersonQueryQueryVariables = Exact<{
    person_id: Scalars['Int']
}>

export type AdminCorporatePersonQueryQuery = { __typename?: 'Query' } & {
    corporate_person: { __typename?: 'CorporatePerson' } & AdminCorporatePersonEditFieldsFragment
}

export type UpdateCorporatePersonMutationMutationVariables = Exact<{
    person_id: Scalars['Int']
    data: CorporatePersonUpdateData
}>

export type UpdateCorporatePersonMutationMutation = { __typename?: 'Mutation' } & {
    corporatePersonUpdate: {
        __typename?: 'CorporatePerson'
    } & AdminCorporatePersonEditFieldsFragment
}

export type AccountIdsOnOpportunityQueryQueryVariables = Exact<{
    filter: Maybe<InvestmentsFilter>
}>

export type AccountIdsOnOpportunityQueryQuery = { __typename?: 'Query' } & {
    investments: { __typename?: 'InvestmentConnection' } & {
        nodes: Array<{ __typename?: 'Investment' } & Pick<Investment, 'account_id'>>
    }
}

export type AdminTodoListQueryVariables = Exact<{ [key: string]: never }>

export type AdminTodoListQuery = { __typename?: 'Query' } & {
    todoList: Array<{ __typename?: 'TodoItem' } & Pick<TodoItem, 'id' | 'title' | 'created_at'>>
}

export type AdminTodoListRemoveItemMutationVariables = Exact<{
    id: Scalars['String']
}>

export type AdminTodoListRemoveItemMutation = { __typename?: 'Mutation' } & {
    todoItemRemove: Array<
        { __typename?: 'TodoItem' } & Pick<TodoItem, 'id' | 'title' | 'created_at'>
    >
}

export type AdminTodoListAddItemMutationVariables = Exact<{
    title: Scalars['String']
}>

export type AdminTodoListAddItemMutation = { __typename?: 'Mutation' } & {
    todoItemCreate: Array<
        { __typename?: 'TodoItem' } & Pick<TodoItem, 'id' | 'title' | 'created_at'>
    >
}

export type AdminDashboardStatsQueryVariables = Exact<{
    dateRange: Maybe<DateRange>
}>

export type AdminDashboardStatsQuery = { __typename?: 'Query' } & {
    accounts_approved: { __typename?: 'AccountConnection' } & Pick<AccountConnection, 'totalCount'>
    accounts_not_approved: { __typename?: 'AccountConnection' } & Pick<
        AccountConnection,
        'totalCount'
    >
    accounts_pending: { __typename?: 'AccountConnection' } & Pick<AccountConnection, 'totalCount'>
    transaction_deposits: { __typename?: 'TransactionsStats' } & Pick<
        TransactionsStats,
        'kb' | 'csas' | 'kb_eur' | 'csas_eur'
    >
    transaction_withdrawals: { __typename?: 'TransactionsStats' } & Pick<
        TransactionsStats,
        'kb' | 'csas' | 'kb_eur' | 'csas_eur'
    >
    transaction_unpaired: { __typename?: 'TransactionsStats' } & Pick<
        TransactionsStats,
        'kb' | 'csas' | 'kb_eur' | 'csas_eur'
    >
    investmentsStats: { __typename?: 'InvestmentsStats' } & Pick<
        InvestmentsStats,
        'total' | 'total_eur' | 'count' | 'count_eur' | 'mean_average' | 'mean_average_eur'
    >
    withdrawalRequestsStats: { __typename?: 'WithdrawalRequestsStats' } & Pick<
        WithdrawalRequestsStats,
        'unresolved_count' | 'unresolved_amount' | 'unresolved_count_eur' | 'unresolved_amount_eur'
    >
}

export type GetOpportunityReportsQueryQueryVariables = Exact<{
    unsent_only: Maybe<Scalars['Boolean']>
}>

export type GetOpportunityReportsQueryQuery = { __typename?: 'Query' } & {
    opportunity_reports: Array<
        { __typename?: 'OpportunityReport' } & Pick<OpportunityReport, 'id' | 'title'> & {
                opportunity: { __typename?: 'Opportunity' } & Pick<Opportunity, 'id' | 'title'>
            }
    >
}

export type AdminInvestmentsStatsQueryVariables = Exact<{
    filter: Maybe<InvestmentsFilter>
    dateRange: Maybe<DateRange>
}>

export type AdminInvestmentsStatsQuery = { __typename?: 'Query' } & {
    investmentsStats: { __typename?: 'InvestmentsStats' } & Pick<
        InvestmentsStats,
        'total' | 'total_eur' | 'count' | 'count_eur' | 'mean_average' | 'mean_average_eur'
    >
}

export type AdminInvestmentsQueryVariables = Exact<{
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
    filter: Maybe<InvestmentsFilter>
    dateRange: Maybe<DateRange>
}>

export type AdminInvestmentsQuery = { __typename?: 'Query' } & {
    investments: { __typename?: 'InvestmentConnection' } & Pick<
        InvestmentConnection,
        'totalCount'
    > & {
            nodes: Array<
                { __typename?: 'Investment' } & Pick<
                    Investment,
                    | 'id'
                    | 'account_id'
                    | 'amount'
                    | 'interest_rate'
                    | 'distribution_fee'
                    | 'created_at'
                    | 'currency'
                > & {
                        opportunity: { __typename?: 'Opportunity' } & Pick<
                            Opportunity,
                            'title' | 'id'
                        >
                        account: { __typename?: 'Account' } & Pick<
                            Account,
                            'id' | 'name' | 'email' | 'surname' | 'is_corporate' | 'corporate_name'
                        >
                    }
            >
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'endCursor' | 'hasNextPage' | 'hasPreviousPage'
            >
        }
}

export type AdminOpportunityNewsCreateMutationVariables = Exact<{
    opportunity_id: Scalars['Int']
    content: Scalars['String']
    is_investor_only: Scalars['Boolean']
    type: OpportunityNewsType
    links: Array<OpportunityNewsLinkInput> | OpportunityNewsLinkInput
}>

export type AdminOpportunityNewsCreateMutation = { __typename?: 'Mutation' } & {
    createOpportunityNews: { __typename?: 'OpportunityNews' } & Pick<
        OpportunityNews,
        'id' | 'opportunity_id' | 'content' | 'type'
    > & {
            links: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'OpportunityNewsLink' } & Pick<
                            OpportunityNewsLink,
                            'url' | 'label' | 'type'
                        >
                    >
                >
            >
        }
}

export type AdminOpportunityNewsUpdateMutationVariables = Exact<{
    id: Scalars['Int']
    opportunity_id: Scalars['Int']
    content: Scalars['String']
    is_investor_only: Scalars['Boolean']
    type: OpportunityNewsType
    links: Array<OpportunityNewsLinkInput> | OpportunityNewsLinkInput
}>

export type AdminOpportunityNewsUpdateMutation = { __typename?: 'Mutation' } & {
    updateOpportunityNews: { __typename?: 'OpportunityNews' } & Pick<
        OpportunityNews,
        'id' | 'opportunity_id' | 'content' | 'type'
    > & {
            links: Maybe<
                Array<
                    Maybe<
                        { __typename?: 'OpportunityNewsLink' } & Pick<
                            OpportunityNewsLink,
                            'url' | 'label' | 'type'
                        >
                    >
                >
            >
        }
}

export type AdminDeleteOpportunityNewsMutationVariables = Exact<{
    id: Scalars['Int']
}>

export type AdminDeleteOpportunityNewsMutation = { __typename?: 'Mutation' } & {
    deleteOpportunityNews: { __typename?: 'OpportunityNews' } & Pick<OpportunityNews, 'id'>
}

export type AdminOpportunityTrancheCreateMutationVariables = Exact<{
    data: OpportunityTrancheData
}>

export type AdminOpportunityTrancheCreateMutation = { __typename?: 'Mutation' } & {
    opportunityTrancheCreate: { __typename?: 'OpportunityTranche' } & Pick<
        OpportunityTranche,
        | 'id'
        | 'fundraising_period_start'
        | 'fundraising_period_end'
        | 'min_fundraising_target'
        | 'max_fundraising_target'
    >
}

export type AdminOpportunityTrancheUpdateMutationVariables = Exact<{
    id: Scalars['Int']
    data: OpportunityTrancheData
}>

export type AdminOpportunityTrancheUpdateMutation = { __typename?: 'Mutation' } & {
    opportunityTrancheUpdate: { __typename?: 'OpportunityTranche' } & Pick<
        OpportunityTranche,
        | 'id'
        | 'fundraising_period_start'
        | 'fundraising_period_end'
        | 'min_fundraising_target'
        | 'max_fundraising_target'
    >
}

export type AdminOpportunityTrancheDeleteMutationVariables = Exact<{
    id: Scalars['Int']
}>

export type AdminOpportunityTrancheDeleteMutation = { __typename?: 'Mutation' } & {
    opportunityTrancheDelete: { __typename?: 'OpportunityTranche' } & Pick<OpportunityTranche, 'id'>
}

export type ReferenceRatesTypesQueryQueryVariables = Exact<{
    type: ReferenceRateType
}>

export type ReferenceRatesTypesQueryQuery = { __typename?: 'Query' } & {
    reference_rates: Array<
        { __typename?: 'ReferenceRate' } & Pick<ReferenceRate, 'id' | 'type' | 'date'>
    >
}

export type AdminReportingKbkycQueryVariables = Exact<{
    dateRange: Maybe<DateRange>
}>

export type AdminReportingKbkycQuery = { __typename?: 'Query' } & {
    reporting: { __typename?: 'Reporting' } & Pick<
        Reporting,
        | 'number_of_non_cz_residents'
        | 'number_of_peps'
        | 'number_of_terminated_clients'
        | 'number_of_unresolved_transaction_aml_checks'
        | 'number_of_manually_resolved_transaction_aml_checks'
    > & {
            number_of_clients: { __typename?: 'CountByRiskProfile' } & Pick<
                CountByRiskProfile,
                'high' | 'medium_high' | 'medium_low' | 'low'
            >
            number_of_new_clients: { __typename?: 'CountByRiskProfile' } & Pick<
                CountByRiskProfile,
                'high' | 'medium_high' | 'medium_low' | 'low'
            >
        }
}

export type GetDistributionPartnersDeprecatedQueryVariables = Exact<{ [key: string]: never }>

export type GetDistributionPartnersDeprecatedQuery = { __typename?: 'Query' } & {
    distribution_partners: Array<
        { __typename?: 'DistributionPartner' } & Pick<DistributionPartner, 'id' | 'name'> & {
                distribution_partner_accounts: Array<
                    { __typename?: 'DistributionPartnerAccount' } & Pick<
                        DistributionPartnerAccount,
                        'id' | 'auth_id'
                    >
                >
            }
    >
}

export type AssignDistributionPartnerAccountDeprecatedMutationVariables = Exact<{
    id: Scalars['Int']
    distribution_partner_account_id: Scalars['Int']
}>

export type AssignDistributionPartnerAccountDeprecatedMutation = { __typename?: 'Mutation' } & {
    assignDistributionPartnerAccount: { __typename?: 'Account' } & Pick<Account, 'id'>
}

export type TransactionAmlCheckUpdateMutationVariables = Exact<{
    id: Scalars['Int']
    reason: Scalars['String']
    checkType: Scalars['String']
}>

export type TransactionAmlCheckUpdateMutation = { __typename?: 'Mutation' } & {
    transactionAmlCheckUpdate: { __typename?: 'TransactionAmlChecks' } & Pick<
        TransactionAmlChecks,
        | 'id'
        | 'ten_times_less_than_average'
        | 'no_4_consecutive_transactions'
        | 'inside_EEA'
        | 'under_2M'
    >
}

export type AdminTransactionDetailQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type AdminTransactionDetailQuery = { __typename?: 'Query' } & {
    transaction: Maybe<
        { __typename?: 'Transaction' } & Pick<
            Transaction,
            | 'id'
            | 'note'
            | 'audit_log'
            | 'original_payload'
            | 'amount'
            | 'account_id'
            | 'currency'
            | 'bank_id'
            | 'bank_type'
            | 'created_at'
            | 'variable_symbol'
            | 'type'
        > & {
                account: Maybe<
                    { __typename?: 'Account' } & Pick<
                        Account,
                        | 'id'
                        | 'name'
                        | 'surname'
                        | 'is_corporate'
                        | 'corporate_name'
                        | 'email'
                        | 'risk_profile'
                    >
                >
                aml_checks: Maybe<
                    { __typename?: 'TransactionAmlChecks' } & Pick<
                        TransactionAmlChecks,
                        | 'id'
                        | 'ten_times_less_than_average'
                        | 'no_4_consecutive_transactions'
                        | 'inside_EEA'
                        | 'under_2M'
                    >
                >
                withdrawal_request: Maybe<
                    { __typename?: 'WithdrawalRequest' } & Pick<WithdrawalRequest, 'id'>
                >
            }
    >
}

export type AdminTransactionDetailMutationMutationVariables = Exact<{
    ids: Array<Scalars['Int']> | Scalars['Int']
    data: TransactionUpdateData
}>

export type AdminTransactionDetailMutationMutation = { __typename?: 'Mutation' } & {
    transactionUpdate: Array<
        { __typename?: 'Transaction' } & Pick<Transaction, 'id' | 'type' | 'note'>
    >
}

export type AdminPairTransactionMutationVariables = Exact<{
    id: Scalars['Int']
    account_id: Scalars['Int']
}>

export type AdminPairTransactionMutation = { __typename?: 'Mutation' } & {
    adminPairTransaction: { __typename?: 'Transaction' } & Pick<Transaction, 'id' | 'type'> & {
            account: Maybe<
                { __typename?: 'Account' } & Pick<
                    Account,
                    'id' | 'name' | 'surname' | 'is_corporate' | 'corporate_name' | 'email'
                >
            >
        }
}

export type ReferralPayoffOpportunitiesQueryQueryVariables = Exact<{ [key: string]: never }>

export type ReferralPayoffOpportunitiesQueryQuery = { __typename?: 'Query' } & {
    opportunities: Array<{ __typename?: 'Opportunity' } & Pick<Opportunity, 'id' | 'title'>>
}

export type PayoffReferralsWithTransactionMutationVariables = Exact<{
    opportunity_id: Scalars['Int']
    transaction_id: Scalars['Int']
}>

export type PayoffReferralsWithTransactionMutation = { __typename?: 'Mutation' } & {
    payoffReferralsWithTransaction: Array<{ __typename?: 'Referral' } & Pick<Referral, 'id'>>
}

export type TransactionsForwardExportQueryVariables = Exact<{
    filter: Maybe<TransactionsFilter>
    dateRange: Maybe<DateRange>
}>

export type TransactionsForwardExportQuery = { __typename?: 'Query' } & {
    transactions: { __typename?: 'TransactionConnection' } & {
        nodes: Array<{ __typename?: 'Transaction' } & Pick<Transaction, 'id' | 'amount'>>
    }
}

export type TransactionReturnFragmentFragment = { __typename?: 'Transaction' } & Pick<
    Transaction,
    'id' | 'amount' | 'currency' | 'original_payload'
>

export type TransactionsReturnExportQueryVariables = Exact<{
    filter: Maybe<TransactionsFilter>
    dateRange: Maybe<DateRange>
    ids: Array<Scalars['Int']> | Scalars['Int']
}>

export type TransactionsReturnExportQuery = { __typename?: 'Query' } & {
    transactions: { __typename?: 'TransactionConnection' } & {
        nodes: Array<{ __typename?: 'Transaction' } & TransactionReturnFragmentFragment>
    }
    transactions_by_id: Array<{ __typename?: 'Transaction' } & TransactionReturnFragmentFragment>
}

export type AdminMutateReturnTransactionsMutationVariables = Exact<{
    ids: Array<Scalars['Int']> | Scalars['Int']
    data: TransactionUpdateData
}>

export type AdminMutateReturnTransactionsMutation = { __typename?: 'Mutation' } & {
    transactionUpdate: Array<{ __typename?: 'Transaction' } & Pick<Transaction, 'id' | 'type'>>
}

export type AdminTransactionsStatsQueryVariables = Exact<{
    filter: Maybe<TransactionsFilter>
    dateRange: Maybe<DateRange>
}>

export type AdminTransactionsStatsQuery = { __typename?: 'Query' } & {
    transactionsStats: { __typename?: 'TransactionsStats' } & Pick<
        TransactionsStats,
        'csas' | 'csas_eur' | 'kb' | 'kb_eur' | 'total' | 'total_count' | 'total_eur'
    >
}

export type AdminTransactionsQueryVariables = Exact<{
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
    filter: Maybe<TransactionsFilter>
    dateRange: Maybe<DateRange>
}>

export type AdminTransactionsQuery = { __typename?: 'Query' } & {
    transactions: { __typename?: 'TransactionConnection' } & Pick<
        TransactionConnection,
        'totalCount'
    > & {
            nodes: Array<
                { __typename?: 'Transaction' } & Pick<
                    Transaction,
                    | 'id'
                    | 'amount'
                    | 'created_at'
                    | 'currency'
                    | 'note'
                    | 'type'
                    | 'variable_symbol'
                    | 'bank_type'
                > & {
                        account: Maybe<
                            { __typename?: 'Account' } & Pick<
                                Account,
                                | 'corporate_name'
                                | 'email'
                                | 'id'
                                | 'is_corporate'
                                | 'name'
                                | 'surname'
                                | 'risk_profile'
                            >
                        >
                        aml_checks: Maybe<
                            { __typename?: 'TransactionAmlChecks' } & Pick<
                                TransactionAmlChecks,
                                | 'id'
                                | 'ten_times_less_than_average'
                                | 'no_4_consecutive_transactions'
                                | 'inside_EEA'
                                | 'under_2M'
                            >
                        >
                        withdrawal_request: Maybe<
                            { __typename?: 'WithdrawalRequest' } & Pick<WithdrawalRequest, 'id'>
                        >
                    }
            >
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'endCursor' | 'hasNextPage' | 'hasPreviousPage'
            >
        }
}

export type ValidateXmlQueryVariables = Exact<{
    xsdSchema: Scalars['String']
    xmlString: Scalars['String']
}>

export type ValidateXmlQuery = { __typename?: 'Query' } & {
    validateXML: { __typename?: 'ValidationResult' } & Pick<ValidationResult, 'valid' | 'errors'>
}

export type AdminWithdrawalRequestsStatsQueryVariables = Exact<{ [key: string]: never }>

export type AdminWithdrawalRequestsStatsQuery = { __typename?: 'Query' } & {
    withdrawalRequestsStats: { __typename?: 'WithdrawalRequestsStats' } & Pick<
        WithdrawalRequestsStats,
        'unresolved_amount' | 'unresolved_count' | 'unresolved_amount_eur' | 'unresolved_count_eur'
    >
}

export type AdminWithdrawalRequestsQueryVariables = Exact<{
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
    filter: Maybe<WithdrawalRequestsFilter>
    dateRange: Maybe<DateRange>
}>

export type AdminWithdrawalRequestsQuery = { __typename?: 'Query' } & {
    withdrawalRequests: { __typename?: 'WithdrawalRequestConnection' } & Pick<
        WithdrawalRequestConnection,
        'totalCount'
    > & {
            nodes: Array<
                { __typename?: 'WithdrawalRequest' } & Pick<
                    WithdrawalRequest,
                    'id' | 'amount' | 'currency' | 'status' | 'created_at'
                > & {
                        account: Maybe<
                            { __typename?: 'Account' } & Pick<
                                Account,
                                | 'corporate_name'
                                | 'email'
                                | 'id'
                                | 'is_corporate'
                                | 'name'
                                | 'surname'
                                | 'iban'
                                | 'iban_eur'
                                | 'variable_symbol'
                                | 'state_of_residency'
                            >
                        >
                    }
            >
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'endCursor' | 'hasNextPage' | 'hasPreviousPage'
            >
        }
}

export type AdminOpportunityDetailFieldsFragment = { __typename?: 'Opportunity' } & Pick<
    Opportunity,
    | 'id'
    | 'cashflow_model'
    | 'text_id'
    | 'title'
    | 'published'
    | 'raised'
    | 'min_fundraising_target'
    | 'max_fundraising_target'
    | 'loan_interest_rate'
    | 'fundraising_period_start'
    | 'fundraising_period_end'
    | 'executive_summary_pdf'
    | 'report_pdf'
    | 'fees_agreement'
    | 'is_private_placement'
    | 'interest_rate_type'
    | 'interest_rates'
    | 'in_widget'
    | 'reference_rate_type'
    | 'reference_rate_id'
    | 'type'
    | 'market_segment'
    | 'currencies'
    | 'audit_log'
    | 'status'
    | 'raised_eur'
    | 'card_photo'
    | 'cover_photo'
    | 'net_value'
    | 'title_en'
    | 'subtitle'
    | 'subtitle_en'
    | 'expected_maturity'
    | 'maturity'
    | 'ik_fundraising_period_end'
    | 'address'
    | 'min_investment'
    | 'max_investment'
    | 'payment_frequency'
    | 'distribution_fee'
>

export type AdminAccountReviewFieldsFragment = { __typename?: 'Account' } & Pick<
    Account,
    | 'id'
    | 'email'
    | 'name'
    | 'surname'
    | 'is_corporate'
    | 'corporate_name'
    | 'pid'
    | 'phone'
    | 'phone_country_code'
    | 'created_at'
    | 'last_aml_review_date'
>

export type AdminCorporatePersonEditFieldsFragment = { __typename?: 'CorporatePerson' } & Pick<
    CorporatePerson,
    | 'id'
    | 'name'
    | 'surname'
    | 'account_id'
    | 'id_number'
    | 'id_type'
    | 'city_of_birth'
    | 'state_of_birth'
    | 'address'
    | 'city'
    | 'zip'
    | 'pid'
    | 'state_of_citizenship'
    | 'state_of_residency'
    | 'risk_profile'
    | 'risk_profile_score'
    | 'is_sanctioned'
    | 'has_right_to_sign'
    | 'is_not_PEP'
    | 'id1_front'
    | 'is_executive'
    | 'is_beneficial_owner'
    | 'id1_back'
    | 'id2_front'
    | 'phone'
    | 'phone_country_code'
    | 'company_relationship'
    | 'audit_log'
>

export type RemoveOpportunityPrivatePlacementEmailMutationVariables = Exact<{
    email: Scalars['String']
    opportunityId: Scalars['Int']
}>

export type RemoveOpportunityPrivatePlacementEmailMutation = { __typename?: 'Mutation' } & {
    removeOpportunityPrivatePlacementEmail: { __typename?: 'SimpleMutationResult' } & Pick<
        SimpleMutationResult,
        'isSuccess'
    >
}

export type AddOpportunityPrivatePlacementEmailMutationVariables = Exact<{
    email: Scalars['String']
    opportunityId: Scalars['Int']
}>

export type AddOpportunityPrivatePlacementEmailMutation = { __typename?: 'Mutation' } & {
    addOpportunityPrivatePlacementEmail: { __typename?: 'OpportunityPrivatePlacementEmail' } & Pick<
        OpportunityPrivatePlacementEmail,
        'opportunity_id' | 'email'
    >
}

export type StartInterestPeriodMutationVariables = Exact<{
    startDate: Scalars['String']
    opportunityId: Scalars['Int']
    fromDate: Maybe<Scalars['String']>
    toDate: Maybe<Scalars['String']>
}>

export type StartInterestPeriodMutation = { __typename?: 'Mutation' } & {
    startInterestPeriod: { __typename?: 'WorkerJobsStartedResult' } & Pick<
        WorkerJobsStartedResult,
        'jobIds'
    >
}

export type AdminWithdrawalRequestsMutationMutationVariables = Exact<{
    ids: Array<Scalars['Int']> | Scalars['Int']
    data: WithdrawalRequestUpdateData
}>

export type AdminWithdrawalRequestsMutationMutation = { __typename?: 'Mutation' } & {
    withdrawalRequestsMutation: Maybe<
        Array<{ __typename?: 'WithdrawalRequest' } & Pick<WithdrawalRequest, 'id' | 'status'>>
    >
}

export type UpdateCashflowsAndInterestRatesMutationVariables = Exact<{
    id: Scalars['Int']
    data: OpportunityCashflowAndInterestRatesUpdateData
}>

export type UpdateCashflowsAndInterestRatesMutation = { __typename?: 'Mutation' } & {
    updateOpportunityCashflowsAndInterestRatesMutation: { __typename?: 'Opportunity' } & Pick<
        Opportunity,
        'id' | 'cashflow_model' | 'interest_rates'
    >
}

export type UpdateOpportunityCardAndCoverPhotosMutationVariables = Exact<{
    id: Scalars['Int']
    data: OpportunityCardAndCoverPhotoUpdateData
}>

export type UpdateOpportunityCardAndCoverPhotosMutation = { __typename?: 'Mutation' } & {
    updateOpportunityCardAndCoverPhotosMutation: { __typename?: 'Opportunity' } & Pick<
        Opportunity,
        'id' | 'card_photo' | 'cover_photo'
    >
}

export type CreateOrUpdateOpportunityMutationVariables = Exact<{
    id: Maybe<Scalars['Int']>
    data: OpportunityCreateOrUpdateData
}>

export type CreateOrUpdateOpportunityMutation = { __typename?: 'Mutation' } & {
    createOrUpdateOpportunityMutation: { __typename?: 'Opportunity' } & Pick<Opportunity, 'id'>
}

export type UpdateOpportunityTracheCashflowMutationVariables = Exact<{
    id: Scalars['Int']
    data: OpportunityTrancheCashflowData
}>

export type UpdateOpportunityTracheCashflowMutation = { __typename?: 'Mutation' } & {
    updateOpportunityTracheCashflow: { __typename?: 'OpportunityTranche' } & Pick<
        OpportunityTranche,
        'id' | 'cashflow_model'
    >
}

export type RequestAccountUpdateMutationVariables = Exact<{
    account_id: Scalars['Int']
}>

export type RequestAccountUpdateMutation = { __typename?: 'Mutation' } & {
    requestAccountUpdate: { __typename?: 'PendingAccountUpdates' } & Pick<
        PendingAccountUpdates,
        'id'
    >
}

export type ApproveUploadedDocumentMutationVariables = Exact<{
    account_id: Scalars['Int']
}>

export type ApproveUploadedDocumentMutation = { __typename?: 'Mutation' } & {
    approveUploadedDocument: { __typename?: 'PendingAccountUpdates' } & Pick<
        PendingAccountUpdates,
        'id'
    >
}

export type AdminOpportunitiesQueryQueryVariables = Exact<{
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
}>

export type AdminOpportunitiesQueryQuery = { __typename?: 'Query' } & {
    adminOpportunities: { __typename?: 'OpportunityConnection' } & Pick<
        OpportunityConnection,
        'totalCount'
    > & {
            nodes: Array<
                { __typename?: 'Opportunity' } & Pick<
                    Opportunity,
                    | 'currencies'
                    | 'id'
                    | 'status'
                    | 'raised'
                    | 'title'
                    | 'fundraising_period_end'
                    | 'raised_eur'
                    | 'net_value'
                    | 'created_at'
                    | 'investors_count'
                    | 'published'
                >
            >
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'endCursor' | 'hasNextPage' | 'hasPreviousPage'
            >
        }
}

export type AdminOpportunityQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type AdminOpportunityQuery = { __typename?: 'Query' } & {
    opportunity: { __typename?: 'Opportunity' } & {
        reports: Array<
            { __typename?: 'OpportunityReport' } & Pick<
                OpportunityReport,
                'id' | 'title' | 'desc' | 'url' | 'notifications_sent' | 'created_at'
            >
        >
        investments: Array<
            { __typename?: 'Investment' } & Pick<
                Investment,
                'id' | 'account_id' | 'created_at' | 'amount' | 'state' | 'interest_period_start'
            >
        >
        private_placement_emails: Array<
            { __typename?: 'OpportunityPrivatePlacementEmail' } & Pick<
                OpportunityPrivatePlacementEmail,
                'email'
            >
        >
        developer_info: { __typename?: 'DeveloperInfoJSON' } & Pick<
            DeveloperInfoJson,
            'ico' | 'name' | 'address' | 'markNo' | 'registeredBy' | 'logoImage'
        >
        news: Maybe<
            Array<
                { __typename?: 'OpportunityNews' } & Pick<
                    OpportunityNews,
                    | 'id'
                    | 'opportunity_id'
                    | 'created_at'
                    | 'updated_at'
                    | 'content'
                    | 'content_en'
                    | 'type'
                    | 'is_investor_only'
                > & {
                        links: Maybe<
                            Array<
                                Maybe<
                                    { __typename?: 'OpportunityNewsLink' } & Pick<
                                        OpportunityNewsLink,
                                        'url' | 'label' | 'label_en' | 'type'
                                    >
                                >
                            >
                        >
                    }
            >
        >
    } & AdminOpportunityDetailFieldsFragment
}

export type AdminOpportunityReferralsQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type AdminOpportunityReferralsQuery = { __typename?: 'Query' } & {
    opportunity: { __typename?: 'Opportunity' } & Pick<Opportunity, 'id'> & {
            referrals: Array<
                { __typename?: 'Referral' } & Pick<
                    Referral,
                    'inviter_payoff_transaction_id' | 'amount' | 'invitee_payoff_transaction_id'
                > & {
                        inviter_investment: Maybe<
                            { __typename?: 'Investment' } & Pick<Investment, 'opportunity_id'>
                        >
                        invitee_investment: Maybe<
                            { __typename?: 'Investment' } & Pick<Investment, 'opportunity_id'>
                        >
                    }
            >
        }
}

export type AdminAmlReviewAccountsQueryQueryVariables = Exact<{ [key: string]: never }>

export type AdminAmlReviewAccountsQueryQuery = { __typename?: 'Query' } & {
    reporting: { __typename?: 'Reporting' } & {
        clients_to_review: Array<{ __typename?: 'Account' } & AdminAccountReviewFieldsFragment>
    }
}

export type AdminOpportunitiesFilterValuesQueryVariables = Exact<{ [key: string]: never }>

export type AdminOpportunitiesFilterValuesQuery = { __typename?: 'Query' } & {
    opportunities: Array<{ __typename?: 'Opportunity' } & Pick<Opportunity, 'id' | 'title'>>
}

export type GetWorkerJobsStatusQueryVariables = Exact<{
    jobIds: Array<Scalars['String']> | Scalars['String']
    queue: QueueName
}>

export type GetWorkerJobsStatusQuery = { __typename?: 'Query' } & {
    getJobsStatus: Array<
        { __typename?: 'GetJobsStatusResult' } & Pick<
            GetJobsStatusResult,
            'id' | 'state' | 'progress'
        >
    >
}

export type CorporatePersonsForAccountRegistrationValidationQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type CorporatePersonsForAccountRegistrationValidationQuery = { __typename?: 'Query' } & {
    account: Maybe<
        { __typename?: 'Account' } & Pick<Account, 'id'> & {
                corporate_persons: Maybe<
                    Array<
                        { __typename?: 'CorporatePerson' } & Pick<
                            CorporatePerson,
                            | 'id'
                            | 'name'
                            | 'surname'
                            | 'is_beneficial_owner'
                            | 'is_executive'
                            | 'city'
                            | 'state_of_residency'
                            | 'zip'
                            | 'address'
                            | 'phone'
                            | 'phone_country_code'
                            | 'personal_data_agreement'
                        > &
                            AdminCorporatePersonEditFieldsFragment
                    >
                >
            }
    >
}

export type AdminOpportunityTranchesQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type AdminOpportunityTranchesQuery = { __typename?: 'Query' } & {
    opportunity: { __typename?: 'Opportunity' } & Pick<Opportunity, 'id' | 'currencies'> & {
            tranches: Array<
                { __typename?: 'OpportunityTranche' } & Pick<
                    OpportunityTranche,
                    | 'id'
                    | 'opportunity_id'
                    | 'min_fundraising_target'
                    | 'max_fundraising_target'
                    | 'fundraising_period_start'
                    | 'fundraising_period_end'
                    | 'cashflow_model'
                    | 'interest_rates'
                    | 'loan_interest_rate'
                    | 'tranche_title'
                >
            >
        }
}

export type AdminReferralsQueryQueryVariables = Exact<{
    first: Maybe<Scalars['Int']>
    after: Maybe<Scalars['String']>
}>

export type AdminReferralsQueryQuery = { __typename?: 'Query' } & {
    referrals: { __typename?: 'ReferralConnection' } & Pick<ReferralConnection, 'totalCount'> & {
            nodes: Array<
                { __typename?: 'Referral' } & Pick<
                    Referral,
                    'id' | 'created_at' | 'updated_at' | 'inviter_id' | 'invitee_id' | 'amount'
                > & {
                        inviter: { __typename?: 'Account' } & Pick<Account, 'email'>
                        invitee: { __typename?: 'Account' } & Pick<Account, 'email'>
                        inviter_investment: Maybe<
                            { __typename?: 'Investment' } & Pick<Investment, 'amount'>
                        >
                        invitee_investment: Maybe<
                            { __typename?: 'Investment' } & Pick<Investment, 'amount'>
                        >
                    }
            >
            pageInfo: { __typename?: 'PageInfo' } & Pick<
                PageInfo,
                'endCursor' | 'hasNextPage' | 'hasPreviousPage'
            >
        }
}

export type AdminReferralsStatsQueryQueryVariables = Exact<{ [key: string]: never }>

export type AdminReferralsStatsQueryQuery = { __typename?: 'Query' } & {
    referralStats: { __typename?: 'ReferralStats' } & Pick<
        ReferralStats,
        | 'total_count'
        | 'total_invitee_invested'
        | 'total_invitee_invested_average'
        | 'total_invitee_invested_count'
    >
}

export type AdminReferralAmountsQueryVariables = Exact<{
    opportunity_id: Scalars['Int']
}>

export type AdminReferralAmountsQuery = { __typename?: 'Query' } & {
    referralAmounts: { __typename?: 'ReferralAmounts' } & Pick<
        ReferralAmounts,
        'total_amount_to_be_paid' | 'total_amount_already_paid'
    >
}

export type AdminPendingAccountUpdateQueryQueryVariables = Exact<{
    account_id: Scalars['Int']
}>

export type AdminPendingAccountUpdateQueryQuery = { __typename?: 'Query' } & {
    adminPendingAccountUpdate: Maybe<
        { __typename?: 'PendingAccountUpdates' } & Pick<
            PendingAccountUpdates,
            'id' | 'id_front' | 'id_back' | 'created_at'
        >
    >
}

export type GetAccountDistributionPartnerAccountQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type GetAccountDistributionPartnerAccountQuery = { __typename?: 'Query' } & {
    account: Maybe<
        { __typename?: 'Account' } & Pick<Account, 'id'> & {
                distribution_partner_account: Maybe<
                    { __typename?: 'DistributionPartnerAccount' } & Pick<
                        DistributionPartnerAccount,
                        'auth_id'
                    > & {
                            distribution_partner: { __typename?: 'DistributionPartner' } & Pick<
                                DistributionPartner,
                                'name' | 'id'
                            >
                        }
                >
            }
    >
}

export type AdminInvestmentsDeprecatedQueryVariables = Exact<{ [key: string]: never }>

export type AdminInvestmentsDeprecatedQuery = { __typename?: 'Query' } & {
    investmentsDeprecated: Array<
        { __typename?: 'Investment' } & Pick<
            Investment,
            | 'id'
            | 'account_id'
            | 'amount'
            | 'interest_rate'
            | 'distribution_fee'
            | 'created_at'
            | 'currency'
            | 'payments_amount'
        > & {
                opportunity: { __typename?: 'Opportunity' } & Pick<Opportunity, 'title' | 'id'>
                account: { __typename?: 'Account' } & Pick<
                    Account,
                    'id' | 'name' | 'email' | 'surname' | 'is_corporate' | 'corporate_name'
                >
            }
    >
}

export type AllSanctionListsQueryVariables = Exact<{ [key: string]: never }>

export type AllSanctionListsQuery = { __typename?: 'Query' } & {
    sanctionLists: Array<
        { __typename?: 'SanctionList' } & Pick<SanctionList, 'filename' | 'lastModified'>
    >
}

export type ProfileFieldsFragment = { __typename?: 'Profile' } & Pick<
    Profile,
    | 'sub'
    | 'email'
    | 'nickname'
    | 'name'
    | 'picture'
    | 'updated_at'
    | 'email_verified'
    | 'roles'
    | 'identities'
> & {
        user_metadata: { __typename?: 'ProfileMetadata' } & Pick<
            ProfileMetadata,
            'affiliateKey' | 'referralCode' | 'privOpportunityID'
        >
    }

export type AuthTokenFieldsFragment = { __typename?: 'AuthToken' } & Pick<
    AuthToken,
    'id_token' | 'access_token'
>

export type AuthResultFieldsFragment = { __typename?: 'AuthResult' } & {
    profile: { __typename?: 'Profile' } & ProfileFieldsFragment
    token: { __typename?: 'AuthToken' } & AuthTokenFieldsFragment
}

export type MarkAsAffiliateMutationVariables = Exact<{
    affiliateKey: Scalars['String']
}>

export type MarkAsAffiliateMutation = { __typename?: 'Mutation' } & {
    markAsAffiliate: { __typename?: 'SimpleMutationResult' } & Pick<
        SimpleMutationResult,
        'isSuccess'
    >
}

export type MarkAsPrivMemberMutationVariables = Exact<{
    privOpportunityID: Scalars['Int']
}>

export type MarkAsPrivMemberMutation = { __typename?: 'Mutation' } & {
    markAsPrivMember: { __typename?: 'SimpleMutationResult' } & Pick<
        SimpleMutationResult,
        'isSuccess'
    >
}

export type CreateEmailSubscriberMutationVariables = Exact<{
    email: Scalars['String']
}>

export type CreateEmailSubscriberMutation = { __typename?: 'Mutation' } & {
    createEmailSubscriber: { __typename?: 'SimpleMutationResult' } & Pick<
        SimpleMutationResult,
        'isSuccess'
    >
}

export type RemoveEmailSubscriberMutationVariables = Exact<{ [key: string]: never }>

export type RemoveEmailSubscriberMutation = { __typename?: 'Mutation' } & {
    removeEmailSubscriber: { __typename?: 'SimpleMutationResult' } & Pick<
        SimpleMutationResult,
        'isSuccess'
    >
}

export type CreateREviewEmailSubscriberMutationVariables = Exact<{
    email: Scalars['String']
}>

export type CreateREviewEmailSubscriberMutation = { __typename?: 'Mutation' } & {
    createREviewEmailSubscriber: { __typename?: 'SimpleMutationResult' } & Pick<
        SimpleMutationResult,
        'isSuccess'
    >
}

export type RemoveREviewEmailSubscriberMutationVariables = Exact<{ [key: string]: never }>

export type RemoveREviewEmailSubscriberMutation = { __typename?: 'Mutation' } & {
    removeREviewEmailSubscriber: { __typename?: 'SimpleMutationResult' } & Pick<
        SimpleMutationResult,
        'isSuccess'
    >
}

export type AddDistributionPartnerAccountIdMutationVariables = Exact<{
    distributionPartnerAccountId: Scalars['String']
}>

export type AddDistributionPartnerAccountIdMutation = { __typename?: 'Mutation' } & {
    addDistributionPartnerAccount: { __typename?: 'SimpleMutationResult' } & Pick<
        SimpleMutationResult,
        'isSuccess'
    >
}

export type HandleLoginCallbackMutationVariables = Exact<{
    code: Scalars['String']
}>

export type HandleLoginCallbackMutation = { __typename?: 'Mutation' } & {
    handleLoginCallback: { __typename?: 'AuthResult' } & AuthResultFieldsFragment
}

export type HandleSignupCallbackMutationVariables = Exact<{
    code: Scalars['String']
    accountToken: Maybe<Scalars['String']>
    is_corporate: Maybe<Scalars['Boolean']>
}>

export type HandleSignupCallbackMutation = { __typename?: 'Mutation' } & {
    handleSignupCallback: { __typename?: 'SignupResult' } & Pick<
        SignupResult,
        'bankAccountOptions'
    > & {
            profile: { __typename?: 'Profile' } & ProfileFieldsFragment
            token: { __typename?: 'AuthToken' } & AuthTokenFieldsFragment
        }
}

export type HandleLinkAccountsCallbackMutationVariables = Exact<{
    code: Scalars['String']
    accountLinkToken: Scalars['String']
}>

export type HandleLinkAccountsCallbackMutation = { __typename?: 'Mutation' } & {
    handleLinkAccountsCallback: { __typename?: 'AuthResult' } & AuthResultFieldsFragment
}

export type AccountSubscriptionInfoQueryVariables = Exact<{ [key: string]: never }>

export type AccountSubscriptionInfoQuery = { __typename?: 'Query' } & {
    myAccount: { __typename?: 'Account' } & Pick<
        Account,
        'id' | 'is_subscriber' | 'is_review_subscriber'
    >
}

export type AccountKbByPidQueryVariables = Exact<{
    pid: Scalars['String']
}>

export type AccountKbByPidQuery = { __typename?: 'Query' } & {
    accountKBByPID: Maybe<{ __typename?: 'Account' } & Pick<Account, 'id' | 'status'>>
}

export type GetAccountKbByIdQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type GetAccountKbByIdQuery = { __typename?: 'Query' } & {
    accountKBByID: Maybe<
        { __typename?: 'Account' } & Pick<
            Account,
            | 'address'
            | 'agrees_from'
            | 'city'
            | 'city_of_birth'
            | 'distribution_partner_account_id'
            | 'email'
            | 'iban'
            | 'iban_eur'
            | 'id'
            | 'id_type'
            | 'id_number'
            | 'is_subscriber'
            | 'is_corporate'
            | 'is_PEP'
            | 'identity_verified_by'
            | 'name'
            | 'phone'
            | 'phone_country_code'
            | 'pid'
            | 'state_of_birth'
            | 'state_of_citizenship'
            | 'state_of_residency'
            | 'status'
            | 'surname'
            | 'variable_symbol'
            | 'verification_provider'
            | 'zip'
            | 'is_usa_citizen'
        > & {
                tax_residences: Array<
                    { __typename?: 'AccountTaxResidence' } & Pick<
                        AccountTaxResidence,
                        'country' | 'vatNumber' | 'hasVatNumber' | 'noVatNumberReason'
                    >
                >
            }
    >
}

export type GetDistributionPartnerAccountsQueryVariables = Exact<{ [key: string]: never }>

export type GetDistributionPartnerAccountsQuery = { __typename?: 'Query' } & {
    distributionPartnerAccounts: Array<
        { __typename?: 'DistributionPartnerAccount' } & Pick<
            DistributionPartnerAccount,
            'distribution_partner_id' | 'id' | 'username'
        >
    >
}

export type KbGeneralPrintStatsQueryVariables = Exact<{ [key: string]: never }>

export type KbGeneralPrintStatsQuery = { __typename?: 'Query' } & {
    platform_stats: { __typename?: 'PlatformStats' } & Pick<
        PlatformStats,
        'opportunities_count' | 'invested_amount' | 'paid_out'
    >
}

export type OpportunitiesCarouselQueryQueryVariables = Exact<{
    offset: Maybe<Scalars['Int']>
    limit: Maybe<Scalars['Int']>
    filter: Maybe<OpportunitiesFilter>
    sort: Maybe<OpportunitiesOrderBy>
}>

export type OpportunitiesCarouselQueryQuery = { __typename?: 'Query' } & {
    opportunities: Array<
        { __typename?: 'Opportunity' } & Pick<
            Opportunity,
            | 'id'
            | 'title'
            | 'title_en'
            | 'max_fundraising_target'
            | 'currencies'
            | 'type'
            | 'interest_rates'
            | 'cashflow_model'
            | 'reference_rate_type'
        > & { card_photo: Opportunity['cover_photo'] } & {
                reference_rates: Maybe<
                    Array<{ __typename?: 'ReferenceRate' } & Pick<ReferenceRate, 'rate' | 'type'>>
                >
            }
    >
}

export type PlatformStatsQueryQueryVariables = Exact<{ [key: string]: never }>

export type PlatformStatsQueryQuery = { __typename?: 'Query' } & {
    platform_stats: { __typename?: 'PlatformStats' } & Pick<
        PlatformStats,
        'funded_opportunities_count' | 'invested_amount' | 'funded_projects_value' | 'paid_out'
    >
}

export type OpportunitiesWidgetQueryQueryVariables = Exact<{
    filter: OpportunitiesFilter
}>

export type OpportunitiesWidgetQueryQuery = { __typename?: 'Query' } & {
    opportunities: Array<
        { __typename?: 'Opportunity' } & Pick<
            Opportunity,
            'id' | 'title' | 'title_en' | 'card_photo' | 'raised' | 'raised_eur' | 'currencies'
        >
    >
}

export type AddBankAccountMutationVariables = Exact<{
    iban: Scalars['String']
    currency: Currency
    is_primary: Scalars['Boolean']
}>

export type AddBankAccountMutation = { __typename?: 'Mutation' } & {
    addBankAccount: { __typename?: 'BankAcount' } & Pick<
        BankAcount,
        'id' | 'account_id' | 'is_primary'
    >
}

export type MyAccountKbMarketingConsentQueryVariables = Exact<{ [key: string]: never }>

export type MyAccountKbMarketingConsentQuery = { __typename?: 'Query' } & {
    myAccount: { __typename?: 'Account' } & Pick<Account, 'id' | 'kb_marketing_consent'>
}

export type SetKbMarketingConsentMutationVariables = Exact<{
    kb_marketing_consent: Scalars['Boolean']
}>

export type SetKbMarketingConsentMutation = { __typename?: 'Mutation' } & {
    setKBMarketingConsent: { __typename?: 'Account' } & Pick<Account, 'id' | 'kb_marketing_consent'>
}

export type MyBankAccountsQueryVariables = Exact<{ [key: string]: never }>

export type MyBankAccountsQuery = { __typename?: 'Query' } & {
    myAccount: { __typename?: 'Account' } & Pick<Account, 'id' | 'iban' | 'iban_eur'> & {
            bank_accounts: Array<
                { __typename?: 'BankAcount' } & Pick<
                    BankAcount,
                    'id' | 'iban' | 'currency' | 'is_primary'
                >
            >
        }
}

export type SetPrimaryBankAccountMutationVariables = Exact<{
    id: Scalars['Int']
}>

export type SetPrimaryBankAccountMutation = { __typename?: 'Mutation' } & {
    setPrimaryBankAccount: { __typename?: 'BankAcount' } & Pick<BankAcount, 'id'>
}

export type RemoveBankAccountMutationVariables = Exact<{
    id: Scalars['Int']
}>

export type RemoveBankAccountMutation = { __typename?: 'Mutation' } & {
    removeBankAccount: { __typename?: 'BankAcount' } & Pick<BankAcount, 'id'>
}

export type CurrentEquityOpportunityInvestmentsQueryVariables = Exact<{
    text_id: Scalars['String']
}>

export type CurrentEquityOpportunityInvestmentsQuery = { __typename?: 'Query' } & {
    myOpportunity: { __typename?: 'Opportunity' } & {
        myInvestments: Array<{ __typename?: 'Investment' } & Pick<Investment, 'id'>>
    }
}

export type MyOpportunityNewsQueryVariables = Exact<{
    opportunityId: Maybe<Scalars['Int']>
    dateRange: Maybe<DateRange>
}>

export type MyOpportunityNewsQuery = { __typename?: 'Query' } & {
    myOpportunityNews: Array<
        { __typename?: 'OpportunityNews' } & Pick<
            OpportunityNews,
            'id' | 'created_at' | 'content' | 'content_en' | 'type'
        > & {
                opportunity: { __typename?: 'Opportunity' } & Pick<
                    Opportunity,
                    'id' | 'title' | 'text_id'
                >
                links: Maybe<
                    Array<
                        Maybe<
                            { __typename?: 'OpportunityNewsLink' } & Pick<
                                OpportunityNewsLink,
                                'type' | 'url' | 'label' | 'label_en'
                            >
                        >
                    >
                >
            }
    >
}

export type GetInvestmentJobsStatusQueryVariables = Exact<{
    jobIds: Array<Scalars['String']> | Scalars['String']
    queue: QueueName
}>

export type GetInvestmentJobsStatusQuery = { __typename?: 'Query' } & {
    getJobsStatus: Array<
        { __typename?: 'GetJobsStatusResult' } & Pick<
            GetJobsStatusResult,
            'id' | 'state' | 'progress' | 'returnvalue' | 'failedReason' | 'errorName'
        >
    >
}

export type MyInvestmentFieldsFragment = { __typename?: 'Investment' } & Pick<
    Investment,
    | 'id'
    | 'account_id'
    | 'opportunity_id'
    | 'amount'
    | 'created_at'
    | 'updated_at'
    | 'currency'
    | 'state'
    | 'interest_rate'
    | 'interest_period_start'
    | 'fees_agreement'
    | 'expected_yield'
    | 'investment_lien_agreement'
    | 'opportunity_tranche_id'
>

export type MyPaymentFieldsFragment = { __typename?: 'Payment' } & Pick<
    Payment,
    'id' | 'amount' | 'amount_interest' | 'amount_principal' | 'created_at' | 'currency'
> & {
        investment: { __typename?: 'Investment' } & Pick<Investment, 'id'>
        account: { __typename?: 'Account' } & Pick<Account, 'id'>
    }

export type MyReportFieldsFragment = { __typename?: 'OpportunityReport' } & Pick<
    OpportunityReport,
    'id' | 'title' | 'desc' | 'url' | 'created_at'
>

export type MyOpportunitiesQueryVariables = Exact<{ [key: string]: never }>

export type MyOpportunitiesQuery = { __typename?: 'Query' } & {
    myOpportunities: Array<
        { __typename?: 'Opportunity' } & Pick<
            Opportunity,
            | 'id'
            | 'text_id'
            | 'title'
            | 'subtitle'
            | 'title_en'
            | 'subtitle_en'
            | 'fundraising_period_start'
            | 'fundraising_period_end'
            | 'maturity'
            | 'expected_maturity'
            | 'max_fundraising_target'
            | 'min_fundraising_target'
            | 'card_photo'
            | 'raised'
            | 'raised_eur'
            | 'currencies'
            | 'payment_frequency'
            | 'status'
            | 'created_at'
            | 'interest_rate_type'
            | 'interest_rates'
            | 'type'
            | 'cashflow_model'
            | 'reference_rate_type'
        > & {
                tranches: Array<
                    { __typename?: 'OpportunityTranche' } & Pick<
                        OpportunityTranche,
                        | 'id'
                        | 'min_fundraising_target'
                        | 'max_fundraising_target'
                        | 'fundraising_period_start'
                        | 'fundraising_period_end'
                        | 'cashflow_model'
                        | 'tranche_title'
                    >
                >
                investments: Array<{ __typename?: 'Investment' } & MyInvestmentFieldsFragment>
                payments: Array<{ __typename?: 'Payment' } & MyPaymentFieldsFragment>
                reference_rates: Maybe<
                    Array<{ __typename?: 'ReferenceRate' } & Pick<ReferenceRate, 'id' | 'rate'>>
                >
            }
    >
}

export type MyOpportunityQueryVariables = Exact<{
    text_id: Scalars['String']
}>

export type MyOpportunityQuery = { __typename?: 'Query' } & {
    opportunity: { __typename?: 'Opportunity' } & Pick<
        Opportunity,
        | 'id'
        | 'text_id'
        | 'title'
        | 'subtitle'
        | 'title_en'
        | 'subtitle_en'
        | 'fundraising_period_start'
        | 'fundraising_period_end'
        | 'maturity'
        | 'expected_maturity'
        | 'max_fundraising_target'
        | 'min_fundraising_target'
        | 'card_photo'
        | 'cover_photo'
        | 'raised'
        | 'raised_eur'
        | 'eur_exchange_rate'
        | 'loan_payoff_date'
        | 'payment_frequency'
        | 'status'
        | 'interest_rate_type'
        | 'interest_rates'
        | 'currencies'
        | 'type'
        | 'reference_rate_type'
        | 'cashflow_model'
    > & {
            investments: Array<{ __typename?: 'Investment' } & MyInvestmentFieldsFragment>
            payments: Array<{ __typename?: 'Payment' } & MyPaymentFieldsFragment>
            reports: Array<{ __typename?: 'OpportunityReport' } & MyReportFieldsFragment>
            reference_rates: Maybe<
                Array<{ __typename?: 'ReferenceRate' } & Pick<ReferenceRate, 'id' | 'rate'>>
            >
            tranches: Array<
                { __typename?: 'OpportunityTranche' } & Pick<
                    OpportunityTranche,
                    | 'id'
                    | 'min_fundraising_target'
                    | 'max_fundraising_target'
                    | 'fundraising_period_start'
                    | 'fundraising_period_end'
                    | 'cashflow_model'
                    | 'tranche_title'
                >
            >
        }
}

export type MyAccountStatsQueryVariables = Exact<{
    currency?: Maybe<Currency>
}>

export type MyAccountStatsQuery = { __typename?: 'Query' } & {
    myAccount: { __typename?: 'Account' } & Pick<Account, 'id'> & {
            account_stats: { __typename?: 'AccountStats' } & Pick<
                AccountStats,
                | 'balance'
                | 'total_invested'
                | 'paid_off'
                | 'net_yield'
                | 'currently_invested'
                | 'expected_yield'
                | 'pending_withdrawal_amount'
                | 'pending_referral_bonuses'
            >
        }
}

export type OpportunitiesQueryQueryVariables = Exact<{
    offset: Maybe<Scalars['Int']>
    limit: Maybe<Scalars['Int']>
    filter: Maybe<OpportunitiesFilter>
    sort: Maybe<OpportunitiesOrderBy>
}>

export type OpportunitiesQueryQuery = { __typename?: 'Query' } & {
    opportunitiesCount: Array<{ __typename?: 'Opportunity' } & Pick<Opportunity, 'id' | 'type'>>
    opportunities: Array<
        { __typename?: 'Opportunity' } & Pick<
            Opportunity,
            | 'address'
            | 'card_photo'
            | 'cashflow_model'
            | 'cover_photo'
            | 'created_at'
            | 'eur_exchange_rate'
            | 'executive_summary_pdf'
            | 'expected_maturity'
            | 'fees_agreement'
            | 'fundraising_period_end'
            | 'fundraising_period_start'
            | 'id'
            | 'priv_fees_agreement'
            | 'priv_interest_rate'
            | 'ik_fundraising_period_end'
            | 'interest_rate_type'
            | 'investors_count'
            | 'loan_payoff_date'
            | 'loan_interest_rate'
            | 'maturity'
            | 'max_fundraising_target'
            | 'max_investment'
            | 'min_fundraising_target'
            | 'min_investment'
            | 'payment_frequency'
            | 'published'
            | 'raised'
            | 'raised_eur'
            | 'report_pdf'
            | 'status'
            | 'subtitle'
            | 'subtitle_en'
            | 'text_id'
            | 'title'
            | 'title_en'
            | 'updated_at'
            | 'interest_rates'
            | 'currencies'
            | 'reference_rate_type'
            | 'reference_rate_id'
            | 'type'
        > & {
                developer_info: { __typename?: 'DeveloperInfoJSON' } & Pick<
                    DeveloperInfoJson,
                    'logoImage' | 'ico' | 'name' | 'address' | 'registeredBy' | 'markNo'
                >
                reference_rates: Maybe<
                    Array<
                        { __typename?: 'ReferenceRate' } & Pick<
                            ReferenceRate,
                            'id' | 'type' | 'rate'
                        >
                    >
                >
            }
    >
}

export type OpportunitesPollingUpdateQueryVariables = Exact<{
    ids: Array<Scalars['Int']> | Scalars['Int']
}>

export type OpportunitesPollingUpdateQuery = { __typename?: 'Query' } & {
    opportunitiesById: Array<
        { __typename?: 'Opportunity' } & Pick<
            Opportunity,
            | 'id'
            | 'expected_maturity'
            | 'fundraising_period_end'
            | 'fundraising_period_start'
            | 'investors_count'
            | 'raised'
            | 'raised_eur'
            | 'status'
            | 'updated_at'
        >
    >
}

export type MyAccountSuspensionsQueryVariables = Exact<{
    onlyUnresolved?: Maybe<Scalars['Boolean']>
}>

export type MyAccountSuspensionsQuery = { __typename?: 'Query' } & {
    myAccount: { __typename?: 'Account' } & Pick<Account, 'id'> & {
            account_suspensions: Array<
                { __typename?: 'AccountSuspension' } & Pick<
                    AccountSuspension,
                    'id' | 'reason' | 'is_resolved'
                >
            >
        }
}

export type OpportunityQueryQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type OpportunityQueryQuery = { __typename?: 'Query' } & {
    opportunity: { __typename?: 'Opportunity' } & Pick<
        Opportunity,
        | 'address'
        | 'card_photo'
        | 'cashflow_model'
        | 'cover_photo'
        | 'created_at'
        | 'eur_exchange_rate'
        | 'executive_summary_pdf'
        | 'expected_maturity'
        | 'fees_agreement'
        | 'fundraising_period_end'
        | 'fundraising_period_start'
        | 'id'
        | 'priv_fees_agreement'
        | 'priv_interest_rate'
        | 'ik_fundraising_period_end'
        | 'interest_rate_type'
        | 'investors_count'
        | 'loan_payoff_date'
        | 'loan_interest_rate'
        | 'maturity'
        | 'max_fundraising_target'
        | 'max_investment'
        | 'min_fundraising_target'
        | 'min_investment'
        | 'payment_frequency'
        | 'published'
        | 'raised'
        | 'raised_eur'
        | 'report_pdf'
        | 'status'
        | 'subtitle'
        | 'subtitle_en'
        | 'text_id'
        | 'title'
        | 'title_en'
        | 'updated_at'
        | 'interest_rates'
        | 'currencies'
        | 'reference_rate_type'
        | 'reference_rate_id'
        | 'type'
        | 'equity_data'
        | 'is_private_placement'
    > & {
            developer_info: { __typename?: 'DeveloperInfoJSON' } & Pick<
                DeveloperInfoJson,
                'logoImage' | 'ico' | 'name' | 'address' | 'registeredBy' | 'markNo'
            >
            reference_rates: Maybe<
                Array<
                    { __typename?: 'ReferenceRate' } & Pick<ReferenceRate, 'id' | 'type' | 'rate'>
                >
            >
            tranches: Array<
                { __typename?: 'OpportunityTranche' } & Pick<
                    OpportunityTranche,
                    | 'id'
                    | 'tranche_title'
                    | 'min_fundraising_target'
                    | 'max_fundraising_target'
                    | 'fundraising_period_end'
                    | 'fundraising_period_start'
                >
            >
        }
}

export type MyAccountPollingUpdateQueryVariables = Exact<{ [key: string]: never }>

export type MyAccountPollingUpdateQuery = { __typename?: 'Query' } & {
    myAccount: { __typename?: 'Account' } & Pick<Account, 'id' | 'status'> & {
            account_stats: { __typename?: 'AccountStats' } & Pick<AccountStats, 'balance'> & {
                    balance_eur: AccountStats['balance']
                }
        }
}

export type IsTempTokenValidQueryVariables = Exact<{
    token: Scalars['String']
    type: TempTokenType
}>

export type IsTempTokenValidQuery = { __typename?: 'Query' } & Pick<Query, 'isTempTokenValid'>

export type UserMyMessagesQueryQueryVariables = Exact<{ [key: string]: never }>

export type UserMyMessagesQueryQuery = { __typename?: 'Query' } & {
    myMessages: Array<
        { __typename?: 'Message' } & Pick<Message, 'id' | 'to' | 'from' | 'subject' | 'created_at'>
    >
}

export type UserMyMessageQueryQueryVariables = Exact<{
    id: Scalars['Int']
}>

export type UserMyMessageQueryQuery = { __typename?: 'Query' } & {
    myMessage: { __typename?: 'Message' } & Pick<
        Message,
        'id' | 'to' | 'from' | 'subject' | 'content' | 'created_at'
    >
}

export type GetAccountEmailSubscriptionInfoQueryVariables = Exact<{ [key: string]: never }>

export type GetAccountEmailSubscriptionInfoQuery = { __typename?: 'Query' } & {
    myAccount: { __typename?: 'Account' } & Pick<Account, 'id' | 'is_subscriber'>
}

export type PendingAccountUpdateQueryQueryVariables = Exact<{ [key: string]: never }>

export type PendingAccountUpdateQueryQuery = { __typename?: 'Query' } & {
    pendingAccountUpdate: Maybe<
        { __typename?: 'PendingAccountUpdates' } & Pick<
            PendingAccountUpdates,
            'id' | 'account_id' | 'id_front' | 'id_back'
        >
    >
}

export type UploadNewIdDocumentMutationVariables = Exact<{
    id_front: Scalars['String']
    id_back: Scalars['String']
}>

export type UploadNewIdDocumentMutation = { __typename?: 'Mutation' } & {
    uploadNewIdDocument: { __typename?: 'PendingAccountUpdates' } & Pick<
        PendingAccountUpdates,
        'id'
    >
}
