import moment from 'moment'
import { ObjectValues } from './ts-utils'

export const DATE_INCLUSION = {
    INCLUSIVE: 'date_inclusive',
    EXCLUSIVE: 'date_exclusive',
} as const

export type DateInclusion = ObjectValues<typeof DATE_INCLUSION>

export const diffInYears = (startDate: moment.MomentInput, endDate: moment.MomentInput) => {
    return moment.duration(Math.abs(moment(startDate).diff(endDate))).asYears()
}

export const diffInMonths = (startDate: moment.MomentInput, endDate: moment.MomentInput) => {
    return moment.duration(Math.abs(moment(startDate).diff(endDate))).asMonths()
}

/**
 * If last day should be included then add + 1 to the return value.
 * @returns Number of full days between two dates
 */
export const diffInDays = (startDate: moment.MomentInput, endDate: moment.MomentInput) =>
    Math.abs(moment(startDate).diff(endDate, 'days'))

export const isSameMonth = (startDate: moment.MomentInput, endDate: moment.MomentInput) =>
    moment(startDate).isSame(endDate, 'month')
export const isSameYear = (startDate: moment.MomentInput, endDate: moment.MomentInput) =>
    moment(startDate).isSame(endDate, 'year')

export const formatDateTime = (date: moment.MomentInput) => moment(date).format('D. M. YYYY, H:mm')

export const formatDate = (date: moment.MomentInput) => moment(date).format('D. M. YYYY')

export const getMonthYear = (date: moment.MomentInput) => moment(date).format('MM/YYYY')
