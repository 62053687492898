import { config as reactSpringConfig } from 'react-spring'

export const config = {
    ...reactSpringConfig,
    swift: {
        tension: 210,
        friction: 18,
        velocity: 8,
    },
}

export const chakraModalContentAnimationProps = {
    initial: 'exit',
    animate: 'enter',
    exit: 'exit',
    variants: {
        exit: {
            opacity: 0,
            transition: {
                duration: 0.3,
            },
        },
        enter: {
            opacity: 1,
            transition: {
                duration: 0.3,
            },
        },
    },
}
