export default {
    baseStyle: {
        control: {
            _checked: {
                bg: 'primary',
                borderColor: 'primary',

                _hover: {
                    bg: 'primary',
                    borderColor: 'primary',
                },
            },
        },
    },
}
