import React, { createContext, useContext } from 'react'
import { LayerStyle } from '..'

/*
A factory which creates a context for a typed opportunity provider. This enables us to define custom layer styles for each app and get a typed context for it.
 */
export function createLayerStyleContext<LayerStyleContextType extends LayerStyle = LayerStyle>(
    id: string,
) {
    const OpportunityContext = createContext<LayerStyleContextType | undefined>(undefined)

    OpportunityContext.displayName = `LayerStyleContext:${id}`

    const Context = createContext<LayerStyleContextType | undefined>(undefined)

    function useLayerStyleContext(): LayerStyleContextType | undefined
    function useLayerStyleContext<V extends unknown>(
        map: Record<LayerStyleContextType | '_default', V>,
    ): V | undefined
    function useLayerStyleContext<V extends unknown>(
        map?: Record<LayerStyleContextType | '_default', V>,
    ) {
        const context = useContext(Context)

        /*
        FIXME: We probably want to have the ability to return undefined in usage scenarios without context.
        if (context === undefined) {
            const error = new Error(
                `useLayerStyle: 'context' is undefined. Seems you forgot to wrap component within the Provider`,
            )
            error.name = 'ContextError'
            Error.captureStackTrace?.(error, useLayerStyle)
            throw error
        }
         */

        // optionally allow directly returning a mapped value based on the context value
        // eslint-disable-next-line no-underscore-dangle
        return map && context ? map[context] || map._default : context
    }

    const Provider = ({
        children,
        layerStyle,
    }: {
        children: React.ReactNode | ((context: LayerStyleContextType) => React.ReactNode)
        layerStyle: LayerStyleContextType
    }) => {
        return (
            <Context.Provider value={layerStyle}>
                {typeof children === 'function' ? children(layerStyle) : children}
            </Context.Provider>
        )
    }

    return { Provider, useLayerStyleContext, Context }
}

const { Provider: LayerStyleProvider, useLayerStyleContext } = createLayerStyleContext('default')

export { LayerStyleProvider, useLayerStyleContext }
