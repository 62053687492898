export default {
    sizes: {
        responsive: {
            popper: {
                maxWidth: 'unset',
                width: 'unset',
            },
        },
    },
    variants: {
        noninteractive: {
            content: {
                pointerEvents: 'none',
            },
        },
    },
    baseStyle: {
        content: {
            bg: '#fff',
            color: 'black',
            boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
            border: 'none',
            borderRadius: 0,
            '.chakra-popover__arrow-positioner': {
                position: 'relative',
            },
        },
        body: {
            p: 4,
        },
    },
}
