import React, { ReactNode, useContext } from 'react'
import {
    SimpleGrid,
    Box,
    useTheme,
    forwardRef,
    ChakraTheme,
    BoxProps,
    SimpleGridProps,
} from '@chakra-ui/react'
import {
    ChakraResponsiveValue,
    convertStylePropToArrayNotation,
} from '@upvestcz/common/styles-utils'
import { MergeChakraProps } from '@upvestcz/common/ts-utils'

const ColumnGridContext = React.createContext<(null | number) | (number | null)[]>(null)

export const ColumnGridItem = ({
    children,
    cols,
    offset,
    autoPlacement = false,
    ...props
}: {
    children: ReactNode
    autoPlacement?: boolean
    cols?: ChakraResponsiveValue<number>
    offset?: ChakraResponsiveValue<number>
} & BoxProps) => {
    const contextColumns = useContext(ColumnGridContext)
    const theme = useTheme<ChakraTheme>()
    const offsetArr = convertStylePropToArrayNotation(offset, theme)
    const colsArr = convertStylePropToArrayNotation(cols || contextColumns, theme)

    const gridColumn = colsArr.map(col =>
        col != null ? `${autoPlacement ? `span ${col}` : '1'} / span ${col}` : null,
    )
    const gridColumnStart = offsetArr.map(offset => (offset != null ? offset + 1 : null))

    return (
        <ColumnGridContext.Provider value={colsArr}>
            <Box gridColumn={gridColumn} gridColumnStart={gridColumnStart} {...props}>
                {children}
            </Box>
        </ColumnGridContext.Provider>
    )
}

type Props = MergeChakraProps<
    {
        children: ReactNode
        hasGapX?: boolean
        hasGapY?: boolean
        columns?: number | number[]
    },
    SimpleGridProps
>

const ColumnGrid = forwardRef<Props, 'div'>(
    ({ children, hasGapX = true, hasGapY = false, columns: columnsProp, sx, ...props }, ref) => {
        const theme = useTheme()
        const contextColumns = useContext(ColumnGridContext)
        const spacing = theme.sizes.gutter
        const columns = columnsProp || contextColumns || theme?.grid?.columns || 10

        return (
            <ColumnGridContext.Provider value={columns}>
                <SimpleGrid
                    {...props}
                    sx={{
                        ...sx,
                        '--columngrid-gutter': spacing,
                    }}
                    spacingX={hasGapX && spacing}
                    spacingY={hasGapY && spacing}
                    columns={columns}
                    ref={ref}
                >
                    {children}
                </SimpleGrid>
            </ColumnGridContext.Provider>
        )
    },
)

export default ColumnGrid
