export const LOCALES = {
    EN: 'en',
    CS: 'cs',
} as const

export type Locale = typeof LOCALES[keyof typeof LOCALES]

export const LOCALES_LABELS_LONG = {
    [LOCALES.CS]: 'Čeština',
    [LOCALES.EN]: 'English',
} as const

export const LOCALES_LABELS_SHORT = {
    [LOCALES.CS]: 'CZ',
    [LOCALES.EN]: 'EN',
} as const
