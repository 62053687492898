const sizes = {
    sm: {
        fontSize: 'sm',
        px: 3,
        h: 10,
        borderRadius: 'none',
    },
    md: {
        fontSize: 'mdlg',
        px: 4,
        h: 12,
        borderRadius: 'none',
    },
    lg: {
        fontSize: 'lg',
        px: 3,
        h: 14,
        borderRadius: 'none',
    },
}

export default {
    baseStyle: {
        field: { caretColor: 'text', _focus: { boxShadow: 'none' } },
    },
    sizes: {
        sm: {
            field: sizes.sm,
            addon: sizes.sm,
        },
        md: {
            field: sizes.md,
            addon: sizes.md,
        },
        lg: {
            field: sizes.lg,
            addon: sizes.lg,
        },
    },
    variants: {
        outline: () => ({
            field: {
                bgColor: '#fff',
                border: '1px solid #DBDBDB',
                _hover: { borderColor: 'textSecondary' },
                _focus: {
                    borderColor: 'primary',
                    boxShadow: 'none',
                    _hover: { borderColor: 'primary' },
                },
                _invalid: { borderColor: 'dangerRed', boxShadow: 'none' },
            },
        }),
    },
}
