export const NAVBAR_HEIGHTS = {
    DESKTOP: {
        INITIAL: 96,
        STUCK: 64,
    },
    MOBILE: {
        INITIAL: 60,
        HIDDEN: 0,
        STUCK: 60,
    },
}

export const NAVBAR_Z_INDEX = 10

export const NAVBAR_BREAKPOINT = 'lg'

export const APP_MENU_HEIGHT = 56

export const CONTAINER_PX = {
    base: 6,
    sm: 8,
    md: 12,
    lg: 16,
}
