import React from 'react'
import { useRouter } from 'next/router'
import { forwardRef, MenuItem, Text, HStack } from '@chakra-ui/react'
import { LOCALES_LABELS_SHORT } from '@upvestcz/common/i18n/locales'
import * as R from 'ramda'
import { LocaleDropdown, LocaleDropdownButton, LocaleDropdownList, LocaleLink } from './Locale'
import { LocaleFlag } from './Locale/LocaleFlag'

const LocaleMenuItem = forwardRef(({ children, ...props }, ref) => (
    // this needs to be as="a" because it's being used as a child of Next.js Link
    <MenuItem {...props} as="a" ref={ref}>
        {children}
    </MenuItem>
))

const AppLocaleDropdown = () => {
    const router = useRouter()

    return (
        <LocaleDropdown>
            <LocaleDropdownButton textStyle="buttonTextSm" fontWeight="normal" />
            <LocaleDropdownList minW="0" py={2} textStyle="buttonTextSm" fontWeight="normal">
                {R.toPairs(LOCALES_LABELS_SHORT).map(([locale, label]) => (
                    <LocaleLink
                        as={LocaleMenuItem}
                        fontWeight={router.locale === locale ? 700 : 400}
                        variant="plain"
                        locale={locale}
                        key={locale}
                        data-test-id={`locale-dropdown-${locale}`}
                    >
                        <Text as={HStack} spacing={3}>
                            <LocaleFlag locale={locale} />
                            <span>{label}</span>
                        </Text>
                    </LocaleLink>
                ))}
            </LocaleDropdownList>
        </LocaleDropdown>
    )
}

export default AppLocaleDropdown
