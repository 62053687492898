import { APPS, useAppUrl } from '../apps'

// TODO: remove this file and use `useAppUrl` directly
export function useLocaleAppBaseUrl() {
    const appUrl = useAppUrl()
    return appUrl(APPS.APP, '/')
}

// TODO: remove this file and use `useAppUrl` directly
export function useLocaleLpBaseUrl() {
    const appUrl = useAppUrl()
    return appUrl(APPS.LP, '/')
}
