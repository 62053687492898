import { useRef } from 'react'

// a simple wrapper around `useRef` that allows to persist variables
// between multiple render calls
// We're using a similar behaviour as in React's `useEffect` or `useCallback`
// with the deps.
// update of the value does not trigger a re-render, unlinke a useMemo.
export const usePersistent = <T>(fn: () => T, deps: unknown[] = []) => {
    const ref = useRef<T | null>(null)
    const depsRef = useRef(deps)

    const depsUpdated = depsRef.current.some((dep, i) => dep !== deps[i])

    if (ref.current === null || depsUpdated) {
        depsRef.current = deps
        ref.current = fn()
    }

    return ref.current
}
