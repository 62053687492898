import { ReactNode, useCallback } from 'react'
import {
    useToast,
    UseToastOptions,
    ToastId,
    Spinner,
    Alert,
    chakra,
    AlertTitle,
    AlertDescription,
} from '@upvestcz/shared-components'
import { MaybePromise } from '@upvestcz/common/ts-utils'

type PromiseToastProps = {
    title: ReactNode
    description?: ReactNode
    id?: ToastId
}

const ChakraPromiseToast = ({ title = 'Loading...', description, id }: PromiseToastProps) => (
    <Alert
        status="info"
        variant="solid" // this is the default in chakra v1
        id={id?.toString()}
        alignItems="start"
        borderRadius="md"
        boxShadow="lg"
        paddingEnd={8}
        textAlign="start"
        width="auto"
    >
        <Spinner marginRight="4" />
        <chakra.div flex="1" maxWidth="100%">
            <AlertTitle>{title}</AlertTitle>
            {description && <AlertDescription display="block">{description}</AlertDescription>}
        </chakra.div>
    </Alert>
)

export const usePromiseToast = () => {
    const toast = useToast()

    const addPromiseToast = useCallback(
        <Result extends unknown, Err extends Error = Error>(
            promise: Promise<Result>,
            options: {
                success: (...args: [Result]) => MaybePromise<Partial<UseToastOptions>>
                error: (...args: [Err]) => MaybePromise<Partial<UseToastOptions>>
                loading: Partial<UseToastOptions>
            },
        ) => {
            const initialOptions = {
                ...options.loading,
                status: 'info',
                duration: null,
                isClosable: false,
            } as const

            const id = toast({
                ...initialOptions,
                render: ({ id }) => (
                    <ChakraPromiseToast
                        id={id}
                        title={initialOptions.title}
                        description={initialOptions.description}
                    />
                ),
            })!

            promise
                .then(async data =>
                    toast.update(id, {
                        status: 'success',
                        duration: 5_000,
                        isClosable: true,
                        ...(await options.success(data)),
                    }),
                )
                .catch(async error =>
                    toast.update(id, {
                        status: 'error',
                        duration: 5_000,
                        isClosable: true,
                        ...(await options.error(error)),
                    }),
                )

            return id
        },
        [toast],
    )

    return { addPromiseToast, toast }
}
