import { useCallback, useMemo, useState } from 'react'
import { useIntersectionObserver } from './use-intersection-observer'

/*
Use a callback ref created by use-callback-ref.js
regular react refs cannot be watched for changes and therefore used as dependencies for other hooks.
 */
export function useIsSticky(callbackRef, rootMargin = '0px') {
    const [isStuck, setIsStuck] = useState(false)
    const elements = useMemo(() => [callbackRef.current], [callbackRef, callbackRef.current])
    const options = useMemo(
        () => ({
            threshold: [1],
            rootMargin,
        }),
        [rootMargin],
    )
    const callback = useCallback(([e]) => {
        setIsStuck(e.intersectionRatio < 1)
    }, [])
    useIntersectionObserver(elements, callback, options)
    return isStuck
}
