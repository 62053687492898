import { useEffect, useState } from 'react'

export function useIsMounted() {
    const [isLoaded, setIsLoaded] = useState(false)

    useEffect(() => {
        setIsLoaded(true)

        return () => setIsLoaded(false)
    }, [])

    return isLoaded
}
