import { ReactNode, MouseEvent } from 'react'
import { TFunction } from 'i18next'
import { Box, BoxProps } from '@chakra-ui/react'
import { MergeChakraProps } from '@upvestcz/common/ts-utils'

type OwnProps = {
    value: string
    children: ReactNode
    context: { addToast: TODO; logger: TODO; t?: TFunction }
}

type Props = MergeChakraProps<OwnProps, BoxProps>

function CopyToClipboard({ value, children, context, ...props }: Props) {
    const { addToast, logger, t = (str: string) => str } = context
    const handleClick = (e: MouseEvent<HTMLDivElement>) => {
        e.preventDefault()
        // supported in all our target browsers 👌
        window.navigator.clipboard
            .writeText(value)
            .then(() => {
                if (addToast) {
                    addToast({
                        status: 'success', // kb toast option
                        type: 'success', // app toast option
                        title: t('Zkopírováno do schránky'),
                        message: t('Zkopírováno do schránky'),
                    })
                }
            })
            .catch(err => {
                if (logger) logger.error(err)
                if (addToast) {
                    addToast({
                        status: 'error',
                        type: 'success',
                        title: t('Při kopírování došlo k chybě'),
                        message: t('Zkopírováno do schránky'),
                    })
                }
            })
    }

    return (
        <Box as="button" type="button" textAlign="left" {...props} onClick={handleClick}>
            {children || value}
        </Box>
    )
}

export default CopyToClipboard
