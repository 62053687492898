import { Locale, LOCALES } from './i18n/locales'
import { czechStringCompare } from './utils'

export const countries = [
    { label: 'Afghanistan', value: 'AF', czechLabel: 'Afghánistán' },
    { label: 'Åland Islands', value: 'AX', czechLabel: 'Alandy' },
    { label: 'Albania', value: 'AL', czechLabel: 'Albánie' },
    { label: 'Algeria', value: 'DZ', czechLabel: 'Alžírsko' },
    { label: 'American Samoa', value: 'AS', czechLabel: 'Americká Samoa' },
    { label: 'AndorrA', value: 'AD', czechLabel: 'Andorra' },
    { label: 'Angola', value: 'AO', czechLabel: 'Angola' },
    { label: 'Anguilla', value: 'AI', czechLabel: 'Anguilla' },
    { label: 'Antarctica', value: 'AQ', czechLabel: 'Antarktida' },
    {
        label: 'Antigua and Barbuda',
        value: 'AG',
        czechLabel: 'Antigua a Barbuda',
    },
    { label: 'Argentina', value: 'AR', czechLabel: 'Argentina' },
    { label: 'Armenia', value: 'AM', czechLabel: 'Arménie' },
    { label: 'Aruba', value: 'AW', czechLabel: 'Aruba' },
    { label: 'Australia', value: 'AU', czechLabel: 'Austrálie' },
    { label: 'Austria', value: 'AT', czechLabel: 'Rakousko' },
    { label: 'Azerbaijan', value: 'AZ', czechLabel: 'Ázerbajdžán' },
    { label: 'Bahamas', value: 'BS', czechLabel: 'Bahamy' },
    { label: 'Bahrain', value: 'BH', czechLabel: 'Bahrajn' },
    { label: 'Bangladesh', value: 'BD', czechLabel: 'Bangladéš' },
    { label: 'Barbados', value: 'BB', czechLabel: 'Barbados' },
    { label: 'Belarus', value: 'BY', czechLabel: 'Bělorusko' },
    { label: 'Belgium', value: 'BE', czechLabel: 'Belgie' },
    { label: 'Belize', value: 'BZ', czechLabel: 'Belize' },
    { label: 'Benin', value: 'BJ', czechLabel: 'Benin' },
    { label: 'Bermuda', value: 'BM', czechLabel: 'Bermudy' },
    { label: 'Bhutan', value: 'BT', czechLabel: 'Bhútánské království' },
    { label: 'Bolivia', value: 'BO', czechLabel: 'Bolívie' },
    {
        label: 'Bosnia and Herzegovina',
        value: 'BA',
        czechLabel: 'Bosna-Hercegovina',
    },
    { label: 'Botswana', value: 'BW', czechLabel: 'Botswana' },
    { label: 'Bouvet Island', value: 'BV', czechLabel: 'Bouvetův ostrov' },
    { label: 'Brazil', value: 'BR', czechLabel: 'Brazílie' },
    {
        label: 'British Indian Ocean Territory',
        value: 'IO',
        czechLabel: 'Britské indickooceánské terit.',
    },
    {
        label: 'Brunei Darussalam',
        value: 'BN',
        czechLabel: 'Brunej Darussalam',
    },
    { label: 'Bulgaria', value: 'BG', czechLabel: 'Bulharsko' },
    { label: 'Burkina Faso', value: 'BF', czechLabel: 'Burkina Faso' },
    { label: 'Burundi', value: 'BI', czechLabel: 'Burundi' },
    { label: 'Cambodia', value: 'KH', czechLabel: 'Kambodža' },
    { label: 'Cameroon', value: 'CM', czechLabel: 'Kamerun' },
    { label: 'Canada', value: 'CA', czechLabel: 'Kanada' },
    { label: 'Cape Verde', value: 'CV', czechLabel: 'Kapverdy' },
    { label: 'Cayman Islands', value: 'KY', czechLabel: 'Kajmanské ostrovy' },
    {
        label: 'Central African Republic',
        value: 'CF',
        czechLabel: 'Středoafrická republika',
    },
    { label: 'Chad', value: 'TD', czechLabel: 'Čad' },
    { label: 'Chile', value: 'CL', czechLabel: 'Chile' },
    { label: 'China', value: 'CN', czechLabel: 'ČLR' },
    { label: 'Christmas Island', value: 'CX', czechLabel: 'Vánoční ostrovy' },
    {
        label: 'Cocos (Keeling) Islands',
        value: 'CC',
        czechLabel: 'Kokosové ostrovy',
    },
    { label: 'Colombia', value: 'CO', czechLabel: 'Kolumbie' },
    { label: 'Comoros', value: 'KM', czechLabel: 'Komory' },
    { label: 'Congo', value: 'CG', czechLabel: 'Konžská republika' },
    {
        label: 'Congo, The Democratic Republic of the',
        value: 'CD',
        czechLabel: 'Kongo, demokratická republika',
    },
    { label: 'Cook Islands', value: 'CK', czechLabel: 'Cookovy ostrovy' },
    { label: 'Costa Rica', value: 'CR', czechLabel: 'Kostarika' },
    { label: "Cote D'Ivoire", value: 'CI', czechLabel: 'Pobřeží slonoviny' },
    { label: 'Croatia', value: 'HR', czechLabel: 'Chorvatsko' },
    { label: 'Cuba', value: 'CU', czechLabel: 'Kuba' },
    { label: 'Cyprus', value: 'CY', czechLabel: 'Kypr' },
    { label: 'Czech Republic', value: 'CZ', czechLabel: 'Česká republika' },
    { label: 'Denmark', value: 'DK', czechLabel: 'Dánsko' },
    { label: 'Djibouti', value: 'DJ', czechLabel: 'Džibutsko' },
    { label: 'Dominica', value: 'DM', czechLabel: 'Dominika' },
    {
        label: 'Dominican Republic',
        value: 'DO',
        czechLabel: 'Dominikánská republika',
    },
    { label: 'Ecuador', value: 'EC', czechLabel: 'Ekvádor' },
    { label: 'Egypt', value: 'EG', czechLabel: 'Egypt' },
    { label: 'El Salvador', value: 'SV', czechLabel: 'Salvador' },
    { label: 'Equatorial Guinea', value: 'GQ', czechLabel: 'Rovníková Guinea' },
    { label: 'Eritrea', value: 'ER', czechLabel: 'Eritrea' },
    { label: 'Estonia', value: 'EE', czechLabel: 'Estonsko' },
    { label: 'Ethiopia', value: 'ET', czechLabel: 'Etiopie' },
    {
        label: 'Falkland Islands (Malvinas)',
        value: 'FK',
        czechLabel: 'Falklandy',
    },
    { label: 'Faroe Islands', value: 'FO', czechLabel: 'Faerské ostrovy' },
    { label: 'Fiji', value: 'FJ', czechLabel: 'Fidži' },
    { label: 'Finland', value: 'FI', czechLabel: 'Finsko' },
    { label: 'France', value: 'FR', czechLabel: 'Francie' },
    { label: 'French Guiana', value: 'GF', czechLabel: 'Francouzská Guayana' },
    {
        label: 'French Polynesia',
        value: 'PF',
        czechLabel: 'Francouzská Polynésie',
    },
    {
        label: 'French Southern Territories',
        value: 'TF',
        czechLabel: 'Francouzská jižní území',
    },
    { label: 'Gabon', value: 'GA', czechLabel: 'Gabon' },
    { label: 'Gambia', value: 'GM', czechLabel: 'Gambie' },
    { label: 'Georgia', value: 'GE', czechLabel: 'Gruzie' },
    { label: 'Germany', value: 'DE', czechLabel: 'Německo' },
    { label: 'Ghana', value: 'GH', czechLabel: 'Ghana' },
    { label: 'Gibraltar', value: 'GI', czechLabel: 'Gibraltar' },
    { label: 'Greece', value: 'GR', czechLabel: 'Řecko' },
    { label: 'Greenland', value: 'GL', czechLabel: 'Grónsko' },
    { label: 'Grenada', value: 'GD', czechLabel: 'Grenada' },
    { label: 'Guadeloupe', value: 'GP', czechLabel: 'Guadeloupe' },
    { label: 'Guam', value: 'GU', czechLabel: 'Guam' },
    { label: 'Guatemala', value: 'GT', czechLabel: 'Guatemala' },
    { label: 'Guernsey', value: 'GG', czechLabel: 'Guernsey' },
    { label: 'Guinea', value: 'GN', czechLabel: 'Guinea' },
    { label: 'Guinea-Bissau', value: 'GW', czechLabel: 'Guinea-Bissau' },
    { label: 'Guyana', value: 'GY', czechLabel: 'Guyana' },
    { label: 'Haiti', value: 'HT', czechLabel: 'Haiti' },
    {
        label: 'Heard Island and Mcdonald Islands',
        value: 'HM',
        czechLabel: 'Heardův a MacDonaldův o.',
    },
    {
        label: 'Holy See (Vatican City State)',
        value: 'VA',
        czechLabel: 'Vatikán',
    },
    { label: 'Honduras', value: 'HN', czechLabel: 'Honduras' },
    { label: 'Hong Kong', value: 'HK', czechLabel: 'Hongkong' },
    { label: 'Hungary', value: 'HU', czechLabel: 'Maďarsko' },
    { label: 'Iceland', value: 'IS', czechLabel: 'Island' },
    { label: 'India', value: 'IN', czechLabel: 'Indie' },
    { label: 'Indonesia', value: 'ID', czechLabel: 'Indonésie' },
    { label: 'Iran, Islamic Republic Of', value: 'IR', czechLabel: 'Irán' },
    { label: 'Iraq', value: 'IQ', czechLabel: 'Irák' },
    { label: 'Ireland', value: 'IE', czechLabel: 'Irsko' },
    { label: 'Isle of Man', value: 'IM', czechLabel: 'Ostrov Man' },
    { label: 'Israel', value: 'IL', czechLabel: 'Izrael' },
    { label: 'Italy', value: 'IT', czechLabel: 'Itálie' },
    { label: 'Jamaica', value: 'JM', czechLabel: 'Jamajka' },
    { label: 'Japan', value: 'JP', czechLabel: 'Japonsko' },
    { label: 'Jersey', value: 'JE', czechLabel: 'Bailiwick Jersey' },
    { label: 'Jordan', value: 'JO', czechLabel: 'Jordánsko' },
    { label: 'Kazakhstan', value: 'KZ', czechLabel: 'Kazachstán' },
    { label: 'Kenya', value: 'KE', czechLabel: 'Keňa' },
    { label: 'Kiribati', value: 'KI', czechLabel: 'Kiribati' },
    {
        label: "Korea, Democratic People's Republic of",
        value: 'KP',
        czechLabel: 'KLDR',
    },
    { label: 'Korea, Republic of', value: 'KR', czechLabel: 'Jižní Korea' },
    { label: 'Kuwait', value: 'KW', czechLabel: 'Kuvajt' },
    { label: 'Kyrgyzstan', value: 'KG', czechLabel: 'Kyrgyzstán' },
    {
        label: "Lao People's Democratic Republic",
        value: 'LA',
        czechLabel: 'Laos',
    },
    { label: 'Latvia', value: 'LV', czechLabel: 'Lotyšsko' },
    { label: 'Lebanon', value: 'LB', czechLabel: 'Libanon' },
    { label: 'Lesotho', value: 'LS', czechLabel: 'Lesotho' },
    { label: 'Liberia', value: 'LR', czechLabel: 'Libérie' },
    { label: 'Libyan Arab Jamahiriya', value: 'LY', czechLabel: 'Libye' },
    { label: 'Liechtenstein', value: 'LI', czechLabel: 'Lichtenštejnsko' },
    { label: 'Lithuania', value: 'LT', czechLabel: 'Litva' },
    { label: 'Luxembourg', value: 'LU', czechLabel: 'Lucembursko' },
    { label: 'Macao', value: 'MO', czechLabel: 'Macao' },
    {
        label: 'Macedonia, The Former Yugoslav Republic of',
        value: 'MK',
        czechLabel: 'Makedonie',
    },
    { label: 'Madagascar', value: 'MG', czechLabel: 'Madagaskar' },
    { label: 'Malawi', value: 'MW', czechLabel: 'Malawi' },
    { label: 'Malaysia', value: 'MY', czechLabel: 'Malajsie' },
    { label: 'Maldives', value: 'MV', czechLabel: 'Maledivy' },
    { label: 'Mali', value: 'ML', czechLabel: 'Mali' },
    { label: 'Malta', value: 'MT', czechLabel: 'Malta' },
    {
        label: 'Marshall Islands',
        value: 'MH',
        czechLabel: 'Marshallovy ostrovy',
    },
    { label: 'Martinique', value: 'MQ', czechLabel: 'Martinik' },
    { label: 'Mauritania', value: 'MR', czechLabel: 'Mauritánie' },
    { label: 'Mauritius', value: 'MU', czechLabel: 'Mauricius' },
    { label: 'Mayotte', value: 'YT', czechLabel: 'Mayotte' },
    { label: 'Mexico', value: 'MX', czechLabel: 'Mexiko' },
    {
        label: 'Micronesia, Federated States of',
        value: 'FM',
        czechLabel: 'Federativní státy a Mikronésie',
    },
    { label: 'Moldova, Republic of', value: 'MD', czechLabel: 'Moldavsko' },
    { label: 'Monaco', value: 'MC', czechLabel: 'Monako' },
    { label: 'Mongolia', value: 'MN', czechLabel: 'Mongolsko' },
    { label: 'Montenegro', value: 'ME', czechLabel: 'Černá Hora' },
    { label: 'Montserrat', value: 'MS', czechLabel: 'Montserrat' },
    { label: 'Morocco', value: 'MA', czechLabel: 'Maroko' },
    { label: 'Mozambique', value: 'MZ', czechLabel: 'Mosambik' },
    { label: 'Myanmar', value: 'MM', czechLabel: 'Myanmarský svaz' },
    { label: 'Namibia', value: 'NA', czechLabel: 'Namíbie' },
    { label: 'Nauru', value: 'NR', czechLabel: 'Nauru' },
    { label: 'Nepal', value: 'NP', czechLabel: 'Nepál' },
    { label: 'Netherlands', value: 'NL', czechLabel: 'Nizozemí' },
    {
        label: 'Netherlands Antilles',
        value: 'AN',
        czechLabel: 'Nizozemské Antily',
    },
    { label: 'New Caledonia', value: 'NC', czechLabel: 'Nová Kaledonie' },
    { label: 'New Zealand', value: 'NZ', czechLabel: 'Nový Zéland' },
    { label: 'Nicaragua', value: 'NI', czechLabel: 'Nikaragua' },
    { label: 'Niger', value: 'NE', czechLabel: 'Niger' },
    { label: 'Nigeria', value: 'NG', czechLabel: 'Nigérie' },
    { label: 'Niue', value: 'NU', czechLabel: 'Niue' },
    { label: 'Norfolk Island', value: 'NF', czechLabel: 'Norfolk' },
    {
        label: 'Northern Mariana Islands',
        value: 'MP',
        czechLabel: 'Severní Marianny',
    },
    { label: 'Norway', value: 'NO', czechLabel: 'Norsko' },
    { label: 'Oman', value: 'OM', czechLabel: 'Omán' },
    { label: 'Pakistan', value: 'PK', czechLabel: 'Pákistán' },
    { label: 'Palau', value: 'PW', czechLabel: 'Palau' },
    {
        label: 'Palestinian Territory, Occupied',
        value: 'PS',
        czechLabel: 'Palestina',
    },
    { label: 'Panama', value: 'PA', czechLabel: 'Panama' },
    { label: 'Papua New Guinea', value: 'PG', czechLabel: 'Papua-Nová Guinea' },
    { label: 'Paraguay', value: 'PY', czechLabel: 'Paraguay' },
    { label: 'Peru', value: 'PE', czechLabel: 'Peru' },
    { label: 'Philippines', value: 'PH', czechLabel: 'Filipíny' },
    { label: 'Pitcairn', value: 'PN', czechLabel: 'Pitcairnovy ostrovy' },
    { label: 'Poland', value: 'PL', czechLabel: 'Polsko' },
    { label: 'Portugal', value: 'PT', czechLabel: 'Portugalsko' },
    { label: 'Puerto Rico', value: 'PR', czechLabel: 'Portoriko' },
    { label: 'Qatar', value: 'QA', czechLabel: 'Katar' },
    { label: 'Reunion', value: 'RE', czechLabel: 'Réunion' },
    { label: 'Romania', value: 'RO', czechLabel: 'Rumunsko' },
    { label: 'Russian Federation', value: 'RU', czechLabel: 'Rusko' },
    { label: 'RWANDA', value: 'RW', czechLabel: 'Rwanda' },
    { label: 'Saint Helena', value: 'SH', czechLabel: 'Sv. Helena' },
    { label: 'Saint Kitts and Nevis', value: 'KN', czechLabel: 'Sv. Kryštof' },
    { label: 'Saint Lucia', value: 'LC', czechLabel: 'Sv. Lucie' },
    {
        label: 'Saint Pierre and Miquelon',
        value: 'PM',
        czechLabel: 'Saint Pierre a Miquelon',
    },
    {
        label: 'Saint Vincent and the Grenadines',
        value: 'VC',
        czechLabel: 'Sv. Vincenc a Grenadiny',
    },
    { label: 'Samoa', value: 'WS', czechLabel: 'Samoa' },
    { label: 'San Marino', value: 'SM', czechLabel: 'San Marino' },
    { label: 'Sao Tome and Principe', value: 'ST', czechLabel: 'Sv. Tomáš' },
    { label: 'Saudi Arabia', value: 'SA', czechLabel: 'Saúdská Arábie' },
    { label: 'Senegal', value: 'SN', czechLabel: 'Senegal' },
    {
        label: 'Serbia',
        value: 'RS',
        czechLabel: 'Srbsko',
    },
    { label: 'Seychelles', value: 'SC', czechLabel: 'Seychelly' },
    { label: 'Sierra Leone', value: 'SL', czechLabel: 'Sierra Leone' },
    { label: 'Singapore', value: 'SG', czechLabel: 'Singapur' },
    { label: 'Slovakia', value: 'SK', czechLabel: 'Slovensko' },
    { label: 'Slovenia', value: 'SI', czechLabel: 'Slovinsko' },
    {
        label: 'Solomon Islands',
        value: 'SB',
        czechLabel: 'Šalomounovy ostrovy',
    },
    { label: 'Somalia', value: 'SO', czechLabel: 'Somalsko' },
    { label: 'South Africa', value: 'ZA', czechLabel: 'Jihoafrická republika' },
    {
        label: 'South Georgia and the South Sandwich Islands',
        value: 'GS',
        czechLabel: 'Jižní Georgie a Jižní Sanwich. o.',
    },
    { label: 'Spain', value: 'ES', czechLabel: 'Španělsko' },
    { label: 'Sri Lanka', value: 'LK', czechLabel: 'Srí Lanka' },
    { label: 'Sudan', value: 'SD', czechLabel: 'Súdán' },
    { label: 'Surilabel', value: 'SR', czechLabel: 'Surinam' },
    { label: 'Svalbard and Jan Mayen', value: 'SJ', czechLabel: 'Špicberky' },
    { label: 'Swaziland', value: 'SZ', czechLabel: 'Svazijsko' },
    { label: 'Sweden', value: 'SE', czechLabel: 'Švédsko' },
    { label: 'Switzerland', value: 'CH', czechLabel: 'Švýcarsko' },
    { label: 'Syrian Arab Republic', value: 'SY', czechLabel: 'Sýrie' },
    {
        label: 'Taiwan, Province of China',
        value: 'TW',
        czechLabel: 'Tchaj-wan',
    },
    { label: 'Tajikistan', value: 'TJ', czechLabel: 'Tádžikistán' },
    {
        label: 'Tanzania, United Republic of',
        value: 'TZ',
        czechLabel: 'Tanzánie',
    },
    { label: 'Thailand', value: 'TH', czechLabel: 'Thajsko' },
    {
        label: 'Timor-Leste',
        value: 'TL',
        czechLabel: 'Demokratická republika Východní Timor',
    },
    { label: 'Togo', value: 'TG', czechLabel: 'Togo' },
    { label: 'Tokelau', value: 'TK', czechLabel: 'Tokelau' },
    { label: 'Tonga', value: 'TO', czechLabel: 'Tonga' },
    {
        label: 'Trinidad and Tobago',
        value: 'TT',
        czechLabel: 'Trinidad a Tobago',
    },
    { label: 'Tunisia', value: 'TN', czechLabel: 'Tunisko' },
    { label: 'Turkey', value: 'TR', czechLabel: 'Turecko' },
    { label: 'Turkmenistan', value: 'TM', czechLabel: 'Turkmenistán' },
    {
        label: 'Turks and Caicos Islands',
        value: 'TC',
        czechLabel: 'Turks a Caicos',
    },
    { label: 'Tuvalu', value: 'TV', czechLabel: 'Tuvalu' },
    { label: 'Uganda', value: 'UG', czechLabel: 'Uganda' },
    { label: 'Ukraine', value: 'UA', czechLabel: 'Ukrajina' },
    {
        label: 'United Arab Emirates',
        value: 'AE',
        czechLabel: 'Spojené arabské emiráty',
    },
    { label: 'United Kingdom', value: 'GB', czechLabel: 'Velká Británie' },
    { label: 'United States', value: 'US', czechLabel: 'USA' },
    {
        label: 'United States Minor Outlying Islands',
        value: 'UM',
        czechLabel: 'Ostrovy USA v Tichém o.',
    },
    { label: 'Uruguay', value: 'UY', czechLabel: 'Uruguay' },
    { label: 'Uzbekistan', value: 'UZ', czechLabel: 'Uzbekistán' },
    { label: 'Vanuatu', value: 'VU', czechLabel: 'Vanuatu' },
    { label: 'Venezuela', value: 'VE', czechLabel: 'Venezuela' },
    { label: 'Viet Nam', value: 'VN', czechLabel: 'Vietnam' },
    {
        label: 'Virgin Islands, British',
        value: 'VG',
        czechLabel: 'Britské Panenské ostrovy',
    },
    {
        label: 'Virgin Islands, U.S.',
        value: 'VI',
        czechLabel: 'Panenské ostrovy (USA)',
    },
    {
        label: 'Wallis and Futuna',
        value: 'WF',
        czechLabel: 'Wallisovy ostrovy',
    },
    { label: 'Western Sahara', value: 'EH', czechLabel: 'Západní Sahara' },
    { label: 'Yemen', value: 'YE', czechLabel: 'Jemen' },
    { label: 'Zambia', value: 'ZM', czechLabel: 'Zambie' },
    { label: 'Zimbabwe', value: 'ZW', czechLabel: 'Zimbabwe' },
] as const

export type CountryCode = typeof countries[number]['value']

export const COUNTRIES = countries.reduce(
    (o, country) => ({ ...o, [country.value]: country.value }),
    {} as { [key in CountryCode]: key },
)

export const getCountriesSorted = (locale: Locale = LOCALES.CS) =>
    [...countries]
        .map(country => ({
            value: country.value,
            label: locale === LOCALES.CS ? country.czechLabel : country.label,
        }))
        .sort((a, b) => czechStringCompare(a.label, b.label))

export const getCountryLabelByCode = (code: CountryCode, locale: Locale = LOCALES.CS) => {
    const country = countries.find(c => c.value === code)

    if (!country) {
        throw new Error(`Country with code ${code} not found`)
    }

    return locale === LOCALES.CS ? country.czechLabel : country.label
}
