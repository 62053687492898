import Queue, { JobStatus } from 'bull'
import { customErrorConstructors, isCustomErrorLike } from './errors'

export const JOB_STATES: { [K in JobStatus as Uppercase<string & K>]: K } = Object.freeze({
    COMPLETED: 'completed',
    WAITING: 'waiting',
    ACTIVE: 'active',
    DELAYED: 'delayed',
    FAILED: 'failed',
    PAUSED: 'paused',
} as const)

export class WorkerSerializedError extends Error {
    readonly name = 'WorkerSerializedError'
}

// HOC that wraps a job handler and returns a function that will catch any errors and serialize them
export const withSerializedError = <T extends (...args: any[]) => Promise<any>>(jobHandler: T) => {
    return async (...args: Parameters<T>): Promise<Awaited<ReturnType<T>>> => {
        try {
            return await jobHandler(...args)
        } catch (error) {
            if (error instanceof Error) throw serializeCustomErrors(error)
            throw error
        }
    }
}

export const serializeCustomErrors = <AnError extends Error>(anError: AnError) => {
    // check if error is in customErrorConstructors, if not, just return the error
    if (!isCustomErrorLike(anError)) return anError

    const errorProps = Object.getOwnPropertyNames(anError).reduce(
        // @ts-ignore Custom errors can have custom properties
        (props, prop) => Object.assign(props, { [prop]: anError[prop] }),
        {},
    )

    return new WorkerSerializedError(JSON.stringify(errorProps))
}

export const deserializeCustomErrors = (anError: Error): Error => {
    if (!(anError instanceof WorkerSerializedError)) return anError

    try {
        const errorProps = JSON.parse(anError.message)
        const ErrorConstructor = isCustomErrorLike(errorProps)
            ? customErrorConstructors[errorProps.name]
            : Error

        // @ts-ignore ignore missing props in constructor, the props are added via Object.assign
        return Object.assign(new ErrorConstructor(), errorProps)
    } catch (parseError) {
        // if parsing failed, just return the original error
        return anError
    }
}

export const getErrorInstanceFromFailedJob = (
    jobOrGraphqlData: XOR<
        Pick<Queue.Job, 'stacktrace' | 'failedReason'>,
        {
            failedReason: Maybe<string>
            errorName: Maybe<string>
        }
    >,
) => {
    const { stacktrace, failedReason } = jobOrGraphqlData
    let errorName: string | undefined = jobOrGraphqlData.errorName ?? undefined
    if (!errorName && stacktrace?.length) {
        // stacktrace always includes the error name and message, extract the name (e.g. AccountNotFoundError: Could not find requested account)
        errorName = stacktrace[0]?.split(':')[0] || undefined
    }

    const ErrorConstructor =
        errorName === new WorkerSerializedError().name ? WorkerSerializedError : Error

    return new ErrorConstructor(failedReason ?? undefined)
}
