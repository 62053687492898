export default {
    baseStyle: () => {
        const hoverBg = `blackAlpha.100`
        const activeBg = `blackAlpha.200`

        return {
            background: '#F8F8F8',
            color: 'primary',
            borderRadius: 'full',

            _hover: { bg: hoverBg },
            _groupHover: { bg: hoverBg },
            _active: { bg: activeBg },
        }
    },
    sizes: {
        xl: {
            w: { base: '40px', lg: '48px' },
            h: { base: '40px', lg: '48px' },
        },
    },
}
