/* eslint-disable react/no-array-index-key */
import React, { useEffect } from 'react'
import {
    Flex,
    Box,
    useBreakpointValue,
    BoxProps,
    useDisclosure,
    Button,
    HStack,
    Text,
    ButtonProps,
} from '@chakra-ui/react'
import { Global } from '@emotion/react'
import { Dict } from '@chakra-ui/utils'
import { withSafeArea } from '@upvestcz/common/styles-utils'
import { LOCALES_LABELS_LONG } from '@upvestcz/common/i18n/locales'
import { MergeChakraProps } from '@upvestcz/common/ts-utils'
import { useRouter } from 'next/router'
import Link, { LinkProps, useLink } from './Link'
import { NAVBAR_Z_INDEX, APP_MENU_HEIGHT, NAVBAR_BREAKPOINT } from './theme/v2/variables'
import { useScreenBottomStackingContext } from './contexts/screenBottomStackingContext'
import { LocaleDrawer } from './Locale'
import { LocaleFlag } from './Locale/LocaleFlag'
import * as icons from './icons'

const AppMenuLink = ({ children, ...props }: LinkProps) => {
    const { isActive } = useLink(props)
    const styles: Dict = {
        display: 'flex',
        flexFlow: 'column nowrap',
        alignItems: 'center',
        justifyContent: 'flex-end',
        height: '100%',
        width: '100%', // <button> does not stretch by default
        color: isActive ? 'black' : '#505050',
        sx: {
            svg: {
                color: isActive ? 'black' : 'primary',
            },
        },
    }

    return (
        <Link {...styles} {...props} className="link-normal" variant="plain">
            {children}
        </Link>
    )
}

const AppMenuIcon = (props: MergeChakraProps<{ isOpen?: boolean }, BoxProps>) => {
    const styles: Dict = {
        height: '24px',
        width: 'auto',
        mb: '3px',
    }

    return <Box {...styles} {...props} />
}

const AppMenuText = ({ children, ...props }: BoxProps) => {
    const styles: Dict = {
        fontSize: '10px',
        lineHeight: 1.5,
    }

    return (
        <Box as="span" {...styles} {...props}>
            {children}
        </Box>
    )
}

const AppMenu = ({ children, ...props }: BoxProps) => {
    const isMobile = useBreakpointValue<boolean>(
        { base: true, [NAVBAR_BREAKPOINT]: false },
        NAVBAR_BREAKPOINT,
    )
    const styles: Dict = {
        Root: {
            position: 'fixed',
            bottom: 0,
            left: 0,
            right: 0,
            pt: '8px',
            pb: withSafeArea('6px', 'bottom'),
            boxShadow: '0px -8px 24px rgba(0, 0, 0, 0.06)',
            zIndex: NAVBAR_Z_INDEX + 2,
            height: withSafeArea(APP_MENU_HEIGHT, 'bottom'),
            bgColor: '#fff',
        },
        List: {
            justifyContent: 'space-around',
            alignItems: 'stretch',
        },
        ListItem: {
            flex: 1,
            /*
            css :empty selector - hides items without any html content (including editor line breaks)
            useful for hiding items which have not been rendered by <PermissionGuard>
             */
            _empty: { display: 'none' },
        },
    }

    const { registerElement } = useScreenBottomStackingContext()

    useEffect(() => {
        registerElement('AppMenu', isMobile ? APP_MENU_HEIGHT : 0)

        return () => {
            registerElement('AppMenu', 0)
        }
    }, [isMobile, registerElement])

    if (!isMobile) return null
    return (
        <Box as="nav" {...styles.Root} {...props}>
            <Flex as="ul" {...styles.List}>
                {React.Children.map(children, (child, index) => {
                    return (
                        <Box key={index} as="li" {...styles.ListItem}>
                            {child}
                        </Box>
                    )
                })}
            </Flex>
            <Global
                styles={{
                    // Add an after element to not cover up the bottom of the content with AppMenu
                    'body::after': {
                        content: '""',
                        display: 'block',
                        height: `${APP_MENU_HEIGHT}px`,
                    },
                    // Intercom does not let you move their bubble on mobile, so we have to do it manually. https://community.intercom.com/messenger-8/is-there-a-way-to-adjust-the-position-of-the-messenger-on-mobile-devices-3026
                    '.intercom-launcher, .intercom-lightweight-app-launcher, .intercom-namespace .intercom-dfosxs':
                        {
                            // if this menu is rendered we need to move the intercom bubble up.
                            bottom: `${APP_MENU_HEIGHT + 10}px !important`,
                        },
                    'iframe[name="intercom-notifications-frame"]': {
                        // 52 is extra padding to match default intercom offset.
                        bottom: `${APP_MENU_HEIGHT + 10 + 52}px !important`,
                    },
                }}
            />
        </Box>
    )
}

const AppMenuLanguage = (props: ButtonProps) => {
    const { onOpen, onClose, isOpen } = useDisclosure()
    const { locale } = useRouter()

    return (
        <>
            <Button onClick={onOpen} variant="plain" py={1} {...props}>
                <Text as={HStack} spacing={3} fontWeight="normal">
                    <LocaleFlag locale={locale} />
                    <span>{LOCALES_LABELS_LONG[locale]}</span>
                </Text>
                <icons.CaretIconDown ml="auto" />
            </Button>
            <LocaleDrawer isOpen={isOpen} onClose={onClose} />
        </>
    )
}

export { AppMenu, AppMenuLink, AppMenuIcon, AppMenuText, AppMenuLanguage }
