import * as R from 'ramda'
import { TFunction } from 'i18next'
import { ObjectShape } from 'yup/lib/object'
import * as yup from 'yup'
import { isAccountVerifiedBy, VERIFICATION_PROVIDERS, VerificationProvider } from './account-utils'
import { AUTH0_CONNECTIONS, getPrimaryConnection } from './auth'
import { Exact, NotNullObject, ObjectValues, Override } from './ts-utils'
import {
    createValidationSchemaCorporateBankAccountBase,
    createValidationSchemaCorporateCompanyDetailsBase,
    createValidationSchemaCorporateWithPersons,
    createValidationSchemaPhysicalBankIDBase,
    createValidationSchemaPhysicalBankPage,
    createValidationSchemaPhysicalIdsBase,
    createValidationSchemaPhysicalPersonalDetailsBase,
    KeyOfCorporatePersonSchema,
} from './validation/schemas/registration'
import { Account, CorporatePerson } from './graphql/typegen'
import { findFirstAsync } from './utils'

export const REGISTRATION_TYPE = Object.freeze({
    CORPORATE: 'corporate',
    BANKID: 'bankId',
    PHYSICAL: 'physical',
} as const)

export type RegistrationType = ObjectValues<typeof REGISTRATION_TYPE>

export const REGISTRATION_TYPE_BASE_URL = Object.freeze({
    [REGISTRATION_TYPE.CORPORATE]: '/user/registration-corporate',
    [REGISTRATION_TYPE.BANKID]: '/user/registration-bankid',
    [REGISTRATION_TYPE.PHYSICAL]: '/user/registration',
} as const)

export const getRegistrationType = (routerPathName: string) => {
    if (routerPathName.includes(REGISTRATION_TYPE_BASE_URL[REGISTRATION_TYPE.CORPORATE]))
        return REGISTRATION_TYPE.CORPORATE
    if (routerPathName.includes(REGISTRATION_TYPE_BASE_URL[REGISTRATION_TYPE.BANKID]))
        return REGISTRATION_TYPE.BANKID
    return REGISTRATION_TYPE.PHYSICAL
}
export const getUserRegistrationFlowUrl = (account: {
    is_corporate: boolean
    verification_provider: VerificationProvider
    auth_id: string
}) => {
    // corp accounts
    if (account && account.is_corporate)
        return REGISTRATION_TYPE_BASE_URL[REGISTRATION_TYPE.CORPORATE]
    // users registered via BankID or via BaPos using BankID
    if (
        isAccountVerifiedBy(account, VERIFICATION_PROVIDERS.BANKID) ||
        (isAccountVerifiedBy(account, VERIFICATION_PROVIDERS.BAPO) &&
            getPrimaryConnection(account.auth_id) === AUTH0_CONNECTIONS.BANKID)
    )
        return REGISTRATION_TYPE_BASE_URL[REGISTRATION_TYPE.BANKID]
    // else
    return REGISTRATION_TYPE_BASE_URL[REGISTRATION_TYPE.PHYSICAL]
}

type TabItem = {
    key: string
    label: string
}

export const getRegistrationTabItems = ({ t = R.identity }: { t?: TFunction }) => ({
    [REGISTRATION_TYPE.CORPORATE]: [
        {
            key: 'company-details',
            label: t('Společnost'),
        },
        {
            key: 'corporate-persons',
            label: t('Osoby'),
        },
        {
            key: 'bank',
            label: t('Bankovní účet'),
        },
        {
            key: 'summary',
            label: t('Shrnutí'),
        },
        {
            key: 'agreement',
            label: t('Smlouva'),
        },
    ] as TabItem[],
    [REGISTRATION_TYPE.PHYSICAL]: [
        {
            key: 'personal-details',
            label: t('Osobní údaje'),
        },
        {
            key: 'ids',
            label: t('Doklady'),
        },
        {
            key: 'bank',
            label: t('Bankovní účet'),
        },
        {
            key: 'summary',
            label: t('Shrnutí'),
        },
        {
            key: 'agreement',
            label: t('Smlouva'),
        },
    ] as TabItem[],
    [REGISTRATION_TYPE.BANKID]: [
        {
            key: 'personal-details',
            label: t('Osobní údaje'),
        },
        {
            key: 'bank',
            label: t('Bankovní účet'),
        },
        {
            key: 'summary',
            label: t('Shrnutí'),
        },
        {
            key: 'agreement',
            label: t('Smlouva'),
        },
    ] as TabItem[],
})

export const TAB_ITEM_STATES = Object.freeze({
    SUCCESS: 'SUCCESS',
    ACTIVE: 'ACTIVE',
    BLANK: 'BLANK',
} as const)

export type TabItemState = ObjectValues<typeof TAB_ITEM_STATES>

export const getTabItemsWithState = ({
    t,
    registrationType,
    routerPathname,
}: {
    t?: TFunction
    registrationType: RegistrationType
    routerPathname: string
}) => {
    const tabItems = getRegistrationTabItems({ t })[registrationType]
    const getTabItemUrl = (tabItem: TabItem) =>
        `${REGISTRATION_TYPE_BASE_URL[registrationType]}/${tabItem.key}`

    const activeTabIndex = tabItems.findIndex(tabItem =>
        routerPathname.includes(getTabItemUrl(tabItem)),
    )

    return tabItems.map((tabItem, index) => {
        const state =
            index < activeTabIndex
                ? TAB_ITEM_STATES.SUCCESS
                : index === activeTabIndex
                ? TAB_ITEM_STATES.ACTIVE
                : TAB_ITEM_STATES.BLANK

        const hasBeenFilled = state === TAB_ITEM_STATES.SUCCESS
        const url = getTabItemUrl(tabItem)

        return {
            ...tabItem,
            state,
            hasBeenFilled,
            url,
        }
    })
}

const registrationFormKeys = [
    'name',
    'surname',
    'pid',
    'phone_country_code',
    'phone',
    'id1_front',
    'id1_back',
    'id2_front',
    'iban',
    'bank_account_agreement',
    'corporate_shareholder_equity_amount',
    'corporate_shareholder_equity_origin',
    'corporate_id',
    'corporate_name',
    'corporate_persons',
    'is_not_PEP',
    'kb_marketing_consent',
    // used in BankID registration only
    'address',
    'city',
    'zip',
    'state_of_citizenship',
    'state_of_residency',
    'email',
    'id_type',
    'id_number',
    'state_of_birth',
    'city_of_birth',
    'is_usa_citizen',
    'tax_residences',
] as const

type RegistrationFormKey = typeof registrationFormKeys[number]

type AccountInput = Override<
    Pick<
        Account & {
            bank_account_agreement: boolean
        },
        RegistrationFormKey
    >,
    {
        corporate_persons: Maybe<
            Override<
                Pick<CorporatePerson, KeyOfCorporatePersonSchema>,
                {
                    id: number
                }
            >[]
        >
    }
>

type InitialValuesReturnType = Override<
    Exact<NotNullObject<AccountInput>>,
    {
        corporate_shareholder_equity_amount: string // input's value is string, gets parsed to number upon submitting the form to backend
    } & Pick<AccountInput, 'state_of_birth' | 'state_of_citizenship' | 'state_of_residency'>
>
export const getInitialValues = (account: AccountInput): InitialValuesReturnType => {
    return {
        name: account.name || '',
        surname: account.surname || '',
        pid: account.pid || '',
        phone_country_code: account.phone_country_code || 420,
        phone: account.phone || '',
        id1_front: account.id1_front || '',
        id1_back: account.id1_back || '',
        id2_front: account.id2_front || '',
        iban: account.iban || '',
        bank_account_agreement: account.bank_account_agreement || false,
        corporate_shareholder_equity_amount:
            account.corporate_shareholder_equity_amount?.toString() || '',
        corporate_shareholder_equity_origin: account.corporate_shareholder_equity_origin || '',
        corporate_id: account.corporate_id || '',
        corporate_name: account.corporate_name || '',
        corporate_persons: account.corporate_persons || [],
        is_not_PEP: account.is_not_PEP || false,
        kb_marketing_consent: account.kb_marketing_consent || false,
        // used in BankID registration only
        address: account.address || '',
        city: account.city || '',
        zip: account.zip || '',
        state_of_citizenship: account.state_of_citizenship || null,
        state_of_residency: account.state_of_residency || null,
        email: account.email || '',
        id_type: account.id_type || '',
        id_number: account.id_number || '',
        state_of_birth: account.state_of_birth || null,
        city_of_birth: account.city_of_birth || '',
        is_usa_citizen: account.is_usa_citizen || false,
        tax_residences: account.tax_residences || [],
    }
}

export type RegistrationInitialValues = ReturnType<typeof getInitialValues>

// Returns an url of the registration page which has not
// been filled but all previous have been filled.
// (e.g. if you have filled personal details -> returns /user/registration/ids)
export const getLastUnfilledRegistrationPageUrl = async (
    account: Parameters<typeof getInitialValues>[0] &
        Parameters<typeof getUserRegistrationFlowUrl>[0],
) => {
    const createPageItem =
        ({ schemaBase, url }: { schemaBase: ObjectShape; url: string }) =>
        async () => {
            const isValid = await yup.object().shape(schemaBase).isValid(getInitialValues(account))
            return { url, isValid }
        }

    let PAGE_ITEMS = []
    const correctRegistrationFlowUrl = getUserRegistrationFlowUrl(account)

    // Redirect corporate persons to corporate registrations
    if (correctRegistrationFlowUrl === REGISTRATION_TYPE_BASE_URL[REGISTRATION_TYPE.CORPORATE]) {
        PAGE_ITEMS = [
            createPageItem({
                schemaBase: createValidationSchemaCorporateCompanyDetailsBase(),
                url: `${correctRegistrationFlowUrl}/company-details`,
            }),
            createPageItem({
                schemaBase: createValidationSchemaCorporateWithPersons(),
                url: `${correctRegistrationFlowUrl}/corporate-persons`,
            }),
            createPageItem({
                schemaBase: createValidationSchemaCorporateBankAccountBase(),
                url: `${correctRegistrationFlowUrl}/bank`,
            }),
        ]
    } else if (
        correctRegistrationFlowUrl === REGISTRATION_TYPE_BASE_URL[REGISTRATION_TYPE.BANKID]
    ) {
        // Order of items in this array matters and should correspond
        // to the order of the pages in the registration flow
        PAGE_ITEMS = [
            createPageItem({
                schemaBase: createValidationSchemaPhysicalBankIDBase({
                    // don't check pid unique, because that would never let you to a further step
                    // if you're getting back to your registration (your account already exists)
                    isPidUnique: () => true,
                    softValidation: true,
                }),
                url: `${correctRegistrationFlowUrl}/login-callback-error`, // successful registrations where afterwards BankID details don't pass validation should not be possible. If that's the case, throw user to a bankID error page.
            }),
            createPageItem({
                schemaBase: createValidationSchemaPhysicalBankPage(),
                url: `${correctRegistrationFlowUrl}/bank`,
            }),
        ]
    } else {
        // Order of items in this array matters and should correspond
        // to the order of the pages in the registration flow
        PAGE_ITEMS = [
            createPageItem({
                schemaBase: createValidationSchemaPhysicalPersonalDetailsBase({
                    // don't check pid unique, because that would never let you to a further step
                    // if you're getting back to your registration (your account already exists)
                    isPidUnique: () => true,
                }),
                url: '/signup/3', // if no details filled in, start at signup
            }),
            createPageItem({
                schemaBase: createValidationSchemaPhysicalIdsBase(),
                url: `${correctRegistrationFlowUrl}/ids`,
            }),
            createPageItem({
                schemaBase: createValidationSchemaPhysicalBankPage(),
                url: `${correctRegistrationFlowUrl}/bank`,
            }),
        ]
    }

    const lastUnfilledPage = await findFirstAsync(page => !page.isValid, PAGE_ITEMS)

    // If there is not last unfilled page it means that all the pages
    // are filled and we return the URL of the summary page
    return lastUnfilledPage?.url ?? `${correctRegistrationFlowUrl}/summary`
}
