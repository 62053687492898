import React, { createElement, ReactNode } from 'react'
import { useSpring, animated } from 'react-spring'
import {
    Box,
    Button,
    CheckCircleIcon,
    CloseIcon,
    InfoIcon,
    WarningIcon,
} from '@upvestcz/shared-components'
import { Dict } from '@chakra-ui/utils'

const AnimatedBox = animated(Box)

export type ToastType = 'info' | 'error' | 'success'

const getIconComponent = (type: ToastType) => {
    switch (type) {
        case 'info':
            return InfoIcon
        case 'error':
            return WarningIcon
        case 'success':
            return CheckCircleIcon
        default:
            throw new Error(`An unknown type ${type} passed.`)
    }
}

export const Toast = ({
    children,
    type = 'success',
    onRemoveClick,
}: {
    children: ReactNode
    type: ToastType
    onRemoveClick: () => void
}) => {
    const INACTIVE_BOX_SHADOW =
        '0 4px 6px -1px rgba(255, 255, 255, 0.12), 0 2px 4px -1px rgba(255, 255, 255, 0.08)'
    const ACTIVE_BOX_SHADOW =
        '0 10px 15px -3px rgba(255, 255, 255, 0.15), 0 4px 6px -2px rgba(255, 255, 255, 0.1)'

    const [styleProps, set] = useSpring(() => ({
        boxShadow: INACTIVE_BOX_SHADOW,
    }))

    const setActiveBoxShadow = () => {
        set({ boxShadow: ACTIVE_BOX_SHADOW })
    }
    const setInactiveBoxShadow = () => {
        set({ boxShadow: INACTIVE_BOX_SHADOW })
    }

    const getBgColor = () => {
        if (type === 'info') {
            return 'warningYellow'
        }
        if (type === 'error') {
            return 'dangerRed'
        }
        if (type === 'success') {
            return 'primaryLight'
        }
    }

    const styles: Dict = {
        Root: {
            backgroundColor: getBgColor(),
            pointerEvents: 'all',
            position: 'relative',
            display: 'flex',
            borderRadius: '2px',
            padding: '12px 16px',
            alignItems: 'center',
            color: '#fff',
            fontWeight: 600,
            marginTop: 2,
        },
    }

    return (
        <AnimatedBox
            {...styles.Root}
            style={styleProps}
            onClick={(e: React.MouseEvent<HTMLDivElement>) => {
                e.nativeEvent.stopImmediatePropagation()
            }}
            onMouseOver={setActiveBoxShadow}
            onFocus={setActiveBoxShadow}
            onTouchStart={setActiveBoxShadow}
            onBlur={setInactiveBoxShadow}
            onMouseLeave={setInactiveBoxShadow}
            onTouchEnd={setInactiveBoxShadow}
        >
            <Box mr={4}>{createElement(getIconComponent(type), { width: 8, height: 8 })}</Box>
            <div>{children}</div>
            <Button
                variant="plain"
                onClick={onRemoveClick}
                marginLeft="auto"
                color="#fff"
                fontSize="16px"
                onTouchStart={e => {
                    e.stopPropagation()
                }}
                onTouchMove={e => {
                    e.stopPropagation()
                }}
                onTouchEnd={e => {
                    e.stopPropagation()
                }}
            >
                <CloseIcon width={3} height={3} />
            </Button>
        </AnimatedBox>
    )
}
