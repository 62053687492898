import React, { useEffect, useState, useMemo, ComponentType } from 'react'
import Head from 'next/head'
import { useRouter } from 'next/router'
import dynamic from 'next/dynamic'
import ReactPixel from 'react-facebook-pixel'
import ReactGA from 'react-ga4'
import * as yup from 'yup'
import Script from 'next/script'
import momentBase from 'moment'
import moment from 'moment-timezone'
import 'moment/locale/cs'
import { CypressJSLoadedDetector, CookieBar, AnnouncementBar } from '@upvestcz/shared-components'
import FaviconsHead from '@upvestcz/shared-components/head/favicons'
import { UPVEST_APP_BASE_URL } from '@upvestcz/common/constants'
import { destroyCookie, getTopDomain, parseCookies, setCookie } from '@upvestcz/common/cookies'
import { syncMomentLocales } from '@upvestcz/common/i18n/moment'
import { useSatisMeter } from '@upvestcz/common/tracking'
import * as R from 'ramda'
import { useTranslation } from 'react-i18next'
import { addApolloState, initializeApollo } from '@upvestcz/common/graphql/client'
import { PRIV_MEMBER_COOKIE_KEY } from '@upvestcz/common/account-utils'
import { CookieConsentProvider, useCookieConsentContext } from '@use-cookie-consent/react'
import FontsHead from '@upvestcz/shared-components/head/fonts'
import { isResSent, replace } from '@upvestcz/common/routing'
import { destroyLoginCookies } from '@upvestcz/common/auth'
import { Locale } from '@upvestcz/common/i18n/locales'
import { DEFAULT_NS } from '@upvestcz/common/i18n'
import { setCSPHeaderOnServerResponse } from '@upvestcz/common/csp'
import { Global } from '@emotion/react'
import getConfig from 'next/config'
import { CookieKBMarketingConsent } from '../components/CookieKBMarketingConsent'
import { useAccountPolling } from '../lib/account'
import { withStore } from '../store'
import { useLogout } from '../lib/auth'
import { useAuth } from '../store/auth'

import i18n, { tryToLoadTranslations, useAddTranslations } from '../i18n'
import PageContentProvider from '../components/PageContentProvider'
import { getAppLayout } from '../components/layouts/AppLayout'
import { useToast } from '../lib/toast'
import { MyAppComponent } from '../lib/next'
import { getCSPHeaderValue } from '../lib/csp'
import { CURRENCY_COOKIE_KEY } from '../components/CurrencyProvider'
import { useAccount } from '../store/account'

syncMomentLocales(momentBase, moment)

const { CYPRESS_RUNNING } = getConfig().publicRuntimeConfig

// @ts-ignore: need to upgrade prettier so that we can do `import type { ReactIntercom } ...` and type it properly
const Intercom = dynamic(() => import('react-intercom').then(Intercom => Intercom.ReactIntercom), {
    ssr: false,
}) as ComponentType<{ app_id: string; email?: string; vertical_padding?: number }>

const IdleTimer = dynamic(() => import('react-idle-timer'))

const IDLE_TIMEOUT = 1000 * 60 * 20 // 20 minutes in ms

const LoginExpiryToast = ({ tokenExpired = false }) => {
    const addToast = useToast()
    const { t } = useTranslation()

    useEffect(() => {
        if (tokenExpired) {
            destroyCookie(null, 'tokenExpired')
            addToast({
                type: 'info',
                message: t('Platnost vašeho přihlášení vypršela. Byli jste automaticky odhlášeni.'),
            })
        }
    }, [
        t,
        /*
          addToast
          WARNING: this is intentional.
          Don't let addToast trigger re-renders by changing on add/remove.
        */
        tokenExpired,
    ])

    return null
}

const Tracking = ({ nonce }: { nonce?: string }) => {
    const { consent } = useCookieConsentContext()
    const [, initSatismeter] = useSatisMeter()
    const [, setIsTrackingInitialised] = useState(false)
    const [auth] = useAuth()
    const [account] = useAccount()
    const router = useRouter()

    useEffect(() => {
        if (process.env.GA_TRACKING_CODE) {
            ReactGA.gtag('consent', 'default', {
                ad_storage: consent.marketing ? 'granted' : 'denied',
                analytics_storage: consent.statistics ? 'granted' : 'denied',
                ad_personalization: consent.marketing ? 'granted' : 'denied',
                ad_user_data: consent.marketing ? 'granted' : 'denied',
                security_storage: consent.necessary ? 'granted' : 'denied',
                functionality_storage: consent.necessary ? 'granted' : 'denied',
            })
            // Classified as first-party
            ReactGA.initialize(process.env.GA_TRACKING_CODE)

            if (auth.profile.auth_id) {
                // For a better tracking of engagement data between sessions we can identify
                // users in GA by their ids
                ReactGA.set({ userId: auth.profile.auth_id })
            }
        }

        if (process.env.FB_PIXEL_ID) {
            ReactPixel.fbq('consent', 'revoke')
            ReactPixel.init(process.env.FB_PIXEL_ID)
            ReactPixel.fbq('track', 'PageView')
        }
    }, [])

    useEffect(() => {
        ReactGA.gtag('consent', 'update', {
            ad_storage: consent.marketing ? 'granted' : 'denied',
            analytics_storage: consent.statistics ? 'granted' : 'denied',
            ad_personalization: consent.marketing ? 'granted' : 'denied',
            ad_user_data: consent.marketing ? 'granted' : 'denied',
            security_storage: consent.necessary ? 'granted' : 'denied',
            functionality_storage: consent.necessary ? 'granted' : 'denied',
        })
    }, [consent.marketing, consent.necessary, consent.statistics]) // react to any consent change

    useEffect(() => {
        if (consent.marketing) {
            ReactPixel.fbq('consent', 'grant')
        }
    }, [consent.marketing])

    useEffect(() => {
        if (consent.statistics) {
            setIsTrackingInitialised(true)
            if (process.env.SATISMETER_WRITE_KEY && account) {
                initSatismeter(process.env.SATISMETER_WRITE_KEY, account)
            }
        }
    }, [account, consent.statistics, initSatismeter])

    useEffect(() => {
        if (router.query.shouldResetReferrer === 'true') {
            ReactGA.set({ referrer: UPVEST_APP_BASE_URL })
        }
    }, [router.query.shouldResetReferrer])

    return (
        <>
            <Global
                styles={{
                    '.sm-survey > div > div': {
                        transform: 'scale(0.8)',
                        transformOrigin: 'bottom right',
                    },
                }}
            />
            <Script
                strategy="afterInteractive"
                nonce={nonce}
                async
                src="https://app.satismeter.com/js"
            />
        </>
    )
}

const AccountPolling = () => {
    useAccountPolling()

    return null
}

const MyApp: MyAppComponent = ({
    Component,
    translations,
    pageProps,
    currencyCookie,
    nonce,
    tokenExpired,
    err,
    MAINTENANCE_MESSAGE,
}) => {
    useAddTranslations({ ns: DEFAULT_NS, translations })
    const logout = useLogout()
    const [auth] = useAuth()
    const router = useRouter()
    const { t } = useTranslation()

    useMemo(() => {
        if (i18n.language !== router.locale) {
            i18n.changeLanguage(router.locale)
        }
    }, [router.locale])

    useEffect(() => {
        yup.setLocale({
            mixed: {
                required: t('Toto pole je třeba vyplnit'),
            },
        })

        momentBase.locale(router.locale)
        moment.locale(router.locale)

        // destroy old NEXT_LOCALE cookies bound to subdomain. Use the global domain NEXT_LOCALE cookie instead.
        destroyCookie({}, 'NEXT_LOCALE')
        setCookie({}, 'NEXT_LOCALE', router.locale, {
            domain: getTopDomain(),
            maxAge: 100 * 365 * 24 * 60 * 60, // 100 yrs
        })
    }, [router.locale])

    const isServer = typeof window === 'undefined'

    // Workaround for https://github.com/zeit/next.js/issues/8592
    const modifiedPageProps = { ...pageProps, err }

    const getLayout = typeof Component.getLayout === 'function' ? Component.getLayout : getAppLayout

    const announcements = []
    if (MAINTENANCE_MESSAGE) {
        announcements.push(
            <AnnouncementBar bg="highlightWarning">{MAINTENANCE_MESSAGE}</AnnouncementBar>,
        )
    }

    return (
        <CookieConsentProvider
            useCookieConsentHooksOptions={{
                consentCookieAttributes: {
                    domain: isServer ? undefined : getTopDomain(), // * solution for sharing cookie consent between domains
                    expires: 100 * 365, // 100 yrs - js-cookie does not support maxAge
                },
            }}
        >
            {CYPRESS_RUNNING ? null : <Tracking nonce={nonce} />}
            <Head>
                <title>{t('Upvest')}</title>
                <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1"
                    key="viewport"
                />
                {FontsHead(nonce)}
                <meta
                    name="description"
                    content={t(
                        'Online investice do nemovitostí formou participace na dluhovém financování developerských projektů.',
                    )}
                    key="description"
                />
                <meta
                    name="keywords"
                    content={t(
                        'online investice, mezaninové financování, investice do nemovitostí',
                    )}
                    key="keywords"
                />

                <meta
                    property="og:title"
                    content={t('Online investice do nemovitostních projektů')}
                    key="og:title"
                />
                <meta property="og:type" content="website" key="og:type" />
                <meta property="og:url" content={UPVEST_APP_BASE_URL} key="og:url" />
                <meta
                    property="og:description"
                    content={t(
                        'Investujte online do výstavby nemovitostí a participujte na výnosech z poskytování developerských úvěrů.',
                    )}
                    key="og:description"
                />
                <meta
                    property="og:image"
                    content="https://d3uvu5yktntepe.cloudfront.net/img/app-assets/og/OG_Page_Default.png"
                    key="og:image"
                />

                <meta property="twitter:card" content="summary_large_image" key="twitter:card" />
                <meta
                    property="twitter:image"
                    content="https://d3uvu5yktntepe.cloudfront.net/img/app-assets/og/OG_Page_Default.png"
                    key="twitter:image"
                />
                {FaviconsHead()}
            </Head>
            <Script strategy="beforeInteractive" nonce={nonce} src="/redirectIE11.js" />
            <CypressJSLoadedDetector />
            <PageContentProvider pageProps={pageProps} currencyCookie={currencyCookie}>
                {/* more about the layout "pattern" here: */}
                {/* https://adamwathan.me/2019/10/17/persistent-layout-patterns-in-nextjs/ */}
                {getLayout(<Component {...modifiedPageProps} />, { announcements })}
                <AccountPolling />
                <LoginExpiryToast tokenExpired={tokenExpired} />
                <CookieBar />
                <CookieKBMarketingConsent />
            </PageContentProvider>
            {process.env.PIPELINE_ENV === 'production' && (
                <>
                    <Intercom app_id="hymodpt8" email={auth.profile.name} />
                    {auth.loggedIn && (
                        <IdleTimer
                            onIdle={() => logout()}
                            timeout={IDLE_TIMEOUT}
                            debounce={250}
                            crossTab
                        />
                    )}
                </>
            )}
        </CookieConsentProvider>
    )
}

MyApp.getInitialProps = async ({ Component, ctx }) => {
    let pageProps = {}

    const { priv } = ctx.query

    // try to load base translations
    // WARNING: use locale from ctx, not router! router.locale does not behave correctly on CSR with middleware.
    const locale = ctx.locale as Locale

    const { translations } = await tryToLoadTranslations({
        relativeFilePath: DEFAULT_NS,
        locale,
    })

    if (priv) {
        setCookie(ctx, PRIV_MEMBER_COOKIE_KEY, priv as string, {
            domain: getTopDomain(ctx),
            maxAge: 100 * 365 * 24 * 60 * 60, // 100 yrs
        })
        if (ctx.auth.loggedIn) {
            destroyLoginCookies(ctx)
            return replace({ ctx, location: null })
        }
    }

    const nonce = setCSPHeaderOnServerResponse(getCSPHeaderValue, ctx)

    const initialData = await ctx.initialDataFetch({ ctx })

    if (Component.getInitialProps) {
        const apolloClient = initializeApollo(null, ctx)
        const apollo = {
            client: apolloClient,
            saveCache: (props: Record<string, unknown>) => addApolloState(apolloClient, props),
        }

        try {
            pageProps = await Component.getInitialProps({
                ...ctx,
                data: initialData,
                apollo,
            })
        } catch (err) {
            // the response might be finished on the getInitialProps call
            if (isResSent(ctx)) return null
            throw err // if response not finished, re-throw the error.
        }
    }

    // fetch this at runtime in getInitialProps
    // eslint-disable-next-line prefer-destructuring
    const MAINTENANCE_MESSAGE = process.env.MAINTENANCE_MESSAGE

    return {
        ...initialData,
        pageProps,
        nonce,
        MAINTENANCE_MESSAGE,
        translations,
        tokenExpired: !!parseCookies(ctx).tokenExpired,
        // currencyCookie needed for the default currency value on SSR
        currencyCookie: parseCookies(ctx)[CURRENCY_COOKIE_KEY],
    }
}

export default R.compose(withStore)(MyApp)
