import React, { ReactNode, useMemo } from 'react'
import { MergeChakraProps } from '@upvestcz/common/ts-utils'
import { Grid, useTheme, chakra, ChakraTheme, FlexProps } from '@chakra-ui/react'
import {
    convertStylePropToArrayNotation,
    createMediaQueryString,
    mapStylePropToBreakpoints,
} from '@upvestcz/common/styles-utils'
import { Dict } from '@chakra-ui/utils'

export type AspectRatioProps = MergeChakraProps<
    {
        ratio:
            | string
            | number
            | string[]
            | number[]
            | Record<string, string>
            | Record<string, number>
        children: ReactNode
        notFixed?: boolean
    },
    FlexProps
>

// We create a custom `AspectRatio` component rather than using the one
// which ChakraUI provides because the one from ChakraUI makes all its
// child elements of type `img` render with `object-fit: cover` and there
// is no elegant way to override this behaviour.
const AspectRatio = ({ ratio, children, notFixed = false, ...props }: AspectRatioProps) => {
    const theme = useTheme<ChakraTheme>()
    const styles: Dict = {
        position: 'relative',
        sx: {
            '& > *': {
                gridArea: '1 / 1 / 2 / 2',
            },
            '& > svg': {
                width: 'auto',
                height: 'auto',
            },
            /*
            if not fixed it allows the item to grow above the aspect ratio
            and just maintains minimal box dimensions given by the aspect
             */
            ...(notFixed
                ? {}
                : {
                      '& > *:not(svg)': {
                          position: 'absolute',
                          top: 0,
                          left: 0,
                          width: '100%',
                          height: '100%',
                      },
                  }),
        },
    }

    const ratioJSON = JSON.stringify(ratio)

    const breakpointsFromRatio = useMemo(() => {
        const stylePropArray = convertStylePropToArrayNotation(ratio, theme).map(value =>
            value === null ? null : `0 0 ${value} 1`,
        )
        return mapStylePropToBreakpoints(stylePropArray, theme)
    }, [ratioJSON, theme])

    // the svg viewbox defines the aspect ratio of an image via the css grid + svg hack
    return (
        <Grid {...styles} {...props}>
            {/* Generate children from brakpoints with display: none for each breakpoint
             to avoid layout jump on first render when using useBreakpointValue() */}
            {breakpointsFromRatio.map(({ minWidth, maxWidth, value }) => (
                <chakra.svg
                    key={value}
                    viewBox={value!}
                    sx={{
                        display: 'none',
                        [createMediaQueryString({
                            minWidth,
                            maxWidth,
                        })]: {
                            display: 'block',
                        },
                    }}
                />
            ))}
            {children}
        </Grid>
    )
}

export default AspectRatio
