import { ReactNode, useMemo } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { MergeChakraProps } from '@upvestcz/common/ts-utils'
// import arrowBackground from './images/arrow-background.svg'
// import arrowReverseBackground from './images/arrow-reverse-background.svg'

const arrowBackgroundProps = {
    // use the inlined version of './images/arrow-background.svg' to prevent flicker on initial load
    // (we might consider using a Webpack plugin to automatically inline these in the future)
    backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg width='100' height='100' version='1.1' xmlns='http://www.w3.org/2000/svg'%3e%3cpolygon points='0,0 100,0, 50,26' fill='%23fff' /%3e%3c/svg%3e"), linear-gradient(#f9f9f9,#fff);`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
}

const reverseArrowBackgroundProps = {
    // use the inlined version of './images/arrow-reverse-background.svg' to prevent flicker on initial load
    // (we might consider using a Webpack plugin to automatically inline these in the future)
    backgroundImage: `url("data:image/svg+xml;charset=UTF-8,%3csvg width='100' height='100' version='1.1' xmlns='http://www.w3.org/2000/svg'%3e%3cdefs%3e%3clinearGradient id='grad' x1='50%25' y1='100%25' x2='50%25' y2='0%25'%3e%3cstop offset='0%25' stop-color='%23FDFDFD' /%3e%3cstop offset='100%25' stop-color='%23f9f9f9' /%3e%3c/linearGradient%3e%3c/defs%3e%3cpolygon points='0,0 100,0, 50,26' fill='url(%23grad)' /%3e%3c/svg%3e"), linear-gradient(#f9f9f9 192px, transparent 192px, transparent)`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: '100%',
    backgroundPosition: '0 192px, 0',
}

function Container({
    children,
    background,
    ...props
}: MergeChakraProps<
    { children: ReactNode; background?: BoxProps['background'] | 'arrow' | 'reverse-arrow' },
    BoxProps
>) {
    const backgroundProps = useMemo(() => {
        switch (background) {
            case 'arrow':
                return arrowBackgroundProps
            case 'reverse-arrow':
                return reverseArrowBackgroundProps
            default:
                return { background }
        }
    }, [background])

    return (
        <Box position="relative" {...backgroundProps} {...props}>
            {children}
        </Box>
    )
}

export default Container
