import React, { useEffect, useCallback, useMemo } from 'react'
import { useTransition, animated } from 'react-spring'
import { useTranslation } from 'react-i18next'
import { getTopDomain, parseCookies, setCookie } from '@upvestcz/common/cookies'
import { useDisclosure, Box, Text, Button } from '@chakra-ui/react'
import { withSafeArea } from '@upvestcz/common/styles-utils'
import Link from '@upvestcz/shared-components/Link'
import { useScreenBottomStackingContext } from '@upvestcz/shared-components/contexts/screenBottomStackingContext'
import { gql, useMutation, useQuery } from '@apollo/client'
import {
    SetKbMarketingConsentMutation,
    SetKbMarketingConsentMutationVariables,
    MyAccountKbMarketingConsentQuery,
} from '@upvestcz/common/graphql/typegen'
import * as R from 'ramda'
import ReactGA from 'react-ga4'

import { KB_MARKETING_AGREEMENT } from '@upvestcz/common/constants'
import { useAccount } from '../store/account'

export const useKBMarketingConsent = () => {
    const COOKIE_NAME = 'KB_marketing_cookie'
    const [account, accountDispatch] = useAccount()

    // cookie only says if user chose value or not,
    // doesn't say anything about consent value
    const hasCookieChoice = !!parseCookies()[COOKIE_NAME]

    const { data } = useQuery<MyAccountKbMarketingConsentQuery>(
        gql`
            query myAccountKBMarketingConsent {
                myAccount {
                    id
                    kb_marketing_consent
                }
            }
        `,
        {
            fetchPolicy: 'cache-first',
            skip: !account,
        },
    )

    const [mutate] = useMutation<
        SetKbMarketingConsentMutation,
        SetKbMarketingConsentMutationVariables
    >(
        gql`
            mutation setKBMarketingConsent($kb_marketing_consent: Boolean!) {
                setKBMarketingConsent(kb_marketing_consent: $kb_marketing_consent) {
                    id
                    kb_marketing_consent
                }
            }
        `,
        {
            refetchQueries: ['myAccountKBMarketingConsent'],
        },
    )

    useEffect(() => {
        if (data?.myAccount) {
            const { myAccount } = data

            // if values are the same, do not update
            if (R.whereEq(myAccount, account)) return

            // TODO: do we even need this? Maybe we should only use Account from apollo cache?
            accountDispatch({
                type: 'update',
                payload: myAccount,
            })
        }
    }, [
        // account - is left out on purpose. we don't want to update store with query data when we update the store manually.
        accountDispatch,
        data,
    ])

    const disclosure = useDisclosure()

    useEffect(() => {
        // if user logged in, is KB user and has not given consent
        if (account && account.kb_flag && !account.kb_marketing_consent) {
            // did not make a choice (approve/reject) by cookie yet
            if (!hasCookieChoice) {
                disclosure.onOpen()
                return
            }
        }

        disclosure.onClose()
    }, [hasCookieChoice, account])

    const kbMarketingValue = useMemo(() => {
        return data?.myAccount?.kb_marketing_consent || false
    }, [data])

    const setKbMarketingValue = useCallback(
        async (value: boolean, variant = 'profile') => {
            await mutate({
                variables: {
                    kb_marketing_consent: value,
                },
            })

            ReactGA.event('select_item', {
                item_list_id: `kb_marketing_consent_${variant}`,
                item_list_name: 'KB marketing consent',
                items: [
                    {
                        item_id: `kb_marketing_consent_${value}`,
                        item_name: `${value}`,
                    },
                ],
            })

            // this only says if user chose value or not,
            // not the value itself
            setCookie({}, COOKIE_NAME, 'true', {
                domain: getTopDomain(),
                maxAge: 30 * 24 * 60 * 60, // 30 days
            })
        },
        [mutate],
    )

    return {
        ...disclosure,
        setKbMarketingValue,
        kbMarketingValue,
    }
}

export function CookieKBMarketingConsent() {
    const { t } = useTranslation()
    const {
        setKbMarketingValue,
        isOpen: shouldShow,
        onClose: hideKBMarketingConsent,
    } = useKBMarketingConsent()

    const ABVariant = useMemo(() => {
        return Math.random() > 0.5 ? 'A' : 'B'
    }, [])

    const wording = useMemo(() => {
        // variant A
        if (ABVariant === 'A') {
            return {
                text: t(
                    'Souhlasím s předáváním a zpracováním osobních údajů Členům skupiny KB pro účely marketingu.',
                ),
                acceptButtonLabel: t('Souhlasím'),
                declineButtonLabel: t('Nesouhlasím'),
            }
        }

        // variant B
        return {
            text: t('Chci dostávat zvýhodněné nabídky na produkty od Členů skupiny KB.'),
            acceptButtonLabel: t('Souhlasím'),
            declineButtonLabel: t('Nesouhlasím'),
        }
    }, [])

    const accept = async () => {
        await setKbMarketingValue(true, ABVariant)

        hideKBMarketingConsent()
    }

    const decline = async () => {
        await setKbMarketingValue(false, ABVariant)
        hideKBMarketingConsent()
    }

    const transition = useTransition(shouldShow, {
        from: { opacity: 0, transform: 'translateY(-16px)' },
        enter: { opacity: 1, transform: 'translateY(0%)' },
        leave: { opacity: 0, transform: 'translateY(16px)' },
    })

    const AnimatedBox = animated(Box)

    const { total: bottomOffset } = useScreenBottomStackingContext()

    return transition((style, item) => {
        return (
            item && (
                <AnimatedBox
                    style={style}
                    position="fixed"
                    bottom={withSafeArea(bottomOffset, 'bottom')}
                    left={0}
                    right={0}
                    zIndex={3}
                    background="#EBEBEB"
                    boxShadow="0px 8px 16px rgba(0, 0, 0, 0.1);"
                    py={2}
                >
                    <Box
                        display={{ md: 'flex' }}
                        px={4}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Text>
                            {wording.text}
                            &nbsp;
                            <Link to={KB_MARKETING_AGREEMENT} blank>
                                <Text color="primary" as="span">
                                    {t('Znění\u00A0souhlasu\u00A0zde')}
                                </Text>
                            </Link>
                        </Text>
                        <Button
                            onClick={decline}
                            ml={{ md: 4 }}
                            mt={{ base: 2, md: 0 }}
                            width={{ base: '100%', md: 'auto' }}
                            size="sm"
                            variant="outline"
                        >
                            {wording.declineButtonLabel}
                        </Button>
                        <Button
                            onClick={accept}
                            ml={{ md: 4 }}
                            mt={{ base: 2, md: 0 }}
                            width={{ base: '100%', md: 'auto' }}
                            size="sm"
                        >
                            {wording.acceptButtonLabel}
                        </Button>
                    </Box>
                </AnimatedBox>
            )
        )
    })
}
