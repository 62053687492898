import { Box, useStyleConfig, BoxProps } from '@chakra-ui/react'

type Props = { variant?: 'horizontal' | 'vertical' } & BoxProps

function DashedLine({ variant = 'horizontal', ...props }: Props) {
    const styles = useStyleConfig('DashedLine', {
        variant,
        styleConfig: {
            baseStyle: () => ({
                display: 'inline-block',
                background: 'steelGrey',
            }),
            variants: {
                horizontal: {
                    height: '1px',
                    display: 'block',
                },
                vertical: {
                    width: '1px',
                },
            },
        },
    })

    return <Box as="span" __css={styles} {...props} />
}

export default DashedLine
