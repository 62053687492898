import numeral from 'numeral'
import 'numeral/locales/cs'
import * as R from 'ramda'
import { remove as removeDiacritics } from 'diacritics'
import debounce from 'lodash.debounce'
import Decimal, { Numeric } from 'decimal.js-light'
import { LoggerInstance } from 'winston'
import { CURRENCIES, Currency } from './currency'
import { Locale, LOCALES } from './i18n/locales'
import { hasProperty, isNotUndefined, MaybePromise, ObjectValues } from './ts-utils'

// initialize numeral with czech locale
numeral.locale(LOCALES.CS)

// https://davidwalsh.name/javascript-download
export const downloadBlob = ({ blob, filename }: TODO) => {
    const a = document.createElement('a')
    a.style.display = 'none'
    document.body.appendChild(a)

    a.href = window.URL.createObjectURL(blob)

    a.setAttribute('download', filename)

    a.addEventListener('click', e => {
        e.stopPropagation()
    })

    a.click()

    window.URL.revokeObjectURL(a.href)
    document.body.removeChild(a)
}

export const nextTick = (fn: TODO) => setTimeout(fn, 0)

export const slugify = (name: TODO) =>
    removeDiacritics(name.toLowerCase()).replace(/\s/g, '_').replace(/\./g, '_')

export const getHashFromPath = (path: TODO) => path.split('#').pop() ?? ''

// Creates a debounced function with the following difference to
// how debounced function usually work:
// This function is will *not* return the result of the previous
// call but rather *wait* until the specified timeout elapses
// and only then return the result. This is especially useful
// for async validation functions where we don't want to have stale
// results...
export const createAsyncDebouncedFn = <TFunction extends (...args: any) => any>(
    fn: TFunction,
    props: {
        wait?: number
        options?: { leading: boolean }
    } = {},
) => {
    const propsWithDefaults = {
        wait: 500,
        options: { leading: true },
        ...props,
    }
    const debouncedFn = debounce(
        async ({ resolve, args }) => {
            const res = await fn(...args)

            return resolve(res)
        },
        propsWithDefaults.wait,
        propsWithDefaults.options,
    )

    return (...args: TODO) =>
        new Promise<Awaited<ReturnType<TFunction>>>(resolve => debouncedFn({ resolve, args }))
}

function toFixed(x: TODO) {
    let y = x

    if (Math.abs(x) < 1.0) {
        const e = parseInt(x.toString().split('e-')[1], 10)
        if (e) {
            // eslint-disable-next-line no-restricted-properties
            y *= Math.pow(10, e - 1)
            y = `0.${new Array(e).join('0')}${x.toString().substring(2)}`
        }
    } else {
        let e = parseInt(x.toString().split('+')[1], 10)
        if (e > 20) {
            e -= 20
            // eslint-disable-next-line no-restricted-properties
            y /= Math.pow(10, e)
            y += new Array(e + 1).join('0')
        }
    }
    return y
}

export const toFixedWithoutRounding = (num: number, fixed: number) => {
    const re = new RegExp(`^-?\\d+(?:.\\d{0,${fixed || -1}})?`)

    // regular `.toString()` method cannot be used as it doesn't convert
    // numbers in scientific notation to regular notation
    // (and causes e. g. 5e-15) render as 5 in the end...
    const numString = String(toFixed(num))

    return parseFloat(numString.match(re)![0])
}

export const getLocalCurrencyName = (currency: Currency, lng: Locale = LOCALES.CS) => {
    const currencySymbols = {
        cs: {
            [CURRENCIES.CZK]: 'Kč',
            [CURRENCIES.EUR]: '€',
        },
        en: {
            [CURRENCIES.CZK]: 'CZK',
            [CURRENCIES.EUR]: '€',
        },
    } as const
    return currencySymbols[lng][currency]
}

export const formatCurrency = (nominal: TODO, currency?: Currency, decimals = 2) => {
    const nominalString = numeral(toFixedWithoutRounding(nominal, decimals)).format('0,0[.]00')

    const currencyString = currency ? ` ${getLocalCurrencyName(currency)}` : ''

    return `${nominalString}${currencyString}`
}

export const roundInteger = ({ number, round }: TODO) => Math.ceil(number / round) * round

export const weightedAverage = (nums: Numeric[], weights: Numeric[]) => {
    const [sum, weightSum] = weights.reduce(
        (acc, w, i) => {
            const sum = new Decimal(acc[0])
            const weightSum = new Decimal(acc[1])
            acc[0] = sum.plus(new Decimal(nums[i]).times(w)).toNumber()
            acc[1] = weightSum.plus(w).toNumber()
            return acc
        },
        [0, 0] as [number, number],
    )
    return new Decimal(sum).dividedBy(weightSum).toNumber()
}

export async function retry<T>(fn: () => Promise<T>, maxRetries = 1): Promise<T> {
    try {
        return await fn()
    } catch (err) {
        if (maxRetries <= 0) {
            throw err
        }
        return retry(fn, maxRetries - 1)
    }
}

export const isValueOfEnum = <T extends Record<string, TODO>>(
    enumObject: T,
    value: TODO,
): value is ObjectValues<T> => Object.values(enumObject).includes(value)

export const isResponseLike = (err: unknown): err is { json: () => unknown } =>
    typeof err === 'object' &&
    err !== null &&
    hasProperty(err, 'json') &&
    typeof err.json === 'function'

/**
 * Helper function for sorting czech strings with diacritics.
 * Base on: https://stackoverflow.com/questions/17542108/sort-array-in-czech-localecompare
 */
const czechCharMapL = ' 0123456789aábcčdďeéěfghiíjklmnňoópqrřsštťuúůvwxyýzž'
const czechCharMapU = ' 0123456789AÁBCČDĎEÉĚFGHIÍJKLMNŇOÓPQRŘSŠTŤUÚŮVWXYÝZŽ'
const charsOrder: Record<string, number> = {}

czechCharMapL.split('').forEach((char, i) => {
    charsOrder[czechCharMapL[i]] = i
    charsOrder[czechCharMapU[i]] = i
})

export const czechStringCompare = (a: string, b: string, order = 'asc') => {
    let idx = 0
    let res: number

    const s1 = a.split('')
    const s2 = b.split('')

    while (idx < a.length && idx < b.length && charsOrder[s1[idx]] === charsOrder[s2[idx]]) {
        idx += 1
    }

    if (idx === s1.length && idx === s2.length) res = 0
    if (idx === a.length) res = 1
    if (idx === b.length) res = -1

    res =
        charsOrder[s1[idx]] > charsOrder[s2[idx]]
            ? 1
            : charsOrder[s1[idx]] < charsOrder[s2[idx]]
            ? -1
            : 0

    return order === 'asc' ? res : -res
}

export const objectDiff = <T extends Record<string, unknown>>(
    object1: T,
    object2: T,
    ignoreKeys: Array<string> = [],
) => {
    type accType = {
        prev: T
        new: T
    }

    return R.pipe<T, Array<string>, Array<string>, accType>(
        R.keys,
        R.without(ignoreKeys),
        R.reduce(
            (acc, key) => {
                if (String(object1[key]) !== String(object2[key])) {
                    const prevLens = R.lensPath(['prev', key])
                    const newLens = R.lensPath(['new', key])

                    return R.pipe(
                        R.set(prevLens, object1[key]),
                        R.set(newLens, object2[key]),
                    )(acc) as accType
                }

                return acc
            },
            { prev: {}, new: {} } as accType,
        ),
    )(object1)
}

export const randomInt = (min: number, max: number) => Math.floor(Math.random() * (max - min)) + min

export class ErrorAggregator {
    private messages: Array<unknown>

    constructor() {
        this.messages = []
    }

    push(error: unknown) {
        this.messages.push(error)
    }

    getMessageCount() {
        return this.messages.length
    }

    getMessages() {
        return this.messages
    }
}

export const runAsScript = (
    fn: () => MaybePromise<unknown>,
    logger: Console | LoggerInstance = console,
) => {
    Promise.resolve(fn())
        .then(() => process.exit(0))
        .catch(err => {
            logger.error(err)
            process.exit(1)
        })
}

export const runInOrder = <T>(arrayOfAsyncFunctions: Array<() => PromiseLike<T>>) => {
    return arrayOfAsyncFunctions.reduce((asyncChain: PromiseLike<Array<T>>, asyncFn) => {
        return asyncChain.then((resArray: Array<T>) =>
            asyncFn().then(res => {
                resArray.push(res)
                return resArray
            }),
        )
    }, Promise.resolve([]))
}

export const findFirstAsync = <T>(
    predicate: (value: T) => boolean,
    asyncFunctions: (() => MaybePromise<T>)[],
): Promise<T | undefined> => {
    return asyncFunctions.reduce((previousPromise, asyncFunction) => {
        return previousPromise.then(async previousResult => {
            if (isNotUndefined(previousResult)) return previousResult // Return the result if it is already found.
            const currentResult = await asyncFunction()
            if (isNotUndefined(currentResult) && predicate(currentResult)) {
                return currentResult // Return the current result if it matches
            }
        })
    }, Promise.resolve<T | undefined>(undefined))
}

export const ensureLeadingSlash = (str: string) => {
    return str.length > 0 && str[0] === '/' ? str : `/${str}`
}

export const isJSONEqual = (a: any, b: any) => {
    const receivedJSON = JSON.stringify(a)
    const expectedJSON = JSON.stringify(b)
    return expectedJSON === receivedJSON
}

export function removeAccents(input: string) {
    // Normalize the input string to Unicode Normalization Form D (NFD)
    const normalized = input.normalize('NFD')

    // Use a regular expression to match and replace diacritic characters
    const withoutAccents = normalized.replace(/[\u0300-\u036f]/g, '')

    return withoutAccents
}

export const promiseWithResolvers = <T extends unknown>({
    onFinally,
}: { onFinally?: () => void } = {}) => {
    let resolve: (value: MaybePromise<T>) => void
    let reject: (err: Error) => void
    const promise = new Promise<T>((res, rej) => {
        resolve = res
        reject = rej
    }).finally(() => {
        onFinally?.()
    })
    // @ts-ignore Promise executor is called immediately.
    return { promise, resolve, reject }
}
