import React from 'react'
import {
    Box,
    Heading,
    Text,
    Flex,
    VStack,
    KBStamp,
    FooterSocials,
    FooterContainer,
    FooterContact,
    Link,
} from '@upvestcz/shared-components'
import { useLocaleLpBaseUrl } from '@upvestcz/common/i18n/use-localized-url'
import { useTranslation } from 'react-i18next'
import { useAgreementSet } from '../store/agreementSet'

export const Footer = () => {
    const [agreementSet] = useAgreementSet()
    const { t } = useTranslation()
    const lpBaseUrl = useLocaleLpBaseUrl()

    return (
        <FooterContainer>
            <VStack mb={8} spacing={12} textAlign="center">
                <KBStamp />
                <Text color="textLight">
                    {t(
                        'Společnost upvest s.r.o. je registrována u České národní banky jako poskytovatel platebních služeb malého rozsahu.',
                    )}
                </Text>
            </VStack>
            <Flex direction={{ base: 'column', md: 'row' }}>
                {/* set flex-shrink to 9999 to shink this box completely first before shrinking last box with menu */}
                <Box px={{ base: 4, md: 0 }} flex="0 9999 auto">
                    <Heading as="h2" size="sm" mb={[2, 4]}>
                        {t('Kontakt')}
                    </Heading>
                    <FooterContact />
                </Box>
                <Box
                    pl={{ md: 6 }}
                    ml="auto"
                    flex="0 0 auto"
                    mt={{ base: 8, md: 0 }}
                    width={{ base: '100%', md: 'auto' }}
                    textAlign={{ base: 'center', md: 'left' }}
                >
                    <Heading as="h2" size="sm" mb={4}>
                        {t('Sledujte nás')}
                    </Heading>
                    <FooterSocials size="32px" />
                </Box>
                <Box
                    mt={{ base: 8, md: 0 }}
                    ml={{ md: 16 }}
                    width="100%"
                    maxWidth={{ base: 'none', md: '305px' }}
                    flex="0 1 auto"
                >
                    <nav>
                        <VStack
                            as="ul"
                            spacing={2}
                            alignItems={{ base: 'center', md: 'flex-start' }}
                        >
                            <li>
                                <Link to={`${lpBaseUrl}/cookies`} color="primary" blank>
                                    {t('Cookies')}
                                </Link>
                            </li>
                            <li>
                                <Link to="/referral-terms" color="primary">
                                    {t('Upvest referral program')}
                                </Link>
                            </li>
                            <li>
                                <Link
                                    blank
                                    to={`https://s3.eu-central-1.amazonaws.com/upvest-public/agreement_sets/${agreementSet.agreement_2}`}
                                    color="primary"
                                >
                                    {t('Ochrana osobních údajů')}
                                </Link>
                            </li>
                            <li>
                                <Link blank to="https://blog.upvest.cz" color="primary">
                                    {t('Insights')}
                                </Link>
                            </li>
                        </VStack>
                    </nav>
                </Box>
            </Flex>
            <Box textAlign="center" marginTop={12} color="textLight">
                <p>
                    {t('Copyright\u00A0©\u00A0{{year}}, upvest\u00A0s.r.o., IČ:\u00A005835526', {
                        year: new Date().getFullYear(),
                    })}
                </p>
            </Box>
        </FooterContainer>
    )
}
