import React from 'react'

/*
Adjust for Moderat regular:

font-family: sans-serif
font-size: 16px
word-spacing: 0.01875em;
letter-spacing: 0.01em;
 */

// this cannot be a standalone component, because the <Head> component does not support rendering custom components.
const FontsHead = (nonce?: string) => (
    <>
        <link
            nonce={nonce}
            rel="preload"
            href="https://d3uvu5yktntepe.cloudfront.net/fonts/brick-display-regular-pro.latin-ext.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
        />
        <link
            nonce={nonce}
            rel="preload"
            href="https://d3uvu5yktntepe.cloudfront.net/fonts/Moderat-Bold.latin-ext.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
        />
        <link
            nonce={nonce}
            rel="preload"
            href="https://d3uvu5yktntepe.cloudfront.net/fonts/Moderat-Medium.latin-ext.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
        />
        <link
            nonce={nonce}
            rel="preload"
            href="https://d3uvu5yktntepe.cloudfront.net/fonts/Moderat-Regular.latin-ext.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
        />
        <link
            nonce={nonce}
            rel="preload"
            href="https://d3uvu5yktntepe.cloudfront.net/fonts/DMSans-Regular.latin-ext.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
        />
        <link
            nonce={nonce}
            rel="preload"
            href="https://d3uvu5yktntepe.cloudfront.net/fonts/DMSans-Medium.latin-ext.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
        />
        <link
            nonce={nonce}
            rel="preload"
            href="https://d3uvu5yktntepe.cloudfront.net/fonts/DMSans-Bold.latin-ext.woff2"
            as="font"
            type="font/woff2"
            crossOrigin="anonymous"
        />
        <style
            nonce={nonce}
            key="fontDefinitions"
            dangerouslySetInnerHTML={{
                __html: `
                    @font-face {
                        font-family: 'DM Sans';
                        font-style: normal;
                        font-weight: 400;
                        font-display: swap;
                        src: url('https://d3uvu5yktntepe.cloudfront.net/fonts/DMSans-Regular.latin-ext.woff2') format('woff2'), url('https://d3uvu5yktntepe.cloudfront.net/fonts/DMSans-Regular.latin-ext.woff2') format('woff');
                        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                    }
                    @font-face {
                        font-family: 'DM Sans';
                        font-style: normal;
                        font-weight: 500;
                        font-display: swap;
                        src: url('https://d3uvu5yktntepe.cloudfront.net/fonts/DMSans-Medium.latin-ext.woff2') format('woff2'), url('https://d3uvu5yktntepe.cloudfront.net/fonts/DMSans-Medium.latin-ext.woff2') format('woff');
                        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                    }
                    @font-face {
                        font-family: 'DM Sans';
                        font-style: normal;
                        font-weight: bold;
                        font-display: swap;
                        src: url('https://d3uvu5yktntepe.cloudfront.net/fonts/DMSans-Bold.latin-ext.woff2') format('woff2'), url('https://d3uvu5yktntepe.cloudfront.net/fonts/DMSans-Bold.latin-ext.woff2') format('woff');
                        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                    }
                    @font-face {
                        font-family: 'DM Sans: fallback'; 
                        size-adjust: 102%;
                        ascent-override: normal;
                        descent-override: normal;
                        line-gap-override: 4%;
                        src: Local("Trebuchet MS");
                    }
                    @font-face {
                        font-family: Moderat;
                        font-style: normal;
                        font-weight: 400;
                        font-display: swap;
                        src: url('https://d3uvu5yktntepe.cloudfront.net/fonts/Moderat-Regular.latin-ext.woff2') format('woff2');
                        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                    }
                    @font-face {
                        font-family: Moderat;
                        font-style: normal;
                        font-weight: 500;
                        font-display: swap;
                        src: url('https://d3uvu5yktntepe.cloudfront.net/fonts/Moderat-Medium.latin-ext.woff2') format('woff2');
                        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                    }
                    @font-face {
                        font-family: Moderat;
                        font-style: normal;
                        font-weight: bold;
                        font-display: swap;
                        src: url('https://d3uvu5yktntepe.cloudfront.net/fonts/Moderat-Bold.latin-ext.woff2') format('woff2');
                        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                    }
                    @font-face {
                        font-family: 'Moderat: fallback'; 
                        ascent-override: 85%;
                        descent-override: 10%;
                        line-gap-override: 20%;
                        size-adjust: 100%;
                        src: Local("Trebuchet MS");
                    }
                    @font-face {
                        font-family: 'Brick Display';
                        font-style: normal;
                        font-weight: 400;
                        font-display: swap;
                        src: url('https://d3uvu5yktntepe.cloudfront.net/fonts/brick-display-regular-pro.latin-ext.woff2') format('woff2'), url('https://d3uvu5yktntepe.cloudfront.net/fonts/brick-display-regular-pro.latin-ext.woff') format('woff');
                        unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF, U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
                    }
                    @font-face {
                        font-family: 'Brick Display: fallback'; 
                        ascent-override: 85%;
                        descent-override: 10%;
                        line-gap-override: 20%;
                        /* 
                        this size-adjust is not perfect, but works for short headlines.
                        ideally, we have 105% and 
                        letter-spacing: 0.028125em;
                        word-spacing: 0.0625em;
                        for when the font is not loaded.
                        */
                        size-adjust: 110%;
                        src: Local("Times New Roman");
                    }
`,
            }}
        />
    </>
)

export default FontsHead
