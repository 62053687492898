import { Children, useState, useEffect, useCallback, useRef, ReactNode } from 'react'
import { animated, config, useSpring } from 'react-spring'
import {
    HStack,
    Flex,
    useBreakpointValue,
    Box,
    ListItem,
    List,
    Modal,
    ModalBody,
    ModalContent,
    chakra,
    StackProps,
    BoxProps,
    FlexProps,
    forwardRef,
} from '@chakra-ui/react'
import { createContext, Dict } from '@chakra-ui/utils'
import throttle from 'lodash.throttle'
import { useCallbackRef, useIsSticky } from '@upvestcz/common/hooks'
import { withSafeArea } from '@upvestcz/common/styles-utils'
import { ObjectValues } from '@upvestcz/common/ts-utils'
import Link, { useLink, LinkProps } from './Link'
import { useScrollInfo, SCROLL_DIRECTIONS } from './contexts/scrollInfo'
import { chakraModalContentAnimationProps } from './animations'
import {
    APP_MENU_HEIGHT,
    NAVBAR_Z_INDEX,
    NAVBAR_HEIGHTS,
    NAVBAR_BREAKPOINT,
} from './theme/v2/variables'

export type NavLinkProps = {
    children: ReactNode
    isEmphasised?: boolean
    isActive?: boolean
} & LinkProps

export const NavLink = forwardRef(function NavLink(
    { children, isEmphasised, isActive: isActiveProp, ...props }: NavLinkProps,
    ref,
) {
    const { isActive } = useLink({ ...props, isActive: isActiveProp })

    const styles: Dict = {
        textStyle: 'buttonTextSm',
        fontWeight: isEmphasised || isActive ? 'bold' : 'medium',
        whiteSpace: 'nowrap',
        borderBottom: isActive ? '4px solid' : undefined,
        borderColor: 'primary',
        pb: 1,
        _hover: { color: 'primary', textDecoration: 'none' },
    }

    return (
        <Link ref={ref} {...styles} {...props}>
            {children}
        </Link>
    )
})

export function NavItems({ children, ...props }: { children: ReactNode } & StackProps) {
    const styles: Dict = {
        list: {
            display: { base: 'none', lg: 'flex' },
        },
        listItem: {
            /*
            css :empty selector - hides items without any html content (including editor line breaks)
            useful for hiding items which have not been rendered by <PermissionGuard>
             */
            _empty: { display: 'none' },
        },
    }
    return (
        <HStack as={List} {...styles.list} spacing={6} {...props}>
            {Children.map(children, child => (
                <ListItem {...styles.listItem}>{child}</ListItem>
            ))}
        </HStack>
    )
}

const [NavModalContainerProvider, useNavModalContainer] = createContext({
    name: 'NavModalContainerContext',
    errorMessage:
        'useNavModalContainer: `ref` is undefined. Seems you forgot to wrap the NavModal components in NavModalContainer',
})

export { NavModalContainerProvider, useNavModalContainer }

export const NavModalContainer = ({ children, ...props }: { children: ReactNode } & BoxProps) => {
    const navModalContainerRef = useRef(null)
    const styles: Dict = {
        position: 'fixed',
        zIndex: {
            base: NAVBAR_Z_INDEX + 1,
            [NAVBAR_BREAKPOINT]: NAVBAR_Z_INDEX - 1,
        },
    }

    return (
        <NavModalContainerProvider value={navModalContainerRef}>
            <Box ref={navModalContainerRef} {...styles} {...props} />
            {children}
        </NavModalContainerProvider>
    )
}

export const NavModal = chakra(function NavModal({ children, className, ...props }) {
    const styles: Dict = {
        padding: {
            base: `${NAVBAR_HEIGHTS.MOBILE.INITIAL}px 0 ${withSafeArea(
                APP_MENU_HEIGHT,
                'bottom',
            )} 0`,
            [NAVBAR_BREAKPOINT]: `${NAVBAR_HEIGHTS.DESKTOP.INITIAL}px 0 0 0`,
        },
    }

    const containerRef = useNavModalContainer()

    return (
        <Modal
            size="full"
            scrollBehavior="inside"
            portalProps={{
                containerRef,
            }}
            {...props}
        >
            <ModalContent {...chakraModalContentAnimationProps} {...styles}>
                <ModalBody p={0} className={className}>
                    {children}
                </ModalBody>
            </ModalContent>
        </Modal>
    )
})

const NAVBAR_STATES = {
    INITIAL: 'INITIAL',
    HIDDEN: 'HIDDEN',
    STUCK: 'STUCK',
} as const

const getNavbarAnimationStyles = ({
    state,
    isMobile,
}: {
    state: ObjectValues<typeof NAVBAR_STATES>
    isMobile?: boolean
}) => {
    // we don't know the device type on initial render so we return an empty object
    if (typeof isMobile === 'undefined') {
        return {}
    }

    switch (state) {
        case NAVBAR_STATES.INITIAL:
            return {
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0)',
                transform: `translateY(0%)`,
                ...(isMobile
                    ? {
                          height: `${NAVBAR_HEIGHTS.MOBILE.INITIAL}px`,
                      }
                    : {
                          height: `${NAVBAR_HEIGHTS.DESKTOP.INITIAL}px`,
                      }),
            }
        case NAVBAR_STATES.STUCK:
            return {
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                transform: `translateY(0%)`,
                ...(isMobile
                    ? {
                          height: `${NAVBAR_HEIGHTS.MOBILE.STUCK}px`,
                      }
                    : {
                          height: `${NAVBAR_HEIGHTS.DESKTOP.STUCK}px`,
                      }),
            }
        case NAVBAR_STATES.HIDDEN:
            return {
                boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.1)',
                transform: `translateY(-100%)`,
            }

        default:
            return {}
    }
}

export function NavContainer({
    children,
    variant,
    ...props
}: { children: ReactNode; variant?: 'app' } & FlexProps) {
    const [navbarState, setNavbarState] = useState<ObjectValues<typeof NAVBAR_STATES>>(
        NAVBAR_STATES.INITIAL,
    )
    const isMobile = useBreakpointValue({ base: true, [NAVBAR_BREAKPOINT]: false })
    const { scrollDirection } = useScrollInfo()
    const style = useSpring({
        ...getNavbarAnimationStyles({ state: navbarState, isMobile }),
        config: { ...config.gentle, clamp: true },
    })

    const ref = useCallbackRef(null)
    const isStuck = useIsSticky(ref)

    const calculateNavbarState = useCallback(
        throttle(
            () =>
                requestAnimationFrame(() => {
                    const scrollY = window.pageYOffset // pageYOffset has wider browser support than scrollY
                    const isStuckAndNotAtTop = isStuck && scrollY > 0

                    // on desktops, set the navbar as "stuck" whenever the user scrolls the
                    // page down
                    if (!isMobile) {
                        setNavbarState(
                            isStuckAndNotAtTop ? NAVBAR_STATES.STUCK : NAVBAR_STATES.INITIAL,
                        )
                        return
                    }

                    // if the user scrolls DOWN, the mobile navbar is NOT set as `HIDDEN`, and
                    // the user scroll past the height of the navbar
                    const overMobileScrollThreshold = scrollY > NAVBAR_HEIGHTS.MOBILE.INITIAL

                    // mobile menu app variant
                    if (variant === 'app') {
                        if (isStuckAndNotAtTop && overMobileScrollThreshold) {
                            setNavbarState(NAVBAR_STATES.HIDDEN)
                        } else {
                            setNavbarState(NAVBAR_STATES.INITIAL)
                        }

                        return
                    }

                    if (
                        scrollDirection === SCROLL_DIRECTIONS.DOWN &&
                        isStuckAndNotAtTop &&
                        overMobileScrollThreshold &&
                        navbarState !== NAVBAR_STATES.HIDDEN
                    ) {
                        setNavbarState(NAVBAR_STATES.HIDDEN)
                    }

                    // if the user scrolls UP
                    if (scrollDirection === SCROLL_DIRECTIONS.UP) {
                        // if the user scroll to the very top of the page
                        if (
                            (!isStuckAndNotAtTop || !overMobileScrollThreshold) &&
                            navbarState !== NAVBAR_STATES.INITIAL
                        ) {
                            setNavbarState(NAVBAR_STATES.INITIAL)
                        }
                        // if the user scrolled UP and is below the navbar height
                        else if (isStuckAndNotAtTop && overMobileScrollThreshold) {
                            setNavbarState(NAVBAR_STATES.STUCK)
                        }
                    }
                }),
            100,
        ),
        [isMobile, isStuck, scrollDirection, navbarState],
    )

    useEffect(() => {
        window.addEventListener('scroll', calculateNavbarState, { passive: true })

        return () => window.removeEventListener('scroll', calculateNavbarState)
    }, [calculateNavbarState])

    const AnimatedFlex = animated(Flex)

    return (
        <Box
            display="grid"
            pos="sticky"
            top={0}
            left={0}
            right={0}
            zIndex={NAVBAR_Z_INDEX}
            pointerEvents="none"
        >
            <AnimatedFlex
                pointerEvents="auto"
                gridArea="1 / 1 / 2 / 2"
                align="center"
                px={[6, null, 10]}
                bgColor="#fff"
                // this makes sure that the navbar is render in the correct height on the first render
                // on subsequent renders, React Spring manages the height of the navbar (via the `style` prop)
                height={{
                    base: `${NAVBAR_HEIGHTS.MOBILE.INITIAL}px`,
                    lg: `${NAVBAR_HEIGHTS.DESKTOP.INITIAL}px`,
                }}
                style={style}
                {...props}
            >
                {children}
            </AnimatedFlex>
            {/* A menu spacer to prevent content being under the fixed navbar */}
            <Box
                ref={ref}
                gridArea="1 / 1 / 2 / 2"
                boxSizing="content-box"
                borderTop="1px solid transparent"
                mt="-1px"
                height={{
                    base: `${NAVBAR_HEIGHTS.MOBILE.INITIAL}px`,
                    lg: `${NAVBAR_HEIGHTS.DESKTOP.INITIAL}px`,
                }}
            />
        </Box>
    )
}
