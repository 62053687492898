import { ReactNode } from 'react'
import { Box, BoxProps, useStyleConfig } from '@chakra-ui/react'
import { MergeChakraProps } from '@upvestcz/common/ts-utils'

type Props<ElementProps> = MergeChakraProps<
    {
        children: ReactNode
        size?: 'full' | 'lg' // V3 only
    },
    ElementProps
>

const ContentContainer = <ElementProps extends BoxProps = BoxProps>({
    children,
    ...props
}: Props<ElementProps>) => {
    const styles = useStyleConfig('ContentContainer', props)

    return (
        <Box __css={styles} {...props}>
            {children}
        </Box>
    )
}

export default ContentContainer
