import React from 'react'
import { Menu, MenuProps } from '@chakra-ui/react'

export const LocaleDropdown = ({ children, ...props }: MenuProps) => {
    /*
    const textStyle = useSpring({
        opacity: isCollapsed ? 0 : 1,
        config: { ...config.gentle, clamp: true },
    })
     */

    return (
        <Menu placement="bottom-end" autoSelect={false} {...props}>
            {children}
        </Menu>
    )
}
