import momentLib from 'moment'
import momentTZLib from 'moment-timezone'

export const syncMomentLocales = (moment: typeof momentLib, momentTZ: typeof momentTZLib) => {
    // Need to copy over all locale config to the copy of moment that lives in moment-timezone
    // https://github.com/moment/moment-timezone/issues/647
    const installedLocales = momentTZ.locales()
    moment.locales().forEach(locale => {
        if (!installedLocales.includes(locale)) {
            const localeData = moment.localeData(locale)
            // eslint-disable-next-line no-underscore-dangle
            const localeSpec: momentTZLib.LocaleSpecification = (localeData as any)._config
            momentTZ.defineLocale(locale, localeSpec)
        }
    })
}
