import React from 'react'
import { useRouter } from 'next/router'
import { LOCALES_LABELS_SHORT } from '@upvestcz/common/i18n/locales'
import { MenuButton, MenuButtonProps, Text } from '@chakra-ui/react'
import * as icons from '../../icons'

export const LocaleDropdownButton = (props: MenuButtonProps) => {
    const router = useRouter()
    return (
        <MenuButton aria-label="Languages" data-test-id="locale-dropdown-toggle" {...props}>
            <Text display="inline-flex" alignItems="baseLine">
                {LOCALES_LABELS_SHORT[router.locale]}
                <icons.CaretIconDown ml={1} />
            </Text>
        </MenuButton>
    )
}
