import React from 'react'
import {
    Box,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalNavbar,
    Container,
    Text,
    VStack,
    Heading,
    Link,
    Button,
    Img,
    ColumnGrid,
    ColumnGridItem,
    ContentContainer,
    DashedLine,
    CopyToClipboard,
    Flex,
    useBreakpointValue,
    useTheme,
    ModalProps,
} from '@upvestcz/shared-components'
import { MergeChakraProps } from '@upvestcz/common/ts-utils'
import { chakraModalContentAnimationProps as animationProps } from '@upvestcz/shared-components/animations'
import { Trans, useTranslation } from 'react-i18next'
import { OPPORTUNITY_TYPE, OpportunityType } from '@upvestcz/common/opportunities-utils'
import { useAccount } from '../../store/account'
import { getReferralLink } from '../../lib/account'
import afterInvestInfographicDesktop from '../../images/after-invest-infographic_desktop.svg'
import afterInvestInfographicMobile from '../../images/after-invest-infographic_mobile.svg'
import ProfileReferralGiftCard from '../ProfileReferralGiftCard'
import ReferralShareButtons from '../ReferralShareButtons'
import logger from '../../lib/logger'
import { useToast } from '../../lib/toast'

export const InvestmentThanksModal = ({
    isOpen,
    onClose: onCloseProp,
    variant,
    ...props
}: MergeChakraProps<
    {
        isOpen: boolean
        onClose: () => void
        children?: never
        variant: OpportunityType
    },
    ModalProps
>) => {
    const [account] = useAccount<true>()
    const referralHref = getReferralLink(account)
    const theme = useTheme()
    const gutter = useBreakpointValue(theme.sizes.gutter)
    const { t } = useTranslation()
    const addToast = useToast()

    const styles = {
        GridRow: {
            minWidth: '0',
            cols: [10, null, null, 8],
            offset: [0, null, null, 1],
            mx: [0, null, null, `calc(-1 * ${gutter})`],
        },
        Image: {
            mx: 'auto',
        },
        MaxWidthAndCenteredForTablet: {
            maxWidth: { base: '500px', lg: 'none' },
            mx: { base: 'auto', lg: 0 },
        },
    }

    const onClose = () => {
        onCloseProp()
    }

    const isEquity = variant === OPPORTUNITY_TYPE.EQUITY

    return (
        <Modal size="full" isOpen={isOpen} onClose={onClose} scrollBehavior="inside" {...props}>
            <ModalContent {...animationProps}>
                <Box flex={0}>
                    <ModalNavbar
                        onLogoClick={onClose}
                        navRight={
                            <ModalCloseButton size="xl" top={[3, null, 5]} right={[4, null, 10]} />
                        }
                    />
                </Box>
                <Container
                    pb={['80px', null, '120px']}
                    px={0}
                    display="flex"
                    flexFlow="column nowrap"
                    as={ModalBody}
                    backgroundAttachment="scroll"
                >
                    <ContentContainer>
                        <ColumnGrid columns={10}>
                            <ColumnGridItem {...styles.GridRow} textAlign="center">
                                <Heading size="lg" mb={4}>
                                    {t('Investice proběhla úspěšně')}
                                </Heading>
                                <VStack>
                                    <Box mb={!isEquity ? 6 : 0}>
                                        <Text textStyle="infoText">
                                            {!isEquity
                                                ? t(
                                                      'Na váš e-mail jsme poslali potvrzení o provedení této investice',
                                                  )
                                                : t(
                                                      'Na váš e-mail obdržíte potvrzení o provedení této investice',
                                                  )}
                                        </Text>
                                    </Box>
                                    {!isEquity && (
                                        <>
                                            <Box mb={10}>
                                                <Text textStyle="infoText">
                                                    <Trans t={t}>
                                                        Tímto jste získali{' '}
                                                        <Link
                                                            color="primary"
                                                            to="/faq#co_je_to_podminena_participace"
                                                        >
                                                            Podmíněnou participaci.
                                                        </Link>
                                                    </Trans>
                                                </Text>
                                                <Text textStyle="infoText">
                                                    <Trans t={t}>
                                                        Jak fundraising{' '}
                                                        <Link
                                                            color="primary"
                                                            to="/faq#co_se_deje_po_provedene_investici"
                                                        >
                                                            probíhá dál?
                                                        </Link>
                                                    </Trans>
                                                </Text>
                                            </Box>
                                            <Box width="100%">
                                                <Img
                                                    {...styles.Image}
                                                    display={{ base: 'none', md: 'block' }}
                                                    maxWidth="642px"
                                                    {...afterInvestInfographicDesktop}
                                                    alt={t('Jak fundraising probíhá dál?')}
                                                />
                                                <Img
                                                    {...styles.Image}
                                                    display={{ base: 'block', md: 'none' }}
                                                    maxWidth="330px"
                                                    {...afterInvestInfographicMobile}
                                                    alt={t('Jak fundraising probíhá dál?')}
                                                />
                                            </Box>
                                        </>
                                    )}
                                </VStack>
                            </ColumnGridItem>
                            {!isEquity && (
                                <>
                                    <ColumnGridItem {...styles.GridRow}>
                                        <DashedLine my={{ base: 10, lg: 14 }} />
                                    </ColumnGridItem>
                                    <ColumnGridItem {...styles.GridRow}>
                                        <ColumnGrid>
                                            <ColumnGridItem
                                                minWidth="0"
                                                cols={[10, null, null, 6]}
                                                offset={[0, null, null, 1]}
                                                ml={[0, null, null, `calc(-1 * ${gutter})`]}
                                            >
                                                <Text
                                                    {...styles.MaxWidthAndCenteredForTablet}
                                                    textStyle="heading5"
                                                    mb={4}
                                                >
                                                    {t(
                                                        'Pozvěte své známé a získejte 500 Kč na další investici',
                                                    )}
                                                </Text>
                                            </ColumnGridItem>
                                            <ColumnGridItem
                                                minWidth="0"
                                                cols={[10, null, null, 3]}
                                                offset={[0, null, null, 1]}
                                                ml={[0, null, null, `calc(-1 * ${gutter})`]}
                                            >
                                                <ProfileReferralGiftCard
                                                    {...styles.MaxWidthAndCenteredForTablet}
                                                />
                                            </ColumnGridItem>
                                            <ColumnGridItem
                                                minWidth="0"
                                                cols={[10, null, null, 3]}
                                                offset={[0, null, null, 4]}
                                                mr={[0, null, null, `calc(-1 * ${gutter})`]}
                                                mt={[8, null, null, 0]}
                                            >
                                                <Box {...styles.MaxWidthAndCenteredForTablet}>
                                                    <Text>{t('Odkaz')}</Text>
                                                    <VStack alignItems="flex-start">
                                                        <CopyToClipboard
                                                            value={referralHref}
                                                            context={{ t, addToast, logger }}
                                                        >
                                                            <Text
                                                                fontWeight="bold"
                                                                data-test-id="referral-link"
                                                            >
                                                                {referralHref}
                                                            </Text>
                                                            <Text
                                                                textStyle="buttonTextSm"
                                                                color="primary"
                                                                mt={3}
                                                            >
                                                                {t('Kopírovat odkaz')}
                                                            </Text>
                                                        </CopyToClipboard>
                                                        <Flex
                                                            alignItems="center"
                                                            mt={{ base: 4, lg: 2 }}
                                                            flexWrap="wrap"
                                                        >
                                                            <Text mr={4} mt={2}>
                                                                {t('nebo sdílejte na')}
                                                            </Text>
                                                            <ReferralShareButtons
                                                                mt={2}
                                                                referralHref={referralHref}
                                                            />
                                                        </Flex>
                                                    </VStack>
                                                </Box>
                                            </ColumnGridItem>
                                        </ColumnGrid>
                                    </ColumnGridItem>
                                </>
                            )}
                            <ColumnGridItem
                                {...styles.GridRow}
                                mt={{ base: '40px', lg: '80px' }}
                                textAlign="center"
                            >
                                <Button
                                    w={['100%', 'auto']}
                                    mx="auto"
                                    onClick={onClose}
                                    data-test-id="button-hide-box"
                                >
                                    {t('Zpět na Přehled')}
                                </Button>
                            </ColumnGridItem>
                        </ColumnGrid>
                    </ContentContainer>
                </Container>
            </ModalContent>
        </Modal>
    )
}
