import { ObjectKeys } from './ts-utils'

export const CZECH_BANK_CODES = {
    '0100': 'Komerční banka, a.s.',
    '0300': 'Československá obchodní banka, a. s.',
    '0600': 'MONETA Money Bank, a.s.',
    '0710': 'ČESKÁ NÁRODNÍ BANKA',
    '0800': 'Česká spořitelna, a.s.',
    2010: 'Fio banka, a.s.',
    2020: 'MUFG Bank (Europe) N.V. Prague Branch',
    2030: 'Československé úvěrní družstvo',
    2060: 'Citfin, spořitelní družstvo',
    2070: 'TRINITY BANK a.s.',
    2100: 'Hypoteční banka, a.s.',
    2200: 'Peněžní dům, spořitelní družstvo',
    2220: 'Artesa, spořitelní družstvo',
    2240: 'Poštová banka, a.s., pobočka Česká republika',
    2250: 'Banka CREDITAS a.s.',
    2260: 'NEY spořitelní družstvo',
    2275: 'Podnikatelská družstevní záložna',
    2600: 'Citibank Europe plc, organizační složka',
    2700: 'UniCredit Bank Czech Republic and Slovakia, a.s.',
    3030: 'Air Bank a.s.',
    3050: 'BNP Paribas Personal Finance SA, odštěpný závod',
    3060: 'PKO BP S.A., Czech Branch',
    3500: 'ING Bank N.V.',
    4000: 'Max banka a.s.', // It is CZ bank, but for some reason sometimes it had has SK iban. Also, it was called Expobank before
    4300: 'Českomoravská záruční a rozvojová banka, a.s.',
    5500: 'Raiffeisenbank a.s.',
    5800: 'J & T BANKA, a.s.',
    6000: 'PPF banka a.s.',
    6100: 'Equa bank a.s.',
    6200: 'COMMERZBANK Aktiengesellschaft, pobočka Praha',
    6210: 'mBank S.A., organizační složka',
    6300: 'BNP Paribas S.A., pobočka Česká republika',
    6363: 'Partners Banka, a.s.',
    6700: 'Všeobecná úverová banka a.s., pobočka Praha',
    6800: 'Sberbank CZ, a.s.',
    7910: 'Deutsche Bank Aktiengesellschaft Filiale Prag, organizační složka',
    7940: 'Waldviertler Sparkasse Bank AG',
    7950: 'Raiffeisen stavební spořitelna a.s.',
    7960: 'Českomoravská stavební spořitelna, a.s.',
    7970: 'Wüstenrot - stavební spořitelna a.s.',
    7980: 'Wüstenrot hypoteční banka a.s.',
    7990: 'Modrá pyramida stavební spořitelna, a.s.',
    8030: 'Volksbank Raiffeisenbank Nordoberpfalz eG pobočka Cheb',
    8040: 'Oberbank AG pobočka Česká republika',
    8060: 'Stavební spořitelna České spořitelny, a.s.',
    8090: 'Česká exportní banka, a.s.',
    8150: 'HSBC France - pobočka Praha',
    8190: 'Sparkasse Oberlausitz-Niederschlesien',
    8198: 'FAS finance company s.r.o.',
    8199: 'MoneyPolo Europe s.r.o.',
    8200: 'PRIVAT BANK der Raiffeisenlandesbank Oberösterreich Aktiengesellschaft, pobočka Česká republika',
    8215: 'ALTERNATIVE PAYMENT SOLUTIONS, s.r.o.',
    8220: 'Payment execution s.r.o.',
    8225: 'ORANGETRUST s.r.o.',
    8230: 'EEPAYS s.r.o.',
    8240: 'Družstevní záložna Kredit',
    8250: 'Bank of China (CEE) Ltd. Prague Branch',
    8255: 'Bank of Communications Co., Ltd., Prague Branch odštěpný závod',
    8260: 'PAYMASTER a.s.',
    8265: 'Industrial and Commercial Bank of China Limited, Prague Branch, odštěpný závod',
    8270: 'Fairplay Pay s.r.o.',
    8272: 'VIVA PAYMENT SERVICES S.A. odštěpný závod',
    8280: 'B-Efekt a.s.',
    8283: 'Qpay s.r.o.',
    8291: 'Business Credit s.r.o.',
    8292: 'Money Change s.r.o.',
    8293: 'Mercurius partners s.r.o.',
    8294: 'GrisPayUnion s.r.o.',
    8296: 'PaySysEU s.r.o.',
}

export type CzechBankCode = ObjectKeys<typeof CZECH_BANK_CODES>

export const SLOVAK_BANK_CODES = {
    '0200': 'Všeobecná úverová banka, a.s.',
    '0900': 'Slovenská sporiteľňa, a.s.',
    '0720': 'Národná banka Slovenska',
    1100: 'Tatra banka, a.s.',
    1111: 'UniCredit Bank Czech Republic and Slovakia, a.s.',
    3000: 'Slovenská záručná a rozvojová banka, a.s.',
    3100: 'Prima banka Slovensko, a.s. (predtým Sberbank Slovensko, a.s.)',
    5200: 'OTP Banka Slovensko, a.s. ',
    5600: 'Prima banka Slovensko, a.s.',
    5900: 'Prvá stavebná sporiteľňa, a.s.',
    6500: 'Poštová banka, a.s.',
    7300: 'ING Bank N.V.',
    7500: 'Československá obchodná banka, a.s.',
    7930: 'Wüstenrot stavebná sporiteľňa, a.s.',
    8020: 'Credit Lyonnais (CALYON S.A.)',
    8050: 'Commerzbank Aktiengesellschaft,',
    8100: 'Komerční banka, a.s.',
    8120: 'Privatbanka, a.s.',
    8130: 'Citibank Europe plc',
    8170: 'ČSOB stavebná sporiteľňa, a.s.',
    8160: 'EXIMBANKA SR',
    8180: 'Štátna pokladnica',
    8191: 'Centrálny depozitár cenných papierov SR, a.s.',
    8400: 'COFIDIS SA',
    8300: 'HSBC Bank plc',
    8320: 'J&T BANKA, a.s.',
    8330: 'Fio banka, a.s.',
    8340: 'UNIBON - spořitelní a uvěrní družstvo',
    8350: 'The Royal Bank of Scotland N.V.',
    8360: 'mBank S.A.',
    8370: 'Oberbank AG',
    8390: 'Československé úvěrní družstvo, pobočka Slovensko',
    8410: 'ZUNO BANK AG',
    8420: 'BKS Bank AG v SR',
    8430: 'KDB Bank Europe Ltd.	',
    9550: 'First Data Slovakia, s.r.o.',
    9950: 'SIA Slovakia, s.r.o.',
    9952: 'Trust Pay, a.s.',
    9953: 'Payment Institution NFD, a.s.',
    9954: 'K-PAY a. s.',
    9955: 'Unifiedpost Payments, organizačná zložka',
    8440: 'BNP PARIBAS PERSONAL FINANCE SA',
    8450: 'PKO BP S.A.',
}

export type SlovakBankCode = ObjectKeys<typeof SLOVAK_BANK_CODES>

export const acceptedBankCodes = [
    ...Object.keys(CZECH_BANK_CODES),
    ...Object.keys(SLOVAK_BANK_CODES),
]
