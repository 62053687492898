import { gql } from '@apollo/client'

export const ProfileFields = gql`
    fragment ProfileFields on Profile {
        sub
        email
        nickname
        name
        picture
        updated_at
        email_verified
        user_metadata {
            affiliateKey
            referralCode
            privOpportunityID
        }
        roles
        identities
    }
`

export const AuthTokenFields = gql`
    fragment AuthTokenFields on AuthToken {
        id_token
        access_token
    }
`

export const AuthResultFields = gql`
    fragment AuthResultFields on AuthResult {
        profile {
            ...ProfileFields
        }
        token {
            ...AuthTokenFields
        }
    }
    ${ProfileFields}
    ${AuthTokenFields}
`
