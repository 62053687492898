import { useCookieConsentContext } from '@use-cookie-consent/react'
import { useCallback, useEffect } from 'react'
import ReactGA from 'react-ga4'
import ReactPixel from 'react-facebook-pixel'

// used in blog and lp, app has its own
export const Tracking = () => {
    const { consent } = useCookieConsentContext()

    useEffect(() => {
        if (process.env.GA_TRACKING_CODE) {
            ReactGA.gtag('consent', 'default', {
                ad_storage: consent.marketing ? 'granted' : 'denied',
                analytics_storage: consent.statistics ? 'granted' : 'denied',
                ad_personalization: consent.marketing ? 'granted' : 'denied',
                ad_user_data: consent.marketing ? 'granted' : 'denied',
                security_storage: consent.necessary ? 'granted' : 'denied',
                functionality_storage: consent.necessary ? 'granted' : 'denied',
            })
            // clasified as first-party.
            ReactGA.initialize(process.env.GA_TRACKING_CODE)
            /* later update: */
            setTimeout(() => {
                ReactGA.gtag('consent', 'update', {
                    ad_storage: 'granted',
                    analytics_storage: 'granted',
                    ad_personalization: 'granted',
                    ad_user_data: 'granted',
                })
            }, 1000)
        }

        // followed this example
        // https://support.cookiefirst.com/hc/en-us/articles/5166697908113-Facebook-Meta-GDPR-consent
        if (process.env.FB_PIXEL_ID) {
            // Init fb pixel.
            ReactPixel.fbq('consent', 'revoke')
            ReactPixel.init(process.env.FB_PIXEL_ID)
            ReactPixel.fbq('track', 'PageView')
        }
    }, [])

    useEffect(() => {
        ReactGA.gtag('consent', 'update', {
            ad_storage: consent.marketing ? 'granted' : 'denied',
            analytics_storage: consent.statistics ? 'granted' : 'denied',
            ad_personalization: consent.marketing ? 'granted' : 'denied',
            ad_user_data: consent.marketing ? 'granted' : 'denied',
            security_storage: consent.necessary ? 'granted' : 'denied',
            functionality_storage: consent.necessary ? 'granted' : 'denied',
        })
    }, [consent.marketing, consent.necessary, consent.statistics]) // react to any consent change

    useEffect(() => {
        if (consent.marketing) {
            ReactPixel.fbq('consent', 'grant')
        }
    }, [consent.marketing])

    useEffect(() => {
        if (consent.statistics) {
            // nothing to do here for now.
        }
    }, [consent.statistics])

    return null
}

export function useSatisMeter() {
    useEffect(() => {
        window.satismeter =
            window.satismeter ||
            function () {
                // eslint-disable-next-line prefer-rest-params
                ;(window.satismeter!.q = window.satismeter!.q || []).push(arguments)
            }
        // @ts-ignore
        window.satismeter.l = 1 * new Date()
    })

    const satismeter = useCallback<(...args: any[]) => void>(function (...args) {
        // @ts-ignore
        // eslint-disable-next-line prefer-rest-params
        window.satismeter?.(...args)
    }, [])

    // firing an event: satismeter('track', { event: 'The Event Name' })
    const initSatismeter = useCallback(
        (
            writeKey: string,
            account: {
                id: number
                email: string
                investments_sum: number
                investments_eur_sum: number
                sign_date: string | null
                kb_flag: boolean
            },
        ) => {
            const { email, investments_sum, investments_eur_sum, sign_date, kb_flag } = account
            satismeter({
                writeKey,
                userId: account.id,
                traits: {
                    email,
                    investments_sum,
                    investments_eur_sum,
                    kb_flag,
                    sign_date,
                },
            })
        },
        [satismeter],
    )

    return [satismeter, initSatismeter]
}
