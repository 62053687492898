import moment, { MomentInput } from 'moment'
import { Decimal } from 'decimal.js-light'
import { TFunction } from 'i18next'
import * as R from 'ramda'
import {
    isNotNull,
    isNotUndefined,
    NotUndefined,
    ObjectKeys,
    ObjectValues,
    PickDefined,
} from './ts-utils'
import { diffInMonths } from './date-utils'
import { Locale, LOCALES } from './i18n/locales'
import { formatInterest } from './i18n/formatters'
import { CURRENCIES, Currency } from './currency'
import { ReferenceRateType } from './rate-types'
import { OpportunityStatus as GraphQLOpportunityStatus } from './graphql/typegen'
import { Prisma } from '.prisma/client'
import { isAccountInvestmentClubMember, isAccountPrivMember } from './account-utils'

// equity data as saved in DB
export type OpportunityEquityData = {
    pricePerShare: number
    stage: string
    withOvernightAgreement?: boolean
    accounts: Record<
        number,
        | ({
              fullName: string
              amount: number
              assetAccount: string
              noOfShares: number
              additionalPaymentAmount: number
          } & {
              [key: string]: number // for equityCall1, equityCall2 etc.
          })
        | undefined
    >
}

// transformed data for a particular user as served by the GraphQL endpoint
export type OpportunityEquityUserData = Omit<OpportunityEquityData, 'accounts'> & {
    investmentData: Maybe<NotUndefined<OpportunityEquityData['accounts'][number]>> // undefined gets cast to null
}

export const getExpectedMaturityMonths = (opportunity: {
    fundraising_period_start: moment.MomentInput
    expected_maturity: moment.MomentInput
    maturity: moment.MomentInput
}) =>
    Math.floor(
        diffInMonths(
            opportunity.fundraising_period_start,
            opportunity.maturity || opportunity.expected_maturity,
        ),
    )

export const OPPORTUNITY_PAYMENT_FREQUENCY = {
    MONTHLY: 'monthly',
    QUARTERLY: 'quarterly',
    BIYEARLY: 'biyearly',
    ANNUALLY: 'annually',
    UPON_MATURITY: 'upon_maturity',
    PRVNIHO_PLUKU: 'bridge_prvniho_pluku',
} as const

export type OpportunityPaymentFrequency = ObjectValues<typeof OPPORTUNITY_PAYMENT_FREQUENCY>
export const OPPORTUNITY_MARKET_SEGMENT = {
    RESIDENTAL: 'residential',
    OFFICE: 'office',
    RETAIL: 'retail',
    LOGISTICS: 'logistics',
} as const

export type OpportunityMarketSegment = ObjectValues<typeof OPPORTUNITY_MARKET_SEGMENT>

export const OPPORTUNITY_MARKET_SEGMENT_LABELS_EN = {
    [OPPORTUNITY_MARKET_SEGMENT.RESIDENTAL]: 'Residential',
    [OPPORTUNITY_MARKET_SEGMENT.OFFICE]: 'Office',
    [OPPORTUNITY_MARKET_SEGMENT.RETAIL]: 'Retail',
    [OPPORTUNITY_MARKET_SEGMENT.LOGISTICS]: 'Logistics',
} as const

export const OPPORTUNITY_MARKET_SEGMENT_LABELS = {
    [OPPORTUNITY_MARKET_SEGMENT.RESIDENTAL]: 'Residenční',
    [OPPORTUNITY_MARKET_SEGMENT.OFFICE]: 'Kancelářský',
    [OPPORTUNITY_MARKET_SEGMENT.RETAIL]: 'Maloobchodní',
    [OPPORTUNITY_MARKET_SEGMENT.LOGISTICS]: 'Logistický',
} as const

export const getOpportunityMarketSegmentLabel = (
    type: OpportunityMarketSegment,
    locale: Locale,
) => {
    return (
        locale === LOCALES.CS
            ? OPPORTUNITY_MARKET_SEGMENT_LABELS
            : OPPORTUNITY_MARKET_SEGMENT_LABELS_EN
    )[type]
}

export const OPPORTUNITY_STATUS = {
    OPEN: 'open',
    RAISED: 'raised',
    LOAN_PROVIDED: 'loan_provided',
    LOAN_PAID_OFF: 'loan_paid_off',
    ARCHIVED: 'archived',
    MIN_RAISED: 'min_raised',
} as const

export type OpportunityStatus = ObjectValues<typeof OPPORTUNITY_STATUS> | GraphQLOpportunityStatus

export const OPPORTUNITY_INTEREST_RATE_TYPE = {
    SIMPLE: 'simple',
    COMPOUND: 'compound',
} as const
export type OpportunityInterestRateType = ObjectValues<typeof OPPORTUNITY_INTEREST_RATE_TYPE>

export const OPPORTUNITY_TYPE = {
    UPVEST_LOAN_JUNIOR: 'upvest_loan_junior',
    UPVEST_LOAN_SENIOR: 'upvest_loan_senior',
    EQUITY: 'equity',
    BANK_LOAN_PORTFOLIO: 'bank_loan_portfolio',
} as const
export type OpportunityType = ObjectValues<typeof OPPORTUNITY_TYPE>

export const OPPORTUNITY_TYPE_LABELS_EN = {
    [OPPORTUNITY_TYPE.UPVEST_LOAN_JUNIOR]: 'Debt investment',
    [OPPORTUNITY_TYPE.UPVEST_LOAN_SENIOR]: 'Debt investment (senior)',
    [OPPORTUNITY_TYPE.EQUITY]: 'Equity investment',
    [OPPORTUNITY_TYPE.BANK_LOAN_PORTFOLIO]: 'Bank loan portfolio',
} as const

export const OPPORTUNITY_TYPE_LABELS = {
    [OPPORTUNITY_TYPE.UPVEST_LOAN_JUNIOR]: 'Dluhová investice',
    [OPPORTUNITY_TYPE.UPVEST_LOAN_SENIOR]: 'Dluhová investice (seniorní)',
    [OPPORTUNITY_TYPE.EQUITY]: 'Podílová investice',
    [OPPORTUNITY_TYPE.BANK_LOAN_PORTFOLIO]: 'Úvěr KB',
} as const

export const getOpportunityTypeLabel = (type: OpportunityType, locale: Locale) => {
    return (locale === LOCALES.CS ? OPPORTUNITY_TYPE_LABELS : OPPORTUNITY_TYPE_LABELS_EN)[type]
}

export type OpportunityDeveloperInfo = {
    logoImage: string
    ico: string
    name: string
    address: string
    registeredBy: string
    markNo: string
}

// item in cashflow model array - usually representing one month
export type CashflowItemType = {
    date: string
    cashflow: number | Decimal
    interest: number | Decimal
    amortization: number | Decimal
    final_payment: number | Decimal
}

export type CashflowVariables = ObjectKeys<CashflowItemType>

// type for cashflow model array - timeline of cashflow items
export type CashflowModelType = Array<CashflowItemType>

// represents type of object for given interest rate in cashflow model JSON
export type CashflowObjectType = {
    irr: number
    cashflow_model: CashflowModelType
}

export const getRaisedTotal = (opportunity: {
    raised: number
    raised_eur: number
    eur_exchange_rate?: Maybe<number>
    currencies: Currency[]
}) =>
    getOpportunityPrimaryCurrency(opportunity) === CURRENCIES.EUR
        ? opportunity.raised_eur
        : new Decimal(opportunity.raised)
              .plus(
                  new Decimal(opportunity.raised_eur || 0).times(
                      opportunity.eur_exchange_rate || 0,
                  ),
              )
              .toNumber()

export const getMaxInvestment = (
    opportunity: {
        max_investment: number
        max_fundraising_target: number
    } & Parameters<typeof getRaisedTotal>[0],
) =>
    Math.max(
        Math.min(
            opportunity.max_investment,
            new Decimal(opportunity.max_fundraising_target)
                .minus(getRaisedTotal(opportunity))
                .toNumber(),
        ),
        0,
    )

export const getMinInvestment = (
    opportunity: { min_investment: number } & Parameters<typeof getMaxInvestment>[0],
) => Math.min(opportunity.min_investment, getMaxInvestment(opportunity))

// lifecycle states

export const isFundraisingOpen = (opportunity: {
    status: OpportunityStatus
    fundraising_period_end: moment.MomentInput
}) =>
    (opportunity.status === OPPORTUNITY_STATUS.OPEN ||
        opportunity.status === OPPORTUNITY_STATUS.MIN_RAISED) &&
    !moment().isAfter(opportunity.fundraising_period_end)

export const isFundraisingStarted = (
    opportunity: {
        fundraising_period_start: moment.MomentInput
    } & Parameters<typeof isFundraisingOpen>[0],
) =>
    isFundraisingOpen(opportunity) &&
    moment(opportunity.fundraising_period_start).isSameOrBefore(moment())

export const isRaised = (
    opportunity: { status: OpportunityStatus; min_fundraising_target: number } & Parameters<
        typeof isFundraisingOpen
    >[0] &
        Parameters<typeof getRaisedTotal>[0],
) => {
    // the OR clause solves a case when onnly min investment target is reached and it's not therefore fully raised.
    return (
        opportunity.status === OPPORTUNITY_STATUS.RAISED ||
        (opportunity.status === OPPORTUNITY_STATUS.MIN_RAISED && !isFundraisingOpen(opportunity))
    )
}

export const isFundraisingFailed = (
    opportunity: { min_fundraising_target: number } & Parameters<typeof getRaisedTotal>[0] &
        Parameters<typeof isFundraisingOpen>[0],
) => !isFundraisingOpen(opportunity) && opportunity.status === OPPORTUNITY_STATUS.OPEN

export const isPaidOff = (opportunity: { status: OpportunityStatus }) =>
    opportunity.status === OPPORTUNITY_STATUS.LOAN_PAID_OFF

// investment club

export const isInvestmentClubOpen = (opportunity: {
    status: OpportunityStatus
    ik_fundraising_period_end: moment.MomentInput | null
}) =>
    (opportunity.status === OPPORTUNITY_STATUS.OPEN ||
        opportunity.status === OPPORTUNITY_STATUS.MIN_RAISED) &&
    !moment().isAfter(opportunity.ik_fundraising_period_end ?? undefined)

export const shouldInvestmentClubApply = (
    opportunity: {
        status: OpportunityStatus
        ik_fundraising_period_end: moment.MomentInput | null
    },
    account: {
        is_club_member: boolean
        kb_flag: boolean
    },
) => {
    return (
        account.kb_flag || // if KB client, apply always
        (account.is_club_member && isInvestmentClubOpen(opportunity))
    )
}

export const shouldPrivApply = (
    opportunity: {
        priv_interest_rate: number | null
        priv_fees_agreement: string | null
    },
    account: {
        is_priv_member?: boolean
    },
): opportunity is PickDefined<typeof opportunity, 'priv_interest_rate' | 'priv_fees_agreement'> => {
    return (
        !!account.is_priv_member &&
        !!opportunity.priv_interest_rate &&
        !!opportunity.priv_fees_agreement
    )
}

export const getPayoutFrequencyText = ({
    frequency,
    t,
}: {
    frequency: OpportunityPaymentFrequency
    t: TFunction
}) => {
    switch (frequency) {
        case OPPORTUNITY_PAYMENT_FREQUENCY.MONTHLY:
            return t('Úroky vypláceny měsíčně')
        case OPPORTUNITY_PAYMENT_FREQUENCY.QUARTERLY:
            return t('Úroky vypláceny čtvrtletně')
        case OPPORTUNITY_PAYMENT_FREQUENCY.BIYEARLY:
            return t('Úroky vypláceny pololetně')
        case OPPORTUNITY_PAYMENT_FREQUENCY.UPON_MATURITY:
            return t('Úroky vypláceny po skončení projektu')
        case OPPORTUNITY_PAYMENT_FREQUENCY.ANNUALLY:
            return t('Úroky vypláceny ročně')
        case OPPORTUNITY_PAYMENT_FREQUENCY.PRVNIHO_PLUKU:
            return t('Úroky vypláceny specificky podle smlouvy Prvního Pluku')
        default:
            throw new Error(`Unexpected payout frequency value: ${frequency}`)
    }
}

export const RIBBON_STATES = {
    OPEN: 'OPEN',
    OPEN_MIN_REACHED: 'OPEN_MIN_REACHED',
    RAISED: 'RAISED',
    FAILED: 'FAILED',
    PAID_OFF: 'PAID_OFF',
} as const

export type RibbonState = ObjectValues<typeof RIBBON_STATES>

const getRibbonStateText = ({ state, t }: { state: RibbonState; t: TFunction }) => {
    switch (state) {
        case RIBBON_STATES.OPEN:
            return t('Fundraising probíhá')
        case RIBBON_STATES.OPEN_MIN_REACHED:
            return t('Minimální investiční cíl splněn')
        case RIBBON_STATES.RAISED:
            return t('Úspěšně zafinancováno')
        case RIBBON_STATES.FAILED:
            return t('Neúspěšný fundraising')
        case RIBBON_STATES.PAID_OFF:
            return t('Investice splacena')
        default:
            throw new Error(`Unexpected ribbon state value: ${state}`)
    }
}

export const getStatusLabel = ({
    opportunity,
    possibleStates = Object.values(RIBBON_STATES),
    t,
}: {
    opportunity: {
        min_fundraising_target: number
        max_fundraising_target: number
        raised: number
    } & Parameters<typeof isPaidOff>[0] &
        Parameters<typeof isFundraisingOpen>[0] &
        Parameters<typeof isRaised>[0] &
        Parameters<typeof isFundraisingFailed>[0]
    possibleStates?: RibbonState[]
    t: TFunction
}) => {
    const ribbonState = (() => {
        if (isPaidOff(opportunity)) return RIBBON_STATES.PAID_OFF
        if (isFundraisingOpen(opportunity)) {
            if (opportunity.status === OPPORTUNITY_STATUS.MIN_RAISED)
                return RIBBON_STATES.OPEN_MIN_REACHED
            return RIBBON_STATES.OPEN
        }
        if (isRaised(opportunity)) return RIBBON_STATES.RAISED
        if (isFundraisingFailed(opportunity)) return RIBBON_STATES.FAILED
        return null
    })() as RibbonState
    // return null if label is not in the allowed list.
    return possibleStates.includes(ribbonState)
        ? getRibbonStateText({ state: ribbonState, t })
        : null
}

export const getLocaleTitle = ({
    opportunity,
    locale = LOCALES.CS,
}: {
    opportunity: { title: string; title_en: string }
    locale: Locale
}) => {
    return locale === LOCALES.CS ? opportunity.title : opportunity.title_en
}

export const getLocaleSubtitle = ({
    opportunity,
    locale = LOCALES.CS,
}: {
    opportunity: { subtitle?: string | null; subtitle_en?: string | null }
    locale: Locale
}) => (locale === LOCALES.CS ? opportunity.subtitle : opportunity.subtitle_en)

export const getFirstAndLastInterestRates = (interestRates: InterestRates) => {
    const rates = Object.values(interestRates)
    const first = rates[0]
    const ikRate = rates.slice(0, 2).pop()!
    const last = rates[rates.length - 1]

    return { first, ikRate, last }
}

export const getShownInterestRate = (interest_rates: InterestRates) => {
    const { first, last } = getFirstAndLastInterestRates(interest_rates)

    return formatInterest(first, last)
}

export const getOpportunityPrimaryCurrency = (opportunity: { currencies: Currency[] }) =>
    opportunity.currencies?.[0] || CURRENCIES.CZK

export const isEquityOpportunity = (opportunity: { type: OpportunityType }) =>
    opportunity.type === OPPORTUNITY_TYPE.EQUITY

export const isKBPortfolioOpportunity = (opportunity: { type: OpportunityType }) =>
    opportunity.type === OPPORTUNITY_TYPE.BANK_LOAN_PORTFOLIO

export const hasCashflowModel = <
    T extends { cashflow_model?: Record<string, Maybe<CashflowObjectType>> },
>(
    opportunity: T,
): opportunity is PickDefined<T, 'cashflow_model'> =>
    isNotNull(opportunity.cashflow_model) &&
    isNotUndefined(opportunity.cashflow_model) &&
    !R.isEmpty(opportunity.cashflow_model)

export const getOpportunityCurrentReferenceRate = (
    opportunity: Parameters<typeof isOpportunityWithFloatInterestRate>[0],
) => {
    const { reference_rates } = opportunity
    return isOpportunityWithFloatInterestRate(opportunity)
        ? reference_rates?.[0]?.rate || opportunity.current_reference_rate || 0
        : 0
}

export const getComputedFloatInterestRates = (
    opportunity: Parameters<typeof getOpportunityCurrentReferenceRate>[0] & {
        interest_rates: Record<string, number>
        cashflow_model?: Record<string, Maybe<CashflowObjectType>>
        type: OpportunityType
    },
): Record<string, number> => {
    const { cashflow_model } = opportunity
    if (hasCashflowModel(opportunity)) {
        const res: Record<string, number> = {}

        Object.entries(opportunity.interest_rates).forEach(([key, value]) => {
            const cashflowObject = getCashflowObjectByInterestRate(cashflow_model!, value)

            if (!cashflowObject) {
                throw new Error(`Missing cashflow object for interest rate ${value}`)
            }

            res[key] = cashflowObject.irr
        })

        return res
    }

    const res = R.mapObjIndexed(
        (val: string | number) =>
            new Decimal(val).add(getOpportunityCurrentReferenceRate(opportunity)).toNumber(),
        opportunity.interest_rates,
    )

    return res
}

export const renderInterestRate = (opportunity: {
    type: OpportunityType
    interest_rates: Record<string, number>
    cashflow_model?: Record<string, Maybe<CashflowObjectType>>
    current_reference_rate?: Maybe<number> // for Opportunity from REST API
    reference_rate_type: Maybe<ReferenceRateType>
    reference_rates?: Maybe<any[]> // for Opportunity from GraphQL API
}) => {
    return isOpportunityWithFloatInterestRate(opportunity) ||
        isKBPortfolioOpportunity(opportunity) ||
        hasCashflowModel(opportunity)
        ? getShownInterestRate(getComputedFloatInterestRates(opportunity))
        : getShownInterestRate(opportunity.interest_rates)
}

export const isOpportunityWithFloatInterestRate = <
    IOpportunity extends {
        reference_rate_type: Maybe<ReferenceRateType>
        current_reference_rate?: Maybe<number> // for Opportunity from REST API
        reference_rates?: Maybe<any[]> // for Opportunity from GraphQL API
    },
>(
    opportunity: IOpportunity,
): opportunity is PickDefined<
    IOpportunity,
    'reference_rate_type' | 'current_reference_rate' | 'reference_rates'
> => isNotUndefined(opportunity.reference_rate_type) && isNotNull(opportunity.reference_rate_type)

export const getCashflowBasePrincipal = (cashflow_model: CashflowModelType): number => {
    if (!cashflow_model) throw new Error('Cashflow model is not defined')

    return new Decimal(cashflow_model[0].cashflow).abs().toNumber()
}

export const getCashflowMonths = (
    cashflow_model: CashflowModelType,
    investmentDate: MomentInput = Date.now(),
    past = false,
): CashflowModelType => {
    const investmentDateMoment = moment(investmentDate)

    // slice removes the base principal
    return cashflow_model.slice(1).filter(({ date }: CashflowItemType) => {
        if (past) return investmentDateMoment.isAfter(moment(date))

        return investmentDateMoment.isSameOrBefore(moment(date))
    })
}

export const bankLoanInvestmentCashflow = (
    cashflow_model: CashflowModelType,
    investmentDate: MomentInput = Date.now(),
    investmentAmount: number,
): CashflowModelType => {
    const basePrincipal = getCashflowBasePrincipal(cashflow_model)

    const pastMonths = getCashflowMonths(cashflow_model, investmentDate, true)
    const upcomingMonths = getCashflowMonths(cashflow_model, investmentDate)

    const paidOffPrincipal = pastMonths.reduce(
        (acc: Decimal, cashflowItem: CashflowItemType) =>
            acc.plus(cashflowItem.amortization).plus(cashflowItem.final_payment),
        new Decimal(0),
    )

    const ratio = new Decimal(investmentAmount).dividedBy(
        new Decimal(basePrincipal).minus(paidOffPrincipal),
    )

    // todo calculate each upcomming month with this ratio
    const updatedCashflow = upcomingMonths.map((cashflowItem: CashflowItemType) => ({
        ...cashflowItem,
        cashflow: ratio.mul(cashflowItem.cashflow).toNumber(),
        interest: ratio.mul(cashflowItem.interest).toNumber(),
        amortization: ratio.mul(cashflowItem.amortization).toNumber(),
        final_payment: ratio.mul(cashflowItem.final_payment).toNumber(),
    }))

    return updatedCashflow
}

export type InterestRates = Record<string, number>

export const getInterestByInvestmentAmount = (interestRates: InterestRates, amount: number) => {
    const revValues = [...Object.values(interestRates)].reverse()
    const revThresholds = [...Object.keys(interestRates)].reverse()

    const thresholdIndex = revThresholds.findIndex(threshold => parseInt(threshold, 10) <= amount)

    const interest: number | undefined = revValues[thresholdIndex]

    return interest || revValues.pop() // returns interest or lowest value when interest is undefined
}

export const getCashflowObjectByInterestRate = (
    cashflowModels: Record<string, Maybe<CashflowObjectType>>,
    interestRate: Prisma.Decimal | number,
): Maybe<CashflowObjectType> => {
    return cashflowModels[interestRate.toString()]
}

export const getCashflowInvestmentExpectedYield = (
    investment: {
        created_at: MomentInput
        interest_period_start: Maybe<MomentInput>
        interest_rate: Prisma.Decimal
        amount: number
    },
    cashflow_model: Record<string, CashflowObjectType>,
) => {
    const { created_at, interest_period_start, amount, interest_rate } = investment
    const investmentStartDate = moment(interest_period_start || created_at)

    const cashflowObject = getCashflowObjectByInterestRate(cashflow_model, interest_rate)

    if (!cashflowObject) {
        return null
    }

    const cashflowModel = cashflowObject.cashflow_model

    if (!cashflowModel) {
        return null
    }

    const adjustedCashflow = bankLoanInvestmentCashflow(cashflowModel, investmentStartDate, amount)

    return adjustedCashflow.reduce((expectedYield, cashflowItem) => {
        return expectedYield.add(cashflowItem.interest.toString())
    }, new Decimal(0))
}

export const getCachedXIRRByInterestRate = (
    cashflowModels: Record<string, Maybe<Omit<CashflowObjectType, 'cashflow_model'>>>,
    interestRate: Prisma.Decimal | number,
) => {
    return cashflowModels[interestRate.toString()]?.irr || null
}

// source: https://gist.github.com/ghalimi/4669712
const XIRR = (values: Array<Decimal>, dates: Array<moment.Moment>, guess?: Decimal) => {
    // Credits: algorithm inspired by Apache OpenOffice

    // Calculates the resulting amount
    const irrResult = function (
        values: Array<Decimal>,
        dates: Array<moment.Moment>,
        rate: Decimal,
    ) {
        const r = rate.plus(1)
        let result = values[0]

        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < values.length; i++) {
            const frac = new Decimal(moment(dates[i]).diff(moment(dates[0]), 'days')).div(365)
            result = result.plus(values[i].dividedBy(r.pow(frac)))
        }
        return result
    }

    // Calculates the first derivation
    const irrResultDeriv = function (
        values: Array<Decimal>,
        dates: Array<moment.Moment>,
        rate: Decimal,
    ) {
        const r = rate.plus(1)
        let result = new Decimal(0)
        // eslint-disable-next-line no-plusplus
        for (let i = 1; i < values.length; i++) {
            const frac = new Decimal(moment(dates[i]).diff(moment(dates[0]), 'days')).div(365)
            result = result.minus(values[i].mul(frac).div(r.pow(frac.plus(1))))
        }
        return result
    }

    // Check that values contains at least one positive value and one negative value
    let positive = false
    let negative = false

    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < values.length; i++) {
        if (values[i].isPositive()) positive = true
        if (values[i].isNegative()) negative = true
    }

    // Return error if values does not contain at least one positive value and one negative value
    if (!positive || !negative) return '#NUM!'

    // Initialize guess and resultRate
    // eslint-disable-next-line no-param-reassign
    guess = typeof guess === 'undefined' ? new Decimal(0.1) : guess
    let resultRate = guess

    // Set maximum epsilon for end of iteration
    const epsMax = 1e-10

    // Set maximum number of iterations
    const iterMax = 50

    // Implement Newton's method
    let newRate
    let epsRate
    let resultValue
    let iteration = 0
    let contLoop = true
    do {
        resultValue = irrResult(values, dates, resultRate)
        newRate = resultRate.minus(resultValue.div(irrResultDeriv(values, dates, resultRate)))
        epsRate = newRate.minus(resultRate).abs()
        resultRate = newRate
        contLoop = epsRate.gt(epsMax) && resultValue.abs().gt(epsMax)
        // eslint-disable-next-line no-plusplus
    } while (contLoop && ++iteration < iterMax)

    if (contLoop) return '#NUM!'

    // Return internal rate of return
    return resultRate.toNumber()
}

export const getCashflowModelXIRR = (cashflow_model?: Maybe<CashflowModelType>) => {
    if (!cashflow_model) return -1

    const money = [...cashflow_model].map(({ cashflow }: CashflowItemType) => new Decimal(cashflow))
    const dates = [...cashflow_model].map(({ date }: CashflowItemType) =>
        moment(date, 'YYYY/MM/DD'),
    )

    const xirr = XIRR(money, dates)

    if (Number.isNaN(xirr)) {
        throw new Error('Could not calculate cashflow IRR')
    }

    return (xirr as number) * 100
}

export const hasInvestmentLienAgreement = (
    opportunity: Parameters<typeof isEquityOpportunity>[0] &
        Parameters<typeof isKBPortfolioOpportunity>[0],
) => {
    if (isEquityOpportunity(opportunity)) return false
    if (isKBPortfolioOpportunity(opportunity)) return false
    return true
}

export const getInterestRatesForAccount = (
    opportunity: {
        interest_rates: InterestRates
    } & Parameters<typeof shouldPrivApply>[0] &
        Parameters<typeof isInvestmentClubOpen>[0],
    account?: Maybe<{
        is_priv_member: boolean
        is_club_member: boolean
        kb_flag: boolean
        kb_employee: boolean
    }>,
) => {
    const lowestThreshold = Object.keys(opportunity.interest_rates)[0] || 0
    /*
     * If account is priv member and opportunity has priv data, return priv interest rate
     */
    if (account && shouldPrivApply(opportunity, account)) {
        return { [lowestThreshold]: opportunity.priv_interest_rate }
    }

    if (account && account.kb_employee) {
        // if account is a kb employee, return the highest interest rate.
        return { [lowestThreshold]: Object.values(opportunity.interest_rates).pop()! }
    }

    /*
     * If account is investment club / KB member, return adjusted interest rates
     */
    if (
        account &&
        ((isAccountInvestmentClubMember(account) && isInvestmentClubOpen(opportunity)) ||
            isAccountPrivMember(account))
    ) {
        const subValue: number = Object.values(opportunity.interest_rates).slice(0, 2).pop()!

        return {
            ...opportunity.interest_rates,
            [lowestThreshold]: subValue,
        }
    }

    // return default
    return opportunity.interest_rates
}
