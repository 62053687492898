import React from 'react'
import { Locale, LOCALES, LOCALES_LABELS_LONG } from '@upvestcz/common/i18n/locales'

type Props = {
    locale: Locale
}
export const LocaleFlag = ({ locale }: Props) => {
    switch (locale) {
        case LOCALES.CS:
            return (
                <img
                    src="https://hatscripts.github.io/circle-flags/flags/cz.svg"
                    width="18"
                    alt={LOCALES_LABELS_LONG[LOCALES.CS]}
                />
            )
        case LOCALES.EN:
            return (
                <img
                    src="https://hatscripts.github.io/circle-flags/flags/gb.svg"
                    width="18"
                    alt={LOCALES_LABELS_LONG[LOCALES.EN]}
                />
            )
        default:
            return null
    }
}
