import absoluteUrl from 'next-absolute-url'
import {
    parseCookies as nParseCookies,
    setCookie as nSetCookie,
    destroyCookie as nDestroyCookie,
} from 'nookies'
import { NextPageContext } from 'next'
import { CookieSerializeOptions } from 'next/dist/server/web/types'
/*
Helps keep global options for setting cookies.
 */

export type ContextObjectLike =
    | (Pick<NextPageContext, 'res' | 'req' | 'asPath'> & { ssrSetCookes?: Record<string, string> })
    | Record<string, never>
    | null
    | undefined

export const getTopDomain = (ctx?: ContextObjectLike) => {
    const subdomainList = ['kb.', 'admin.', 'app.', 'www.']
    // `.replace(/:.*/, '')` remove the port from the host (localhost:3000 -> localhost)
    const host = ctx?.req ? absoluteUrl(ctx.req).host.replace(/:.*/, '') : window.location.hostname

    return subdomainList.reduce((result, subdomain) => result.replace(subdomain, ''), host)
}

const setCookie = (
    ctx: ContextObjectLike,
    name: string,
    value: string,
    options: CookieSerializeOptions = {},
) => {
    if (ctx) {
        ctx.ssrSetCookes = { ...(ctx.ssrSetCookes || {}), [name]: value }
    }
    return nSetCookie(ctx, name, value, {
        path: '/',
        secure: process.env.NODE_ENV === 'production' && !process.env.CI,
        ...options,
    })
}

const destroyCookie = (
    ctx: ContextObjectLike,
    name: string,
    options: Record<string, unknown> = {},
) => {
    if (ctx?.ssrSetCookes?.[name]) {
        delete ctx.ssrSetCookes[name]
    }

    nDestroyCookie(ctx, name, { path: '/', ...options })
}

const parseCookies = (ctx?: ContextObjectLike) => {
    const cookies = nParseCookies(ctx)

    if (ctx?.ssrSetCookes) {
        Object.assign(cookies, ctx?.ssrSetCookes)
    }

    return cookies
}

const getCookieHeaderFromCtx = (ctx: ContextObjectLike, withSetCookies = true) => {
    const result = []

    if (withSetCookies && ctx?.res && ctx.res.hasHeader('Set-Cookie')) {
        result.push(
            ([] as string[])
                .concat(ctx.res.getHeader('Set-Cookie') as string)
                .map(str => str.split(';').shift())
                .join('; '),
        )
    }

    if (ctx?.req) {
        result.push(ctx.req.headers.cookie)
    }

    return result.join('; ')
}

export { setCookie, destroyCookie, parseCookies, getCookieHeaderFromCtx }
