export default {
    global: {
        html: {
            height: '100%',
        },
        'html, body': {
            color: 'text',
            fontFamily: 'body',
            lineHeight: 'base',
        },
        body: {
            background: 'inherit',
            minHeight: '100%',
            height: 'auto',
            display: 'flex',
            flexFlow: 'column nowrap',
        },
        '#__next': {
            flexGrow: 1,
            display: 'flex',
            flexFlow: 'column nowrap',
        },
        'ol, ul': {
            listStyle: 'none outside none',
            '&.numbered': {
                listStyle: 'decimal inside',
            },
            '&.dotted': {
                listStyle: 'disc inside',
            },
        },
        'input, select, textarea': {
            WebkitAppearance: 'none',
            borderRadius: 0,
        },
    },
}
