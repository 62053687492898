import React, { ReactNode } from 'react'
import {
    ChakraProvider,
    theme,
    ScrollInfoProvider,
    ScreenBottomStackingContextProvider,
} from '@upvestcz/shared-components'
import { ApolloProvider } from '@apollo/client'
import { useApolloClient } from '@upvestcz/common/graphql/client'
import { UniqueIdProvider, useOriginal } from '@upvestcz/common/hooks'
import { CURRENCIES } from '@upvestcz/common/currency'
import { isValueOfEnum } from '@upvestcz/common/utils'
import { ToastProvider } from '../lib/toast'
import { FaqModalProvider } from './FaqModal'
import { CurrencyProvider } from './CurrencyProvider'
import { PendingInvestmentsProvider } from './PendingInvestmentsProvider'

type Props = {
    children: ReactNode | ReactNode[]
    pageProps: Record<string, unknown>
    currencyCookie?: string
}
const PageContentProvider = ({ children, pageProps, currencyCookie }: Props) => {
    const client = useApolloClient(pageProps as TODO)
    const defaultCurrency = useOriginal(
        isValueOfEnum(CURRENCIES, currencyCookie) ? currencyCookie : undefined,
    )

    return (
        <ApolloProvider client={client}>
            <ChakraProvider theme={theme} resetCSS>
                <UniqueIdProvider>
                    <ToastProvider>
                        <FaqModalProvider>
                            <ScreenBottomStackingContextProvider>
                                <ScrollInfoProvider>
                                    <CurrencyProvider defaultCurrency={defaultCurrency}>
                                        <PendingInvestmentsProvider>
                                            {children}
                                        </PendingInvestmentsProvider>
                                    </CurrencyProvider>
                                </ScrollInfoProvider>
                            </ScreenBottomStackingContextProvider>
                        </FaqModalProvider>
                    </ToastProvider>
                </UniqueIdProvider>
            </ChakraProvider>
        </ApolloProvider>
    )
}

export default PageContentProvider
