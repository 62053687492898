import { useCallback } from 'react'
import { useRouter } from 'next/router'
import { ObjectValues } from './ts-utils'
import { getLocalePrefix } from './i18n/get-locale-prefix'
import { Locale, LOCALES } from './i18n/locales'

export const APPS = Object.freeze({
    APP: 'app',
    LP: 'lp',
    ADMIN: 'admin',
    BLOG: 'blog',
    KB: 'kb',
} as const)

export type AppType = ObjectValues<typeof APPS>

const APP_BASE_URLS = Object.freeze({
    [APPS.APP]: process.env.APP_URL ?? 'http://localhost:3000',
    [APPS.LP]: process.env.LP_URL ?? 'http://localhost:3000',
    [APPS.ADMIN]: process.env.ADMIN_URL ?? 'http://localhost:3000',
    [APPS.BLOG]: process.env.BLOG_URL ?? 'http://localhost:3000',
    [APPS.KB]: process.env.KB_URL ?? 'http://localhost:3000',
} as const)

const hasi18n = (app: AppType) => ([APPS.APP, APPS.LP] as AppType[]).includes(app)

export const getAppUrl = (app: AppType, path: string, locale: Locale = LOCALES.CS) => {
    // get rid of trailing slash if any
    return `${APP_BASE_URLS[app]}${hasi18n(app) ? getLocalePrefix(locale) : ''}${path.replace(
        /\/$/,
        '',
    )}`
}

/**
 * Automatically applies router locale to the app url.
 */
export const useAppUrl = () => {
    const { locale } = useRouter()

    return useCallback((app: AppType, path: string) => getAppUrl(app, path, locale), [locale])
}
