export default {
    baseStyle: {
        fontWeight: 500,
        color: '#000',
    },
    sizes: {
        xl: ({ theme }) => ({
            ...theme.textStyles.heading1,
        }),
        lgxl: ({ theme }) => ({
            ...theme.textStyles.heading2,
        }),
        lg: ({ theme }) => ({
            ...theme.textStyles.heading3,
        }),
        md: ({ theme }) => ({
            ...theme.textStyles.heading4,
        }),
        sm: ({ theme }) => ({
            ...theme.textStyles.heading5,
        }),
        xs: ({ theme }) => ({
            ...theme.textStyles.heading6,
        }),
    },
}
