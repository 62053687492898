import React, { useContext, useEffect, useState } from 'react'
import ReactGA from 'react-ga4'
import useSWR from 'swr'
import {
    Container,
    Heading,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    Text,
    Button,
    Box,
    LineLoader,
    useDisclosure,
    ModalProps,
    ButtonProps,
} from '@upvestcz/shared-components'
import { TFunction } from 'i18next'
import { chakraModalContentAnimationProps as animationProps } from '@upvestcz/shared-components/animations'
import { MergeChakraProps } from '@upvestcz/common/ts-utils'
import { useTranslation } from 'react-i18next'
import { tryToLoadTranslations, useAddTranslations } from '../i18n'
import AutomaticTranslationAlert from './AutomaticTranslationAlert'
import { FaqItemKey } from './FaqInfo'

type FaqModalContextType = {
    onOpen: (key: FaqItemKey) => void
    onClose: () => void
    isOpen: boolean
}
const FaqModalContext = React.createContext<FaqModalContextType | undefined>(undefined)
const useFaqModalContext = () => {
    const context = useContext(FaqModalContext)
    if (!context) {
        throw new Error('useFaqModalContext must be used within a FaqModalProvider')
    }
    return context
}
export const FaqModalProvider = ({ children }: { children: React.ReactNode }) => {
    const { isOpen, onOpen: onModalOpen, onClose: onModalClose } = useDisclosure()
    const [key, setKey] = useState<FaqItemKey | undefined>(undefined)

    const onOpen = (key: FaqItemKey) => {
        setKey(key)
        onModalOpen()
    }
    const onClose = () => {
        setKey(undefined)
        onModalClose()
    }
    const context = {
        onOpen,
        isOpen,
        onClose,
    }

    return (
        <FaqModalContext.Provider value={context}>
            {children}
            {key && <FaqModal isOpen={isOpen} onClose={onClose} questionKey={key} />}
        </FaqModalContext.Provider>
    )
}

const faqItemsFetcher = (t: TFunction) =>
    Promise.all([
        import('./FaqInfo').then(({ getItems }) => getItems(t)),
        tryToLoadTranslations({ relativeFilePath: 'faq' }),
    ])

const AsyncFaqModalContent = ({ questionKey }: { questionKey: FaqItemKey }) => {
    const { t } = useTranslation()
    const { data: [items, translations] = [] } = useSWR('/faqItems', () => faqItemsFetcher(t))

    useAddTranslations({ ns: 'faq', ...translations })

    useEffect(() => {
        if (questionKey) {
            ReactGA.event('select_content', {
                content_type: 'faq',
                content_id: questionKey,
            })
        }
    }, [])

    // if loading
    if (!items || !translations)
        return (
            <Box pos="absolute" top="50%" left="50%" transform="translate(-50%, -50%)" width="75%">
                <LineLoader />
            </Box>
        )
    // if result for key does not exist
    if (!items || !items[questionKey]) return null
    // else
    return (
        <>
            <AutomaticTranslationAlert size="sm" mb={2} textAlign="initial" />
            <Heading size="sm" mb={6}>
                {items[questionKey].question}
            </Heading>
            <Text>{items[questionKey].answer}</Text>
        </>
    )
}
type Props = MergeChakraProps<
    {
        isOpen: boolean
        onClose: () => void
        questionKey: FaqItemKey
    },
    Omit<ModalProps, 'children'>
>
export const FaqModal = ({ isOpen, onClose, questionKey, ...props }: Props) => {
    return (
        <Modal size="md" isOpen={isOpen} onClose={onClose} scrollBehavior="inside" {...props}>
            <ModalOverlay />
            <ModalContent {...animationProps}>
                <Container
                    py={[6, null, 10]}
                    textAlign="center"
                    display="flex"
                    flexFlow="column nowrap"
                    as={ModalBody}
                >
                    {questionKey && <AsyncFaqModalContent questionKey={questionKey} />}
                </Container>
                <ModalCloseButton />
            </ModalContent>
        </Modal>
    )
}

type ModalTogglerProps = MergeChakraProps<
    {
        questionKey: FaqItemKey
    },
    ButtonProps
>
const FaqModalToggler = ({ children, questionKey, ...props }: ModalTogglerProps) => {
    const { onOpen } = useFaqModalContext()
    const styles = {
        minWidth: 0,
    }
    return (
        <Button
            as="button"
            variant="contextualLink"
            onClick={() => onOpen(questionKey)}
            tabIndex={0}
            {...styles}
            {...props}
        >
            {children}
        </Button>
    )
}

export { FaqModalToggler }
