import React from 'react'
import { Box, BoxProps, keyframes, useStyleConfig } from '@chakra-ui/react'
import { MergeChakraProps } from '@upvestcz/common/ts-utils'

// at 1.1s is about:  500ms -> 100ms pause -> 500ms
const pulse = keyframes({
    '0%': {
        transformOrigin: 'left center',
        transform: 'scaleX(0)',
    },
    '45%': {
        transform: 'scaleX(1)',
        transformOrigin: 'left center',
    },
    '55%': {
        transform: 'scaleX(1)',
        transformOrigin: 'right center',
    },
    '100%': {
        transform: 'scaleX(0)',
        transformOrigin: 'right center',
    },
})
export type LineLoaderProps = MergeChakraProps<
    {
        variant?: 'primary' | 'grey'
        size?: 'sm' | 'md'
    },
    BoxProps
>
export const LineLoader = ({ variant, size, ...props }: LineLoaderProps) => {
    const styles = useStyleConfig('LineLoader', {
        variant,
        size,

        styleConfig: {
            baseStyle: {
                animation: `${pulse} 1.1s infinite linear`,
                height: '4px',
                background: 'rgba(0, 0, 0, 0.1)',
            },
            variants: {
                primary: {
                    background: 'primary',
                },
            },
            sizes: {
                sm: {
                    height: '2px',
                },
            },
        },
    })

    return <Box __css={styles} {...props} />
}
