import type { ComponentStyleConfig } from '@chakra-ui/theme'

const CookieBar: ComponentStyleConfig = {
    parts: [
        'ModalAcordionItem',
        'ModalAccordionPanel',
        'ModalSwitch',
        'ModalSwitchLabel',
        'FormControlStack',
    ],
    baseStyle: {
        ModalAcordionItem: {
            flex: '1',
            textAlign: 'left',
            fontSize: 'sm',
            fontWeight: 'bold',
        },
        ModalAccordionPanel: {
            fontSize: 'sm',
            pb: 4,
        },
        ModalSwitch: {
            size: 'md',
            transform: 'scale(1.25)',
            transformOrigin: 'bottomCenter',
        },
        ModalSwitchLabel: {
            margin: 0,
            textAlign: 'center',
            fontSize: 'sm',
            fontWeight: 'bold',
        },
        FormControlStack: {
            spacing: '1',
            direction: 'column',
            w: '100%',
            alignItems: 'center',
            pt: '6px',
        },
    },
}

export default CookieBar
