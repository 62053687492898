import Heading from './heading'
import Link from './link'
import Button from './button'
import Input from './input'
import FormLabel from './form-label'
import Textarea from './textarea'
import CloseButton from './close-button'
import Select from './select'
import Radio from './radio'
import Menu from './menu'
import Table from './table'
import FormError from './form-error'
import Stat from './stat'
import Progress from './progress'
import Modal from './modal'
import Popover from './popover'
import Switch from './switch'
import NumberInput from './number-input'
import ContentContainer from './content-container'
import CustomTabs from './custom-tabs'
import CookieBar from './cookie-bar'

export default {
    Heading,
    Link,
    Button,
    Input,
    FormLabel,
    Textarea,
    CloseButton,
    Select,
    Radio,
    Menu,
    Table,
    FormError,
    Stat,
    Progress,
    Modal,
    Popover,
    Switch,
    NumberInput,
    ContentContainer,
    CustomTabs,
    CookieBar,
}
