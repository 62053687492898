import { ObjectValues } from './ts-utils'

export const CURRENCIES = {
    CZK: 'CZK',
    EUR: 'EUR',
} as const

export const CURRENCY_SYMBOLS = {
    CZK: 'Kč',
    EUR: '€',
} as const

export type Currency = ObjectValues<typeof CURRENCIES>
