import * as R from 'ramda'
import Input from './input'

const { variants, baseStyle, sizes: inputSizes } = Input

const sizes = {
    sm: {
        stepper: {
            _first: {
                borderTopEndRadius: 'none',
            },
            _last: {
                borderBottomEndRadius: 'none',
            },
        },
    },
    md: {
        stepper: {
            _first: {
                borderTopEndRadius: 'none',
            },
            _last: {
                borderBottomEndRadius: 'none',
            },
        },
    },
    lg: {
        stepper: {
            _first: {
                borderTopEndRadius: 'none',
            },
            _last: {
                borderBottomEndRadius: 'none',
            },
        },
    },
}

export default {
    baseStyle: R.mergeDeepRight(baseStyle, {
        field: { caretColor: 'text', _focus: { boxShadow: 'none' } },
    }),
    sizes: R.mergeDeepRight(inputSizes, sizes),
    variants,
}
