import React, { useCallback, useEffect } from 'react'
import * as R from 'ramda'
import { parseCookies, setCookie } from '@upvestcz/common/cookies'
import { TokenExpiredError } from '@upvestcz/common/errors'
import { replace } from '@upvestcz/common/routing'
import {
    checkJwtTokenExpiration,
    loggedIn,
    getProfile,
    getToken,
    destroyLoginCookies,
    getAccessToken,
} from '@upvestcz/common/auth'
import { AppContext } from 'next/app'
import { useRouter } from 'next/router'
import {
    fetchAccount,
    fetchMyAgreementSet,
    fetchCurrentAgreementSet,
    createAccount,
} from '../lib/api'
import {
    useAccount,
    AccountProvider,
    State as AccountState,
    GLOBAL_CONTEXT_KEY as ACCOUNT_GLOBAL_CONTEXT_KEY,
} from './account'
import {
    AgreementSetProvider,
    useAgreementSet,
    State as AgreementSetState,
    GLOBAL_CONTEXT_KEY as AGREEMENT_SET_GLOBAL_CONTEXT_KEY,
} from './agreementSet'
import { AuthProvider, useAuth, State as AuthState } from './auth'
import { InitialDataFetch, MyAppComponent, MyAppProps } from '../lib/next'
import logger from '../lib/logger'

const IS_SERVER = typeof window === 'undefined'

type GlobalContextKey = typeof AGREEMENT_SET_GLOBAL_CONTEXT_KEY | typeof ACCOUNT_GLOBAL_CONTEXT_KEY

// or from the React Context which is bound to the `window` object
// as the `getInitialProps` method hasn't got the access
// to React Context
const getFromApiOrContext = <T extends GlobalContextKey>({
    request,
    globalContextKey,
}: {
    globalContextKey: T
    request: () => Promise<{ data: Window[T] }>
}) => (IS_SERVER ? request().then(R.prop('data')) : Promise.resolve(window[globalContextKey]))

const initialDataFetch: InitialDataFetch = ({ ctx }) => {
    const getNonAuthData = async () => ({
        account: null,
        agreementSet: (await getFromApiOrContext({
            request: () => fetchCurrentAgreementSet(ctx),
            globalContextKey: AGREEMENT_SET_GLOBAL_CONTEXT_KEY,
        })) as AgreementSetState,
    })

    const getAuthData = async () => ({
        account: (await getFromApiOrContext({
            request: () =>
                fetchAccount(ctx.auth.profile.auth_id, ctx).catch(err => {
                    // If user hasn't got an account record in the DB
                    // (but *does* have got an Auth0 profile), create it
                    // as the whole app assumes that every user has got an account
                    if (err.status === 404) {
                        return createAccount(
                            ctx.auth.profile.auth_id!,
                            { email: ctx.auth.profile.email },
                            ctx,
                        )
                    }

                    throw err
                }),
            globalContextKey: ACCOUNT_GLOBAL_CONTEXT_KEY,
        })) as AccountState,
        agreementSet: (await getFromApiOrContext({
            request: () => fetchMyAgreementSet(ctx),
            globalContextKey: AGREEMENT_SET_GLOBAL_CONTEXT_KEY,
        })) as AgreementSetState,
    })

    return ctx.auth.loggedIn ? getAuthData() : getNonAuthData()
}

const LoginMonitor = ({ currentAuthInitialValue }: { currentAuthInitialValue: AuthState }) => {
    const [auth] = useAuth()
    const clearStore = useClearStore()
    const { access_token } = parseCookies()
    const router = useRouter()

    useEffect(() => {
        const onChangeComplete = () => {
            // this is a hotfix for user login cookies being destroyed guring navigation in getInitialProps. Store needs to be cleared.
            if (auth.loggedIn && !access_token) {
                clearStore()
            }
        }
        router.events.on('routeChangeComplete', onChangeComplete)

        return () => {
            router.events.off('routeChangeComplete', onChangeComplete)
        }
    }, [access_token, auth, clearStore, currentAuthInitialValue])

    return null
}

export function withStore(AppComponent: MyAppComponent) {
    const WithStore = ({
        account,
        agreementSet,
        auth,
        ...props
    }: {
        account: AccountState
        agreementSet: AgreementSetState
        auth: AuthState
    } & MyAppProps) => {
        return (
            <AccountProvider initialValue={account}>
                <AgreementSetProvider initialValue={agreementSet}>
                    <AuthProvider initialValue={auth}>
                        <LoginMonitor currentAuthInitialValue={auth} />
                        <AppComponent {...props} />
                    </AuthProvider>
                </AgreementSetProvider>
            </AccountProvider>
        )
    }

    WithStore.getInitialProps = async (ctx: AppContext) => {
        const getAuth = (ctx: AppContext | Record<string, never>) => ({
            loggedIn: loggedIn(ctx.ctx),
            profile: getProfile(ctx.ctx),
            token: getToken(ctx.ctx),
            accessToken: getAccessToken(ctx.ctx),
        })

        const auth = getAuth(ctx)

        if (auth.token || auth.accessToken) {
            /*
            If we ever use refresh_token to remember the user, this needs to be remade
            into a post request and checked server-side via jwt.verify and the refresh.
             */
            try {
                // if any one of these expires, logout.
                checkJwtTokenExpiration(auth.token)
                checkJwtTokenExpiration(auth.accessToken)
            } catch (err) {
                if (err instanceof TokenExpiredError) {
                    // Don't log anything. Login expiration is an expected state.
                    // The cookies don't get applied until the next request. (destroyCookie sets a Set-Cookie header)
                    destroyLoginCookies(ctx.ctx)
                    // expiration detection cannot be passed as a prop because of possible redirects in other `getInitialProps` HOC's
                    setCookie(ctx.ctx, 'tokenExpired', 'true')

                    // replace auth object with signed out values
                    Object.assign(auth, getAuth({}))
                    return replace({
                        ctx: ctx.ctx,
                        location: null,
                    })
                }
                logger.error(err)
            }
        }

        if (typeof AppComponent.getInitialProps === 'function') {
            const pageProps = await AppComponent.getInitialProps({
                ...ctx,
                ctx: {
                    ...ctx.ctx,
                    auth: auth as AuthState,
                    initialDataFetch,
                },
            })

            return { ...pageProps, auth }
        }

        return { auth }
    }

    return WithStore
}

export const useClearStore = () => {
    const [, dispatchAccount] = useAccount()
    const [, dispatchAgreementSet] = useAgreementSet()
    const [, dispatchAuth] = useAuth()

    return useCallback(
        () =>
            Promise.all([
                dispatchAccount({ type: 'clear' }),
                dispatchAuth({ type: 'clear' }),
                fetchCurrentAgreementSet()
                    .then(R.prop('data'))
                    .then(agreementSet =>
                        dispatchAgreementSet({ type: 'set', payload: agreementSet }),
                    ),
            ]),
        [dispatchAccount, dispatchAgreementSet, dispatchAuth],
    )
}
