import Bull from 'bull'
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react'
import { parseCookies, setCookie } from '@upvestcz/common/cookies'
import { ERROR_CODES, isCustomErrorLike, WorkerJobNotFoundError } from '@upvestcz/common/errors'
import { useTranslation } from 'react-i18next'
import {
    deserializeCustomErrors,
    getErrorInstanceFromFailedJob,
    JOB_STATES,
} from '@upvestcz/common/workers'
import { gql, useQuery } from '@apollo/client'
import {
    GetInvestmentJobsStatusQuery,
    GetInvestmentJobsStatusQueryVariables,
    QueueName,
} from '@upvestcz/common/graphql/typegen'
import { promiseWithResolvers } from '@upvestcz/common/utils'
import { useRouter } from 'next/router'
import { UPVEST_EMAIL } from '@upvestcz/common/constants'
import { handleGraphQLError } from '@upvestcz/common/graphql/utils'
import { usePromiseToast } from './Toast'
import type { CreateInvestmentJobReturnValue } from '../../server/workers/jobs/create_investment' // FIXME: don't import types from server?
import logger from '../lib/logger'

export const PENDING_INVESTMENTS_COOKIE_KEY = 'pendingInvestments'

const InvestmentsCookie = {
    get: () => parsePendingInvestments(parseCookies()[PENDING_INVESTMENTS_COOKIE_KEY]),
    set: (jobIds: Bull.JobId[]) =>
        setCookie(null, PENDING_INVESTMENTS_COOKIE_KEY, JSON.stringify(jobIds)),
}

type PendingInvestmentsContextType = {
    pendingInvestments: Bull.JobId[]
    addPendingInvestment: (jobId: Bull.JobId) => Promise<CreateInvestmentJobReturnValue>
}

const PendingInvestmentsContext = React.createContext<PendingInvestmentsContextType | undefined>(
    undefined,
)

const usePendingInvestmentsProvider = () => {
    const context = useContext(PendingInvestmentsContext)
    if (context === undefined) {
        throw new Error(
            'usePendingInvestmentsProvider must be used within a PendingInvestmentsProvider',
        )
    }
    return context
}

const parsePendingInvestments = (investmentsArrayString: Maybe<string> | undefined) => {
    return ((investmentsArrayString ? JSON.parse(investmentsArrayString) : null) ||
        []) as Bull.JobId[]
}

const useGetJobsStatusQuery = (
    jobIds: Bull.JobId[],
    onPollingFailed: (error: Error, stopPolling: () => void) => void,
) => {
    const { data, stopPolling } = useQuery<
        GetInvestmentJobsStatusQuery,
        GetInvestmentJobsStatusQueryVariables
    >(
        gql`
            query GetInvestmentJobsStatus($jobIds: [String!]!, $queue: QueueName!) {
                getJobsStatus(jobIds: $jobIds, queue: $queue) {
                    id
                    state
                    progress
                    returnvalue
                    failedReason
                    errorName
                }
            }
        `,
        {
            variables: {
                jobIds: jobIds.map(id => id.toString()) ?? [],
                queue: QueueName.Investment,
            },
            pollInterval: 2000,
            fetchPolicy: 'network-only',
            skip: !jobIds.length,
            onError(error) {
                const { mainError } = handleGraphQLError(error)
                onPollingFailed(mainError, stopPolling)
            },
        },
    )

    return data?.getJobsStatus ?? []
}

const PendingInvestmentsProvider = ({
    children,
}: {
    children: React.ReactNode | ((context: PendingInvestmentsContextType) => React.ReactNode)
}) => {
    const { t } = useTranslation()
    const { addPromiseToast, toast } = usePromiseToast()
    const router = useRouter()

    const [pendingInvestmentsMap, setPendingInvestmentsMap] = useState(
        new Map(
            InvestmentsCookie.get().map(jobId => {
                return [
                    jobId,
                    promiseWithResolvers<CreateInvestmentJobReturnValue>({
                        onFinally: () => {
                            removePendingInvestment(jobId)
                        },
                    }),
                ]
            }),
        ),
    )

    const removePendingInvestment = useCallback((jobId: Bull.JobId) => {
        setPendingInvestmentsMap(prev => {
            const newPendingInvestments = new Map(prev)
            newPendingInvestments.delete(jobId)
            InvestmentsCookie.set(Array.from(newPendingInvestments.keys()))
            return newPendingInvestments
        })
    }, [])

    const addPendingInvestment = useCallback(
        (jobId: Bull.JobId) => {
            const newInvestmentPromise = promiseWithResolvers<CreateInvestmentJobReturnValue>({
                onFinally: () => {
                    removePendingInvestment(jobId)
                },
            })

            setPendingInvestmentsMap(prev => {
                const newPendingInvestments = new Map(prev)
                newPendingInvestments.set(jobId, newInvestmentPromise)
                InvestmentsCookie.set(Array.from(newPendingInvestments.keys()))
                return newPendingInvestments
            })

            return newInvestmentPromise.promise
        },
        [removePendingInvestment],
    )

    const pendingInvestmentJobIds = useMemo(
        () => Array.from(pendingInvestmentsMap.keys()),
        [pendingInvestmentsMap],
    )

    const workerJobsStatus = useGetJobsStatusQuery(pendingInvestmentJobIds, (err, stopPolling) => {
        if (isCustomErrorLike(err) && err.code === ERROR_CODES.WORKER_JOB_NOT_FOUND) {
            const { jobId } = err as WorkerJobNotFoundError
            // If the job is not found
            toast.close(jobId)
            pendingInvestmentsMap.get(jobId)?.reject(new WorkerJobNotFoundError(jobId))
        } else {
            logger.error(err)
            pendingInvestmentsMap.forEach((_, jobId) => {
                toast.close(jobId)
            })
            toast({
                title: t('Nepodařilo se získat stav investičních pokynů'),
                description: t(
                    'Nepodařilo se získat stav investičních pokynů. Prosím zkuste načíst stránku znovu, případně kontaktujte naši podporu na {{email}}.',
                    {
                        email: UPVEST_EMAIL,
                    },
                ),
                status: 'error',
                duration: 5_000,
                isClosable: true,
            })
            stopPolling()
        }
    })

    const context: PendingInvestmentsContextType = {
        pendingInvestments: pendingInvestmentJobIds,
        addPendingInvestment,
    }

    // This adds the promise toast for every pending investment.
    useEffect(() => {
        pendingInvestmentsMap.forEach(({ promise }, jobId) => {
            if (!toast.isActive(jobId)) {
                addPromiseToast(promise, {
                    loading: {
                        id: jobId, // This is important, so that the toast is not duplicated
                        title: t('Pokyn k investici se zpracovává'),
                        description: t('Prosím vyčkejte na dokončení investice'),
                    },
                    success: async ({ investment, opportunityType }) => {
                        const { amount, currency } = investment
                        // Open the InvestmentThanks modal
                        await router.push(
                            `/user/dashboard?thanks=${opportunityType}&currency=${currency}`,
                        )
                        return {
                            title: t('Investice byla úspěšně dokončena'),
                            description: t(
                                'Investice ve výši {{amount,formatAmount}} byla úspěšně dokončena',
                                {
                                    amount: {
                                        value: amount,
                                        currency,
                                    },
                                },
                            ),
                        }
                    },
                    error: err => {
                        const description = (() => {
                            switch (isCustomErrorLike(err) ? err.code : undefined) {
                                case ERROR_CODES.MAX_FUNDRAISING_TARGET_OVERFLOW:
                                    return t(
                                        'Investovaná částka přesahuje maximální možnou investici. Je možné že projekt již byl doinvestován.',
                                    )
                                case ERROR_CODES.NOT_ENOUGH_BALANCE:
                                    return t(
                                        'Na vašem investičním účtu nemáte dostatek prostředků k provedení investice.',
                                    )
                                case ERROR_CODES.FUNDRAISING_NOT_STARTED:
                                    return t(
                                        'Investiční příležitost není dostupná. Buď již skončila, nebo ještě nebyla spuštěna.',
                                    )

                                default:
                                    return t(
                                        'Prosím zkuste to znovu, nebo kontaktujte naši podporu na {{email}}.',
                                        {
                                            email: UPVEST_EMAIL,
                                        },
                                    )
                            }
                        })()

                        return {
                            title: t('Investici se nepodařilo provést'),
                            description,
                        }
                    },
                })
            }
        })
    }, [t, pendingInvestmentsMap, toast, addPromiseToast])

    // This checks the status of the jobs on evenry query poll update and resolves the promise if the job is completed.
    useEffect(() => {
        workerJobsStatus.forEach(job => {
            if (job.state === JOB_STATES.COMPLETED) {
                pendingInvestmentsMap.get(job.id.toString())?.resolve(job.returnvalue)
            }
            if (job.state === JOB_STATES.FAILED) {
                pendingInvestmentsMap
                    .get(job.id.toString())
                    ?.reject(deserializeCustomErrors(getErrorInstanceFromFailedJob(job)))
            }
        })
    }, [pendingInvestmentsMap, workerJobsStatus])

    return (
        <PendingInvestmentsContext.Provider value={context}>
            {typeof children === 'function' ? children(context) : children}
        </PendingInvestmentsContext.Provider>
    )
}

export { PendingInvestmentsProvider, usePendingInvestmentsProvider }
