// Heavily inspired by https://kentcdodds.com/blog/how-to-use-react-context-effectively
// You can read more there about this pattern
import { NormalizedProfile } from '@upvestcz/common/auth'
import {
    ActionWithResolve,
    AsyncDispatch,
    asyncReducer,
    useAsyncDispatch,
} from '@upvestcz/common/hooks/use-async-dispatch'
import React, { useReducer, useContext, useEffect, ReactNode } from 'react'

interface LoggedInAuth {
    loggedIn: true
    profile: NormalizedProfile
    token: string
}

interface NotLoggedInAuth {
    loggedIn: false
    profile: EmptyObject
    token: null
}

export type State = LoggedInAuth | NotLoggedInAuth

interface SetAction extends ActionWithResolve {
    type: 'set'
    payload: State
}

interface ClearAction extends ActionWithResolve {
    type: 'clear'
}

type Action = SetAction | ClearAction

export const GLOBAL_CONTEXT_KEY = '__AUTH_STATE__'

const AuthStateContext = React.createContext<State | undefined>(undefined)
const AuthDispatchContext = React.createContext<AsyncDispatch<Action> | undefined>(undefined)

const reducer = asyncReducer((_: State, action: Action) => {
    switch (action.type) {
        case 'set':
            return action.payload
        case 'clear':
            return {
                loggedIn: false,
                profile: {},
                token: null,
            } as const
        default:
            throw new Error(`An unknown action of type passed`)
    }
})

function AuthProvider({ initialValue, children }: { initialValue: State; children: ReactNode }) {
    const [state, dispatch] = useReducer(reducer, initialValue || null)

    const promisifiedDispatch = useAsyncDispatch(dispatch)

    useEffect(() => {
        // bind the state to the window for reuse in getInitialProps
        // inspired here: https://github.com/zeit/next.js/blob/canary/examples/with-redux/lib/with-redux-store.js
        // eslint-disable-next-line no-underscore-dangle
        window.__AUTH_STATE__ = state
    }, [state])

    return (
        <AuthStateContext.Provider value={state}>
            <AuthDispatchContext.Provider value={promisifiedDispatch}>
                {children}
            </AuthDispatchContext.Provider>
        </AuthStateContext.Provider>
    )
}

function useAuthState<forceLoggedIn extends boolean = false>() {
    const context = useContext(AuthStateContext)

    if (context === undefined) {
        throw new Error('useAuthState must be used within a AuthProvider')
    }

    return context as forceLoggedIn extends true ? LoggedInAuth : State
}

function useAuthDispatch() {
    const context = useContext(AuthDispatchContext)

    if (context === undefined) {
        throw new Error('useAuthDispatch must be used within a AuthProvider')
    }

    return context
}

function useAuth<forceLoggedIn extends boolean>() {
    return [useAuthState<forceLoggedIn>(), useAuthDispatch()] as const
}

export { AuthProvider, useAuth }
