import { FunctionComponent, createContext, useContext, useRef } from 'react'

type IdState = {
    id: number
    get(name?: string): string
}

const UniqueIdContext = createContext<IdState['get']>(() => {
    throw new TypeError('Please wrap your application with UniqueIdProvider')
})

const useIdGetter = (prefix = 'id') => {
    const state = {
        id: 0,
        get: (name?: string) => {
            state.id += 1
            return `${prefix}-${name ? `${name}-` : ''}${state.id}`
        },
    }
    const ref = useRef<IdState>(state)
    return ref.current.get
}

export const UniqueIdProvider: FunctionComponent = ({ children }) => {
    const get = useIdGetter()
    return <UniqueIdContext.Provider value={get}>{children}</UniqueIdContext.Provider>
}

export const useId = (name?: string) => {
    const getUniqueId = useContext(UniqueIdContext)
    const ref = useRef<string>(getUniqueId(name))
    return ref.current
}
