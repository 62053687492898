import { Dispatch, Reducer, useCallback } from 'react'

type PromiseResolveFunction = <T>(value?: T | PromiseLike<T>) => void

export interface ActionWithResolve {
    resolve?: PromiseResolveFunction
}

export type AsyncDispatch<Action extends ActionWithResolve> = (action: Action) => Promise<void>

export const useAsyncDispatch = <Action extends ActionWithResolve>(
    dispatchFn: Dispatch<Action>,
) => {
    return useCallback<AsyncDispatch<Action>>(
        (action: Action) => {
            return new Promise(resolve => {
                dispatchFn({
                    ...action,
                    resolve,
                })
            })
        },
        [dispatchFn],
    )
}

export const asyncReducer = <R extends Reducer<any, any>>(reducerFn: R) => {
    return ((prevState, action) => {
        try {
            const returnValue = reducerFn(prevState, action)
            action.resolve?.()
            return returnValue
        } catch (err) {
            // in case of error, resolve and re-throw.
            action.resolve?.()
            throw err
        }
    }) as R
}
