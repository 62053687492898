import jwt from 'jsonwebtoken'
import * as R from 'ramda'
import { NextPageContext } from 'next'
import { User } from 'auth0'
import { TokenExpiredError } from './errors'
import { AffilatesKey } from './referrals'
import { ObjectValues } from './ts-utils'
import { ContextObjectLike, destroyCookie, parseCookies, setCookie } from './cookies'
import { Role } from './roles'

export type RequiredRoles = Role | Array<Role | Role[]>

export const PROFILE_CUSTOM_CLAIMS = {
    roles: 'http://upvest/roles',
    identities: 'http://upvest/identities',
    user_metadata: 'http://upvest/user_metadata',
} as const

export type ProfileCustomClaim = ObjectValues<typeof PROFILE_CUSTOM_CLAIMS>

// profile from saved cookie or decoded id_token
export interface Profile {
    'http://upvest/roles': Role[]
    'http://upvest/identities': Auth0Connection[]
    sub: string
    email: string | null // can be missing when profile created from BankID and not finished registration
    'http://upvest/user_metadata': {
        referralCode?: string | null
        affiliateKey?: AffilatesKey | null
        privOpportunityID?: number | null
    }
    nickname: string
    name: string
    picture: string
    updated_at: string
    email_verified?: boolean | null
}

export interface NormalizedProfile extends Profile {
    auth_id: string
}

export interface AuthTokens {
    id_token: string
    access_token: string
}

export type AccessTokenJSON = {
    'http://upvest/roles': Role[]
    'http://upvest/identities': Auth0Connection[]
    'http://upvest/currentConnection': Auth0Connection
    iss: string
    sub: string
    aud: string | string[]
    iat: number
    exp: number
    azp: string
    scope: string
    gty?: string
}

export const BANK_ID_SCOPE_TYPES = {
    LOGIN: 'login',
    SIGNUP: 'signup',
    BAPO: 'bapo',
}

export const BANK_ID_SCOPES = {
    [BANK_ID_SCOPE_TYPES.LOGIN]: ['openid', 'profile.email'].join(' '),
    [BANK_ID_SCOPE_TYPES.SIGNUP]: [
        'openid',
        'profile.email',
        'profile.birthnumber',
        'profile.verification',
        'profile.legalstatus',
        'profile.paymentAccounts',
        'profile.addresses',
        'profile.updatedat',
        'profile.name',
        'profile.birthplaceNationality',
        'profile.idcards',
    ].join(' '),
    [BANK_ID_SCOPE_TYPES.BAPO]: [
        'openid',
        'profile.name',
        'profile.gender',
        'profile.birthnumber',
        'profile.birthplaceNationality',
        'profile.addresses',
        'profile.idcards',
        'profile.paymentAccounts',
        'profile.email',
        'profile.phonenumber',
        'profile.updatedat',
        'profile.legalstatus',
    ].join(' '),
}

export type bankIdScopeType = ObjectValues<typeof BANK_ID_SCOPE_TYPES>

export const convetUserToProfileLike = (user: User): Omit<Profile, 'http://upvest/roles'> => {
    const {
        user_id,
        email,
        user_metadata,
        nickname,
        name,
        updated_at,
        email_verified,
        picture,
        identities = [],
    } = user as Required<User> // assume all the basic props exist on the user

    return {
        [PROFILE_CUSTOM_CLAIMS.identities]: identities.map(
            ({ connection }) => connection as Auth0Connection,
        ),
        sub: user_id,
        email,
        [PROFILE_CUSTOM_CLAIMS.user_metadata]: user_metadata,
        nickname,
        name,
        picture,
        updated_at,
        email_verified,
    }
}

export const normalizeProfile = (profile: Profile): NormalizedProfile => {
    return {
        ...profile,
        auth_id: profile.sub,
    }
}
// This is to make the API of the `withPermissionCheck` similar
// to the `express-jwt-permissions`.
// (To have a similar permission check guard API on both FE and Be)
const getRequiredRolesArray = (requiredRoles: RequiredRoles): Role[][] => {
    // 'foo' -> [['foo']]
    if (typeof requiredRoles === 'string') {
        return [[requiredRoles]]
    }

    // ['foo', 'bar'] -> [['foo', 'bar']]
    if (Array.isArray(requiredRoles) && requiredRoles.every(item => typeof item === 'string')) {
        return [requiredRoles] as Role[][]
    }

    return requiredRoles as Role[][]
}

export const areRolesSufficient = ({
    requiredRoles,
    profile,
}: {
    profile: Profile | AccessTokenJSON
    requiredRoles: RequiredRoles
}) => {
    const requiredRolesArray = getRequiredRolesArray(requiredRoles)

    const currentAccountRoles = profile['http://upvest/roles']

    // the first "level" of arrays are bound by an "OR" condition (.some())
    // and the second "level" of arrays are bound by an "AND" condition (.every())
    // e.g. [['admin'], ['analyst', 'superuser']] -> 'admin' OR ('analyst' AND 'superuser')
    // (The goal is to make it same as on the BE with `express-jwt-permissions`)
    return requiredRolesArray.some(requiredRoles =>
        requiredRoles.every(requiredRole => currentAccountRoles.includes(requiredRole)),
    )
}

export const checkJwtTokenExpiration = (token: string) => {
    // check for token expiration - this code has been stolen from the `jwt.verify` function
    const clockTimestamp = Math.floor(Date.now() / 1000)
    const payload = jwt.decode(token)

    if (
        !payload ||
        typeof payload === 'string' ||
        !payload.exp ||
        typeof payload.exp !== 'number'
    ) {
        throw new TokenExpiredError('jwt token expiration date is invalid')
    }
    if (clockTimestamp >= payload.exp) {
        throw new TokenExpiredError('jwt token expired')
    }
    return true
}

export const AUTH0_CONNECTIONS = {
    EMAIL_PASSWORD: 'Username-Password-Authentication', // this is a different string than in `AUTH0_CONNECTION_PREFIXES`
    GOOGLE: 'google-oauth2',
    BANKID: 'bankid',
} as const

export const AUTH0_CONNECTION_NAMES = {
    [AUTH0_CONNECTIONS.EMAIL_PASSWORD]: 'E-mail',
    [AUTH0_CONNECTIONS.GOOGLE]: 'Google',
    [AUTH0_CONNECTIONS.BANKID]: 'BankID',
} as const

export type Auth0Connection = ObjectValues<typeof AUTH0_CONNECTIONS>

/**
 * Returns false or an array of connections that match the passed connections list
 */
export function isRegisteredWith(
    userProfile: {
        'http://upvest/identities': Auth0Connection[]
    },
    connections: Auth0Connection[], // these connections are checked as an OR condition.
    checkAllConnections = false, // Checks all connections of the user instead of only the one user signed up with.
) {
    if (!userProfile) return false

    const identities = userProfile[PROFILE_CUSTOM_CLAIMS.identities].slice(
        0,
        checkAllConnections ? undefined : 1,
    )

    const foundConnections = identities.filter(identity => connections.includes(identity))
    return !!foundConnections.length && foundConnections
}

// The difference between a `connection` and a `provider` is subtle
// and poorly documented.
// A `connection` is the *name* of the connection. This can be
// `Username-Password-Authentication`, `google-oauth2`, or `bankid`.
// A `provider` can be the same string in the case of email/password connection
// (`Username-Password-Authentication`) or in the case of the predefined social
// connections such as `google-oauth2`. However, for custom social (oauth)
// connection, the provider is `oauth2` that corresponds to the "type"
// of the connection.
export const getPrimaryConnection = R.pipe<string, string, string[], string, string>(
    R.replace(/^oauth2\|/, ''), // remove the `oauth2|` prefix that Auth0 prepends for custom oauth connections
    R.split('|'),
    R.head,
    R.replace('auth0', AUTH0_CONNECTIONS.EMAIL_PASSWORD), // allows us to use getConnection with auth_id instead of information from profile
)

export const getProvider = R.pipe<string, string[], string>(R.split('|'), R.head)

export const getToken = (ctx: NextPageContext) => {
    return parseCookies(ctx).id_token
}
export const getAccessToken = (ctx: NextPageContext) => {
    return parseCookies(ctx).access_token
}

export const getProfile = (ctx: NextPageContext) => {
    const profileRaw = parseCookies(ctx).profile

    return profileRaw ? JSON.parse(profileRaw) : {}
}

export const loggedIn = (ctx: NextPageContext) => {
    return !!getToken(ctx)
}

export const setLoginCookies = (
    {
        id_token,
        profile,
        access_token,
    }: { id_token: string; profile?: string; access_token: string },
    ctx: ContextObjectLike = null,
) => {
    setCookie(ctx, 'id_token', id_token)
    setCookie(ctx, 'access_token', access_token)
    if (profile) setCookie(ctx, 'profile', profile)
}

export const destroyLoginCookies = (ctx: ContextObjectLike = null) => {
    destroyCookie(ctx, 'id_token')
    destroyCookie(ctx, 'access_token')
    destroyCookie(ctx, 'profile')
}

export const getAccessTokenFromCookies = (ctx?: ContextObjectLike) => {
    return parseCookies(ctx).access_token
}
