import { RefObject, useEffect } from 'react'
import { nextTick } from '../utils'

export function useOnClickOutside(
    ref: RefObject<HTMLElement>,
    handler: (event: MouseEvent) => void,
) {
    useEffect(
        () => {
            const listener = (event: MouseEvent) => {
                // Do nothing if clicking ref's element or descendent elements
                if (
                    !ref.current ||
                    ref.current.contains(event.target as HTMLElement) ||
                    !document.body.contains(event.target as HTMLElement)
                ) {
                    return
                }

                handler(event)
            }

            // add listener on next tick, else this will still register the click on a modal open button
            nextTick(() => document.addEventListener('click', listener))

            return () => {
                document.removeEventListener('click', listener)
            }
        },
        // Add ref and handler to effect dependencies
        // It's worth noting that because passed in handler is a new ...
        // ... function on every render that will cause this effect ...
        // ... callback/cleanup to run every render. It's not a big deal ...
        // ... but to optimize you can wrap handler in useCallback before ...
        // ... passing it into this hook.
        [ref, handler],
    )
}
