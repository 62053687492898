import NextJSLink from 'next/link'
import { Link as ChakraLink, forwardRef, LinkProps as ChakraLinkProps } from '@chakra-ui/react'
import { NextRouter, useRouter } from 'next/router'
import cx from 'classnames'
import { routerHashNavigation } from '@upvestcz/common/routing'
import { Locale } from '@upvestcz/common/i18n/locales'

type LinkPropsType = {
    onClick?: React.EventHandler<React.MouseEvent>
    'data-exact'?: boolean
    'data-active'?: boolean
    target?: string
    rel?: string
}

const isUrlExternal = (url: string) => {
    return url.startsWith('http') || url.startsWith('mailto:') || url.startsWith('tel:')
}

const isUrlHash = (url: string) => url.startsWith('#')

const getIsActive = ({
    router,
    to,
    asPath,
    isActive,
}: {
    isActive?: boolean
    router: NextRouter
    to?: string
    asPath?: string
}) => {
    // if we handle active state ourselves via prop
    if (typeof isActive === 'boolean') {
        return isActive
    }

    if (!router || !to) return false

    // out heuristic for determining active state
    return (
        router.pathname.includes(to) ||
        router.asPath.includes(to) ||
        (!!asPath && router.pathname.includes(asPath)) ||
        (!!asPath && router.asPath.includes(asPath))
    )
}

const getIsExact = ({
    router,
    to,
    asPath,
    isActive,
}: {
    router: NextRouter
    to?: string
    asPath?: string
    isActive?: boolean
}) => {
    // if we handle active state ourselves via prop
    if (typeof isActive === 'boolean') {
        return isActive
    }

    if (!router || !to) return false

    // out heuristic for determining active state
    return (
        router.pathname === to ||
        router.asPath === to ||
        (asPath && router.pathname === asPath) ||
        (asPath && router.asPath === asPath)
    )
}

const getHashLinkClickHandler =
    (
        onClick?: React.EventHandler<React.MouseEvent>,
        { replace, scroll }: { replace?: boolean; scroll?: boolean } = {},
    ) =>
    async (e: React.MouseEvent) => {
        onClick && onClick(e)
        // prevent default hash behaviour causing
        // an undesirable scroll
        !e.defaultPrevented && e.preventDefault()
        const href = decodeURIComponent(e.currentTarget.getAttribute('href') ?? '')

        await routerHashNavigation(href, { replace, scroll })
    }

export type LinkProps = {
    blank?: boolean
    isActive?: boolean
    to?: string
    className?: string
    activeClassName?: string
    'aria-label'?: string
    'data-test-id'?: string
    locale?: Locale
    exact?: boolean
    asPath?: string
    scroll?: boolean
    shallow?: boolean
    replace?: boolean
    onClick?: React.EventHandler<React.MouseEvent>
} & ChakraLinkProps

export const useLink = (props: LinkProps) => {
    const router = useRouter()
    const isExternal = isUrlExternal(props.to || '')
    const isHash = isUrlHash(props.to || '')
    const isActive = getIsActive({ router, ...props })
    const isExact = getIsExact({ router, ...props })
    const linkProps: LinkPropsType = {}
    if (isActive)
        Object.assign(linkProps, { 'data-active': true, 'data-exact': isExact ? true : undefined })
    if (props.blank) Object.assign(linkProps, { target: '_blank', rel: 'noopener noreferrer' })
    if (isHash)
        Object.assign(linkProps, {
            onClick: getHashLinkClickHandler(props.onClick, {
                replace: props.replace,
                scroll: props.scroll,
            }),
        })

    return { isActive, isExact, linkProps, isExternal, isHash }
}

const Link = forwardRef<LinkProps, 'a'>(function Link(props, ref) {
    const {
        to,
        children,
        className, // todo: remove this when legacy SCSS components are removed
        activeClassName, // todo: remove this when legacy SCSS components are removed
        exact,
        'aria-label': ariaLabel,
        'data-test-id': dataTestId,
        locale,
        asPath,
        shallow,
        scroll,
        replace,
        ...restProps
    } = props
    const { linkProps, isExternal, isActive, isExact } = useLink(props)

    delete restProps.blank // do not pass to dom
    delete restProps.isActive // do not pass to dom

    const renderLinkComponent = ({ href }: { href?: string } = {}) => (
        <ChakraLink
            ref={ref}
            aria-label={ariaLabel}
            data-test-id={dataTestId}
            href={href}
            className={cx(
                className,
                activeClassName
                    ? {
                          [activeClassName]: exact ? isExact : isActive,
                      }
                    : null,
            )}
            locale={locale}
            {...linkProps}
            {...restProps}
            onClick={linkProps.onClick || restProps.onClick}
        >
            {children}
        </ChakraLink>
    )

    if (isExternal || !to) return renderLinkComponent({ href: to })

    return (
        <NextJSLink
            locale={locale}
            href={to}
            shallow={shallow}
            as={asPath}
            scroll={scroll}
            replace={replace}
            passHref
        >
            {renderLinkComponent()}
        </NextJSLink>
    )
})

export default Link
