import { useIsMounted } from '@upvestcz/common/hooks'
import { parseCookies } from '@upvestcz/common/cookies'
import { CURRENCIES } from '@upvestcz/common/currency'
import { gql, useQuery } from '@apollo/client'
import * as R from 'ramda'
import { useEffect } from 'react'
import { hasAccountFinishedRegistration } from '@upvestcz/common/account-utils'
import { MyAccountPollingUpdateQuery } from '@upvestcz/common/graphql/typegen'
import { useAccount } from '../store/account'
import logger from './logger'
import { useAuth } from '../store/auth'

export const getReferralLink = (account: { referral_code?: Maybe<string> }) =>
    `${process.env.APP_URL}${account.referral_code ? `/r/${account.referral_code}` : ''}`

export const useHasEurSwitcherAllowed = (override?: boolean) => {
    const isMounted = useIsMounted()
    const [account] = useAccount()
    const { currency = CURRENCIES.CZK } = parseCookies()
    return (
        isMounted &&
        (override ||
            currency !== CURRENCIES.CZK || // if currency cookie set to other than default.
            !!account?.balance_eur ||
            !!account?.transactions_eur_sum ||
            !!account?.investments_eur_sum)
    )
}

type AccountClubFlags = {
    is_priv_member: boolean
    is_club_member: boolean
    kb_flag: boolean
    kb_employee: boolean
}

export const getClubFlags = (account: Maybe<AccountClubFlags>) => {
    if (account) return account
    return {
        is_priv_member: false,
        is_club_member: false,
        kb_flag: false,
        kb_employee: false,
    }
}

export const useAccountPolling = () => {
    const [account, accountDispatch] = useAccount()
    const [auth] = useAuth()
    const hasUnfinishedRegistration = !hasAccountFinishedRegistration(account)

    const { data, stopPolling } = useQuery<MyAccountPollingUpdateQuery>(
        gql`
            query myAccountPollingUpdate {
                myAccount {
                    id
                    status
                    account_stats {
                        balance: balance(currency: CZK)
                        balance_eur: balance(currency: EUR)
                    }
                }
            }
        `,
        {
            skip: !auth.loggedIn || !account || hasUnfinishedRegistration,
            pollInterval: account ? 30000 : 0,
            onError: () => {
                stopPolling()
                logger.error(`Account balance polling failed for account ${account?.id}`)
            },
        },
    )

    useEffect(() => {
        if (data?.myAccount) {
            const { account_stats, ...myAccount } = R.omit(['__typename', 'id'], data.myAccount)

            const flatAccount = {
                ...myAccount,
                ...account_stats,
            }
            // if values are the same, do not update
            if (R.whereEq(flatAccount, account)) return

            accountDispatch({
                type: 'update',
                payload: flatAccount,
            })
        }
    }, [
        // account - is left out on purpose. we don't want to update store with query data when we update the store manually.
        accountDispatch,
        data,
    ])
}
