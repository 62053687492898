import { CSPDefinitionObject, mergeCSPDefinitionsToString, POLICIES } from '@upvestcz/common/csp'

export function getCSPHeaderValue({ nonce }: { nonce: string }) {
    const csp: CSPDefinitionObject = {
        'img-src': ['blob:'],
        'script-src': ["'strict-dynamic'", `'nonce-${nonce}'`],
        'connect-src': [`'nonce-${nonce}'`],
        'object-src': ["'none'"],
    }

    return mergeCSPDefinitionsToString([
        POLICIES.BASE_POLICY,
        csp,
        POLICIES.googleTagManager,
        POLICIES.googleAnalytics4,
        POLICIES.googleAds,
        POLICIES.googleFonts,
        POLICIES.s3CDNPublic,
        POLICIES.s3CDNPrivate,
        POLICIES.intercom,
        POLICIES.facebookPixel,
        POLICIES.imgix,
        POLICIES.sentry,
        POLICIES.satismeter,
    ])
}
