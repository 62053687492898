/// <reference types="next/image-types/global" />
import React from 'react'
import { ImgProps, useColorMode } from '@chakra-ui/react'
import { MergeChakraProps } from '@upvestcz/common/ts-utils'
import Img, { CustomImgProps } from './Img'
import logo_black from './images/upvest_logo_black.svg'
import logo_white from './images/upvest_logo_white.svg'

type Props = MergeChakraProps<
    {
        variant?: 'light' | 'dark'
    } & CustomImgProps,
    ImgProps
>

const Logo = ({ variant = 'dark', ...props }: Props) => {
    const { colorMode } = useColorMode()
    // XNOR between variant and colorMode
    const isDark = (variant === 'dark') === (colorMode !== 'dark')
    const logo = isDark ? logo_black : logo_white
    return <Img h={{ base: 8, xl: '36px' }} alt="Upvest logo" src={logo.src} {...props} priority />
}

export default Logo
