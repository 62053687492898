const progress = {
    baseStyle: {
        filledTrack: {
            bgColor: 'primary',
        },
        track: {
            bgColor: 'grey',
        },
    },
    variants: {
        error: {
            filledTrack: {
                bgColor: 'red',
            },
        },
    },
}

export default progress
