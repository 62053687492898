/* eslint-disable @typescript-eslint/no-explicit-any */
import moment from 'moment'
import { Locale, LOCALES } from './locales'
import { CURRENCIES } from '../currency'
import { getLocalCurrencyName, toFixedWithoutRounding } from '../utils'

export type FormatterFunction = (value: any, lng?: string) => string

export function formatAmount(value: any, lng?: string) {
    const amount = typeof value === 'object' ? value : { value }

    if (amount.value === null || amount.value === undefined || amount.value === '') {
        return ''
    }

    const minDecimals = value.minDecimals ?? value.decimals ?? 0
    const maxDecimals = value.maxDecimals ?? value.decimals ?? 2

    const formattedValue = toFixedWithoutRounding(amount.value, maxDecimals).toLocaleString(lng, {
        minimumFractionDigits: minDecimals,
        maximumFractionDigits: maxDecimals,
    })
    const currencyString = amount.currency
        ? `\u00a0${getLocalCurrencyName(amount.currency, lng as Locale)}`
        : ''

    return `${formattedValue}${currencyString}`
}

export function formatDayMonthYear(value: any, lng?: string) {
    const formatValue = lng === LOCALES.CS ? 'D. M. YYYY' : 'D/M/YYYY'

    return moment(value).format(formatValue)
}

export function formatDateTime(value: any, lng?: string) {
    const formatValue = lng === LOCALES.CS ? 'D. M. YYYY, H:mm' : 'D/M/YYYY, H:mm'

    return moment(value).format(formatValue)
}

export function formatCurrency(value: any, lng?: string) {
    const currencies = {
        cs: {
            [CURRENCIES.CZK]: 'Kč',
            [CURRENCIES.EUR]: '€',
        },
        en: {
            [CURRENCIES.CZK]: 'CZK',
            [CURRENCIES.EUR]: '€',
        },
    } as const

    return currencies[lng as 'cs' | 'en'][value as keyof typeof CURRENCIES]
}

export const formatInterest = (low: number, high?: number, withPercent = false) => {
    const maybePercentSign = withPercent ? '%' : ''

    if (high && high !== low) {
        return `${low.toLocaleString('cs', {
            maximumFractionDigits: 2,
        })}${maybePercentSign} - ${high.toLocaleString('cs', {
            maximumFractionDigits: 2,
        })}${maybePercentSign}`
    }
    return `${low.toLocaleString('cs', {
        maximumFractionDigits: 2,
    })}${maybePercentSign}`
}
