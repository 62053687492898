import { phone as parsePhone } from 'phone'
import * as R from 'ramda'
import { AnySchema, TestContext, object, TestFunction } from 'yup'
import { getBirthDate } from '../account-utils'
import { MaybePromise } from '../ts-utils'

export const isPhoneNumberValid = ({
    phone,
    phone_country_code,
}: {
    phone?: string | number
    phone_country_code: string | number
}) => typeof phone !== 'undefined' && !!parsePhone(`+${phone_country_code}${phone}`).isValid

const getAge = (dateString: string) => {
    const today = new Date()
    const birthDate = new Date(dateString)
    let age = today.getFullYear() - birthDate.getFullYear()
    const m = today.getMonth() - birthDate.getMonth()
    if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
        // eslint-disable-next-line no-plusplus
        age--
    }
    return age
}

export const getAgeFromPid = (pid: string) => {
    const birthDate = getBirthDate({ pid })
    if (!birthDate) return 0

    return getAge(birthDate.toISOString())
}

export const validatePidAge = (pid: string) => {
    const AGE_THRESHOLD = 18
    return getAgeFromPid(pid) >= AGE_THRESHOLD
}

// taken over from https://www.zizka.ch/pages/programming/ruzne/rodne-cislo-identifikacni-cislo-rc-ico-kontrola-validace.html
export const validatePid = (pid: string) => {
    const sanitizedPid = pid.replace('/', '')

    try {
        if (sanitizedPid.length === 0) return true
        if (sanitizedPid.length < 9) throw new Error()
        let year = parseInt(sanitizedPid.substr(0, 2), 10)
        let month = parseInt(sanitizedPid.substr(2, 2), 10)
        const day = parseInt(sanitizedPid.substr(4, 2), 10)
        // const ext = parseInt(sanitizedPid.substr(6, 3), 10) // unused
        if (sanitizedPid.length === 9 && year < 54) return true
        let c = 0
        if (sanitizedPid.length === 10) c = parseInt(sanitizedPid.substr(9, 1), 10)
        let m = parseInt(sanitizedPid.substr(0, 9), 10) % 11
        if (m === 10) m = 0
        if (m !== c) throw new Error()
        year += year < 54 ? 2000 : 1900
        if (month > 70 && year > 2003) month -= 70
        else if (month > 50) month -= 50
        else if (month > 20 && year > 2003) month -= 20
        const d = new Date()
        if (year > d.getFullYear()) throw new Error()
        if (month === 0) throw new Error()
        if (month > 12) throw new Error()
        if (day === 0) throw new Error()
        if (day > 31) throw new Error()
    } catch (e) {
        return false
    }
    return true
}

// This helper function enhances a validation schema field of an async
// validation function (such as if an email or username is taken). The point is
// to only perform the async validation (which usually involves sending an API
// request to the server) once all the sync (think client-side) validation
// checks passed to prevent hitting the validation endpoint in an excessive
// manner and/or with invalid data
export const chainAsyncValidation = ({
    syncValidation,
    validationName,
    validationErrorMessage,
    validationFn,
}: {
    syncValidation: AnySchema
    validationName: string
    validationErrorMessage: string
    validationFn: (val: any, testContext?: TestContext) => MaybePromise<boolean>
}) => {
    const syncValidationClone = syncValidation.clone()

    return syncValidation.test(
        validationName,
        validationErrorMessage,
        async (value, testContext) => {
            if (!(await syncValidationClone.isValid(value))) {
                return false
            }

            return validationFn(value, testContext)
        },
    )
}

export const nonexclusiveMax = (max: number, message: string) => ({
    message,
    name: 'nonexclusiveMax',
    exclusive: false,
    params: { max },
    test: (value: number | null | undefined) => R.isNil(value) || value <= max,
})

export const makeAllEntriesOptional = (obj: Record<string, AnySchema>) =>
    Object.fromEntries(
        Object.entries(obj).map(([key, value]: [string, AnySchema]) => {
            if (value.type === 'array') {
                return [key, value.notRequired().min(0)]
            }

            return [key, value.notRequired()]
        }),
    )

export const makeAllEntriesNullable = (obj: Record<string, AnySchema>) =>
    Object.fromEntries(
        Object.entries(obj).map(([key, value]: [string, AnySchema]) => [key, value.nullable()]),
    )

export const makeAllInputsOptional = (inputs: Record<string, AnySchema>) => {
    return object().shape(makeAllEntriesOptional(inputs))
}

export const makeAllInputsRequired = (inputs: Record<string, AnySchema>) => {
    return object().shape(
        Object.fromEntries(
            Object.entries(inputs).map(([key, value]: [string, AnySchema]) => [
                key,
                value.required(),
            ]),
        ),
    )
}

// util for custom test functions that should pass if the value is nullable and/or optional
export const handleNullNotRequired = (testFunction: TODO) => {
    return (...props: Parameters<TestFunction>) => {
        const [value, ctx] = props

        if (ctx.schema.spec.nullable && value === null) {
            return true
        }

        if (ctx.schema.spec.presence === 'optional' && !value) {
            return true
        }

        return testFunction(value, ctx)
    }
}
