import * as R from 'ramda'
import React, { ReactNode } from 'react'
import { LayerStyle } from '../theme/v3/foundations/layerStyles'
import { LayerStyleProvider, useLayerStyleContext } from './providers/layerStyleContext'

type Props = {
    layerStyle?: LayerStyle
    children: ReactNode | ((props: Record<string, any>) => ReactNode)
}

function isChakraComponent(element: React.ReactElement): boolean {
    // @ts-ignore
    // eslint-disable-next-line no-underscore-dangle
    return element.type.__emotion_base
}

export const ApplyLayerStyle = ({ children, layerStyle: layerStyleProp }: Props) => {
    /*
     return mapped children with added props
     layerStyle={layerStyle} // only if child is chakra component
     data-layerstyle={layerStyle}
     */
    const contextLayerStyle = useLayerStyleContext()
    const layerStyle = layerStyleProp || contextLayerStyle!

    const propsToPass = {
        /*
         * data prop is used to allow customizations of styling for each layerStyle. Inspired by https://github.com/chakra-ui/chakra-ui/issues/2231#issuecomment-771531813
         */
        'data-layerstyle': layerStyle,
        layerStyle,
    }

    return (
        <LayerStyleProvider layerStyle={layerStyle}>
            {typeof children === 'function'
                ? children(propsToPass)
                : React.Children.map(children, child => {
                      if (React.isValidElement(child)) {
                          return React.cloneElement(
                              child,
                              R.omit(!isChakraComponent(child) ? ['layerStyle'] : [], propsToPass),
                          )
                      }
                      return child
                  })}
        </LayerStyleProvider>
    )
}
