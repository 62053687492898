import React, { createContext, useCallback, useContext, useState } from 'react'
import { Decimal, Numeric } from 'decimal.js-light'

type ScreenBottomStackingContextType = {
    elements: Record<string, number>
    registerElement: (id: string, height: number) => void
    total: number
}

const ScreenBottomStackingContext = createContext<ScreenBottomStackingContextType | undefined>(
    undefined,
)

export const useScreenBottomStackingContext = () => {
    const context = useContext(ScreenBottomStackingContext)

    if (context === undefined) {
        return {
            elements: {},
            registerElement: () => {
                // noop
            },
            total: 0,
        } as ScreenBottomStackingContextType
    }

    return context as ScreenBottomStackingContextType
}

const sumAll = (values: Numeric[]) =>
    values.reduce((acc: Decimal, value) => acc.add(value), new Decimal(0))

export function ScreenBottomStackingContextProvider({ children }: { children: React.ReactNode }) {
    const [elements, setElements] = useState<Record<string, number>>({})

    const registerElement = useCallback(
        (id: string, height: number) => {
            if (elements[id] !== height) setElements(prev => ({ ...prev, [id]: height }))
        },
        [elements],
    )

    return (
        <ScreenBottomStackingContext.Provider
            value={{
                elements,
                registerElement,
                total: sumAll(Object.values(elements)).toNumber(),
            }}
        >
            {children}
        </ScreenBottomStackingContext.Provider>
    )
}
