import React from 'react'
import {
    ContentContainer,
    Flex,
    Box,
    icons,
    useMultiStyleConfig,
    BoxProps,
} from '@upvestcz/shared-components'
import { MergeChakraProps } from '@upvestcz/common/ts-utils'

type Props = MergeChakraProps<
    {
        size?: 'sm' | 'md'
        bgColor?: string
    },
    BoxProps
>
const InfoAlert = ({ children, bgColor = 'primary', size, ...restProps }: Props) => {
    const styles = useMultiStyleConfig('InfoAlert', {
        size,
        styleConfig: {
            parts: ['Root', 'Icon', 'InnerContainer'],
            baseStyle: {
                Root: {
                    maxWidth: '1440px',
                    margin: '0 auto',
                    overflow: 'hidden',
                },
                Icon: {
                    mr: 6,
                    color: 'primary',
                },
                InnerContainer: {
                    flexDirection: 'row nowrap',
                    alignItems: 'center',
                    position: 'relative',
                    zIndex: 0,
                    py: 4,
                    pr: '6.2%', // 72px/1160 from figma

                    '&::before': {
                        content: '""',
                        zIndex: -1,
                        position: 'absolute',
                        right: { base: '-999px', md: 0 },
                        left: '-9999px',
                        top: 0,
                        bottom: 0,
                        background: bgColor,
                        opacity: 0.2,
                        transform: { md: 'skewX(-24.228deg)' },
                        transformOrigin: 'top right',
                    },
                },
            },
            sizes: {
                sm: {
                    Icon: {
                        mr: 2,
                        color: 'primary',
                    },
                    InnerContainer: {
                        py: 2,

                        '&::before': {
                            right: '-999px',
                        },
                    },
                },
            },
        },
    })

    const containerProps = size === 'sm' ? { px: 4 } : {}

    return (
        <Box as="section" sx={styles.Root} {...restProps}>
            <ContentContainer {...containerProps}>
                <Flex sx={styles.InnerContainer}>
                    <icons.InformationIcon sx={styles.Icon} />
                    {children}
                </Flex>
            </ContentContainer>
        </Box>
    )
}

export default InfoAlert
