import * as R from 'ramda'
import crypto from 'crypto'
import { v4 as uuidv4 } from 'uuid'
import { Response } from 'express'
import { NextPageContext } from 'next'
import { getApiUrl } from './api'
import { define } from './ts-utils'

export type CSPDefinitionObject = {
    'default-src'?: Readonly<string[]>
    'script-src'?: Readonly<string[]>
    'img-src'?: Readonly<string[]>
    'font-src'?: Readonly<string[]>
    'connect-src'?: Readonly<string[]>
    'style-src'?: Readonly<string[]>
    'object-src'?: Readonly<string[]>
    // others fallback to 'default-src'
}

export function generateNonce() {
    return Buffer.from(uuidv4()).toString('base64')
}

export const setCSPHeaderOnServerResponse = (
    getCSPHeaderValueFn: ({ nonce }: { nonce: string }) => string,
    ctx?: Maybe<NextPageContext>,
) => {
    let nonce
    if (ctx?.res && process.env.NODE_ENV === 'production') {
        const res = ctx.res as Response
        nonce = generateNonce()
        res.locals = {
            ...res.locals,
            nonce,
        }

        ctx.res.setHeader('Content-Security-Policy', getCSPHeaderValueFn({ nonce }))
    }
    return nonce
}

export const getNonceFromServerResponse = (ctx?: Maybe<NextPageContext>) => {
    if (ctx?.res) {
        const res = ctx.res as Response
        return res.locals?.nonce as string
    }
    return undefined
}

export const cspHashOf = (text: string) => {
    const hash = crypto.createHash('sha256')
    hash.update(text)
    return `'sha256-${hash.digest('base64')}'`
}

// function to merge many CSPDefinitionObjects into one, concatting the value arrays using Ramda
function mergeCSPDefinitions(cspDefinitions: CSPDefinitionObject[]): CSPDefinitionObject {
    const mergedDefinitions = cspDefinitions.reduce((acc, cspDef) => {
        // merges the arrays for each key in the two CSPDefinitionObjects
        return R.mergeWith(R.concat, acc, cspDef)
    }, {})

    return R.mapObjIndexed(
        R.uniq, // removes duplicates from the arrays for each directive
        mergedDefinitions,
    )
}

// function to merge many CSPDefinitionObjects into one, concatting the value arrays using Ramda
// and then joining the values into a single string
export function mergeCSPDefinitionsToString(cspDefinitions: CSPDefinitionObject[]): string {
    const merged = mergeCSPDefinitions(cspDefinitions)
    return Object.entries(merged)
        .map(([key, value]) => {
            return `${key} ${value.join(' ')}`
        })
        .join('; ')
}

const API_URL = getApiUrl()

export const POLICIES = {
    BASE_POLICY: define<CSPDefinitionObject>({
        'default-src': ["'self'"],
        'script-src': ["'self'", '*.upvest.cz', 'upvest.cz', "'unsafe-eval'"],
        'img-src': ["'self'", 'https:', 'data:'],
        'font-src': ["'self'", 'data:'],
        'connect-src': ["'self'", API_URL, '*.upvest.cz', 'upvest.cz'],
        'style-src': ["'self'", "'unsafe-inline'"],
    }),
    googleTagManager: define<CSPDefinitionObject>({
        'script-src': [
            "'unsafe-inline'",
            "'unsafe-eval'",
            'https://www.googletagmanager.com',
            'https://tagmanager.google.com',
        ],
        'style-src': [
            'https://tagmanager.google.com',
            'https://fonts.googleapis.com',
            'https://www.gstatic.com',
        ],
        'img-src': [
            'https://www.googletagmanager.com',
            'https://ssl.gstatic.com',
            'https://www.gstatic.com',
        ],
        'font-src': ['https://fonts.gstatic.com', 'https://fonts.googleapis.com', 'data:'],
    }),
    googleAnalytics4: define<CSPDefinitionObject>({
        'script-src': ['https://*.googletagmanager.com'],
        'img-src': ['https://*.google-analytics.com', 'https://*.googletagmanager.com'],
        'connect-src': [
            'https://*.google-analytics.com',
            'https://*.analytics.google.com',
            'https://*.googletagmanager.com',
            'https://*.g.doubleclick.net',
        ],
    }),

    googleAds: define<CSPDefinitionObject>({
        'script-src': [
            'https://www.googleadservices.com',
            'https://www.google.com',
            'https://googleads.g.doubleclick.net',
        ],
        'img-src': ['https://googleads.g.doubleclick.net', 'https://www.google.com'],
        'default-src': [
            'https://bid.g.doubleclick.net',
            'https://*.doubleclick.net',
            'https://www.google.com',
        ],
        'connect-src': [
            'https://*.g.doubleclick.net',
            'https://*.googlesyndication.com',
            'https://*.google.com',
            'https://google.com',
        ],
    }),
    googleFonts: define<CSPDefinitionObject>({
        'font-src': ['fonts.gstatic.com'],
        'style-src': ['fonts.googleapis.com'],
    }),
    s3CDNPublic: define<CSPDefinitionObject>({
        'default-src': ['https://d3uvu5yktntepe.cloudfront.net'],
        'font-src': ['https://d3uvu5yktntepe.cloudfront.net'],
        'connect-src': ['https://upvest-public.s3.eu-central-1.amazonaws.com'],
    }),
    s3CDNPrivate: define<CSPDefinitionObject>({
        // private bucket - app and admin only
        'connect-src': [
            'https://upvest-bucket.s3.eu-central-1.amazonaws.com',
            'https://upvest-bucket-dev.s3.eu-central-1.amazonaws.com',
        ],
    }),
    intercom: define<CSPDefinitionObject>({
        'script-src': [
            'https://app.intercom.io',
            'https://widget.intercom.io',
            'https://js.intercomcdn.com',
        ],
        'connect-src': [
            'https://via.intercom.io',
            'https://api.intercom.io',
            'https://api.au.intercom.io',
            'https://api.eu.intercom.io',
            'https://api-iam.intercom.io',
            'https://api-iam.eu.intercom.io',
            'https://api-iam.au.intercom.io',
            'https://api-ping.intercom.io',
            'https://nexus-websocket-a.intercom.io',
            'wss://nexus-websocket-a.intercom.io',
            'https://nexus-websocket-b.intercom.io',
            'wss://nexus-websocket-b.intercom.io',
            'https://nexus-europe-websocket.intercom.io',
            'wss://nexus-europe-websocket.intercom.io',
            'https://nexus-australia-websocket.intercom.io',
            'wss://nexus-australia-websocket.intercom.io',
            'https://uploads.intercomcdn.com',
            'https://uploads.intercomcdn.eu',
            'https://uploads.au.intercomcdn.com',
            'https://uploads.intercomusercontent.com',
            'https://widget.intercom.io',
        ],
        'default-src': [
            'https://intercom-sheets.com',
            'https://www.intercom-reporting.com',
            'https://www.youtube.com',
            'https://player.vimeo.com',
            'https://fast.wistia.net',
            'https://intercom.help',
            'https://api-iam.intercom.io',
            'https://api-iam.eu.intercom.io',
            'https://api-iam.au.intercom.io',
            'https://js.intercomcdn.com',
        ],
        'font-src': ['https://js.intercomcdn.com', 'https://fonts.intercomcdn.com'],
        'img-src': [
            'blob:',
            'data:',
            'https://js.intercomcdn.com',
            'https://static.intercomassets.com',
            'https://downloads.intercomcdn.com',
            'https://downloads.intercomcdn.eu',
            'https://downloads.au.intercomcdn.com',
            'https://uploads.intercomusercontent.com',
            'https://gifs.intercomcdn.com ',
            'https://video-messages.intercomcdn.com',
            'https://messenger-apps.intercom.io',
            'https://messenger-apps.eu.intercom.io',
            'https://messenger-apps.au.intercom.io',
            'https://*.intercom-attachments-1.com',
            'https://*.intercom-attachments.eu',
            'https://*.au.intercom-attachments.com',
            'https://*.intercom-attachments-2.com',
            'https://*.intercom-attachments-3.com',
            'https://*.intercom-attachments-4.com',
            'https://*.intercom-attachments-5.com',
            'https://*.intercom-attachments-6.com',
            'https://*.intercom-attachments-7.com',
            'https://*.intercom-attachments-8.com',
            'https://*.intercom-attachments-9.com',
            'https://static.intercomassets.eu',
            'https://static.au.intercomassets.com',
        ],
        'style-src': ['unsafe-inline'],
    }),
    facebookPixel: define<CSPDefinitionObject>({
        'script-src': ['https://connect.facebook.net', '*.facebook.com', 'facebook.com'],
        'connect-src': ['*.facebook.com', 'facebook.com'],
    }),
    vercelSpeedInsights: define<CSPDefinitionObject>({
        'connect-src': ['https://vitals.vercel-insights.com'],
        'script-src': ['https://vercel.live', 'cdn.vercel-insights.com', 'vercel.live'],
    }),
    imgix: define<CSPDefinitionObject>({
        'default-src': ['https://upvest.imgix.net'],
        'img-src': ['https://upvest.imgix.net'],
    }),
    sentry: define<CSPDefinitionObject>({
        'connect-src': ['*.sentry.io'],
    }),
    satismeter: define<CSPDefinitionObject>({
        'default-src': ['https://app.satismeter.com'],
        'script-src': ['https://app.satismeter.com'],
        'connect-src': ['https://app.satismeter.com'],
    }),
} as const
