import { useCallback, useRef } from 'react'
import {
    Button as ChakraButton,
    ButtonProps as ChakraButtonProps,
    Text,
    forwardRef,
    Box,
    useMergeRefs,
} from '@chakra-ui/react'
import cx from 'classnames'
import gsap from 'gsap'
import { useGSAP } from '@gsap/react'
import { MergeChakraProps } from '@upvestcz/common/ts-utils'
import { Dict } from '@chakra-ui/utils'
import { LineLoader } from './LineLoader'

export type ButtonProps = MergeChakraProps<
    {
        withAnimatedIcons?: boolean
    },
    ChakraButtonProps
>

const Button = forwardRef<ButtonProps, 'button'>(function Button(
    { children, withAnimatedIcons, ...props },
    ref,
) {
    const styles: Dict = {
        Root: {
            sx: {
                '.chakra-button__spinner': {
                    position: 'initial',
                    mr: 0,
                },
            },
        },
        Spinner: {
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
        },
        InnerSpan: {
            display: 'inherit',
            textAlign: 'inherit',
            justifyContent: 'inherit',
            alignItems: 'inherit',
        },
    } as const

    const el = useRef<HTMLButtonElement>()
    const tl = useRef<GSAPTimeline>()

    useGSAP(
        () => {
            if (withAnimatedIcons) {
                tl.current = gsap
                    .timeline()
                    .to('.Button__icon--right svg', {
                        duration: 0.1,
                        xPercent: 100,
                    })
                    .to(
                        '.Button__content',
                        {
                            duration: 0.15,
                            x: '0.7em',
                        },
                        '+=0.05',
                    )
                    .to(
                        '.Button__icon--left svg',
                        {
                            duration: 0.15,
                            xPercent: 100,
                        },
                        '<',
                    )
                    .reverse()
            }
        },
        { scope: el },
    )

    const handleMouseEnter = useCallback(() => {
        if (withAnimatedIcons) tl.current?.reversed(false)
    }, [withAnimatedIcons])
    const handleMouseLeave = useCallback(() => {
        if (withAnimatedIcons) tl.current?.reversed(true)
    }, [withAnimatedIcons])

    const refs = useMergeRefs(el, ref)

    const iconProps =
        withAnimatedIcons && props.rightIcon
            ? {
                  leftIcon: (
                      <Box
                          as="span"
                          display="inline-flex"
                          className="Button__icon--left"
                          sx={{
                              overflow: 'hidden',
                              minWidth: 'min-content',
                              flex: '0 0 auto',
                              _before: {
                                  display: 'inline-block',
                                  content: '""',
                                  ml: '-100%',
                              },
                          }}
                      >
                          {props.rightIcon}
                      </Box>
                  ),
                  rightIcon: (
                      <Box
                          as="span"
                          display="inline-flex"
                          className="Button__icon--right"
                          sx={{
                              ml: 2,
                              overflow: 'hidden',
                              minWidth: 'min-content',
                              flex: '0 0 auto',
                          }}
                      >
                          {props.rightIcon}
                      </Box>
                  ),
              }
            : {}

    return (
        <ChakraButton
            loadingText={children as string}
            spinner={<LineLoader {...styles.Spinner} />}
            position="relative"
            className={cx({
                'Button--with-animatted-icons': withAnimatedIcons,
            })}
            ref={refs}
            {...styles.Root}
            {...props}
            {...iconProps}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
        >
            {/* wrapped in span due to errors caused by google translate DOM node modifications */}
            <Text as="span" className="Button__content" {...styles.InnerSpan}>
                {children}
            </Text>
        </ChakraButton>
    )
})

export default Button
