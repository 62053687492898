import { mapObjIndexed } from 'ramda'
import { CONTAINER_PX } from '../variables'

export default {
    baseStyle: {
        px: CONTAINER_PX,
        width: '100%',
        maxWidth: mapObjIndexed(val => `${1160 + val * 2 * 4}px`, CONTAINER_PX),
        mx: 'auto',
    },
}
