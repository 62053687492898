import { useCallback, useMemo, useState } from 'react'

export type CallbackRef<T> = {
    (node: T): void
    current: T | undefined
}
export const useCallbackRef = <T>(defaultValue?: T) => {
    const [current, setCurrent] = useState(defaultValue)

    const callbackFn = (node: T) => {
        if (node !== null) {
            setCurrent(node)
        }
    }
    callbackFn.current = defaultValue

    const callbackRef = useCallback(callbackFn, [])

    return useMemo(() => {
        callbackRef.current = current
        return callbackRef
    }, [current, callbackRef])
}
