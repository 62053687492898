import React, { ReactNode } from 'react'
import { Box, BoxProps } from '@chakra-ui/react'
import { MergeChakraProps } from '@upvestcz/common/ts-utils'
import { Dict } from '@chakra-ui/utils'

type OwnProps = { children: ReactNode }

type Props = MergeChakraProps<OwnProps, BoxProps>

const AnnouncementBar = ({ children, ...props }: Props) => {
    const styles: Dict = {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        background: '#F6F6F6',
        py: 1,
        px: 6,
        fontFamily: 'body',
        fontSize: '12px',
        lineHeight: 1.6,
        fontWeight: 500,
        position: 'relative',
        zIndex: 1,
        textAlign: 'center',
    }
    return (
        <Box {...styles} {...props}>
            {children}
        </Box>
    )
}

export default AnnouncementBar
