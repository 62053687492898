import { pxToRem } from '@upvestcz/common/styles-utils'

export default {
    fonts: {
        body: "'Moderat', 'Moderat: fallback', 'Trebuchet MS', Helvetica, sans-serif",
        heading: "'Brick Display', 'Brick Display: fallback', 'Times New Roman', serif",
        monospaceNumbers: "'Roboto', sans-serif",
        mono: "'Roboto', sans-serif",
    },
    fontSizes: {
        xs: pxToRem(12),
        xssm: pxToRem(13),
        sm: pxToRem(14),
        smmd: pxToRem(15),
        md: pxToRem(16),
        mdlg: pxToRem(17),
        lg: pxToRem(18),
        xl: pxToRem(20),
        '2xl': pxToRem(24),
        '3xl': pxToRem(30),
        '3xl4xl': pxToRem(32),
        '4xl': pxToRem(36),
        '4xl5xl': pxToRem(40),
        '5xl': pxToRem(48),
        '6xl': pxToRem(60),
        '7xl': pxToRem(72),
        '8xl': pxToRem(96),
        '9xl': pxToRem(128),
    },
    textStyles: {
        buttonText: {
            fontFamily: 'body',
            fontSize: 'md',
            lineHeight: 1.25,
            textTransform: 'uppercase',
            fontWeight: 700,
        },
        get buttonTextSm() {
            return {
                ...this.buttonText,
                fontSize: 'xssm',
                lineHeight: 1,
            }
        },
        get buttonTextLg() {
            return {
                ...this.buttonText,
                fontSize: 'md',
                lineHeight: 1.5,
            }
        },
        heading1: {
            fontFamily: 'heading',
            fontSize: ['5xl', null, '7xl'],
            letterSpacing: ['-0.063rem', null, '-0.094rem'],
            lineHeight: ['3rem', null, '4.5rem'],
        },
        heading2: {
            fontFamily: 'heading',
            fontSize: ['4xl5xl', null, '5xl'],
            letterSpacing: ['-0.041rem', null, '-0.041rem'],
            lineHeight: ['3.125rem', null, '3.75rem'],
        },
        heading3: {
            fontFamily: 'heading',
            fontSize: ['3xl4xl', null, '4xl5xl'],
            letterSpacing: ['-0.041rem', null, '-0.052rem'],
            lineHeight: ['2.5rem', null, '3rem'],
        },
        heading4: {
            fontWeight: 700,
            fontFamily: 'body',
            fontSize: ['2xl', null, '3xl4xl'],
            letterSpacing: '-0.02em',
            lineHeight: ['2rem', null, '2.5rem'],
        },
        heading5: {
            fontWeight: 700,
            fontFamily: 'body',
            fontSize: '2xl',
            letterSpacing: '-0.02em',
            lineHeight: '2rem',
        },
        heading6: {
            fontWeight: 700,
            fontFamily: 'body',
            fontSize: 'lg',
            lineHeight: '1.5rem',
            letterSpacing: '-0.02em',
        },
        infoText: {
            fontFamily: 'body',
            fontSize: 'mdlg',
            color: 'textSecondary',
        },
        muted: {
            fontFamily: 'body',
            fontSize: 'xssm',
            color: 'textSecondary',
        },
    },
}
