import React from 'react'
import { createIcon, Icon, IconProps } from '@chakra-ui/react'
import { config, useSpring, animated } from 'react-spring'

export const GoogleIcon = createIcon({
    displayName: 'GoogleIcon',
    viewBox: '0 0 533.5 544.3',
    path: (
        <>
            <path
                d="M533.5 278.4c0-18.5-1.5-37.1-4.7-55.3H272.1v104.8h147c-6.1 33.8-25.7 63.7-54.4 82.7v68h87.7c51.5-47.4 81.1-117.4 81.1-200.2z"
                fill="#4285f4"
            />
            <path
                d="M272.1 544.3c73.4 0 135.3-24.1 180.4-65.7l-87.7-68c-24.4 16.6-55.9 26-92.6 26-71 0-131.2-47.9-152.8-112.3H28.9v70.1c46.2 91.9 140.3 149.9 243.2 149.9z"
                fill="#34a853"
            />
            <path
                d="M119.3 324.3c-11.4-33.8-11.4-70.4 0-104.2V150H28.9c-38.6 76.9-38.6 167.5 0 244.4l90.4-70.1z"
                fill="#fbbc04"
            />
            <path
                d="M272.1 107.7c38.8-.6 76.3 14 104.4 40.8l77.7-77.7C405 24.6 339.7-.8 272.1 0 169.2 0 75.1 58 28.9 150l90.4 70.1c21.5-64.5 81.8-112.4 152.8-112.4z"
                fill="#ea4335"
            />
        </>
    ),
})

export const PresentIcon = createIcon({
    displayName: 'PresentIcon',
    viewBox: '0 0 80 80',
    path: (
        <>
            <path d="M8 31V72H72V31" stroke="#167F6E" strokeWidth="3" />
            <path d="M4.5 20.4998V30.4998H75.5V20.4998H4.5Z" stroke="#167F6E" strokeWidth="3" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M57.893 9.17607C56.5671 7.18775 54.2923 6.00098 51.8105 6.00098C50.4559 6.00098 49.1299 6.36674 47.9782 7.06129C45.6644 8.4465 42.2359 14.8959 40 19.9095C41.2235 19.9582 42.7461 20.001 44.3795 20.001H44.3815C52.4499 20.001 54.9563 19.0185 55.6572 18.5983C59.0038 16.5827 60.0059 12.357 57.893 9.17607Z"
                stroke="#167F6E"
                strokeWidth="3"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M22.107 9.17607C23.4329 7.18775 25.7077 6.00098 28.1895 6.00098C29.5441 6.00098 30.8701 6.36674 32.0218 7.06129C34.3356 8.4465 37.7641 14.8959 40 19.9095C38.7765 19.9582 37.2539 20.001 35.6205 20.001H35.6185C27.5501 20.001 25.0437 19.0185 24.3428 18.5983C20.9962 16.5827 19.9941 12.357 22.107 9.17607Z"
                stroke="#167F6E"
                strokeWidth="3"
            />
            <path d="M44 35L44 72L36 72L36 35" stroke="#167F6E" strokeWidth="3" />
            <path d="M8 54L36 54" stroke="#167F6E" strokeWidth="3" />
            <path d="M8 46L36 46" stroke="#167F6E" strokeWidth="3" />
            <path d="M44 54L72 54" stroke="#167F6E" strokeWidth="3" />
            <path d="M44 46L72 46" stroke="#167F6E" strokeWidth="3" />
            <path d="M47 31L47 20" stroke="#167F6E" strokeWidth="3" />
            <path d="M33 31L33 20" stroke="#167F6E" strokeWidth="3" />
        </>
    ),
})

export const ArrowIcon = createIcon({
    displayName: 'ArrowIcon',
    viewBox: '0 0 16 16',
    defaultProps: {
        fill: 'white',
        height: '16px',
        width: '16px',
    },
    path: (
        <>
            <path d="M0 8H9" stroke="#167F6E" strokeWidth="2" />
            <path d="M8 3L14 8L8 13" stroke="#167F6E" strokeWidth="2" />
        </>
    ),
})

export const CaretIcon = createIcon({
    displayName: 'CaretIcon',
    viewBox: '0 0 8 13',
    defaultProps: {
        color: 'primary',
        fill: 'none',
        stroke: 'currentColor',
        height: '13px',
        width: 'auto',
    },
    path: (
        <path
            d="M1 1L6 6.5L1 12"
            vectorEffect="non-scaling-stroke"
            stroke="inherit"
            strokeWidth="2"
        />
    ),
})

// currently used only for menu account dropdown
export const CaretIconDown = createIcon({
    displayName: 'CaretIconDown',
    viewBox: '0 0 10 7',
    defaultProps: {
        fill: 'none',
        stroke: 'currentColor',
        height: '7px',
        width: 'auto',
    },
    path: <path d="M9 1L5 5L1 1" stroke="inherit" strokeWidth="2" />,
})

export const ThinCaretIcon = createIcon({
    displayName: 'CaretIcon',
    viewBox: '0 0 12 22',
    defaultProps: {
        fill: 'none',
        stroke: '#BEBEBE',
        height: '1em',
        width: 'auto',
    },
    path: <path d="M1 1l10 10L1 21" stroke="inherit" />,
})

export const WatchIcon = createIcon({
    displayName: 'WatchIcon',
    viewBox: '0 0 24 32',
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M7 2H17V4H19V0H5V4H7V2ZM24 16.0049C24 9.37761 18.6273 4.0049 12 4.0049C5.37272 4.0049 0 9.37761 0 16.0049C0 22.6322 5.37272 28.0049 12 28.0049C18.6273 28.0049 24 22.6322 24 16.0049ZM22 16.0049C22 21.5276 17.5227 26.0049 12 26.0049C6.47728 26.0049 2 21.5276 2 16.0049C2 10.4822 6.47728 6.0049 12 6.0049C17.5227 6.0049 22 10.4822 22 16.0049ZM11 16.4195V9H13V15.5905L15.7068 18.2945L14.2932 19.7095L11 16.4195ZM7 28V30H17V28H19V32H5V28H7Z"
            fill="#167F6E"
        />
    ),
})

export const IDsIcon = createIcon({
    displayName: 'IDsIcon',
    viewBox: '0 0 32 32',
    path: (
        <>
            <rect fill="#fff" x="1" y="6" width="30" height="20" stroke="#167F6E" strokeWidth="2" />
            <rect fill="#fff" x="6" y="11" width="9" height="10" stroke="#167F6E" strokeWidth="2" />
            <path d="M19 12H27" stroke="#167F6E" strokeWidth="2" />
            <path d="M19 16H27" stroke="#167F6E" strokeWidth="2" />
            <path d="M19 20H27" stroke="#167F6E" strokeWidth="2" />
        </>
    ),
})

export const EyeIcon = createIcon({
    displayName: 'EyeIcon',
    viewBox: '0 0 32 32',
    path: (
        <>
            <path
                fill="transparent"
                d="M2.98414 17.126C2.64827 16.6697 2.38154 16.2836 2.19153 16C2.38154 15.7164 2.64827 15.3303 2.98414 14.874C3.72322 13.8699 4.79052 12.5343 6.10509 11.2025C8.77799 8.4946 12.2792 6 16 6C19.7208 6 23.222 8.4946 25.8949 11.2025C27.2095 12.5343 28.2768 13.8699 29.0159 14.874C29.3517 15.3303 29.6185 15.7164 29.8085 16C29.6185 16.2836 29.3517 16.6697 29.0159 17.126C28.2768 18.1301 27.2095 19.4657 25.8949 20.7975C23.222 23.5054 19.7208 26 16 26C12.2792 26 8.77799 23.5054 6.10509 20.7975C4.79052 19.4657 3.72322 18.1301 2.98414 17.126Z"
                stroke="#167F6E"
                strokeWidth="2"
            />
            <circle fill="transparent" cx="16" cy="16" r="4" stroke="#167F6E" strokeWidth="2" />
        </>
    ),
})

export const ClosedEyeIcon = createIcon({
    displayName: 'ClosedEyeIcon',
    viewBox: '0 0 32 32',
    path: (
        <>
            <path
                d="M2.98414 17.126C2.64827 16.6697 2.38154 16.2836 2.19153 16C2.38154 15.7164 2.64827 15.3303 2.98414 14.874C3.72322 13.8699 4.79052 12.5343 6.10509 11.2025C8.77799 8.4946 12.2792 6 16 6C19.7208 6 23.222 8.4946 25.8949 11.2025C27.2095 12.5343 28.2768 13.8699 29.0159 14.874C29.3517 15.3303 29.6185 15.7164 29.8085 16C29.6185 16.2836 29.3517 16.6697 29.0159 17.126C28.2768 18.1301 27.2095 19.4657 25.8949 20.7975C23.222 23.5054 19.7208 26 16 26C12.2792 26 8.77799 23.5054 6.10509 20.7975C4.79052 19.4657 3.72322 18.1301 2.98414 17.126Z"
                stroke="#167F6E"
                fill="transparent"
                strokeWidth="2"
            />
            <path
                d="M2.61244 15.3891C2.7248 15.2302 2.84886 15.0578 2.98414 14.874C3.72322 13.8699 4.79052 12.5343 6.10509 11.2025C8.77799 8.4946 12.2792 6 16 6C19.7208 6 23.222 8.4946 25.8949 11.2025C27.2095 12.5343 28.2768 13.8699 29.0159 14.874C29.1511 15.0578 29.2752 15.2302 29.3876 15.3891C28.6565 15.5662 27.6486 15.7912 26.4255 16.0165C23.7524 16.5089 20.0652 17 16 17C11.9348 17 8.24763 16.5089 5.57455 16.0165C4.3514 15.7912 3.34352 15.5662 2.61244 15.3891Z"
                stroke="#167F6E"
                fill="transparent"
                strokeWidth="2"
            />
        </>
    ),
})

export const UploadIcon = createIcon({
    displayName: 'UploadIcon',
    viewBox: '0 0 32 32',
    path: (
        <g fill="transparent">
            <path d="M27 13V25H5V13" stroke="#167F6E" strokeWidth="2" />
            <path d="M16 22L16 11" stroke="#167F6E" strokeWidth="2" />
            <path d="M10 12L16 6L22 12" stroke="#167F6E" strokeWidth="2" />
        </g>
    ),
})

export const PhotoIcon = createIcon({
    displayName: 'PhotoIcon',
    viewBox: '0 0 32 32',
    path: (
        <g fill="transparent">
            <path d="M3 27H29V9H3.83871V24.5455" stroke="#167F6E" strokeWidth="2" />
            <circle cx="15" cy="18" r="5" stroke="#167F6E" strokeWidth="2" />
            <path d="M22 13L26 13" stroke="#167F6E" strokeWidth="2" />
            <path d="M8 8V5L15 5V8" stroke="#167F6E" strokeWidth="2" />
        </g>
    ),
})

export const PencilIcon = createIcon({
    displayName: 'PencilIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        stroke: 'currentColor',
        fill: 'none',
    },
    path: (
        <path
            d="M19.6409 9.92282L22.2819 7.28188L17 2L3.20842 15.7916L2.03467 22.2472L8.4903 21.0735L17.8803 11.6834L14.3185 8.12165"
            stroke="inherit"
            strokeWidth="2"
            fill="transparent"
        />
    ),
})

export const InfoIcon = createIcon({
    displayName: 'InfoIcon',
    viewBox: '0 0 12 12',
    path: (
        <>
            <circle cx="6" cy="6" r="6" fill="#505050" />
            <path fill="#fff" d="M5 5h2v5H5zM5 2h2v2H5z" />
        </>
    ),
})

export const CalendarIcon = createIcon({
    displayName: 'CalendarIcon',
    viewBox: '0 0 20 20',
    path: (
        <path
            d="M5.625 0H3.75v2.5H0V20h20v-1.875H1.875V4.375h16.25V12.5H20v-10h-3.75V0h-1.875v2.5h-8.75V0zm-1.25 6.25H6.25v1.875H4.375V6.25zm6.25 0H8.75v1.875h1.875V6.25zm2.5 0H15v1.875h-1.875V6.25zM6.25 10H4.375v1.875H6.25V10zm2.5 0h1.875v1.875H8.75V10zM20 14.375H7.5v1.875H20v-1.875z"
            fill="#167F6E"
        />
    ),
})

export const GainsIcon = createIcon({
    displayName: 'GainsIcon',
    viewBox: '0 0 32 32',
    path: (
        <path
            d="M29 4H17v3h12v12h3V4h-3zm-3 6h-2.105l-7.45 7.51-5.556-5.6L.935 21.944l2.13 2.112 7.824-7.886 5.555 5.6L26 12.138V10zm6 19H0v3h32v-3z"
            fill="#167F6E"
        />
    ),
})

export const WalletIcon = createIcon({
    displayName: 'WalletIcon',
    viewBox: '0 0 38 34',
    defaultProps: {
        width: 'auto',
        height: '1em',
    },
    path: (
        <path
            d="M22.97 3.923L9.32 8.89h15.458L22.97 3.923zM8.792 5.89L3.556 7.796A1.501 1.501 0 015 5.891h3.792zM.5 7.39A4.5 4.5 0 015 2.89h12.034L24.763.078l1.024 2.813H32.5v6h3v5.61h-3v-2.61H5a4.49 4.49 0 01-1.5-.257v17.757a1.5 1.5 0 001.5 1.5h27.5V28.5h3v5.39H5a4.5 4.5 0 01-4.5-4.5v-22zm29 1.5h-1.53l-1.091-3H29.5v3zm8.5 17H23.5v-9H38v9zm-11.5-6v3H35v-3h-8.5z"
            fill="#167F6E"
        />
    ),
})

export const SocialsTwitterIcon = createIcon({
    displayName: 'SocialsTwitterIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        fill: 'none',
        width: 'auto',
        height: '1em',
        stroke: 'currentColor',
    },
    path: (
        <>
            <path d="M5 4H8.5L19 20H15.5L5 4Z" stroke="inherit" strokeMiterlimit="10" />
            <path d="M13 11L19 4" stroke="inherit" />
            <path d="M5 20L11 13" stroke="inherit" />
        </>
    ),
})

export const SocialsLinkedinIcon = createIcon({
    displayName: 'SocialsLinkedinIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        fill: 'none',
        stroke: 'currentColor',
        width: 'auto',
        height: '1em',
    },
    path: (
        <>
            <path
                d="M5.61905 4C4.72614 4 4 4.72614 4 5.61905C4 6.51195 4.72614 7.2381 5.61905 7.2381C6.51195 7.2381 7.2381 6.51195 7.2381 5.61905C7.2381 4.72614 6.51195 4 5.61905 4Z"
                stroke="inherit"
            />
            <path d="M4 20.1905H7.2381V8.85718H4V20.1905Z" stroke="inherit" />
            <path
                d="M17.7617 20.1905H20.9998V12.5001C20.9691 10.2455 20.149 8.77544 18.6304 8.25573C16.7442 7.60973 12.9046 9.28515 12.9046 10.4746C12.9046 10.4746 12.9046 9.63574 12.9046 9.26196V8.8572H9.6665V20.1905H12.9046V12.5001C12.9046 12.3908 12.9491 12.2855 13.0268 12.2102C13.9319 11.3311 15.1858 11.0478 16.2156 11.4825C17.1983 11.8986 17.7617 12.8595 17.7609 14.1191L17.7617 20.1905Z"
                stroke="inherit"
            />
        </>
    ),
})
export const SocialsFacebookIcon = createIcon({
    displayName: 'SocialsFacebookIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        stroke: 'currentColor',
        width: 'auto',
        height: '1em',
        fill: 'none',
    },
    path: (
        <path
            d="M10.0649 7.17375V10.005H6.95801V13.5H10.0649V21.375H13.7418V13.5H16.8749L17.2499 10.0069H13.7418V7.17375C13.7418 6.67312 14.1374 6.42375 14.3043 6.27C14.5986 6.01875 16.0199 5.97937 16.0199 5.97937H17.413V2.8125C16.6877 2.70414 15.9562 2.64151 15.223 2.625C9.95801 2.625 10.0649 7.17375 10.0649 7.17375Z"
            stroke="inherit"
            strokeMiterlimit="10"
        />
    ),
})

export const LocationIcon = createIcon({
    displayName: 'LocationIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        fill: 'none',
        stroke: 'currentColor',
        width: '24px',
        height: '24px',
    },
    path: (
        <>
            <path
                d="M14.546 20.8C17.273 17.867 20 13.061 20 9.067 20 4.612 16.642 1 12.5 1 8.358 1 5 4.612 5 9.067 5 15.667 12.5 23 12.5 23"
                stroke="inherit"
                strokeWidth="2"
                vectorEffect="non-scaling-stroke"
            />
            <path
                clipRule="evenodd"
                d="M16 8.5a3.5 3.5 0 10-6.999-.001A3.5 3.5 0 0016 8.5v0z"
                stroke="inherit"
                strokeWidth="2"
                vectorEffect="non-scaling-stroke"
            />
            <path d="M4 23h16" stroke="inherit" strokeWidth="2" vectorEffect="non-scaling-stroke" />
        </>
    ),
})

export const EmailIcon = createIcon({
    displayName: 'EmailIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        fill: 'none',
        stroke: 'currentColor',
        width: '24px',
        height: '24px',
    },
    path: (
        <path
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            vectorEffect="non-scaling-stroke"
            d="M21.75 6.75v10.5a2.25 2.25 0 01-2.25 2.25h-15a2.25 2.25 0 01-2.25-2.25V6.75m19.5 0A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25m19.5 0v.243a2.25 2.25 0 01-1.07 1.916l-7.5 4.615a2.25 2.25 0 01-2.36 0L3.32 8.91a2.25 2.25 0 01-1.07-1.916V6.75"
        />
    ),
})

export const PhoneIcon = createIcon({
    displayName: 'PhoneIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        fill: 'none',
        stroke: 'currentColor',
        width: '24px',
        height: '24px',
    },
    path: (
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            vectorEffect="non-scaling-stroke"
            d="M2.25 6.75c0 8.284 6.716 15 15 15h2.25a2.25 2.25 0 002.25-2.25v-1.372c0-.516-.351-.966-.852-1.091l-4.423-1.106c-.44-.11-.902.055-1.173.417l-.97 1.293c-.282.376-.769.542-1.21.38a12.035 12.035 0 01-7.143-7.143c-.162-.441.004-.928.38-1.21l1.293-.97c.363-.271.527-.734.417-1.173L6.963 3.102a1.125 1.125 0 00-1.091-.852H4.5A2.25 2.25 0 002.25 4.5v2.25z"
        />
    ),
})

export const PersonIcon = createIcon({
    displayName: 'PersonIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        fill: 'none',
        stroke: 'currentColor',
        width: '24px',
        height: '24px',
    },
    path: (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M12 12C14.7614 12 17 9.76142 17 7C17 4.23858 14.7614 2 12 2C9.23858 2 7 4.23858 7 7C7 9.76142 9.23858 12 12 12Z"
                stroke="inherit"
                strokeWidth="2"
            />
            <path d="M4 24V15H20V24" stroke="inherit" strokeWidth="2" />
            <path d="M8 18V24" stroke="inherit" strokeWidth="2" />
            <path d="M16 18V24" stroke="inherit" strokeWidth="2" />
        </>
    ),
})

export const DownloadIcon = createIcon({
    displayName: 'DownloadIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        fill: 'none',
        stroke: 'currentColor',
        width: '24px',
        height: '24px',
    },
    path: <path d="M12 3v9M20 20H4M17 11l-5 5-5-5" stroke="inherit" strokeWidth="2" />,
})

export const MenuIcon = ({ isOpen, ...props }: { isOpen: boolean } & IconProps) => {
    const firstLineStyle = useSpring({
        transform: isOpen
            ? 'translate(1px, -1px) rotate(45deg) scaleX(1.26)'
            : 'translate(0px, 0px) rotate(0deg) scaleX(1) ',
        config: { ...config.gentle, clamp: true },
    })

    const secondLineStyle = useSpring({
        transform: isOpen ? 'scaleX(0) ' : 'scaleX(1) ',
        opacity: isOpen ? 0 : 1,
        config: { ...config.gentle, clamp: true },
    })

    const thirdLineStyle = useSpring({
        transform: isOpen
            ? 'translate(1px, 1px) rotate(-45deg) scaleX(1.26)'
            : 'translate(0px, 0px) rotate(0deg) scaleX(1)',
        config: { ...config.gentle, clamp: true },
    })

    return (
        <Icon
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            width="24px"
            height="24px"
            {...props}
            sx={{
                path: {
                    transformBox: 'fill-box',
                },
            }}
        >
            <animated.path d="M3 5h18" stroke="inherit" strokeWidth="2" style={firstLineStyle} />
            <animated.path d="M3 12h12" stroke="inherit" strokeWidth="2" style={secondLineStyle} />
            <animated.path d="M3 19h18" stroke="inherit" strokeWidth="2" style={thirdLineStyle} />
        </Icon>
    )
}

export const FaqIcon = createIcon({
    displayName: 'FaqIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        fill: 'none',
        stroke: 'currentColor',
        width: '24px',
        height: '24px',
    },
    path: (
        <>
            <path d="M9 21H3V5a2 2 0 012-2h14a2 2 0 012 2v16h-6" stroke="inherit" strokeWidth="2" />
            <path
                d="M12 18v-3h5V9a2 2 0 00-2-2H9a2 2 0 00-2 2v2M12 22v-2"
                stroke="inherit"
                strokeWidth="2"
            />
        </>
    ),
})

export const BuildingsIcon = createIcon({
    displayName: 'BuildingsIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        fill: 'none',
        stroke: 'currentColor',
        width: '24px',
        height: '24px',
    },
    path: (
        <path
            d="M16 8h5v13h-7m0 0H2V4h12v17zM6 9V7M6 13v-2M6 17v-2M10 9V7M10 13v-2M10 17v-2M17 13v-2M17 17v-2"
            stroke="inherit"
            strokeWidth="2"
        />
    ),
})

export const BarChartIcon = createIcon({
    displayName: 'BarChartIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        fill: 'none',
        stroke: 'currentColor',
        width: '24px',
        height: '24px',
    },
    path: <path d="M21 21V3h-4v18M14 21V9h-4v12M7 21v-6H3v6" stroke="inherit" strokeWidth="2" />,
})

export const CopyIcon = createIcon({
    displayName: 'CopyIcon',
    viewBox: '0 0 19 22',
    defaultProps: {
        fill: 'currentColor',
        width: 'auto',
        height: '22px',
    },
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M2.662 0h8.998a2.665 2.665 0 012.662 2.661h1.275a2.665 2.665 0 012.663 2.662v14.015A2.665 2.665 0 0115.597 22H6.6a2.665 2.665 0 01-2.662-2.661H2.662A2.665 2.665 0 010 16.677V2.662A2.665 2.665 0 012.662 0zm-.55 16.677c0 .302.248.549.55.549h1.276V5.323A2.665 2.665 0 016.6 2.661h5.609a.55.55 0 00-.55-.548H2.662a.55.55 0 00-.55.55v14.014zM6.6 20.179a.842.842 0 01-.841-.841V5.323c0-.464.377-.841.841-.841h8.997c.464 0 .842.377.842.841v14.015a.842.842 0 01-.842.841H6.6z"
            fill="inherit"
        />
    ),
})

export const DownloadPaperIcon = createIcon({
    displayName: 'DownloadPaperIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        stroke: 'currentColor',
        fill: 'none',
        width: 'auto',
        height: '24px',
    },
    path: (
        <>
            <path d="M5.794 19.222V2H22v20H2V9.778" stroke="inherit" strokeWidth="2" />
            <path d="M14 5v9" stroke="inherit" strokeWidth="2" />
            <path d="M19 13l-5 5-5-5" stroke="inherit" strokeWidth="2" />
        </>
    ),
})

export const EventIcon = createIcon({
    displayName: 'EventIcon',
    viewBox: '0 0 32 33',
    defaultProps: {
        width: '32px',
        height: '33px',
    },
    path: (
        <>
            <svg fill="none" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 33">
                <path d="M32 4.956h-3v21.958h3V4.956z" fill="#167F6E" />
                <path
                    d="M32 4.956H0V7.95h32V4.956zM9 .963H6v3.993h3V.963zM26 .963h-3v3.993h3V.963zM24.303 11.02L10.868 24.43l2.121 2.117 13.435-13.41-2.12-2.117z"
                    fill="#167F6E"
                />
                <path d="M3 4.956H0v27.947h3V4.956z" fill="#167F6E" />
                <path
                    d="M32 29.908H1v2.995h31v-2.995zM7.99 17.3l7.07 7.058-2.12 2.118-7.072-7.058L7.99 17.3z"
                    fill="#167F6E"
                />
            </svg>
        </>
    ),
})

export const InvestmentClubIcon = createIcon({
    displayName: 'InvestmentClubIcon',
    viewBox: '0 0 32 33',
    defaultProps: {
        width: '32px',
        height: '33px',
        fill: '#167F6E',
    },
    path: (
        <>
            <path
                d="M0.5 30.9202V0.963379L9.719 12.1998L15.9632 2.42658L22.277 11.7478L31.5 1.147V30.9202H28.5V9.14883L21.9802 16.6444L16.036 7.87014L10.0229 17.2835L3.5 9.3332V30.9202H0.5Z"
                fill="inherit"
            />
            <path d="M26 22.9904H3V25.9641H26V22.9904Z" fill="inherit" />
            <path d="M31.5 29.929H0.5V32.9027H31.5V29.929Z" fill="inherit" />
        </>
    ),
})

export const PdfFileIcon = createIcon({
    displayName: 'PdfFileIcon',
    viewBox: '0 0 32 32',
    defaultProps: {
        height: '32px',
        width: 'auto',
        color: 'inherit',
        fill: 'none',
    },
    path: (
        <>
            <path
                clipRule="evenodd"
                d="M28 10v20H4V2h16l8 8z"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path d="M20 3v7h4" stroke="currentColor" strokeWidth="2" />
            <path d="M21 18v-2h4v-1h-5v7h1v-3h3v-1h-3z" fill="currentColor" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 22v-7h2.995a2 2 0 110 4H9v3H8zm3.001-4H9v-2h2.001c.556 0 .999.448.999 1 0 .556-.447 1-.999 1z"
                fill="currentColor"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 22v-7h2.995c1.111 0 2.005.898 2.005 2.006v2.988c0 1.12-.898 2.006-2.005 2.006H14zm1-1v-5h2.001c.556 0 .999.448.999 1v3c0 .556-.447 1-.999 1H15z"
                fill="currentColor"
            />
        </>
    ),
})

export const FileIcon = createIcon({
    displayName: 'FileIcon',
    viewBox: '0 0 32 32',
    defaultProps: {
        height: '32px',
        width: 'auto',
        color: 'inherit',
        fill: 'none',
    },
    path: (
        <>
            <path
                clipRule="evenodd"
                d="M28 10v20H4V2h16l8 8z"
                stroke="currentColor"
                strokeWidth="2"
            />
            <path d="M20 3v7h4" stroke="currentColor" strokeWidth="2" />
        </>
    ),
})

export const InformationIcon = createIcon({
    displayName: 'InformationIcon',
    viewBox: '0 0 32 32',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'currentColor',
    },
    path: (
        <path
            d="M16 1c8.284 0 15 6.716 15 15 0 8.284-6.716 15-15 15-8.284 0-15-6.716-15-15C1 7.716 7.716 1 16 1zm0 2C8.82 3 3 8.82 3 16s5.82 13 13 13 13-5.82 13-13S23.18 3 16 3zm-1 10h-3v-2h8v2h-3v9h3v2h-8v-2h3v-9zm2-6v2h-2V7h2z"
            fill="inherit"
        />
    ),
})

export const PlusIcon = createIcon({
    displayName: 'PlusIcon',
    viewBox: '0 0 32 32',
    defaultProps: {
        height: '17px',
        width: 'auto',
        stroke: 'currentColor',
    },
    path: (
        <>
            <path d="M4 16H28" stroke="inherit" strokeWidth="2" vectorEffect="non-scaling-stroke" />
            <path d="M16 28V4" stroke="inherit" strokeWidth="2" vectorEffect="non-scaling-stroke" />
        </>
    ),
})

export const CrossIcon = createIcon({
    displayName: 'CrossIcon',
    viewBox: '0 0 14 14',
    defaultProps: {
        height: '14px',
        width: 'auto',
        fill: 'currentColor',
    },
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M8.414 7l5.293-5.293L12.293.293 7 5.586 1.707.293.293 1.707 5.586 7 .293 12.293l1.414 1.414L7 8.414l5.293 5.293 1.414-1.414L8.414 7z"
            fill="inherit"
        />
    ),
})

export const MonitorIcon = createIcon({
    displayName: 'MonitorIcon',
    viewBox: '0 0 32 32',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'currentColor',
    },
    path: (
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M0 3V21V24H3H15V29H7V32H27V29H18V24H32V21H3V3H29V16H32V3V0H29H3H0V3ZM19 12H7V15H19V12ZM25 6H7V9H25V6Z"
            fill="inherit"
        />
    ),
})

export const PlayIcon = createIcon({
    displayName: 'PlayIcon',
    viewBox: '0 0 32 32',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'currentColor',
    },
    path: <path d="M26 16 0 32V0l26 16Z" fill="inherit" />,
})

export const OneIcon = createIcon({
    displayName: 'OneIcon',
    viewBox: '0 0 64 64',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'currentColor',
    },
    path: (
        <>
            <circle cx="32" cy="32" r="32" fill="#167F6E" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                xmlns="http://www.w3.org/2000/svg"
                d="M22.12 44v-.4l1.56-.48c1.013-.347 1.52-1.027 1.52-2.04V21.72l-3.24.44v-.52c1.573-.32 3.08-.773 4.52-1.36a28.215 28.215 0 0 0 4.12-2.04l.52.36-.16 5.72v16.76c0 1.04.507 1.707 1.52 2l1.64.52v.4h-12Zm18.031.6c-.96 0-1.773-.333-2.44-1s-1-1.467-1-2.4c0-.933.333-1.747 1-2.44a3.256 3.256 0 0 1 2.44-1.04c.96 0 1.773.347 2.44 1.04.667.693 1 1.507 1 2.44s-.333 1.733-1 2.4c-.667.667-1.48 1-2.44 1Z"
                fill="#fff"
            />
        </>
    ),
})

export const TwoIcon = createIcon({
    displayName: 'TwoIcon',
    viewBox: '0 0 64 64',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'currentColor',
    },
    path: (
        <>
            <circle cx="32" cy="32" r="32" fill="#167F6E" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="m20.76 25.36.28-5.68c.8-.453 1.787-.84 2.96-1.16 1.2-.32 2.547-.48 4.04-.48 2.133 0 3.853.307 5.16.92 1.307.587 2.267 1.4 2.88 2.44s.92 2.213.92 3.52c0 1.84-.893 3.64-2.68 5.4-1.787 1.733-4.227 3.653-7.32 5.76-.587.427-1.28.947-2.08 1.56-.8.613-1.547 1.213-2.24 1.8h15V44h-17.6v-3.36c.96-.933 1.893-1.853 2.8-2.76l2.64-2.64c1.92-1.973 3.347-3.72 4.28-5.24.96-1.547 1.44-3.2 1.44-4.96 0-1.84-.333-3.32-1-4.44-.667-1.147-1.667-1.72-3-1.72-.827 0-1.6.213-2.32.64-.693.4-1.307 1.107-1.84 2.12l-1.92 3.72h-.4ZM44.456 44.6c-.96 0-1.774-.333-2.44-1-.667-.667-1-1.467-1-2.4 0-.933.333-1.747 1-2.44a3.256 3.256 0 0 1 2.44-1.04c.96 0 1.773.347 2.44 1.04.666.693 1 1.507 1 2.44s-.334 1.733-1 2.4c-.667.667-1.48 1-2.44 1Z"
                fill="#fff"
            />
        </>
    ),
})

export const ThreeIcon = createIcon({
    displayName: 'ThreeIcon',
    viewBox: '0 0 64 64',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'currentColor',
    },
    path: (
        <>
            <circle cx="32" cy="32" r="32" fill="#167F6E" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M28.16 44.6c-1.787 0-3.253-.2-4.4-.6-1.12-.4-2.227-.867-3.32-1.4l.44-5.2h.4l1.76 3.24c1.173 2.107 2.8 3.16 4.88 3.16 1.227 0 2.213-.507 2.96-1.52.773-1.04 1.16-2.773 1.16-5.2 0-2.107-.32-3.613-.96-4.52-.64-.933-1.733-1.4-3.28-1.4h-2.4v-.8h2.12c1.413 0 2.427-.427 3.04-1.28.64-.853.96-2.333.96-4.44 0-2.053-.307-3.507-.92-4.36-.613-.88-1.427-1.32-2.44-1.32s-1.867.293-2.56.88a7.356 7.356 0 0 0-1.64 2.08l-1.8 3.36-.4-.04.4-5.88c.853-.373 1.8-.68 2.84-.92 1.067-.267 2.253-.4 3.56-.4 2.8 0 4.92.613 6.36 1.84 1.467 1.2 2.2 2.707 2.2 4.52 0 1.307-.56 2.56-1.68 3.76-1.12 1.173-2.88 1.987-5.28 2.44 2.72.24 4.693.973 5.92 2.2 1.227 1.227 1.84 2.707 1.84 4.44 0 1.387-.4 2.64-1.2 3.76-.773 1.12-1.893 2-3.36 2.64-1.44.64-3.173.96-5.2.96Zm16.296 0c-.96 0-1.774-.333-2.44-1-.667-.667-1-1.467-1-2.4 0-.933.333-1.747 1-2.44a3.256 3.256 0 0 1 2.44-1.04c.96 0 1.773.347 2.44 1.04.666.693 1 1.507 1 2.44s-.334 1.733-1 2.4c-.667.667-1.48 1-2.44 1Z"
                fill="#fff"
            />
        </>
    ),
})

export const CheckIcon = createIcon({
    displayName: 'CheckIcon',
    viewBox: '0 0 34 34',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'currentColor',
    },
    path: (
        <>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.0007 0.333252C7.80065 0.333252 0.333984 7.79992 0.333984 16.9999C0.333984 26.1999
            7.80065 33.6666 17.0007 33.6666C26.2007 33.6666 33.6673 26.1999 33.6673 16.9999C33.6673
            7.79992 26.2007 0.333252 17.0007 0.333252ZM17.0007 30.3333C9.65065 30.3333 3.66732
            24.3499 3.66732 16.9999C3.66732 9.64992 9.65065 3.66659 17.0007 3.66659C24.3507 3.66659
            30.334 9.64992 30.334 16.9999C30.334 24.3499 24.3507 30.3333 17.0007 30.3333ZM24.6507
            9.63325L13.6673 20.6166L9.35065 16.3166L7.00065 18.6666L13.6673 25.3333L27.0007
            11.9999L24.6507 9.63325Z"
                fill="inherit"
            />
        </>
    ),
})

/**
 * Icons below are from https://heroicons.com/
 */
export const HomeIcon = createIcon({
    displayName: 'HomeIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'none',
        stroke: 'currentColor',
    },
    path: (
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819"
        />
    ),
})

export const AccountsIcon = createIcon({
    displayName: 'AccountsIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'none',
        stroke: 'currentColor',
    },
    path: (
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15 19.128a9.38 9.38 0 002.625.372 9.337 9.337 0 004.121-.952 4.125 4.125 0 00-7.533-2.493M15 19.128v-.003c0-1.113-.285-2.16-.786-3.07M15 19.128v.106A12.318 12.318 0 018.624 21c-2.331 0-4.512-.645-6.374-1.766l-.001-.109a6.375 6.375 0 0111.964-3.07M12 6.375a3.375 3.375 0 11-6.75 0 3.375 3.375 0 016.75 0zm8.25 2.25a2.625 2.625 0 11-5.25 0 2.625 2.625 0 015.25 0z"
        />
    ),
})

export const TransactionIcon = createIcon({
    displayName: 'TransactionIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'none',
        stroke: 'currentColor',
    },
    path: (
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M9 3.75H6.912a2.25 2.25 0 00-2.15 1.588L2.35 13.177a2.25 2.25 0 00-.1.661V18a2.25 2.25 0 002.25 2.25h15A2.25 2.25 0 0021.75 18v-4.162c0-.224-.034-.447-.1-.661L19.24 5.338a2.25 2.25 0 00-2.15-1.588H15M2.25 13.5h3.86a2.25 2.25 0 012.012 1.244l.256.512a2.25 2.25 0 002.013 1.244h3.218a2.25 2.25 0 002.013-1.244l.256-.512a2.25 2.25 0 012.013-1.244h3.859M12 3v8.25m0 0l-3-3m3 3l3-3"
        />
    ),
})

export const ChartBarIcon = createIcon({
    displayName: 'BarChartIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'none',
        stroke: 'currentColor',
    },
    path: (
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M3 13.125C3 12.504 3.504 12 4.125 12h2.25c.621 0 1.125.504 1.125 1.125v6.75C7.5 20.496 6.996 21 6.375 21h-2.25A1.125 1.125 0 013 19.875v-6.75zM9.75 8.625c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125v11.25c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V8.625zM16.5 4.125c0-.621.504-1.125 1.125-1.125h2.25C20.496 3 21 3.504 21 4.125v15.75c0 .621-.504 1.125-1.125 1.125h-2.25a1.125 1.125 0 01-1.125-1.125V4.125z"
        />
    ),
})

export const CreditCardIcon = createIcon({
    displayName: 'CreditCardIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'none',
        stroke: 'currentColor',
    },
    path: (
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 8.25h19.5M2.25 9h19.5m-16.5 5.25h6m-6 2.25h3m-3.75 3h15a2.25 2.25 0 002.25-2.25V6.75A2.25 2.25 0 0019.5 4.5h-15a2.25 2.25 0 00-2.25 2.25v10.5A2.25 2.25 0 004.5 19.5z"
        />
    ),
})

export const OfficeBuildingsIcon = createIcon({
    displayName: 'OfficeBuildingsIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'none',
        stroke: 'currentColor',
    },
    path: (
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M2.25 21h19.5m-18-18v18m10.5-18v18m6-13.5V21M6.75 6.75h.75m-.75 3h.75m-.75 3h.75m3-6h.75m-.75 3h.75m-.75 3h.75M6.75 21v-3.375c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21M3 3h12m-.75 4.5H21m-3.75 3.75h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008zm0 3h.008v.008h-.008v-.008z"
        />
    ),
})

export const ShieldIcon = createIcon({
    displayName: 'ShieldIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'none',
        stroke: 'currentColor',
    },
    path: (
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 9v3.75m0-10.036A11.959 11.959 0 013.598 6 11.99 11.99 0 003 9.75c0 5.592 3.824 10.29 9 11.622 5.176-1.332 9-6.03 9-11.622 0-1.31-.21-2.57-.598-3.75h-.152c-3.196 0-6.1-1.249-8.25-3.286zm0 13.036h.008v.008H12v-.008z"
        />
    ),
})

export const HeartIcon = createIcon({
    displayName: 'HeartIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'none',
        stroke: 'currentColor',
    },
    path: (
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12z"
        />
    ),
})

export const LogoutIcon = createIcon({
    displayName: 'LogoutIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'none',
        stroke: 'currentColor',
    },
    path: (
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M15.75 9V5.25A2.25 2.25 0 0013.5 3h-6a2.25 2.25 0 00-2.25 2.25v13.5A2.25 2.25 0 007.5 21h6a2.25 2.25 0 002.25-2.25V15M12 9l-3 3m0 0l3 3m-3-3h12.75"
        />
    ),
})

export const ProfileIcon = createIcon({
    displayName: 'LogoutIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'none',
        stroke: 'currentColor',
    },
    path: (
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M17.982 18.725A7.488 7.488 0 0012 15.75a7.488 7.488 0 00-5.982 2.975m11.963 0a9 9 0 10-11.963 0m11.963 0A8.966 8.966 0 0112 21a8.966 8.966 0 01-5.982-2.275M15 9.75a3 3 0 11-6 0 3 3 0 016 0z"
        />
    ),
})

export const ReportIcon = createIcon({
    displayName: 'ReportIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'none',
        stroke: 'currentColor',
    },
    path: (
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25M9 16.5v.75m3-3v3M15 12v5.25m-4.5-15H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z"
        />
    ),
})

export const StackIcon = createIcon({
    displayName: 'StackIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'none',
        stroke: 'currentColor',
    },
    path: (
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M6.429 9.75L2.25 12l4.179 2.25m0-4.5l5.571 3 5.571-3m-11.142 0L2.25 7.5 12 2.25l9.75 5.25-4.179 2.25m0 0L21.75 12l-4.179 2.25m0 0l4.179 2.25L12 21.75 2.25 16.5l4.179-2.25m11.142 0l-5.571 3-5.571-3"
        />
    ),
})

export const ShareIcon = createIcon({
    displayName: 'ShareIcon',
    viewBox: '0 0 24 24',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'none',
        stroke: 'currentColor',
    },
    path: (
        <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
            d="M9 8.25H7.5a2.25 2.25 0 00-2.25 2.25v9a2.25 2.25 0 002.25 2.25h9a2.25 2.25 0 002.25-2.25v-9a2.25 2.25 0 00-2.25-2.25H15m0-3l-3-3m0 0l-3 3m3-3V15"
        />
    ),
})

export const SlashIcon = createIcon({
    displayName: 'SlashIcon',
    viewBox: '0 0 40 40',
    defaultProps: {
        height: '40px',
        width: 'auto',
        fill: 'none',
        stroke: 'currentColor',
    },
    path: (
        <path
            d="M5 35.5996L35 5.59961"
            stroke="inherit"
            strokeWidth="2"
            vectorEffect="non-scaling-stroke"
        />
    ),
})

export const MenuBackCaret = createIcon({
    displayName: 'MenuBackCaret',
    viewBox: '0 0 18 32',
    defaultProps: {
        height: '32px',
        width: 'auto',
        fill: 'none',
        stroke: 'currentColor',
    },
    path: <path d="M16.5 1L1.5 16L16.5 31" stroke="inherit" strokeWidth="2" />,
})
