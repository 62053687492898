const modal = {
    baseStyle: {
        dialog: {
            borderRadius: 'none',
        },
    },
    sizes: {
        full: {
            dialogContainer: {
                // outer div
                height: '100%',
            },
            dialog: {
                // inner div

                // We want the fullscreen modal to literally take up the
                // whole screen. We don't want any top/bottom margin.
                my: 0,
                height: 'auto',
                minHeight: '100%',
            },
        },
        xxl: {
            dialog: {
                width: '1100px',
            },
        },
    },
}

export default modal
