// Generate Short Payment Descriptor which is used in e.g. bank mobile apps
// for scanning QR codes
// The format spec can be found on https://qr-platba.cz/pro-vyvojare/specifikace-formatu/
import React from 'react'
import * as R from 'ramda'
import { CZECH_BANK_CODES, SLOVAK_BANK_CODES } from './bank_codes'
import { COUNTRIES, CountryCode } from './countries'
import { isNull, ObjectKeys } from './ts-utils'

// Validates Czech and Slovak numbers according to the checksum.
// The same validation rules apply for both the prefix and the main part
export const validateBankAccountNumber = (number?: string) => {
    const accountNumbers = (number || '').padStart(10, '0').split('')
    const weights = [6, 3, 7, 9, 10, 5, 8, 4, 2, 1]

    const numberWeights = R.zip(accountNumbers, weights)

    const checksum = numberWeights.reduce((sum, [number, weight]) => {
        return sum + parseInt(number, 10) * weight
    }, 0)

    return checksum % 11 === 0
}

export type BankAccount = {
    bank_account_prefix?: string | null
    bank_account_number: string
    bank_account_code?: string | null
}

export const generateSPD = ({
    variableSymbol,
    specificSymbol,
    amount,
    currency = 'CZK',
    iban,
}: {
    variableSymbol?: number | null
    specificSymbol?: number | null
    amount?: string | number
    currency?: string
    iban: string
}) => {
    // SPD*1.0*ACC:CZ2201000000000007401552*CC:CZK*X-VS:${variableSymbol}
    const chunks = ['SPD', '1.0']

    if (!iban) {
        throw new TypeError('You must pass an `iban` to the `generateSPD` function')
    }

    chunks.push(`ACC:${iban}`)

    chunks.push(`CC:${currency}`)

    chunks.push(`PT:IP`) // sign for Payment Type:Instant Payment

    if (amount) {
        chunks.push(`AM:${amount}`)
    }

    if (variableSymbol) {
        chunks.push(`X-VS:${variableSymbol}`)
    }

    if (specificSymbol) {
        chunks.push(`X-SS:${specificSymbol}`)
    }

    return chunks.join('*')
}

export const parseAccountNumber = (text: string) => {
    const regexpAccountNumber = /(([0-9]{0,6})-)?([0-9]{2,10})(\/([0-9]{4}))?/
    const match = text.match(regexpAccountNumber)
    if (match) {
        const prefix = match[2]
        const accountNumber = match[3]
        const bankCode = match[5]

        return {
            prefix,
            accountNumber,
            bankCode,
        }
    }
    return null
}

export const checkFullBankNumberPaste = (
    e: ClipboardEvent | React.ClipboardEvent,
    cb: ({
        prefix,
        accountNumber,
        bankCode,
    }: {
        prefix: string
        accountNumber: string
        bankCode: string
    }) => void,
) => {
    const text = (e.clipboardData || window.clipboardData).getData('text')
    const parseResult = parseAccountNumber(text)

    if (parseResult) {
        const { prefix, bankCode } = parseResult

        // if not just pasting a single number
        if (prefix || bankCode) {
            cb(parseResult)
        }
    }
}

export const removeLeadingZeros = (str: string) => str.replace(/^0+/, '')

/**
 * Only works for Czech and Slovak bank account encoded as IBAN
 *
 * format: countryCode|controlNumber|bankCode|bankAccountPrefix|bankAccountNumber
 *
 * countryCode: 2 characters
 * controlNumber: 2 characters
 * bankCode: 4 characters
 * bankAccountPrefix: 6 characters
 * bankAccountNumber: 10 characters
 */

export function parseIBAN(iban: null): {
    bank_account_prefix: null
    bank_account_number: null
    bank_account_code: null
}
export function parseIBAN(iban: string): {
    bank_account_prefix: string | null
    bank_account_number: string
    bank_account_code: string
}

export function parseIBAN(iban: string | null): {
    bank_account_prefix: string | null
    bank_account_number: string | null
    bank_account_code: string | null
}

export function parseIBAN(iban: Maybe<string>) {
    if (isNull(iban)) {
        return {
            bank_account_prefix: null,
            bank_account_number: null,
            bank_account_code: null,
        }
    }

    return {
        // default to null if string is empty after removing zeros
        bank_account_prefix: removeLeadingZeros(iban.slice(8, 14)) || null,
        bank_account_number: removeLeadingZeros(iban.slice(14)),
        bank_account_code: iban.slice(4, 8),
    }
}

const allowedBankAccountCodes: Array<CountryCode> = [COUNTRIES.CZ, COUNTRIES.SK]

export const nationalNumberToIban = ({
    bank_account_prefix,
    bank_account_number,
    bank_account_code,
}: {
    bank_account_prefix?: string | null
    bank_account_number?: string | null
    bank_account_code?: string | null
}) => {
    if (!bank_account_code || !bank_account_number) return null

    const countryCode = getBankAccountCountry(bank_account_code)

    if (!countryCode) return null

    // works only for CZ and SK account numbers
    if (!allowedBankAccountCodes.includes(countryCode)) return null

    const bban = `${bank_account_code}${(bank_account_prefix || '')
        .padStart(6, '0')
        .slice(-6)}${bank_account_number.padStart(10, '0').slice(-10)}`

    const remainder = mod9710(
        `${bban}${countryCode}00`.replace(/[A-Z]/g, letter => `${letter.charCodeAt(0) - 55}`),
    )

    const controllNumber = `${98 - remainder}`.padStart(2, '0')

    return `${countryCode}${controllNumber}${bban}`
}

const mod9710 = (validationString: string): number => {
    let currString = validationString

    while (currString.length > 2) {
        // > Any computer programming language or software package that is used to compute D
        // > mod 97 directly must have the ability to handle integers of more than 30 digits.
        // > In practice, this can only be done by software that either supports
        // > arbitrary-precision arithmetic or that can handle 219-bit (unsigned) integers
        // https://en.wikipedia.org/wiki/International_Bank_Account_Number#Modulo_operation_on_IBAN
        const part = currString.slice(0, 6)
        const partInt = parseInt(part, 10)

        if (Number.isNaN(partInt)) {
            return NaN
        }

        currString = (partInt % 97) + currString.slice(part.length)
    }

    return parseInt(currString, 10) % 97
}

export const getBankAccountCountry = (bank_account_code: string): CountryCode | null => {
    if (Object.keys(CZECH_BANK_CODES).includes(bank_account_code)) {
        return COUNTRIES.CZ
    }

    if (Object.keys(SLOVAK_BANK_CODES).includes(bank_account_code)) {
        return COUNTRIES.SK
    }

    return null
}

/*
 * Returns TRUE if the IBAN is valid
 * Returns FALSE if the IBAN's length is not as should be (for CY the IBAN Should be 28 chars long starting with CY )
 * Returns any other number (checksum) when the IBAN is invalid (check digits do not match)
 *
 * based on: https://stackoverflow.com/questions/21928083/iban-validation-check?answertab=scoredesc#tab-top
 */
const BANK_CODE_LENGTHS = {
    CZ: 24,
    SK: 24,
    LT: 20, // Revolut accounts might need this
} as const

type BankCodeLengthKeys = ObjectKeys<typeof BANK_CODE_LENGTHS>

export const isBankAccountNumberValidInIBAN = (iban?: string) => {
    if (!iban) return false

    return validateBankAccountNumber(parseIBAN(iban).bank_account_number)
}

export const isIBANValid = (input?: string) => {
    if (!input) return false

    const iban = String(input)
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, '') // keep only alphanumeric characters
    const code = iban.match(/^([A-Z]{2})(\d{2})([A-Z\d]+)$/) // match and capture (1) the country code, (2) the check digits, and (3) the rest

    if (!code) return false

    const [, countryCode, checkSum, bankAccount] = code

    // check syntax and length
    if (iban.length !== BANK_CODE_LENGTHS[countryCode as BankCodeLengthKeys]) return false

    // rearrange country code and check digits, and convert chars to ints
    const digits = `${bankAccount}${countryCode}00`.replace(
        /[A-Z]/g,
        letter => `${letter.charCodeAt(0) - 55}`,
    )

    const remainder = mod9710(digits)
    const controlNumber = `${98 - remainder}`.padStart(2, '0')

    return controlNumber === checkSum
}
