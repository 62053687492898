import React, { useReducer, useContext, useEffect, ReactNode } from 'react'
import {
    ActionWithResolve,
    AsyncDispatch,
    asyncReducer,
    useAsyncDispatch,
} from '@upvestcz/common/hooks/use-async-dispatch'
import { AgreementSet } from '../lib/api'

interface SetAction extends ActionWithResolve {
    type: 'set'
    payload: AgreementSet
}

type Action = SetAction

export type State = AgreementSet

export const GLOBAL_CONTEXT_KEY = '__AGREEMENT_SET_STATE__'

const AgreementSetStateContext = React.createContext<State | undefined>(undefined)
const AgreementSetDispatchContext = React.createContext<AsyncDispatch<Action> | undefined>(
    undefined,
)

const reducer = asyncReducer((_: State, action: Action) => {
    switch (action.type) {
        case 'set':
            return action.payload
        default:
            throw new Error(`An unknown action of type ${action.type} passed`)
    }
})

function AgreementSetProvider({
    initialValue,
    children,
}: {
    initialValue: State
    children: ReactNode
}) {
    const [state, dispatch] = useReducer(reducer, initialValue || null)

    const promisifiedDispatch = useAsyncDispatch(dispatch)

    useEffect(() => {
        // bind the state to the window for reuse in getInitialProps
        // inspired here: https://github.com/zeit/next.js/blob/canary/examples/with-redux/lib/with-redux-store.js
        // eslint-disable-next-line no-underscore-dangle
        window[GLOBAL_CONTEXT_KEY] = state
    }, [state])

    return (
        <AgreementSetStateContext.Provider value={state}>
            <AgreementSetDispatchContext.Provider value={promisifiedDispatch}>
                {children}
            </AgreementSetDispatchContext.Provider>
        </AgreementSetStateContext.Provider>
    )
}

function useAgreementSetState() {
    const context = useContext(AgreementSetStateContext)

    if (context === undefined) {
        throw new Error('useAgreementSetState must be used within a AgreementSetProvider')
    }

    return context
}

function useAgreementSetDispatch() {
    const context = useContext(AgreementSetDispatchContext)

    if (context === undefined) {
        throw new Error('useAgreementSetDispatch must be used within a AgreementSetProvider')
    }

    return context
}

function useAgreementSet() {
    return [useAgreementSetState(), useAgreementSetDispatch()] as const
}

export { AgreementSetProvider, useAgreementSet }
