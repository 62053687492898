export default {
    // new colors 2023
    black: '#1D1D1B', // text color
    steelGrey: '#B4C0C5',
    darkGrey: '#414549',
    neonPrimary: '#74FBD6',
    primary: '#115D4B',
    get text() {
        return this.black
    },
    textSecondary: '#555',
    textLight: '#767676',
    lightGrey: '#BEBEBE',
    lighterGrey: '#EBEBEB',
    grey: '#DBDBDB',
    primaryLight: '#00AA8E',
    dangerRed: '#D80000',
    warningYellow: '#F2C111',
    chartBlue: '#216E95',
    chartLightGreen: '#77C9BB',
    chartRed: '#E65765',
    chartGreen: '#167F6E',
    chartDarkGreen: '#234b43',
    chartCyan: '#77c9bb',
    chartYellow: '#ffc002',
    chartGridLines: '#E1E1E1',
    highlightWarning: '#FBD7A2',
    highlightDanger: '#F9CCC4',
    highlightSuccess: '#D5E5E2',
    lessPositive: '#C5F8D0',
}
