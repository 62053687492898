import * as yup from 'yup'
import * as R from 'ramda'
import { TFunction } from 'i18next'
import { DOCUMENT_TYPES } from '@upvestcz/common/account-utils'
import { isPhoneNumberValid } from '../utils'
import { SHAREHOLDER_EQUITY_DISCLOSURE_THRESHOLD } from '../../constants'

import { countries } from '../../countries'
import { MaybePromise } from '../../ts-utils'
import {
    createCommonBankAccountFields,
    createFATCAFieldsSchema,
    createPidSyncValidation,
} from './account'

const getEmailSyncValidation = (t: TFunction) =>
    yup
        .string()
        .required(t('Vyplňte prosím Váš e-mail'))
        .email(t('Vyplňte prosím platný e-mail'))
        .test(
            'valid-email', // blacklisted domains
            t('Vyplňte prosím platný e-mail'), // Error message
            function (value) {
                // Check if value is defined
                if (!value) return true
                const blacklist = ['@passmail.com', '@passinbox.com']
                // return true if not on blacklist
                return !blacklist.some(domain => value.endsWith(domain))
            },
        )

export const getEmailSchema = ({
    t = R.identity,
    isEmailUnique,
}: {
    t?: TFunction
    isEmailUnique: (email: string) => MaybePromise<boolean>
}) => {
    const emailSyncValidation = getEmailSyncValidation(t)
    return emailSyncValidation.test(
        'email-unique',
        t('Tento e-mail už je registrovaný'),
        async email => {
            // don't try to validate the uniqueness of the email
            // unless the other checks are OK
            if (!(await emailSyncValidation.isValid(email))) {
                return true // Mark `email-unique` as valid if other sync checks have failed
            }

            return isEmailUnique(email!)
        },
    )
}

/*
Physical account schemas
 */

export const createValidationSchemaPhysicalPersonalDetailsBase = ({
    isPidUnique,
    t = R.identity,
}: {
    t?: TFunction
    isPidUnique: (pid: string) => MaybePromise<boolean>
}) => ({
    name: yup.string().required(t('Vyplňte, prosím, své jméno.')),
    surname: yup.string().required(t('Vyplňte, prosím, své příjmení.')),
    pid: createPidSyncValidation(t).test(
        'checkPidUnique',
        t('Uživatel s tímto rodným číslem je již registrován.'),
        async pid => {
            // don't try to validate the uniqueness of the pid
            // unless the other checks are OK
            if (!(await createPidSyncValidation(t).isValid(pid))) {
                return true // Mark checkPidUnique as valid if other sync checks have failed
            }

            return isPidUnique(pid!)
        },
    ),
    phone_country_code: yup.number().required(t('Vyplňte, prosím, telefonní předvolbu.')),
    phone: yup
        .string()
        .required(t('Vyplňte, prosím, telefonní číslo.'))
        .test({
            name: 'is-phone-valid',
            message: t('Neplatné telefonní číslo'),
            test(value, context) {
                return isPhoneNumberValid({
                    phone: value,
                    phone_country_code: context.parent.phone_country_code,
                })
            },
        }),
})

export const createValidationSchemaPhysicalIdsBase = (t: TFunction = R.identity) => ({
    id1_front: yup.string().required(t('Nahrajte, prosím, přední stranu občanského průkazu.')),
    id1_back: yup.string().required(t('Nahrajte, prosím, zadní stranu občanského průkazu.')),
    id2_front: yup.string().required(t('Nahrajte, prosím, přední stranu druhého dokladu.')),
})

export const createValidationSchemaPhysicalBankPage = (t: TFunction = R.identity) => ({
    ...createValidationSchemaPhysicalBankAccountBase(t),
    ...createFATCAFieldsSchema(t),
})

export const createValidationSchemaPhysicalBankAccountBase = (t: TFunction = R.identity) =>
    // omit iban_eur since it is not needed during registration
    R.omit(['iban_eur'], createCommonBankAccountFields(t))

export const createValidationSchemaPhysical = ({
    isPidUnique,
    t = R.identity,
}: {
    isPidUnique: (pid: string) => MaybePromise<boolean>
    t?: TFunction
}) =>
    yup.object().shape({
        // Personal Details
        ...createValidationSchemaPhysicalPersonalDetailsBase({ isPidUnique, t }),
        // IDs
        ...createValidationSchemaPhysicalIdsBase(t),
        // Bank account
        ...createValidationSchemaPhysicalBankPage(t),
        // Summary
        is_not_PEP: yup.boolean(),
        kb_marketing_consent: yup.boolean(),
    })

/*
 Soft validation is for first step of registration
 before user has to choose or fill in any missing data.
 */
export const createValidationSchemaPhysicalBankIDBase = ({
    isPidUnique,
    t = R.identity,
    softValidation = false,
    isSignup = false,
}: {
    isPidUnique: (pid: string) => MaybePromise<boolean>
    t?: TFunction
    softValidation?: boolean
    isSignup?: boolean
}) => {
    const { iban } = createCommonBankAccountFields(t)
    const pidSyncValidation = createPidSyncValidation(t)
    const phoneValidation = yup
        .string()
        .required()
        .test({
            name: 'is-phone-valid',
            message: t('Neplatné telefonní číslo'),
            test(value, context) {
                return isPhoneNumberValid({
                    phone: value,
                    phone_country_code: context.parent.phone_country_code,
                })
            },
        })
    return {
        iban: softValidation
            ? // cannot use `.notRequired()` as it disallows passing empty strings
              yup.mixed().when({
                  is(value: unknown) {
                      return !!value
                  },
                  then: iban,
                  otherwise: yup.mixed(),
              })
            : iban,
        is_not_PEP: yup.boolean().required(),
        name: yup.string().required(t('Vyplňte, prosím, své jméno.')),
        surname: yup.string().required(t('Vyplňte, prosím, své příjmení.')),
        pid: pidSyncValidation.test(
            'checkPidUnique',
            t('Uživatel s tímto rodným číslem je již registrován.'),
            async pid => {
                // don't try to validate the uniqueness of the pid
                // unless the other checks are OK
                if (!(await pidSyncValidation.isValid(pid))) {
                    return true // Mark checkPidUnique as valid if other sync checks have failed
                }

                return isPidUnique(pid!)
            },
        ),
        state_of_residency: yup
            .string()
            .oneOf(countries.map(country => country.value))
            .required(),
        state_of_citizenship: yup
            .string()
            .oneOf(countries.map(country => country.value))
            .required(),
        address: yup.string().required(),
        city: yup.string().required(),
        zip: yup.string().required(),
        // optional
        phone_country_code: softValidation ? yup.number() : yup.number().required(),
        // cannot use `.notRequired()` as it disallows passing empty strings
        phone: softValidation
            ? yup.mixed().when({
                  is(value: unknown) {
                      return !!value
                  },
                  then: phoneValidation,
                  otherwise: yup.mixed(),
              })
            : phoneValidation,
        /**
         * isSignup here makes email required for signup through BankID, we are not able to update the email in auth0 later
         * more: https://community.auth0.com/t/unable-to-update-users-email/115906
         */
        email:
            isSignup || !softValidation
                ? yup.string().email().required()
                : yup.mixed().when({
                      is(value: unknown) {
                          return !!value
                      },
                      then: yup.string().email().nullable(),
                      otherwise: yup.mixed().nullable(),
                  }),
    }
}

export const createValidationSchemaBankID = ({
    isPidUnique,
    t = R.identity,
}: {
    isPidUnique: (pid: string) => MaybePromise<boolean>
    t?: TFunction
}) =>
    yup.object().shape({
        // Personal Details
        ...createValidationSchemaPhysicalBankIDBase({ isPidUnique, t }),
        ...createFATCAFieldsSchema(t),
        kb_marketing_consent: yup.boolean().required(),
    })

// The difference between this function and `createValidationSchemaBankID` is that we
// don't have to validate `email` with the latter but do have to validate it with
// the former
export const createValidationSchemaBankIDSignUp = ({
    isPidUnique,
    isEmailUnique,
    t = R.identity,
}: {
    isPidUnique: (pid: string) => MaybePromise<boolean>
    isEmailUnique: (email: string) => MaybePromise<boolean>
    t?: TFunction
}) =>
    yup.object().shape({
        // Personal Details
        ...createValidationSchemaPhysicalBankIDBase({ isPidUnique, t }),
        ...createFATCAFieldsSchema(t),
        email: getEmailSchema({ isEmailUnique, t }),
        kb_marketing_consent: yup.boolean().required(),
    })

export const createValidationSchemaBankIDHandover = ({
    isSignup,
    isPidUnique,
}: {
    isPidUnique: (pid: string) => MaybePromise<boolean>
    isSignup?: boolean
}) =>
    yup.object().shape({
        // Personal Details
        ...createValidationSchemaPhysicalBankIDBase({
            softValidation: true,
            isSignup,
            isPidUnique,
        }),
        id_type: yup.string().oneOf(Object.values(DOCUMENT_TYPES)).required(),
        id_number: yup.string().required(),
        state_of_birth: yup.string().required(),
        city_of_birth: yup.string().required(),
    })

/*
Corporate account schemas
 */

export const corporatePersonBlankSchema = yup.object().shape({
    name: yup.string().required(),
    surname: yup.string().required(),
    pid: createPidSyncValidation().notRequired(),
    personal_data_agreement: yup.boolean(),
    is_beneficial_owner: yup.boolean(),
    is_executive: yup.boolean(),
    phone: yup.string(),
    phone_country_code: yup.number().nullable(),
    id1_front: yup.string(),
    id1_back: yup.string(),
    id2_front: yup.string(),
    company_relationship: yup.string(),
    is_not_PEP: yup.boolean(),
})

export const createCorporatePersonSchema = (t: TFunction = R.identity) =>
    yup.object().shape({
        name: yup.string().required(t('Vyplňte, prosím, jméno osoby')),
        surname: yup.string().required(t('Vyplňte, prosím, příjmení osoby')),
        pid: createPidSyncValidation(t),
        personal_data_agreement: yup
            .boolean()
            .oneOf([true], t('Vyplňte, prosím, souhlas se zpracováním osobních údajů.')),
        is_beneficial_owner: yup.boolean(),
        phone: yup.string().when('is_executive', {
            is: true,
            then: fieldSchema => fieldSchema.required(t('Vyplňte, prosím, telefonní číslo')),
        }),
        phone_country_code: yup.number().when('is_executive', {
            is: true,
            then: fieldSchema => fieldSchema.required(t('Vyplňte, prosím, telefonní předvolbu.')),
            otherwise: fieldSchema => fieldSchema.nullable(),
        }),
        is_executive: yup.boolean(),
        id1_front: yup.string().required(t('Nahrajte, prosím, přední stranu občanského průkazu.')),
        id1_back: yup.string().required(t('Nahrajte, prosím, zadní stranu občanského průkazu.')),
        id2_front: yup.string().when('is_executive', {
            is: true,
            then: fieldSchema =>
                fieldSchema.required(t('Nahrajte, prosím, přední stranu druhého průkazu.')),
        }),
        company_relationship: yup.string().when('is_executive', {
            is: false,
            then: fieldSchema =>
                fieldSchema.required(
                    t('Napište, prosím, jaký je vztah této osoby k dané společnosti.'),
                ),
        }),
        is_not_PEP: yup.boolean(),
    })

// get keys from the schema and remove the generic [x: string]: never from the object.
export type KeyOfCorporatePersonSchema<
    Schema = yup.InferType<ReturnType<typeof createCorporatePersonSchema>>,
> = keyof {
    [K in keyof Schema as Schema[K] extends never ? never : K]: Schema[K]
}

export const createValidationSchemaCorporateCompanyDetailsBase = (t: TFunction = R.identity) => ({
    corporate_id: yup.string().required(t('Vyplňte, prosím, IČO vaší společnosti.')),
    corporate_name: yup.string().required(t('Vyplňte, prosím, název vaší společnosti.')),
})

export const createValidationSchemaCorporateBankAccountBase = (t: TFunction = R.identity) => ({
    // omit iban_eur since it is not needed during registration
    ...R.omit(['iban_eur'], createCommonBankAccountFields(t)),
    corporate_shareholder_equity_amount: yup.number(),
    corporate_shareholder_equity_origin: yup.mixed().when('corporate_shareholder_equity_amount', {
        is: (amount: number) => amount >= SHAREHOLDER_EQUITY_DISCLOSURE_THRESHOLD,
        then: yup.string().required(t('Vyplňte, prosím, původ základního kapitálu.')),
    }),
})

export const createValidationSchemaCorporateWithPersons = (t: TFunction = R.identity) => ({
    corporate_persons: yup.mixed().when('corporate_id', {
        is: (corporateId: string) => !!corporateId,
        then: yup
            .mixed()
            .test(
                'at-least-one-person',
                t('Společnost musí mít alespoň jednu uvedenou osobu.'),
                persons => {
                    return !!(persons && persons.length)
                },
            )
            .test(
                'is-valid',
                t('Vyplňte, prosím, požadované údaje o osobách ve společnosti.'),
                persons => {
                    // all objects in the `corporate_persons` array should match the
                    // `corporatePersonSchema` schema
                    return Promise.all(
                        // fails if the callback is an arrow function...
                        persons.map(async function (person: any) {
                            await createCorporatePersonSchema().validate(person)
                            return true
                        }),
                    ).then(validations => validations.every(validation => validation === true))
                },
            )
            .test(
                'at-least-one-executive',
                t('Alespoň jedna z osob ve společnosti musí být uvedena jako jednatel.'),
                persons =>
                    // fails if the callback is an arrow function...
                    persons.some(function (person: any) {
                        return person.is_executive
                    }),
            )
            .test(
                'at-least-one-beneficial-owner',
                t('Alespoň jedna z osob ve společnosti musí být uvedena jako skutečný majitel.'),
                persons =>
                    // fails if the callback is an arrow function...
                    persons.some(function (person: any) {
                        return person.is_beneficial_owner
                    }),
            ),
    }),
})

export const createValidationSchemaCorporate = (t: TFunction = R.identity) =>
    yup.object().shape({
        // company-details
        ...createValidationSchemaCorporateCompanyDetailsBase(t),
        // ids
        ...createValidationSchemaCorporateWithPersons(t),
        // bank
        ...createValidationSchemaCorporateBankAccountBase(t),
    })
