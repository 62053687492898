import React, { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react'
import { ObjectValues } from '@upvestcz/common/ts-utils'
import throttle from 'lodash.throttle'

export const SCROLL_DIRECTIONS = Object.freeze({
    UP: 'UP',
    DOWN: 'DOWN',
    INITIAL: 'INITIAL',
} as const)

type ScrollDirection = ObjectValues<typeof SCROLL_DIRECTIONS>

type ScrollInfoContextType = {
    isBelowFirstFold: boolean
    scrollDirection: ScrollDirection
}

const ScrollInfoContext = createContext<ScrollInfoContextType | undefined>(undefined)

export const useScrollInfo = () => {
    const context = useContext(ScrollInfoContext)

    if (context === undefined) {
        const error = new Error(
            'useScrollInfo: `context` is undefined. Seems you forgot to wrap component within the Provider',
        )
        error.name = 'ContextError'
        Error.captureStackTrace?.(error, useScrollInfo)
        throw error
    }

    return context as ScrollInfoContextType
}

export function ScrollInfoProvider({ children }: { children: React.ReactNode }) {
    const [isBelowFirstFold, setIsBelowFirstFold] = useState<boolean>(false)
    const [scrollDirection, setScrollDirection] = useState<ScrollDirection>(
        SCROLL_DIRECTIONS.INITIAL,
    )
    const lastPageYOffsetRef = useRef<number | null>(null)

    const calculateScrollInfo = useCallback(
        throttle(() => {
            const { pageYOffset, scrollY, innerHeight } = window
            const currentYPos = pageYOffset || scrollY
            const prevPageYOffset = lastPageYOffsetRef.current

            if (currentYPos > innerHeight && !isBelowFirstFold) {
                setIsBelowFirstFold(true)
            }

            if (currentYPos < innerHeight && isBelowFirstFold) {
                setIsBelowFirstFold(false)
            }

            if (prevPageYOffset !== null) {
                if (currentYPos < prevPageYOffset && scrollDirection !== SCROLL_DIRECTIONS.UP) {
                    setScrollDirection(SCROLL_DIRECTIONS.UP)
                }

                if (currentYPos > prevPageYOffset && scrollDirection !== SCROLL_DIRECTIONS.DOWN) {
                    setScrollDirection(SCROLL_DIRECTIONS.DOWN)
                }
            }

            lastPageYOffsetRef.current = currentYPos
        }, 100),
        [isBelowFirstFold, scrollDirection],
    )

    useEffect(() => {
        lastPageYOffsetRef.current = window.pageYOffset || window.scrollY
        window.addEventListener('scroll', calculateScrollInfo, { passive: true })

        // call the function in order to get the initial values even when the user
        // doesn't scroll
        calculateScrollInfo()

        return () => {
            window.removeEventListener('scroll', calculateScrollInfo)
        }
    }, [calculateScrollInfo, isBelowFirstFold])

    return (
        <ScrollInfoContext.Provider
            value={{
                isBelowFirstFold,
                scrollDirection,
            }}
        >
            {children}
        </ScrollInfoContext.Provider>
    )
}
