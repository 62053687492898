import { useState, useRef, ReactNode } from 'react'
import { useTranslation } from 'react-i18next'
import { useTransition, animated, useSpring } from 'react-spring'
import {
    Box,
    Stack,
    Flex,
    Text,
    NavLink,
    HStack,
    icons,
    Portal,
    usePopper,
    ButtonProps,
    forwardRef,
    chakra,
} from '@upvestcz/shared-components'
import { Dict } from '@chakra-ui/utils'
import { NAVBAR_Z_INDEX } from '@upvestcz/shared-components/theme/v2/variables'

import { useOnClickOutside } from '@upvestcz/common/hooks'
// we've got to import the `NavLinkProps` type from `shared-components/Nav` instead of `shared-components`
// as we can't use the `export type ...` syntax unless we upgrade `prettier`
import { NavLinkProps } from '@upvestcz/shared-components/Nav'
import { isAccountInvestmentClubMember, isAccountPrivMember } from '@upvestcz/common/account-utils'
import { useLogout } from '../lib/auth'
import { useAccount } from '../store/account'

const AnimatedStack = animated(Stack)
const AnimatedHTMLButton = animated(chakra.button)

const ProfileDropdownLink = forwardRef(function ProfileDropdownLink(
    { children, ...props }: { children: ReactNode } & NavLinkProps,
    ref,
) {
    const styles: Dict = {
        py: 1,
        border: 'none',
    }

    return (
        <NavLink ref={ref} {...styles} {...props}>
            {children}
        </NavLink>
    )
})

function ProfileDropdown(props: ButtonProps) {
    const [isOpen, setIsOpen] = useState(false)
    const { getPopperProps, getReferenceProps, transformOrigin } = usePopper({
        matchWidth: true,
        strategy: 'fixed',
        placement: 'bottom-end',
    })
    const dropdownRef = useRef<HTMLDivElement>(null)
    const transition = useTransition(isOpen, {
        from: { opacity: 0, transform: 'translateY(-10%)' },
        enter: { opacity: 1, transform: 'translateY(0%)' },
        leave: { opacity: 0, transform: 'translateY(-10%)' },
    })
    const style = useSpring({
        boxShadow: isOpen ? '0px 8px 16px rgba(0, 0, 0, 0.1)' : '0px 8px 16px rgba(0, 0, 0, 0)',
    })
    useOnClickOutside(dropdownRef, () => {
        setIsOpen(false)
    })
    const [account] = useAccount()
    const logout = useLogout()
    const { t } = useTranslation()

    return (
        <AnimatedHTMLButton
            as={Box}
            pos="relative"
            data-test-id="button-open-profile-dropdown"
            style={style}
            py={2}
            px={4}
            {...getReferenceProps(props, dropdownRef)}
        >
            <ProfileDropdownLink
                as="button"
                variant="link"
                onClick={() => {
                    setIsOpen(!isOpen)
                }}
            >
                <Flex align="baseline">
                    {account &&
                        (isAccountInvestmentClubMember(account) ||
                            isAccountPrivMember(account) ||
                            account.kb_employee) && (
                            <icons.InvestmentClubIcon
                                height="1.15em"
                                width="auto"
                                mr={2}
                                data-test-id="investment-club-icon"
                                {...(account.kb_employee ? { fill: '#e4242f' } : {})}
                            />
                        )}
                    <Text>
                        {t('Můj účet')} <icons.CaretIconDown />
                    </Text>
                </Flex>
            </ProfileDropdownLink>
            <Portal>
                <Box {...getPopperProps()} zIndex={NAVBAR_Z_INDEX + 3}>
                    {transition(
                        (style, item) =>
                            item && (
                                <AnimatedStack
                                    bgColor="#fff"
                                    boxShadow="0px 8px 16px rgba(0, 0, 0, 0.1)"
                                    spacing={3}
                                    style={{ ...style, transformOrigin }}
                                    textAlign="left"
                                    pos="absolute"
                                    py={6}
                                    px={6}
                                    minW="100%"
                                    right={0}
                                    top="100%"
                                    marginTop={2}
                                >
                                    <ProfileDropdownLink
                                        data-test-id="link-profile"
                                        to="/user/profile"
                                        onClick={() => {
                                            setIsOpen(!isOpen)
                                        }}
                                    >
                                        {t('Profil')}
                                    </ProfileDropdownLink>
                                    <ProfileDropdownLink
                                        key="messages"
                                        to="/user/messages"
                                        onClick={() => {
                                            setIsOpen(!isOpen)
                                        }}
                                    >
                                        <HStack spacing={2}>
                                            <span>{t('Zprávy')}</span>
                                        </HStack>
                                    </ProfileDropdownLink>
                                    <ProfileDropdownLink
                                        textAlign="left"
                                        as="button"
                                        onClick={() => {
                                            setIsOpen(false)
                                            logout()
                                        }}
                                    >
                                        {t('Odhlásit se')}
                                    </ProfileDropdownLink>
                                </AnimatedStack>
                            ),
                    )}
                </Box>
            </Portal>
        </AnimatedHTMLButton>
    )
}

export default ProfileDropdown
