import i18n from 'i18next'
import { useMemo } from 'react'
import * as R from 'ramda'
import { Locale, LOCALES } from '@upvestcz/common/i18n/locales'
import * as formatters from '@upvestcz/common/i18n/formatters'
import { initializeI18n, LocaleTranslationsMap } from '@upvestcz/common/i18n'
import { ensureArray } from '@upvestcz/common/ts-utils'

export default initializeI18n(i18n, {
    resources: {
        // none by default. Translation files are loaded asynchronously in _app's getInitialProps.
    },
    formatters: R.pick(
        ['formatAmount', 'formatCurrency', 'formatDateTime', 'formatDayMonthYear'],
        formatters,
    ),
})

interface AddTranslationsOptions {
    ns: string
    translations?: LocaleTranslationsMap
    translationsEn?: Record<string, string>
    translationsCs?: Record<string, string>
}

export function addTranslations({
    ns,
    translations,
    translationsEn, // deprecated
    translationsCs, // deprecated
}: AddTranslationsOptions) {
    Object.entries(translations ?? {}).forEach(([locale, translationsKeyValues]) => {
        if (translationsKeyValues) i18n.addResourceBundle(locale, ns, translationsKeyValues)
    })

    // deprecated
    if (translationsCs) i18n.addResources(LOCALES.CS, ns, translationsCs)
    if (translationsEn) i18n.addResources(LOCALES.EN, ns, translationsEn)
}

export function useAddTranslations({
    ns,
    translationsEn, // deprecated
    translationsCs, // deprecated
    translations,
}: AddTranslationsOptions) {
    useMemo(() => {
        addTranslations({ ns, translationsEn, translationsCs, translations })
    }, [ns, translationsCs, translationsEn, translations])
}

export const tryToLoadTranslations = async ({
    relativeFilePath,
    locale = [LOCALES.CS, LOCALES.EN],
}: {
    relativeFilePath: string
    locale?: Locale | Locale[]
}) => {
    try {
        const folderPath = './public/locales'

        const translations = await Promise.all(
            ensureArray(locale).map(async locale => {
                const translations = (
                    await import(`${folderPath}/${locale}/${relativeFilePath}.json`)
                ).default as Record<string, string>

                return [locale, translations] as const // [Locale, Record<string, string>]
            }),
        ).then(res => Object.fromEntries(res) as LocaleTranslationsMap)

        // deprecated
        const translationsCs = (await import(`${folderPath}/cs/${relativeFilePath}.json`))
            .default as Record<string, string>
        const translationsEn = (await import(`${folderPath}/en/${relativeFilePath}.json`))
            .default as Record<string, string>

        return { translationsCs, translationsEn, translations }
    } catch (err: TODO) {
        if (err.code === 'MODULE_NOT_FOUND') {
            return {}
        }
        throw err
    }
}
