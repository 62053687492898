import React, { useCallback } from 'react'
import { useRouter } from 'next/router'
import { getTopDomain, setCookie } from '@upvestcz/common/cookies'
import { MergeChakraProps } from '@upvestcz/common/ts-utils'
import { Locale } from '@upvestcz/common/i18n/locales'
import Link, { LinkProps } from '../Link'

type Props = MergeChakraProps<
    {
        locale: Locale
    },
    LinkProps
>

const useLocaleLink = ({ locale, onClick }: Pick<Props, 'locale' | 'onClick'>) => {
    const router = useRouter()
    const { pathname, asPath, query } = router

    const hashIndex = asPath.indexOf('#')
    const urlWithIgnoredHash = asPath.slice(0, hashIndex < 0 ? undefined : hashIndex)

    const handleClick = useCallback(
        (e: React.MouseEvent) => {
            e.preventDefault()

            /*
        The locale cookie needs to be set before the page redirect, so the nextjs middleware already knows which locale is correct.
         */
            setCookie(null, 'NEXT_LOCALE', locale, {
                domain: getTopDomain(),
                maxAge: 100 * 365 * 24 * 60 * 60, // 100 yrs
            })
            // change just the locale and maintain all other route information including href's query
            router.push({ pathname, query }, urlWithIgnoredHash, { locale })
            if (onClick) onClick(e)
        },
        [locale, onClick, pathname, query, router, urlWithIgnoredHash],
    )

    const linkProps = {
        onClick: handleClick,
        to: urlWithIgnoredHash,
        isActive: router.locale === locale,
        locale,
    }

    return linkProps
}
// This component redirects the user to whatever page they are currently
// at but with a different locale.
export function LocaleLink({ locale, children, onClick, ...props }: Props) {
    const linkProps = useLocaleLink({ locale, onClick })
    return (
        <Link {...props} {...linkProps}>
            {children}
        </Link>
    )
}

export default LocaleLink
