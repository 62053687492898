// Heavily inspired by https://kentcdodds.com/blog/how-to-use-react-context-effectively
// You can read more there about this pattern
import React, { useReducer, useContext, useEffect, ReactNode } from 'react'
import { CURRENCIES, Currency } from '@upvestcz/common/currency'
import { ACCOUNT_STATUS } from '@upvestcz/common/account-utils'
import * as R from 'ramda'
import {
    ActionWithResolve,
    AsyncDispatch,
    asyncReducer,
    useAsyncDispatch,
} from '@upvestcz/common/hooks/use-async-dispatch'
import { Prisma } from '@prisma/client'
import { Account } from '../lib/api'

interface SetAction extends ActionWithResolve {
    type: 'set'
    payload: Account
}

interface ConcludeAction extends ActionWithResolve {
    type: 'conclude'
}

interface ClearAction extends ActionWithResolve {
    type: 'clear'
}

interface WithdrawalAction extends ActionWithResolve {
    type: 'withdrawal'
    payload: {
        currency: Currency
        amount: number
    }
}

interface InvestmentAction extends ActionWithResolve {
    type: 'investment'
    payload: { amount: number; currency: Currency }
}

interface UpdateAction extends ActionWithResolve {
    type: 'update'
    payload: Partial<Account>
}

export type State = Account | null

type Action =
    | SetAction
    | ConcludeAction
    | ClearAction
    | WithdrawalAction
    | InvestmentAction
    | UpdateAction

export const GLOBAL_CONTEXT_KEY = '__ACCOUNT_STATE__'

const AccountStateContext = React.createContext<State | undefined>(undefined)
const AccountDispatchContext = React.createContext<AsyncDispatch<Action> | undefined>(undefined)

const reducer = asyncReducer((state: State, action: Action): State => {
    switch (action.type) {
        case 'set':
            return action.payload

        case 'clear':
            return null
        case 'conclude':
            if (state === null) {
                throw new Error('Tried to perform `conclude` action on an empty account')
            }

            return {
                ...state,
                status: ACCOUNT_STATUS.PENDING,
            }

        case 'withdrawal':
            if (state === null) {
                throw new Error('Tried to perform `withdrawal` action on an empty account')
            }

            if (action.payload.currency === CURRENCIES.EUR) {
                return {
                    ...state,
                    balance_eur: new Prisma.Decimal(state.balance_eur)
                        .minus(action.payload.amount)
                        .toNumber(),
                    withdrawal_requests_eur_sum: new Prisma.Decimal(
                        state.withdrawal_requests_eur_sum,
                    )
                        .plus(action.payload.amount)
                        .toNumber(),
                }
            }

            return {
                ...state,
                balance: new Prisma.Decimal(state.balance).minus(action.payload.amount).toNumber(),
                withdrawal_requests_sum: new Prisma.Decimal(state.withdrawal_requests_sum)
                    .plus(action.payload.amount)
                    .toNumber(),
            }
        case 'investment':
            if (state === null) {
                throw new Error('Tried to perform `investment` action on an empty account')
            }

            if (action.payload.currency === CURRENCIES.CZK) {
                return {
                    ...state,
                    balance: new Prisma.Decimal(state.balance)
                        .minus(action.payload.amount)
                        .toNumber(),
                    investments_sum: new Prisma.Decimal(state.investments_sum)
                        .plus(action.payload.amount)
                        .plus(state.pending_referral_bonuses_sum)
                        .toNumber(),
                    pending_referral_bonuses_sum: 0,
                }
            }
            // EUR investments
            return {
                ...state,
                balance_eur: new Prisma.Decimal(state.balance_eur)
                    .minus(action.payload.amount)
                    .toNumber(),
                investments_eur_sum: new Prisma.Decimal(state.investments_eur_sum)
                    .plus(action.payload.amount)
                    .toNumber(), // no pending_referral_bonuses for EUR.
            }
        case 'update':
            if (state === null) {
                throw new Error('Tried to perform `update` action on an empty account')
            }

            return R.merge(state, action.payload)
        default:
            throw new Error(`An unknown action passed`)
    }
})

function AccountProvider({ initialValue, children }: { initialValue: State; children: ReactNode }) {
    const [state, dispatch] = useReducer(reducer, initialValue || null)

    const promisifiedDispatch = useAsyncDispatch(dispatch)

    useEffect(() => {
        // bind the state to the window for reuse in getInitialProps
        // inspired here: https://github.com/zeit/next.js/blob/canary/examples/with-redux/lib/with-redux-store.js
        // eslint-disable-next-line no-underscore-dangle
        window[GLOBAL_CONTEXT_KEY] = state
    }, [state])

    return (
        <AccountStateContext.Provider value={state}>
            <AccountDispatchContext.Provider value={promisifiedDispatch}>
                {children}
            </AccountDispatchContext.Provider>
        </AccountStateContext.Provider>
    )
}

function useAccountState<forceLoggedIn = false>() {
    const context = useContext(AccountStateContext)

    if (context === undefined) {
        throw new Error('useAccountState must be used within a AccountProvider')
    }

    return context as forceLoggedIn extends true ? Account : State
}

function useAccountDispatch() {
    const context = useContext(AccountDispatchContext)

    if (context === undefined) {
        throw new Error('useAccountDispatch must be used within a AccountProvider')
    }

    return context
}

function useAccount<forceLoggedIn>() {
    return [useAccountState<forceLoggedIn>(), useAccountDispatch()] as const
}

export { AccountProvider, useAccount }
