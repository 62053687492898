import {
    OpportunityStatus,
    OpportunityPaymentFrequency,
    OpportunityType,
    CashflowObjectType,
    OpportunityDeveloperInfo,
} from '@upvestcz/common/opportunities-utils'
import {
    VerificationProvider,
    DOCUMENT_TYPES,
    AccountStatus,
    AccountRisk,
    TaxResidency,
} from '@upvestcz/common/account-utils'
import * as R from 'ramda'
import Bull from 'bull'
import { replace } from '@upvestcz/common/routing'
import { getApiUrl } from '@upvestcz/common/api'
import { destroyLoginCookies, getAccessTokenFromCookies, Profile } from '@upvestcz/common/auth'
import 'isomorphic-fetch'
import { NextPageContext } from 'next'
import { Locale } from '@upvestcz/common/i18n/locales'
import { ERROR_CODES, CustomError } from '@upvestcz/common/errors'
import useSWR from 'swr'
import { InvestmentState } from '@upvestcz/common/investment-utils'
import { Currency } from '@upvestcz/common/currency'
import { ObjectValues } from '@upvestcz/common/ts-utils'
import { CountryCode } from '@upvestcz/common/countries'
import { getCookieHeaderFromCtx } from '@upvestcz/common/cookies'
import { createAsyncDebouncedFn, isJSONEqual, isResponseLike } from '@upvestcz/common/utils'
import { ReferenceRateType } from '@upvestcz/common/rate-types'
import memoizeOne from 'memoize-one'
import { SmsCode } from '@upvestcz/common/temp-token'
import { CorporatePerson } from '../@types/CorporatePersons'

const { stringify } = JSON

const API_URL = getApiUrl()

type DateRepresentation = string | number | Date

export interface Opportunity {
    address: string
    card_photo: string
    cover_photo: Maybe<string>
    created_at: DateRepresentation
    developer_info: OpportunityDeveloperInfo
    eur_exchange_rate: Maybe<number> // REST ONLY
    executive_summary_pdf: Maybe<string>
    expected_maturity: DateRepresentation
    fees_agreement: Maybe<string>
    fundraising_period_end: DateRepresentation
    fundraising_period_start: DateRepresentation
    id: number
    priv_fees_agreement: Maybe<string>
    priv_interest_rate: Maybe<number>
    ik_fundraising_period_end: Maybe<DateRepresentation> // REST ONLY
    interest_rate_type: string // REST ONLY
    investors_count: number
    loan_payoff_date: Maybe<DateRepresentation>
    loan_interest_rate: number
    maturity: DateRepresentation
    max_fundraising_target: number
    max_investment: number
    min_fundraising_target: number
    min_investment: number
    payment_frequency: OpportunityPaymentFrequency
    published: boolean
    raised: number
    raised_eur: number // REST ONLY
    report_pdf: Maybe<string>
    status: OpportunityStatus
    subtitle: Maybe<string>
    subtitle_en: Maybe<string>
    text_id: Maybe<string> // TODO: this should be required
    title: string
    title_en: string
    updated_at: DateRepresentation
    interest_rates: Record<string, number>
    currencies: Currency[]
    reference_rate_type: Maybe<ReferenceRateType>
    reference_rate_id: number | null
    current_reference_rate?: Maybe<number>
    is_private_placement: boolean
    cashflow_model?: Record<string, Maybe<CashflowObjectType>> // undefined kept for mocks
    type: OpportunityType
}

export interface Account {
    transactions_sum: number
    transactions_eur_sum: number
    investments_sum: number
    investments_eur_sum: number
    payments_sum: number
    payments_eur_sum: number
    withdrawal_requests_sum: number
    withdrawal_requests_eur_sum: number
    used_referral_bonuses_sum: number
    pending_referral_bonuses_sum: number
    id: number
    status: AccountStatus
    is_corporate: boolean
    corporate_name: string | null
    corporate_id: string | null
    auth_id: string
    name: string | null
    surname: string | null
    address: string | null
    city: string | null
    state: string | null
    zip: string | null
    pid: string | null
    email: string
    phone: string | null
    phone_country_code: number
    phone_verified: boolean
    iban: string | null
    iban_eur: string | null
    id1_front: string | null
    id1_back: string | null
    id2_front: string | null

    bank_account_verified: boolean
    variable_symbol: number
    personal_data_agreement: boolean
    bank_account_agreement: boolean
    created_at: string
    updated_at: string
    agrees_from: string | null
    sign_date: string | null
    state_of_citizenship: Maybe<CountryCode>
    state_of_residency: Maybe<CountryCode>
    risk_profile: AccountRisk
    risk_profile_score: number
    agreement_set_id: number
    referral_code: string | null
    is_not_PEP: boolean
    corporate_shareholder_equity_amount: number | null
    corporate_shareholder_equity_origin: string | null
    distribution_partner_account_id: number | null
    balance: number
    balance_eur: number
    is_club_member: boolean
    kb_flag: boolean
    kb_employee: boolean
    kb_marketing_consent: boolean
    is_priv_member: boolean
    corporate_persons: TODO[] | null
    verification_provider: VerificationProvider
    id_type: Maybe<ObjectValues<typeof DOCUMENT_TYPES>>
    id_number: Maybe<string>
    state_of_birth: Maybe<CountryCode>
    city_of_birth: Maybe<string>
    is_payment_account: boolean

    is_usa_citizen: boolean
    tax_residences: TaxResidency[]
}

export interface AgreementSet {
    id: number
    valid_from: string
    valid_to: string
    agreement_1: string
    agreement_2: string
    agreement_3: string
    agreement_4: string
    agreement_5: string
    agreement_6: string
    agreement_7: string | null
    created_at: string
    updated_at: string
}

export interface Referral {
    id: number
    created_at: string
    amount: number
    invitee_name: string
    inviter_invested: boolean
    invitee_invested: boolean
}

export interface Investment {
    account?: Account
    account_id: number
    amount: number
    created_at: DateRepresentation
    currency: Currency
    fees_agreement: Maybe<string>
    id: number
    interest_period_end?: Maybe<DateRepresentation>
    interest_period_start?: Maybe<DateRepresentation>
    interest_rate: number
    opportunity?: Opportunity
    opportunity_id: number
    payments_amount?: number
    state: InvestmentState
    updated_at: DateRepresentation
}

export interface Payment {
    account: Account
    amount: number
    amount_interest: number
    amount_principal: number
    created_at: DateRepresentation
    currency: Currency
    id: number
    investment: Investment
    updated_at?: Maybe<DateRepresentation>
}

export interface Message {
    id: number
    account_id: number
    from: string
    to: string
    subject: string
    content: string
    created_at: Date
    updated_at: Date
}

export type MessageWithoutContent = Omit<Message, 'content'>

/*
 * API fetch wrapper with JSON parsing
 */
export async function api<Data>(endpoint: string, options?: RequestInit, ctx?: NextPageContext) {
    // Use relative URLs in the browser and absolute URLs on the server to correctly proxy client-side requests through this app
    const baseUrl = API_URL

    const cookie = ctx ? getCookieHeaderFromCtx(ctx) : undefined
    const accessToken = getAccessTokenFromCookies(ctx)

    const res = (await fetch(`${baseUrl}${endpoint}`, {
        credentials: 'include',
        ...options,
        headers: {
            ...options?.headers,
            ...(cookie ? { Cookie: cookie } : undefined),
            ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined),
        },
    })) as Response & { data: Data }

    // Handle unauthorized request
    if (res.status === 401) {
        destroyLoginCookies(ctx)

        const cookies: {
            tokenExpired?: string
        } = {}

        let redirected = false
        try {
            const json = await res.json()
            const { code } = json
            if (code && code === ERROR_CODES.TOKEN_EXPIRED) {
                cookies.tokenExpired = 'true'

                replace({
                    ctx,
                    location: null,
                    cookies,
                })
                redirected = true
            }
            // eslint-disable-next-line no-empty
        } catch {}

        if (!redirected) {
            replace({
                ctx,
                location: '/',
                cookies,
            })
        }
    }
    // Ok request
    if (!res.ok) {
        throw res
    }
    if (res.status === 204) {
        // @ts-ignore
        res.data = null
    } else if (((options?.headers || {}) as Record<string, string>).Accept === 'application/json') {
        res.data = await res.json()
    }
    return res
}

export const handleRESTError = async <ErrDataType = CustomError>(err: unknown) => {
    let runtimeError
    let responseError
    if (isResponseLike(err)) {
        responseError = err as Response & { data: ErrDataType }
        // Error responses can also contain JSON with the error thrown on the server-side.
        if ((responseError?.headers?.get('Content-Type') || '').startsWith('application/json')) {
            responseError.data = await responseError.json()
        }
    } else {
        runtimeError = err
    }

    return {
        runtimeError,
        responseError,
        mainError: responseError || runtimeError,
    }
}

export function fetcher<Data>(url: string, ctx?: NextPageContext) {
    return api<Data>(
        url,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    ).then(R.prop('data'))
}

/*
====== SHARED ======
 */

/**
 * GET account
 */
export function fetchAccount(accountId: string, ctx?: NextPageContext) {
    return api<Account>(
        `/api/accounts/${accountId}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * POST log in
 */
export function login(
    payload: { username: string; password: string; accountLinkToken?: string },
    ctx?: NextPageContext,
) {
    return api<{
        access_token: string
        id_token: string
        scope: string
        expires_in: number
        token_type: string
        profile: Profile
    }>(
        `/api/auth/login`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

/**
 * Update user
 */
export function createAccount(accountId: string, payload: TODO, ctx?: NextPageContext) {
    return api<Account>(
        `/api/accounts/${accountId}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

/**
 * GET opportunities
 */
export function fetchOpportunities(ctx?: NextPageContext) {
    return api<Opportunity[]>(
        `/api/opportunities/`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * GET opportunity
 */
export function fetchOpportunity(id: number, ctx?: NextPageContext) {
    return api<Opportunity>(
        `/api/opportunities/${id}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * PUT edit corporate person
 */
export function editCorporatePerson(
    id: number,
    payload: Partial<CorporatePerson>,
    ctx?: NextPageContext,
) {
    return api<CorporatePerson>(
        `/api/corporate-persons/${id}`,
        {
            method: 'PUT',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

/*
====== APP ======
 */

/**
 * Request SMS Code
 */
export function requestSMSCode(accountId: string, payload: TODO = {}) {
    return api(`/api/accounts/${accountId}/code`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify(payload),
    })
}

/**
 * Send verification email
 */
export function sendVerificationEmail(ctx?: NextPageContext) {
    return api(
        `/api/accounts/verify-email`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * GET profile from auth0
 */
export function fetchProfileFromAuth0(ctx?: NextPageContext) {
    return api<Profile>(
        `/api/auth/profile`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * POST sign up
 */
export function signUp(
    payload: { email: string; password: string; is_corporate: boolean },
    ctx?: NextPageContext,
) {
    return api<{
        access_token: string
        id_token: string
        scope: string
        expires_in: number
        token_type: string
        profile: Profile
    }>(
        `/api/auth/sign-up`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

/**
 * POST request change password e-mail
 */
export function requestChangePasswordEmail(payload: TODO, ctx?: NextPageContext) {
    return api(
        `/api/auth/request-change-password-email`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

/**
 * Request withdrawal
 */
export function requestWithdrawal(payload: {
    amount: number
    currency: Currency
    smsCode: SmsCode
}) {
    return api<{
        id: number
        amount: number
        currency: Currency
    }>(`/api/accounts/withdraw`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify(payload),
    })
}

/**
 * Conclude user registration
 */
export function concludeAccount(
    accountId: string,
    payload: {
        smsCode: SmsCode
    },
) {
    return api<Account>(`/api/accounts/${accountId}/conclude`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify(payload),
    })
}

/**
 * Create investment
 */
export function createInvestment(
    id: number,
    body: {
        opportunity_id: number
        amount: number
        currency: Currency
        smsCode: SmsCode
    },
) {
    return api<{
        jobId: Bull.JobId
    }>(`/api/opportunities/${id}/investment`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify(body),
    })
}

/**
 * Rollover investments
 */
export function createRolloverInvestments(
    id: number,
    body: {
        smsCode: SmsCode
        opportunity_id: number
        amount: number
        currency: Currency
        include_interest: boolean
        rolloverInvestments: number[]
    },
) {
    return api<{
        jobId: Bull.JobId
    }>(`/api/opportunities/${id}/investment-rollover`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify(body),
    })
}

/**
 * Equity investments
 */
export function createEquityInvestment(
    id: number,
    body: {
        smsCode: SmsCode
        opportunity_id: number
        amount: number
        currency: Currency
        assetAccount?: string
    },
) {
    return api<{
        jobId: Bull.JobId
    }>(`/api/opportunities/${id}/investment-equity`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify(body),
    })
}

export function fetchMyAgreementSet(ctx?: NextPageContext) {
    return api<AgreementSet>(
        `/api/agreement-set/my`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

export function fetchCurrentAgreementSet(ctx?: NextPageContext) {
    return api<AgreementSet>(
        `/api/agreement-set/current`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * GET fetch referrals
 */
export function fetchMyReferrals(ctx?: NextPageContext) {
    return api<Referral[]>(
        `/api/accounts/referrals/my`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * GET inviter's name
 */
export function fetchInviterName(referralCode: string, ctx?: NextPageContext) {
    return api(
        `/api/accounts/inviter-name/${referralCode}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * GET fetch limited transactions
 */
export function fetchMyTransactionsLimited(ctx?: NextPageContext) {
    return api(
        `/api/accounts/transactions/my-limited`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * POST fetch limited transactions
 */
export function fetchMyTransactions(
    payload: {
        smsCode: SmsCode
    },
    ctx?: NextPageContext,
) {
    return api(
        `/api/accounts/transactions/my`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

/**
 * POST download transaction history pdf
 */
export function exportMyTransactions(payload: TODO, ctx?: NextPageContext) {
    return api(
        `/api/accounts/transactions/my-export`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/pdf',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

/**
 * GET fetch parsed company from ares
 */
export function initCompany(ico: string, ctx?: NextPageContext) {
    return api(
        `/api/ares/${ico}`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

/**
 * POST create corporate person
 */
export function createCorporatePerson(
    payload: {
        name: string
        surname: string
        is_executive: boolean
    },
    ctx?: NextPageContext,
) {
    return api<CorporatePerson>(
        `/api/corporate-persons/create`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

/**
 * DELETE edit corporate person
 */
export function deleteCorporatePerson(id: number, ctx?: NextPageContext) {
    return api(
        `/api/corporate-persons/${id}`,
        {
            method: 'DELETE',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

export function fetchMyMessages(ctx?: NextPageContext) {
    return api<MessageWithoutContent[]>(
        `/api/accounts/messages/my`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}

export function updatePersonalDetails(
    payload: Partial<Account> & {
        smsCode: SmsCode
    },
    ctx?: NextPageContext,
) {
    return api<Account>(
        `/api/accounts/personal-details`,
        {
            method: 'PATCH',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

export function updateMyAgreementSet(payload: TODO, ctx?: NextPageContext) {
    return api(
        `/api/accounts/update-my-agreement-set`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

export function addReferralCode(payload: TODO, ctx?: NextPageContext) {
    return api(
        `/api/auth/add-referral-code`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

/*
1) memoize last submitted value, so formik validations don't spam our API endpoint when pid field is touched
2) debounce the calls so fast typing does not trigger multiple calls.
 */
export const checkEmailUnique = memoizeOne(
    createAsyncDebouncedFn(
        (payload: TODO, ctx?: NextPageContext) => {
            return api(
                `/api/auth/check-email-unique`,
                {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                    body: stringify(payload),
                },
                ctx,
            )
        },
        { wait: 500 },
    ),
    isJSONEqual,
)

export function generateIdsUploadToken(payload: { locale: Locale }, ctx?: NextPageContext) {
    return api(
        `/api/registration/generate-ids-upload-token`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

export function fetchIdsByUploadToken(payload: TODO, ctx?: NextPageContext) {
    return api(
        `/api/registration/get-ids-by-upload-token`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

export function insertIdsByUploadToken(payload: TODO, ctx?: NextPageContext) {
    return api(
        `/api/registration/insert-ids-by-upload-token`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: stringify(payload),
        },
        ctx,
    )
}

export function getBankIDRedirectUrl(payload: { bank_id: string; origin: string }) {
    return api<{ bankIDRedirectUrl: string }>(`/api/registration/bank-id-redirect-url`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(payload),
    })
}

export function addAccountDetailsFromBankID(payload: { code: string }) {
    return api<{ updatedAccount: Account; bankAccountOptions?: string[] }>(
        `/api/registration/add-account-details-from-bank-id`,
        {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(payload),
        },
    )
}

export function getBankIDAvailableBanks() {
    return api(`/api/registration/bank-id-bank-list`, {
        method: 'GET',
    })
}

export function requestEmailChange(payload: TODO) {
    return api(`/api/accounts/request-email-change`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify(payload),
    })
}

export function confirmEmailChange(payload: { token: string }) {
    return api(`/api/accounts/confirm-email-change`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify(payload),
    })
}

/*
1) memoize last submitted value, so formik validations don't spam our API endpoint when pid field is touched
2) debounce the calls so fast typing does not trigger multiple calls.
 */
export const isPidUnique = memoizeOne(
    createAsyncDebouncedFn(
        (payload: TODO) => {
            return api(`/api/accounts/is-pid-unique`, {
                method: 'POST',
                headers: {
                    Accept: 'application/json',
                    'Content-Type': 'application/json',
                },
                body: stringify(payload),
            })
        },
        { wait: 500 },
    ),
    isJSONEqual,
)

export function resetPassword(payload: TODO) {
    return api(`/api/auth/reset-password`, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        body: stringify(payload),
    })
}

export function generateTaxReport(year: number) {
    return api(`/api/accounts/generate-tax-report`, {
        method: 'POST',
        headers: {
            Accept: 'application/pdf',
            'Content-Type': 'application/json',
        },
        body: stringify({ year }),
    })
}

export function generateLienAgreementPreview(
    opportunity_id: number,
    payload: { currency: Currency; amount: number },
) {
    return api(`/api/opportunities/${opportunity_id}/preview-lien-agreement`, {
        method: 'POST',
        headers: {
            Accept: 'application/pdf',
            'Content-Type': 'application/json',
        },
        body: stringify(payload),
    })
}
export function generateIntentAgreementPreview(
    opportunity_id: number,
    payload: { currency: Currency; amount: number },
) {
    return api(`/api/opportunities/${opportunity_id}/preview-intent-agreement`, {
        method: 'POST',
        headers: {
            Accept: 'application/pdf',
            'Content-Type': 'application/json',
        },
        body: stringify(payload),
    })
}
export function uploadIntentAgreement(
    opportunity_id: number,
    payload: { currency: Currency; smsCode: SmsCode; amount: number },
) {
    return api(`/api/opportunities/${opportunity_id}/upload-intent-agreement`, {
        method: 'POST',
        headers: {
            Accept: 'application/pdf',
            'Content-Type': 'application/json',
        },
        body: stringify(payload),
    })
}

export const useAvailableBanks = () => {
    return useSWR<{
        availableBanks: {
            id: string
            title: string
            description: string
            available_logo_images: {
                id: string
                url: string
                width: number
                height: number
            }[]
        }[]
    }>('/api/registration/bank-id-available-banks', fetcher)
}

/*
TEMP endpoints
 */

/**
 * GET rollover participation option for opportunity
 */
export function fetchRolloverInvestmentsForOpportunity(
    opportunityId: number,
    ctx?: NextPageContext,
) {
    return api<Array<Investment & { accrued_interest: number }>>(
        `/api/rolloverInvestmentsFor/${opportunityId}`,
        {
            method: 'GET',
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
        },
        ctx,
    )
}
