import { chakra, ChakraTheme, useTheme } from '@chakra-ui/react'
import { generateSizesAttr } from '@upvestcz/common/styles-utils'
import { OneOrArrayOf } from '@upvestcz/common/ts-utils'
import queryString from 'query-string'
import Image, { ImageLoaderProps, StaticImport } from './CustomNextImage'

type ImgixProps = {
    auto?: OneOrArrayOf<'compress' | 'format'>
    ar?: string // string like "16:9"
    fit?: 'clamp' | 'clip' | 'crop' | 'facearea' | 'fill' | 'fillmax' | 'max' | 'min' | 'scale'
    crop?: OneOrArrayOf<'focalpoint' | 'entropy'>
    'fp-x'?: number
    'fp-y'?: number
    'fp-z'?: number
    'fp-debug'?: boolean
}

export const imgixReplaceLoader = (
    { src, width, quality }: ImageLoaderProps,
    imgixProps: ImgixProps = {},
) => {
    /*
    https://d3uvu5yktntepe.cloudfront.net used to be set up as CDN for images in the upvest-public bucket. Replace that by imgIX too.
     */
    const urlsToReplace = {
        'https://upvest-public.s3.eu-central-1.amazonaws.com': 'https://upvest.imgix.net',
        'https://d3uvu5yktntepe.cloudfront.net': 'https://upvest.imgix.net',
        '//images.ctfassets.net/yva5d8fcnvln': '//upvest-contentful.imgix.net', // https: is missin on purpose - urls from contentful don't contain the protocol.
    }

    const defaultParams: ImgixProps = {
        auto: 'format',
    }

    return `${Object.entries(urlsToReplace).reduce(
        (result, [urlToReplace, urlTarget]) => result.replace(urlToReplace, urlTarget),
        src,
    )}?${queryString.stringify(
        {
            ...defaultParams,
            ...imgixProps,
            w: width,
            q: quality || 80,
        },
        { sort: false, arrayFormat: 'comma' },
    )}`
}

export type CustomImgProps = {
    src?: string | StaticImport | null
    imgWidth?: string | number
    imgHeight?: string | number
    sizes?: string | string[] | Record<string, string>
    alt?: string
    priority?: boolean
    quality?: number
    unoptimized?: boolean
    imgixProps?: ImgixProps
}

const Img = ({ src, imgWidth, imgHeight, sizes, imgixProps, ...props }: CustomImgProps) => {
    const theme = useTheme<ChakraTheme>()
    const isSvg = typeof src === 'string' && src.endsWith('.svg')

    if (!src) return null
    return (
        <Image
            loader={props => imgixReplaceLoader(props, imgixProps)}
            src={src}
            width={imgWidth}
            height={imgHeight}
            layout="responsive"
            lazyBoundary="100%"
            unoptimized={isSvg}
            sizes={sizes ? generateSizesAttr(sizes, theme) : undefined}
            {...props}
        />
    )
}

export default chakra(Img)
