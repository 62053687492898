import * as R from 'ramda'
import { ApolloError } from '@apollo/client'
import { Profile, PROFILE_CUSTOM_CLAIMS, ProfileCustomClaim } from '../auth'
import { Auth0Connection } from './typegen'
import { ObjectKeys } from '../ts-utils'

type EncodedProfile = Omit<Profile, ProfileCustomClaim> & {
    roles: Profile['http://upvest/roles']
    user_metadata: Profile['http://upvest/user_metadata']
    identities: Auth0Connection[] // these have encoded chars, therefore they don't match the type in the Profile. Get the encoded values from GraphQl types
}

export const encodeGraphQLDisallowedChars = (str: string) => {
    /*
    Encode dashes in property names
    https://github.com/graphql/graphql-js/issues/936
     */
    const regex = /-/g
    return str.replace(regex, '__')
}

export const decodeGraphQLDisallowedChars = (str: string) => {
    /*
    Encode dashes in property names
    https://github.com/graphql/graphql-js/issues/936
     */
    const regex = /__/g
    return str.replace(regex, '-')
}

type ProfileCustomClaimKey = ObjectKeys<typeof PROFILE_CUSTOM_CLAIMS>

export function encodeProfile(profile: Profile) {
    const keys = Object.keys(PROFILE_CUSTOM_CLAIMS) as ProfileCustomClaimKey[]
    const namespacedKeys = Object.values(PROFILE_CUSTOM_CLAIMS) as ProfileCustomClaim[]

    const encodedKeyValues = R.pipe<
        ProfileCustomClaimKey[],
        Record<string, any>,
        Partial<EncodedProfile>
    >(
        // make keys into object with values
        R.reduce<ProfileCustomClaimKey, Record<string, any>>(
            (resObj, key) => ({
                ...resObj,
                [key]: profile[PROFILE_CUSTOM_CLAIMS[key]],
            }),
            {},
        ),
        // optionally transform some of the values
        R.evolve({
            identities: arr => arr.map(encodeGraphQLDisallowedChars) as Auth0Connection[],
        }),
    )(keys)

    return {
        ...R.omit(namespacedKeys, profile),
        ...encodedKeyValues,
    } as EncodedProfile
}

export function decodeProfile(profile: EncodedProfile): Profile {
    const keys = Object.keys(PROFILE_CUSTOM_CLAIMS) as ProfileCustomClaimKey[]

    const decodedKeyValues = R.pipe<ProfileCustomClaimKey[], Record<string, any>, Partial<Profile>>(
        // make keys into object with values
        R.reduce<ProfileCustomClaimKey, Record<string, any>>(
            (resObj, key) => ({
                ...resObj,
                [PROFILE_CUSTOM_CLAIMS[key]]: profile[key],
            }),
            {},
        ),
        // optionally transform some of the values
        R.evolve({
            identities: arr =>
                arr.map(decodeGraphQLDisallowedChars) as Profile['http://upvest/identities'],
        }),
    )(keys)

    return {
        ...R.omit(keys, profile),
        ...decodedKeyValues,
    } as Profile
}

export const handleGraphQLError = (
    err: unknown,
): {
    isGraphQLError: boolean
    mainError: Error
    networkError?: ApolloError['networkError']
    graphQLErrors?: ApolloError['graphQLErrors']
} => {
    if (!(err instanceof ApolloError)) {
        return { isGraphQLError: false, mainError: err as Error }
    }

    const { networkError } = err
    let { graphQLErrors } = err
    // fix for apollo client not handling graphQLErrors correctly on non-2xx responses
    // https://github.com/apollographql/apollo-client/issues/6222
    graphQLErrors = [
        ...graphQLErrors,
        ...((networkError &&
            'result' in networkError &&
            (typeof networkError.result === 'string'
                ? [networkError.result]
                : networkError.result?.errors)) ||
            []),
    ]

    return {
        networkError,
        graphQLErrors,
        isGraphQLError: true,
        mainError: graphQLErrors[0] || networkError,
    }
}
