import { extendTheme } from '@chakra-ui/react'
import foundations from './foundations'
import components from './components'
import { googleChartsTooltipStyles } from './global'

const theme = extendTheme({
    ...foundations,
    components,
    version: 2,
})

const themeGlobals = theme.styles.global

theme.styles.global = props => ({
    ...themeGlobals(props),
    ...googleChartsTooltipStyles,
})

export default theme
