const stat = {
    baseStyle: {
        label: {
            fontSize: 'sm',
            color: 'textSecondary',
            fontWeight: 400,
        },
        number: {
            fontFamily: 'body',
        },
    },
    sizes: {
        md: {
            number: {
                fontSize: '2rem',
                lineHeight: '125%',
            },
        },
    },
}

export default stat
