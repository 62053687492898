// taken from https://github.com/vercel/next.js/blob/canary/examples/with-apollo/lib/apolloClient.js
import { ApolloClient, from, HttpLink, InMemoryCache } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
import { destroyLoginCookies, getAccessTokenFromCookies } from '@upvestcz/common/auth'
import { CURRENCIES } from '@upvestcz/common/currency'
import { setContext } from '@apollo/client/link/context'
import { without } from 'ramda'
import { FieldFunctionOptions } from '@apollo/client/cache/inmemory/policies'
import { removeTypenameFromVariables } from '@apollo/client/link/remove-typename'
import { replace } from '../routing'
import { getApiUrl } from '../api'
import { ERROR_CODES } from '../errors'
import { ContextObjectLike, getCookieHeaderFromCtx } from '../cookies'
import { usePersistent } from '../hooks'
import { isNotUndefined } from '../ts-utils'

export const APOLLO_STATE_PROP_NAME = '__APOLLO_STATE__'

export type ApolloCacheShape = Record<string, unknown>

export interface PageProps {
    [APOLLO_STATE_PROP_NAME]: ApolloCacheShape
}

let apolloClient: ApolloClient<ApolloCacheShape>

function createApolloClient(ctx?: ContextObjectLike) {
    const ssrMode = typeof window === 'undefined'

    // Use relative URLs in the browser and absolute URLs on the server to correctly proxy client-side requests through this app
    const baseUrl = getApiUrl()

    const authLink = setContext((_, { headers }) => {
        const accessToken = getAccessTokenFromCookies(ctx)

        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : undefined),
            },
        }
    })

    const cookieLink = setContext((_, { headers }) => {
        const cookieHeader = ctx ? getCookieHeaderFromCtx(ctx) : undefined

        // return the headers to the context so httpLink can read them
        return {
            headers: {
                ...headers,
                // forward the cookie header (if called on the server)
                ...(cookieHeader ? { cookie: cookieHeader } : undefined),
            },
        }
    })

    const removeTypenameLink = removeTypenameFromVariables()

    return new ApolloClient({
        ssrMode,
        link: from([
            onError(({ graphQLErrors }) => {
                if (graphQLErrors) {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const err of graphQLErrors) {
                        // Redirect to login when the server returns an `unauthenticated` error
                        // @ts-ignore
                        if (err.code === ERROR_CODES.UNAUTHENTICATED) {
                            destroyLoginCookies(ctx)
                            replace({
                                ctx,
                                location: '/',
                            })
                        }
                        // @ts-ignore
                        if (err.code === ERROR_CODES.TOKEN_EXPIRED) {
                            destroyLoginCookies(ctx)
                            replace({
                                ctx,
                                location: null,
                                cookies: {
                                    tokenExpired: 'true',
                                },
                            })
                        }
                    }
                }
            }),
            authLink,
            cookieLink,
            removeTypenameLink,
            new HttpLink({
                uri: `${baseUrl}/graphql`,
                credentials: 'include',
            }),
        ]),
        cache: new InMemoryCache({
            typePolicies: {
                Query: {
                    fields: {
                        opportunities: {
                            // Don't cache separate results based on field's pagination arguments.
                            keyArgs: args =>
                                without(['offset', 'limit'], args ? Object.keys(args) : []),
                            merge(existing, incoming, { args }: FieldFunctionOptions) {
                                const offset = args?.offset
                                const hasOffset = isNotUndefined(offset)
                                if (hasOffset) {
                                    // this query uses pagination, merge results by place in the list
                                    const merged = existing ? existing.slice(0) : []
                                    // eslint-disable-next-line no-plusplus
                                    for (let i = 0; i < incoming.length; ++i) {
                                        merged[offset + i] = incoming[i]
                                    }
                                    return merged
                                }

                                // this query doesn't use pagination, just replace the existing list
                                return incoming
                            },
                        },
                    },
                },
                Account: {
                    fields: {
                        account_stats: {
                            merge: true,
                        },
                    },
                },
                AccountStats: {
                    fields: {
                        balance: {
                            keyArgs: args => {
                                const DEFAULT_CURRENCY_ARG = CURRENCIES.CZK
                                const currencyArg = args?.currency || DEFAULT_CURRENCY_ARG
                                // if currency arg is default, behave like there was no arg passed, otherwise use arg value
                                return currencyArg === DEFAULT_CURRENCY_ARG ? false : ['currency']
                            },
                        },
                    },
                },
            },
        }),
        defaultOptions: {
            /**
             * errorPolicy determines the level of events for errors in the execution result. The options are:
             * - none (default): any errors from the request are treated like runtime errors and the observable is stopped (XXX this is default to lower breaking changes going from AC 1.0 => 2.0)
             * - ignore: errors from the request do not stop the observable, but also don't call `next`
             * - all: errors are treated like data and will notify observables
             */
            watchQuery: {
                fetchPolicy: 'cache-and-network',
            },
            query: {
                fetchPolicy: 'network-only',
                errorPolicy: 'none',
            },
            mutate: {
                errorPolicy: 'none',
            },
        },
    })
}

export function initializeApollo(
    initialState: ApolloCacheShape | null = null,
    ctx?: Parameters<typeof createApolloClient>[0],
) {
    // eslint-disable-next-line no-underscore-dangle
    const _apolloClient = apolloClient ?? createApolloClient(ctx)

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract()

        // Merge the existing cache into data passed from getStaticProps/getServerSideProps
        const data = { ...(initialState || {}), ...existingCache }

        // Restore the cache with the merged data
        _apolloClient.cache.restore(data)
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient
    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient

    return _apolloClient
}

export function addApolloState<Props>(
    client: ApolloClient<ApolloCacheShape>,
    pageProps: Props,
): Props & { [APOLLO_STATE_PROP_NAME]: ApolloCacheShape } {
    return { ...pageProps, [APOLLO_STATE_PROP_NAME]: client.cache.extract() }
}

export function useApolloClient(pageProps: PageProps = {} as PageProps) {
    const state = pageProps[APOLLO_STATE_PROP_NAME]
    // don't re-initialize the client when pageProps change on every page navigation.
    const client = usePersistent(() => initializeApollo(state))
    return client
}
