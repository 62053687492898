import { Box, BoxProps } from '@chakra-ui/react'
import { ReactNode } from 'react'

function Nbsp({ children, ...props }: { children?: ReactNode } & BoxProps) {
    if (!children) {
        return <>&nbsp;</>
    }

    return (
        <Box as="span" whiteSpace="nowrap" {...props}>
            {children}
        </Box>
    )
}

export default Nbsp
