import { UnknownAdGroupError } from './errors'

export const KB_ROLES = {
    READ: 'read',
    BAPO: 'bapo',
    PRIV: 'priv',
    ADMIN: 'admin',
} as const

// TODO: replace in favour of `ObjectValues` helper
export type KbRole = typeof KB_ROLES[keyof typeof KB_ROLES]

export const areKBRolesSufficient = ({ account, roles }: { account: TODO; roles: KbRole[] }) => {
    // "admin" role should always suffice
    if (account.role === KB_ROLES.ADMIN) {
        return true
    }

    // fallback to lowest tier
    if (!roles.length) {
        return true
    }

    return roles.some(role => role === account.role)
}

export const KB_ORIGINAL_ROLES = {
    PRIV: process.env.KB_PRIV_ROLE,
    BAPO: process.env.KB_BAPO_ROLE,
    READ: process.env.KB_READ_ROLE,
} as const

type AdGroup = keyof typeof KB_ROLES

const getNormalizedAdGroup = (adGroup: AdGroup) => {
    if (!Array.isArray(adGroup)) {
        return adGroup
    }

    return [KB_ORIGINAL_ROLES.PRIV, KB_ORIGINAL_ROLES.BAPO, KB_ORIGINAL_ROLES.READ].find(role =>
        adGroup.includes(role),
    )
}

const mapAdGroupToRole = (adGroup: AdGroup) => {
    const normalizedAdGroup = getNormalizedAdGroup(adGroup)

    switch (normalizedAdGroup) {
        case KB_ORIGINAL_ROLES.PRIV:
            return KB_ROLES.PRIV
        case KB_ORIGINAL_ROLES.BAPO:
            return KB_ROLES.BAPO
        case KB_ORIGINAL_ROLES.READ:
            return KB_ROLES.READ
        default:
            throw new UnknownAdGroupError(`An unknown adGroup "${normalizedAdGroup}" encountered.`)
    }
}

export const getUserRole = (user: TODO) => {
    if (user.user_metadata && user.user_metadata.isAdmin === true) {
        return KB_ROLES.ADMIN
    }

    return mapAdGroupToRole(user.adGroup)
}

export const getRegistrationLink = (
    distributionPartnerAccountId: number,
    isCorporateAccount = false,
) => {
    const url = new URL(`${process.env.LP_URL}/campaign/kb-register`)

    const params = new URLSearchParams({
        id: distributionPartnerAccountId.toString(),
        ...(isCorporateAccount ? { corporate: 'true' } : {}),
    })

    url.search = params.toString()

    return url.toString()
}

export const DISTRIBUTION_PARTNER_ACCOUNT_ID_COOKIE_KEY = 'kb_distribution_partner_account_id'
