import linkStyles from './link'

const button = {
    baseStyle: {
        textStyle: 'buttonText',
        borderRadius: 'none',
        _focus: {
            boxShadow: 'none',
        },
        _disabled: {
            opacity: 1,
        },
    },
    sizes: {
        xs: {
            h: 8,
            px: 3,
        },
        sm: {
            h: 10,
            px: 5,
            fontSize: 'xssm',
        },
        md: {
            h: 12,
            px: 8,
            fontSize: 'sm',
        },
        lg: {
            h: 14,
            px: 8,
            fontSize: 'md',
        },
    },
    variants: {
        solid: {
            color: 'black',
            bgColor: 'neonPrimary',
            _hover: {
                textDecoration: 'none',
                bgColor: 'primaryLight',

                _disabled: {
                    backgroundColor: '#bbbbbb',
                },
            },
            _loading: {
                backgroundColor: '#bbbbbb',
            },
            _disabled: {
                backgroundColor: '#bbbbbb',
            },
        },
        solidWhite: {
            border: '1px solid',
            borderColor: 'steelGrey',
            bgColor: 'white',
            color: 'black',

            _hover: {
                textDecoration: 'none',

                _disabled: {
                    backgroundColor: '#bbbbbb',
                },
            },
            _loading: {
                backgroundColor: '#bbbbbb',
            },
            _disabled: {
                backgroundColor: '#bbbbbb',
            },
        },
        solidRed: {
            bgColor: '#F06160',
            color: 'white',
            boxShadow: '0px 8px 16px rgba(22, 124, 127, 0.16)',
            _focus: {
                boxShadow: '0px 8px 16px rgba(22, 124, 127, 0.16)',
            },
            _hover: {
                textDecoration: 'none',
                boxShadow: '0px 8px 24px rgba(22, 124, 127, 0.24)',

                _disabled: {
                    backgroundColor: '#bbbbbb',
                },
            },
            _loading: {
                boxShadow: '0px 8px 24px rgba(22, 124, 127, 0.24)',
                backgroundColor: '#f27372',
                color: '#fff',
            },
            _disabled: {
                boxShadow: '0px 8px 24px rgba(22, 124, 127, 0.24)',
                backgroundColor: '#bbbbbb',
                color: '#fff',
            },
        },
        outline: {
            border: '1px solid #DBDBDB',
            _hover: {
                textDecoration: 'none',
            },
        },
        link: {
            verticalAlign: 'initial',
            color: 'text',
            textTransform: 'none',
            _hover: {
                color: 'primary',
            },
        },
        plain: {
            background: 'none',
            border: 'none',
            outline: 'none',
            margin: 0,
            textTransform: 'none',
            padding: 0,
            height: 'auto',
            width: 'auto',
            minWidth: 0,
            verticalAlign: 'initial',
            _hover: {
                textDecoration: 'none',
            },
        },
        roundedSecondary: {
            borderRadius: '999px',
            backgroundColor: 'black',
            color: 'white',
            fontSize: 'lg',
            fontWeight: '700',
            px: 8,
            py: 6,
            height: '4.25rem',
            _hover: {
                backgroundColor: '#333333',
                textDecoration: 'none',
            },
        },
        get linkPrimary() {
            return {
                ...this.plain,
                color: 'primary',
                textTransform: 'none',
                fontWeight: 'bold',
                _hover: {
                    textDecoration: 'underline',
                },
                _disabled: {
                    color: '#bbbbbb',
                    _hover: {
                        textDecoration: 'none',
                    },
                },
            }
        },
        get contextualLink() {
            return {
                ...this.plain,
                ...linkStyles.variants.contextualLink,
                textTransform: 'none',
                whiteSpace: 'initial',
                textAlign: 'initial',
                _disabled: {
                    color: '#bbbbbb',
                },
            }
        },
    },
}

export default button
