import { isResponsiveObjectLike, objectToArrayNotation, isNumber } from '@chakra-ui/utils'
import { LayerStyle } from '@upvestcz/shared-components/v3'
import { ChakraTheme } from '@upvestcz/shared-components'

export type ChakraResponsiveValue<T> = T | Array<T | null> | Record<string, T | null>

const measurementRegex = /(\d+\.?\d*)/u

const calculateMeasurement = (value: number | string, modifier: number) => {
    if (typeof value === 'number') {
        return `${value + modifier}`
    }

    return value.replace(measurementRegex, match => `${parseFloat(match) + modifier}`)
}

/**
 * 0.01 and 0.1 are too small of a difference for `px` breakpoint values
 *
 * @see https://github.com/chakra-ui/chakra-ui/issues/2188#issuecomment-712774785
 */
function subtract(value: string) {
    return calculateMeasurement(value, value.endsWith('px') ? -1 : -0.01)
}

/**
 * Convert media query value to string
 */
function toMediaString(value: string | number) {
    return isNumber(value) ? `${value}px` : value
}

/**
 * Converts any chakra style value into an array mapped to breakpoints from chakra theme.
 * @param inputValue - A single value, an array, or breakpoint object with key value pairs
 * @param theme - current chakra theme
 * @returns {*[]}
 */
export const convertStylePropToArrayNotation = <T>(
    inputValue: T | T[] | Record<string, T>,
    theme: ChakraTheme,
) => {
    const bps = Object.keys(theme.breakpoints).filter(bpName => Number.isNaN(parseInt(bpName, 10))) // filter out array keys [0, 1, ..] from bp names
    const outputValue = (
        inputValue && isResponsiveObjectLike(inputValue as Record<string, T>, bps)
            ? objectToArrayNotation(inputValue as Record<string, T>, bps)
            : inputValue
    ) as T[]
    return ([] as T[]).concat(outputValue)
}

/**
 * Create a media query string from the breakpoints,
 * using a combination of `min-width` and `max-width`.
 */
export function createMediaQueryString({
    minWidth = null,
    maxWidth = null,
}: {
    minWidth?: string | null
    maxWidth?: string | null
}) {
    let query = '@media '
    if (minWidth !== null) {
        const hasMinWidth = parseInt(minWidth, 10) >= 0

        if (!hasMinWidth && !maxWidth) {
            return query
        }

        query += `(min-width: ${toMediaString(minWidth)})`
    }

    if (!maxWidth) {
        return query
    }

    if (minWidth && maxWidth) {
        query += ' and '
    }

    query += `(max-width: ${toMediaString(subtract(maxWidth))})`

    return query
}

/**
 * Converts a style prop notation to min/max breakpoint definitions with the value
 * @param styleProp - A single value, an array, or breakpoint object with key value pairs
 * @param theme - current chakra theme
 */
export const mapStylePropToBreakpoints = <T>(
    styleProp: T | T[] | Record<string, T>,
    theme: ChakraTheme,
) => {
    const stylePropArray = convertStylePropToArrayNotation(styleProp, theme)
    let lastIndex: number | null = null
    const indexedBreakpoints = Object.values(theme.breakpoints).sort((a, b) => {
        return parseInt(a, 10) - parseInt(b, 10)
    })
    return stylePropArray.reduceRight(
        (result, value, index) => {
            if (value === null) return result
            const newResult = [
                ...result,
                {
                    minWidth: indexedBreakpoints[index],
                    maxWidth: (lastIndex && indexedBreakpoints[lastIndex]) || undefined,
                    value,
                },
            ]
            lastIndex = index
            return newResult
        },
        [] as {
            minWidth: string
            maxWidth?: string
            value: T
        }[],
    )
}

/**
 *
 * @param styleProp - A single value, an array, or breakpoint object with key value pairs
 * @param theme - current chakra theme
 */
export const generateSizesAttr = (
    styleProp: string[] | Record<string, string> | string,
    theme: ChakraTheme,
) => {
    return convertStylePropToArrayNotation(styleProp, theme)
        .map((val, index) => {
            if (val === null) return null
            return `${
                index > 0
                    ? createMediaQueryString({ minWidth: theme.breakpoints[index] }).replace(
                          '@media ',
                          '',
                      )
                    : ''
            } ${val}`
        })
        .filter(val => !!val)
        .reverse()
        .join(', ')
}

export const pxToRem = (px: number | string) => `${parseFloat(px.toString()) / 16}rem`

export const withSafeArea = (value: string | number, direction = 'bottom') =>
    `calc(${toMediaString(value)} + env(safe-area-inset-${direction}, 0px))`

/*
Helpers for handling styling specific to a layer style
 */
export type WithLayerStyles<T extends Record<string, any>> = T & {
    layerStyles?: Partial<Record<LayerStyle, T>>
}

export const defineWithLayerStyles = <ST extends Record<string, any>>(
    styles: WithLayerStyles<ST>,
): WithLayerStyles<ST> => styles

export const resolveWithLayerStyles = <ST extends Record<string, any>>(
    styles?: WithLayerStyles<ST>,
    transform: (styles: ST) => Record<string, any> = styles => styles, // return the styles as is by default
) => {
    if (!styles) return {}
    const { layerStyles, ...values } = styles

    /*
        theming for individual layer styles inspired by https://github.com/chakra-ui/chakra-ui/issues/2231#issuecomment-771531813
        The styles for individual layer styles should mainly generate css variables that can be used in the baseStyle.
        That way the styles can still be ovverriden by the style props. Otherwise, the selectors generated here have a higher specificity than the style props and cannot be overriden.
     */

    return {
        ...transform(values as ST),
        ...Object.entries(layerStyles || {}).reduce((acc, [key, value]) => {
            return {
                ...acc,
                [`&[data-layerstyle="${key}"], [data-layerstyle="${key}"] &`]: transform(value),
            }
        }, {} as ST),
    }
}
